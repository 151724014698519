/**
 * Conjunto de pestañas de información de la muestra lanzado desde ResultForm
 */
import React, { useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { Tab, Tabs } from "@material-ui/core";
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import { SampleTab } from "./SampleTab";
import { GenotypeTab } from "./GenotypeTab";
import { ReportTab } from "./ReportTab";
import { AnimalTab } from "../animals/AnimalTab";
import { indexAccepted, indexAnalyzed, indexIssued, sampleStatus } from "../../config";

export function SampleTabs({ 
    data, 
    setData, 
    matching,     
    setMatching,
    matchingSel, 
    image,
    imageAnimal, 
    address, 
    setAddress, 
    isLoading, 
    setIsModified,
    setIsIssue,
    setError,
}) {
    const { user } = useContext(UserContext);    
    const [value, setValue] = useState("0");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <TabContext value={value}>
            <Tabs 
                value={value} 
                indicatorColor="primary"
                onChange={handleChange} 
                aria-label="pestañas de muestra"
            >
                <Tab label="Muestra" value="0"></Tab>
                {user.profile==="supervisor" && (data[0].animal_id > 0 || data[0].sample2_animal_id > 0) 
                ?<Tab label="Animal" value="1"></Tab>:""}
                {data[0].status===sampleStatus[indexAccepted].label || data[0].status===sampleStatus[indexAnalyzed].label || data[0].status===sampleStatus[indexIssued].label
                ?<Tab label="Genotipado" value="2"></Tab>:""}
                {(user.profile==="supervisor" && data[0].status===sampleStatus[indexIssued].label) || 
                ((user.profile==="laboratorio" && (data[0].status===sampleStatus[indexAnalyzed].label || data[0].status===sampleStatus[indexIssued].label)))
                ?<Tab label="Informe" value="3"></Tab>:""}
            </Tabs>
            <TabPanel value="0">
                <SampleTab 
                    data={data} 
                    setData={setData}
                    matchingSel={matchingSel}
                    image={image}
                    address={address}
                    setAddress={setAddress}
                    isLoading={isLoading}
                    setIsModified={setIsModified} 
                />
            </TabPanel>
            <TabPanel value="1">
                <AnimalTab 
                    data={data} 
                    image={imageAnimal}
                    isLoading={isLoading}
                />
            </TabPanel>
            <TabPanel value="2">
                <GenotypeTab 
                    data={data}
                    setData={setData}
                    matching={matching}
                    setMatching={setMatching}
                    matchingSel={matchingSel}
                    isLoading={isLoading}
                    readOnly={user.profile!=="laboratorio"}                    
                    setIsModified={setIsModified} 
                    setError={setError}
                />
            </TabPanel>
            <TabPanel value="3">
                <ReportTab 
                    data={data} 
                    setData={setData}
                    matching={matching}
                    setIsModified={setIsModified} 
                    setIsIssue={setIsIssue}
                    imageAnimal={imageAnimal}
                />
            </TabPanel>
        </TabContext>  
    )
}
