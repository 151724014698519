/**
 * Componente principal de entrada para mostrar los usuarios 
 */
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router";
import { ListForm } from "../common/ListForm";
import { UserItem } from "./UserItem";
import { UserIcon } from "./UserIcon";
import { UsersMenuFilter } from "./UsersMenuFilter";
import { fetchApi, mysqlEscape } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";
import { FloatAlert } from "../common/FloatAlert";

export function Users({ pathBack }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();

    function getQueryUsers ({ filter, search, limitPlus, offset = 0 }) {
        let where = "";
        if (filter) { 
            if (filter === "deshabilitado") { 
                where = "WHERE is_disabled = 1";
            } else {
                where = "WHERE is_disabled = 0 AND profile = '" + mysqlEscape(filter) + "'";
            }
        } else {
            where = "WHERE is_disabled = 0";
        }
        if (search) {
            const searchScape = mysqlEscape(search);
            where += " AND ((id LIKE '%"+searchScape+"%') "
                +" OR (name LIKE '%"+searchScape+"%')"
                +" OR (email LIKE '%"+searchScape+"%'))";        
        }
        return "SELECT id, name, email, profile, is_disabled FROM user " 
            + where + " ORDER BY id LIMIT " + offset + ", " + (limitPlus===0 ? 1 : limitPlus);    
    };
    
    function getTitleUsers (filter) {
        return (filter==="administrador" ? "Administradores" : 
            filter==="veterinario" ? "Veterinarios" : 
            filter==="laboratorio" ? "Analistas" : 
            filter==="supervisor" ? "Supervisores" : 
            filter==="recolector" ? "Recolectores" : 
            filter==="vigilante" ? "Vigilantes" : 
            filter==="deshabilitado" ? "Deshabilitados" : "Usuarios")
    }    
    
    function actionUserClick ({ id, pathBack, filter, history }) {
        if (filter) {
            history.push(`${pathBack}/usuarios/${id}?filter=${filter}`);
        } else {
            history.push(`${pathBack}/usuarios/${id}`);
        }    
    }

    async function deleteUser (id) {

        async function isLinked() {
            const query = "SELECT (SELECT COUNT(*) FROM sample WHERE user_id='" 
                + mysqlEscape(id) +"') + (SELECT COUNT(*) FROM animal WHERE user_id='" 
                + mysqlEscape(id) +"') + (SELECT COUNT(*) FROM block_label WHERE user_id='"
                + mysqlEscape(id) +"') as count";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function isDisabled() {
            const query = "SELECT is_disabled FROM user WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_disabled) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdateDisabled() {
            const query = "UPDATE user SET is_disabled = 1 WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchUpdateEnabled() {
            const query = "UPDATE user SET is_disabled = 0 WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM user WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
           
        if (await isDisabled()) {
            if (await fetchUpdateEnabled()) {
                // Habilitación correcta
                return true
            } else {
                // Error de API
                return false
            }
        } else {
            if (await isLinked()) {
                if (await fetchUpdateDisabled()) {
                    // Deshabilitación correcta
                    return true;              
                } else {
                    // Error de API
                    return false;
                }
            } else {
                if (await fetchDelete()) {
                    // Eliminación correcta
                    return true;              
                } else {
                    // Error de API
                    return false;
                }
            }
        }
    }

    async function actionFunction1Element (element) {
        // Eliminar si es posible
        const result = await deleteUser(element.id);
        if (result) {
            return { status: "deleted" };
        } else {
            return { status: "notdeleted" };
        }
    };

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    }    

    const textFunction1 = "Eliminar";
    const textDeleteQuestion = "¿Estás seguro de que quieres eliminar los usuarios?";
    const textDeleteDescription = "Si continúas este proceso se eliminarán los usuarios del sistema, " 
        + "salvo que tuvieran información vinculada, en cuyo caso serían deshabilidados";

    return (
        <Fragment>
            <ListForm
                filterParam={filterParam}
                pathBack={pathBack}
                pathNew="/nuevousuario"
                item={UserItem}
                icon={UserIcon}
                menu={UsersMenuFilter}
                getQuery={getQueryUsers}                    
                getTitle={getTitleUsers}
                actionItemClick={actionUserClick}                  
                actionFunction1Element={actionFunction1Element}
                textFunction1={textFunction1}
                textDeleteQuestion={textDeleteQuestion}
                textDeleteDescription={textDeleteDescription}
            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido eliminar o actualizar los usuarios. Por favor, comprueba tu conexión." 
                severity="error"
            />
        </Fragment>
    )
}
