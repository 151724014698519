/**
 * Cabecera de la orden
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import logoLabtecnic from "../../assets/logolabtecnic.png";
import moment from "moment";

const useStyles = makeStyles (() => ({
    container: {
        paddingTop: "1cm",
    },
    line: {
        display: "block",
        width: "100%",
        fontSize: "0",
        textAlign: "right",
    },
    caption: {
        display: "inline-block",
        width: "50%",
        font: "9px Arial, sans-serif",
        textAlign: "right",
    },
}));

export function HeaderWorksheet({ numPage, totalPages }) {
    const classes = useStyles();

    return (
        <div className={classes.container}> 
            <Grid container>
                <Grid item xs={8}>
                    <img src={logoLabtecnic} alt="logo Labtenic"/>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.line}>
                        <div className={classes.caption}>Registro: F-MTD-BM-O1</div>
                    </div>
                    <div className={classes.line}>
                        <div className={classes.caption}>Revisón: REV.2</div>               
                    </div>
                    <div className={classes.line}>
                        <div className={classes.caption}>Fecha: {moment(new Date()).format("DD-MM-YYYY")}</div>               
                    </div>
                    <div className={classes.line}>
                        <div className={classes.caption}>Página {numPage} de {totalPages}</div>
                    </div>
                </Grid>            
            </Grid>             
        </div>
    )
}
