/**
 * Cabecera del informe
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Barcode } from "../common/Barcode";
import logoLabtecnic from "../../assets/logolabtecnic.png";
import logoCert from "../../assets/logocert.png";

const useStyles = makeStyles (() => ({
    container: {
        paddingTop: "1cm",
    },
    center: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    line: {
        display: "block",
        width: "100%",
        fontSize: "0",
    },
    caption: {
        display: "inline-block",
        width: "50%",
        font: "9px Arial, sans-serif",
        textAlign: "right",
    },
    value: {
        display: "inline-block",
        width: "50%",
        font: "bold 10px Arial, sans-serif",
        textAlign: "right",
    }
}));

export function HeaderReport({ sampleLabel, reportId }) {
    const classes = useStyles();

    return (
        <div className={classes.container}> 
            <Grid container>
                <Grid item xs={4}>
                    <img src={logoLabtecnic} alt="logo Labtenic"/>
                </Grid>
                <Grid item xs={4}>
                    <img src={logoCert} className={classes.center} alt="logo certificados"/>
                </Grid>
                <Grid item xs={4}>
                    <Barcode 
                        value={sampleLabel}
                        isHideValue 
                    />
                    <div className={classes.line}>
                        <div className={classes.caption}>Nº de muestra:</div>
                        <div className={classes.value}>{sampleLabel}</div>               
                    </div>
                    <div className={classes.line}>
                        <div className={classes.caption}>Nº de informe:</div>
                        <div className={classes.value}>{reportId > 0 ? reportId : ""}</div>
                    </div>
                </Grid>            
            </Grid>             
        </div>
    )
}
