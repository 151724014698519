/**
 * Tarjeta para mostrar un mapa de las detecciones y muestras 
 */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../contexts/UserContext";
import { Card, CardContent } from "@material-ui/core";
import { useDataFetch, mysqlEscape } from "../../hooks/DataFetch";
import { usePosition } from "../../hooks/Position";
import { Map } from "../common/Map";
import { indexAnalyzed, indexIssued, sampleStatus } from "../../config";

const useStyles = makeStyles((theme)=> ({
    card: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0.5),
        }
    },
    content: {
        height: theme.spacing(43),
        textAlign: "center",
        marginLeft: "auto",
    },    
}))

export function CardMap() {
    const classes = useStyles();
    const history = useHistory();
    const { lat, lng } = usePosition();
    const { user } = useContext(UserContext);
    const { data } = useDataFetch({ 
        query: `SELECT id, latitude, longitude, 'Detección' as source FROM detection WHERE client_id='` 
            + mysqlEscape(user.client_id) + `' AND is_archived = 0 UNION ` 
            + `SELECT id, latitude, longitude, status as source FROM sample WHERE user_id='` 
            + mysqlEscape(user.id) + `' AND is_archived = 0 AND is_disabled = 0 ` 
            + `AND status <> '${sampleStatus[indexAnalyzed].label}' `
            + `AND status <> '${sampleStatus[indexIssued].label}' ` 
    });

    const center = { 
        lat: lat ? lat : (user.latitude ? parseFloat(user.latitude) : 0), 
        lng: lng ? lng : (user.longitude ? parseFloat(user.longitude) : 0)
    };

    const points = data 
    ? data.map (row => ({ 
        id: row.id, 
        lat: row.latitude, 
        lng: row.longitude,
        source: row.source
    })) 
    : []; 

    const handleMarkerClick = (id) => {
        if (data.filter(point => point.id === id)[0].source==="Detección") {
            history.push (`/recolector/detecciones/${id}`);
        } else {
            history.push (`/recolector/muestras/${id}`);            
        }
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                {user.latitude 
                ? <Map
                    center={center} 
                    points={points} 
                    defaultZoom={12}
                    handleMarkerClick={handleMarkerClick}
                />
                : ""}
            </CardContent>
        </Card>        
    )
}
 