/**
 * Orden de trabajo
 */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useIsMounted } from "../../hooks/IsMounted";
import { fetchApi, jsonParseMysql, mysqlEscape } from "../../hooks/DataFetch";
import { FloatAlert } from "../common/FloatAlert";
import { Loading } from "../Loading";
import { Title } from "../common/Title";
import { ReactComponent as WorksheetIcon } from '../../assets/clipboard.svg';
import { FormGroup, Paper } from "@material-ui/core";
import { FormGroupButton } from "../common/FormGroupButton";
import { Worksheet } from "./Worksheet";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",        
    },
    form: {
        maxWidth: "1200px",
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            maxWidth: "none",
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0.5),
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        width: "21cm",
        height: "29,7cm",
    }
}));

export function WorksheetForm({ pathBack }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const classes = useStyles();
    const { listId } = useParams();
    const isMounted = useIsMounted();
    const history = useHistory();    
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([{
        id: "",
        client_name: "",
        sampled: "",
        animal_type: "",
        type: "",
        block_label_id: "",
        label: "",
        serial: "",
    }]);
    const [error, setError] = useState({ open: false, status: '' });
    const worksheetRef = useRef();

    useEffect(() => {

        async function fetchLoad() {
            // Lee las muestras de entrada
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT sample.id as id, client.name as client_name, sample.sampled as sampled, " 
                    + "animal.type as animal_type, sample.type as type, " 
                    + "sample.block_label_id as block_label_id, sample.label as label, client.serial as serial " 
                    + "FROM sample " 
                    + "LEFT JOIN block_label ON (sample.block_label_id = block_label.id) "
                    + "LEFT JOIN client ON (block_label.client_id = client.id) " 
                    + "LEFT JOIN animal ON (sample.animal_id = animal.id) " 
                    + "WHERE sample.id IN (" + mysqlEscape(listId) +")" 
            }); 
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                if (isMounted) {
                    setData(readData);
                }
            };
            if (isMounted) setIsLoading(false);
        } 

        listId && fetchLoad();
    }, [listId, isMounted])    

    function exit() {
        if (filterParam) {
            history.push (`${pathBack}/${data[0].type==="Saliva"?"muestrassaliva":"muestrasheces"}?filter=${filterParam}`);
        } else {
            history.push (`${pathBack}/${data[0].type==="Saliva"?"muestrassaliva":"muestrasheces"}`);
        }
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }

    const handlePrint = useReactToPrint({
        content: () => worksheetRef.current,
    });    

    const handleExit = () => {
        // Botón salir
        exit();
    }    

    if (isLoading) {
        return <Loading />
    } else {   
        return (
            <div className={classes.container}>
                <Title
                    text="Orden de trabajo" 
                    icon={WorksheetIcon} 
                    pathBack={`${pathBack}/${data[0].type==="Saliva"?"muestrassaliva":"muestrasheces"}`}
                />
                <FormGroup className={classes.form}>
                    <Paper className={classes.paper}>
                        <Worksheet 
                            ref={worksheetRef} 
                            data={data}
                        />
                    </Paper>
                    <FloatAlert
                        open={error.open} 
                        onClose={handleErrorClose} 
                        text="No hemos podido leer correctamente de la base de datos. Comprueba tu conexión." 
                        severity="error"
                    />      
                </FormGroup>
                <FormGroupButton 
                    disabledApply={isLoading}
                    hideDelete={true}
                    handleApplyClick={handlePrint} 
                    handleExitClick={handleExit} 
                    printMode={true}
                />                              
            </div>
        )
    }
};
