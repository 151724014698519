/**
 * Listado de coincidencias
 */
import React, { Fragment, useContext, useState } from "react";
import { useLocation } from "react-router";
import { UserContext } from "../../contexts/UserContext";
import { ListForm } from "../common/ListForm";
import { fetchApi, mysqlEscape, mysqlInteger } from "../../hooks/DataFetch";
import { indexIssued, sampleStatus } from "../../config";
import { getSelect } from "../../utils/queryUtils";
import { MatchIcon } from "./MatchIcon";
import { MatchesMenuFilter } from "./MatchesMenuFilter";
import { FloatAlert } from "../common/FloatAlert";
import { useIsMounted } from "../../hooks/IsMounted";

export function Matches({ pathBack }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const { user } = useContext(UserContext);        
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();

    const table = "sample";
    const defaultColumns = [
        { id: "checkbox", width: 50, type: "checkbox"},
        { id: "type", width: 50, type: "icon" },
        { id: "sampleSaliva_label", label: "M. Saliva", table: "sampleSaliva", fieldName: "label", width: 130, type: "labelSSaliva"},
        { id: "label", label: "M. Heces", width: 130, type: "labelS"},
        { id: "date_end", label: "F. análisis", width: 120, type: "date" },
        { id: "animal_label", label: "Animal", width: 130, table: "animalSaliva", fieldName: "label", type: "labelASaliva" },
        { id: "animal_name", label: "Nombre animal", width: 150, table: "animalSaliva", fieldName: "name" },
        { id: "animal_owner", label: "Propietario", width: 140, table: "animalSaliva", fieldName: "owner"},
        { id: "animal_phone", label: "Teléfono", width: 120, table: "animalSaliva", fieldName: "phone" },
        { id: "animal_death", label: "F. baja.", width: 120, table: "animalSaliva", fieldName: "death", type: "date" },
        { id: "observations", label: "Observaciones", width: 250 },
    ]

    function getQuerySamples ({ filter, search, limitPlus, offset = 0, columns, orderBy, orderDirection }) { 
        const whereArchived = `AND ${table}.is_archived = ${filter && filter === "archivada" ? 1 : 0}`;
        const where = ` WHERE (client.id = '${mysqlEscape(user.client_id)}' `
            + `AND sample.sample_id > 0 AND sample.type = 'Heces' AND sample.status = '${sampleStatus[indexIssued].label}' ${whereArchived})`;        
        const leftJoin = " " 
            + "LEFT JOIN sample as sampleSaliva ON (sample.sample_id = sampleSaliva.id) "
            + "LEFT JOIN animal as animalSaliva ON (sampleSaliva.animal_id = animalSaliva.id) " 
            + "LEFT JOIN user ON (sample.user_id = user.id) "             
            + "LEFT JOIN client ON (user.client_id = client.id) " 
            + "LEFT JOIN user as userSaliva ON (sampleSaliva.user_id = userSaliva.id) "             
            + "LEFT JOIN client as clientSaliva ON (userSaliva.client_id = clientSaliva.id) ";
        const fixedFields = ", " 
            + table +".block_label_id as sample_block_label_id, " 
            + "sampleSaliva.block_label_id as sample_block_label_idSaliva, " 
            + "animalSaliva.block_label_id as animal_block_label_idSaliva, " 
            + "client.serial, " 
            + "clientSaliva.serial as serialSaliva, " 
            + "sample.id as id";
        const descending = true;
        return getSelect({ 
            columns, 
            defaultColumns, 
            table, 
            leftJoin, 
            where, 
            search, 
            fixedFields, 
            offset, 
            limitPlus, 
            descending, 
            orderBy, 
            orderDirection 
        }); 
    };

    function getTitleSamples () {
        return ("Coincidencias");
    };    
    
    function actionSampleClick ({ id, pathBack, filter, history }) {
        if (filter) {
            history.push(`${pathBack}/coincidencias/${id}?filter=${filter}`);
        } else {
            history.push(`${pathBack}/coincidencias/${id}`);
        }    
    };

    async function archiveSample (id) {

        async function fetchUpdate(query) {
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        async function isSampleArchived() {
            const query = "SELECT is_archived FROM sample WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return false;   
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_archived) === 1) {
                    return true;
                } else {
                    return false;
                }
            }
        }         

        if (await isSampleArchived()) {
            await fetchUpdate(`UPDATE sample SET is_archived = 0 WHERE id=${mysqlInteger(id)}`);
        } else {
            await fetchUpdate(`UPDATE sample SET is_archived = 1 WHERE id=${mysqlInteger(id)}`);
        }
        return true;
    }
    
    async function actionFunction1Element (element) {
        // Archivar
        await archiveSample(element.id);
        return { status: "archived" };
    };

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    }    

    const textFunction1 = "Archivar";    

    return (
        <Fragment>
            <ListForm 
                filterParam={filterParam}
                pathBack={pathBack}
                icon={MatchIcon}
                menu={MatchesMenuFilter} 
                getQuery={getQuerySamples} 
                getTitle={getTitleSamples}
                actionItemClick={actionSampleClick}
                defaultColumns={defaultColumns}
                colors={sampleStatus}
                actionFunction1Element={actionFunction1Element}
                textFunction1={textFunction1}
            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido eliminar o actualizar las muestras. Por favor, comprueba tu conexión." 
                severity="error"
            />            
        </Fragment>        
    )

}
