/**
 * Tarjeta para mostrar el número de usuarios de un determinado perfil
 */
import React from "react";
import { useHistory } from "react-router-dom";
import { SvgIcon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as AdminIcon } from '../../assets/security_agent.svg';
import { ReactComponent as VeterinaryIcon } from '../../assets/doctor.svg';
import { ReactComponent as AnalystIcon } from '../../assets/scientist.svg';
import { ReactComponent as SupervisorIcon } from '../../assets/businesswoman2.svg';
import { ReactComponent as CollectorIcon } from '../../assets/delivery_man.svg';
import { ReactComponent as VigilantIcon } from '../../assets/policeman.svg';
import { useDataFetch, mysqlEscape } from "../../hooks/DataFetch";
import { CardDashboard } from "../common/CardDashboard";
import { viewBox } from "../../config";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

const useStyles = makeStyles((theme)=> ({
    typography: {
        fontSize: 150,
        [theme.breakpoints.down('sm')]: {
            fontSize: 120,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 35,
        }
    },
    typographySmall: {
        fontSize: 35,
    }
}))

export function CardUserCounter({ profile }) {
    const classes = useStyles();
    const history = useHistory();
    const windowDim = useWindowDimensions();
    const { data } = useDataFetch({ 
        query: "SELECT count(*) as count FROM user WHERE profile='" 
            + mysqlEscape(profile) + "' AND is_disabled = 0" 
    });
    let avatar = undefined;
    let title = "";
    switch (profile) {
        case "administrador":
            avatar = AdminIcon; 
            title = "Administradores"           
            break;
        case "veterinario":
            avatar = VeterinaryIcon; 
            title = "Veterinarios"           
            break;
        case "laboratorio":
            avatar = AnalystIcon; 
            title = "Analistas"           
            break;
        case "supervisor":
            avatar = SupervisorIcon; 
            title = "Supervisores"           
            break;
        case "recolector":
            avatar = CollectorIcon; 
            title = "Recolectores"           
            break;
        case "vigilante":
            avatar = VigilantIcon; 
            title = "Vigilantes"           
            break;
        default:
            break;
    }

    const handleClick = () => {
        history.push (`/administrador/usuarios?filter=${profile}`)
    }

    return (
        <CardDashboard
            icon={<SvgIcon component={avatar} viewBox={viewBox}/>}
            title={title}
            subtitle="Registrados"
            handleClick={handleClick}
            shortXs={true}
        >            
            <Typography 
                className={windowDim.height <= 800 ? classes.typographySmall : classes.typography} 
                color="primary" 
                variant="h1"
            >
                {data[0] ? data[0].count : <Skeleton variant="text" />}
            </Typography>
        </CardDashboard>
    )
}
