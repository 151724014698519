/**
 * Campo de informe
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles ((theme) => ({
    container: {
        display: "flex",
    },
    common: {
        borderColor: theme.palette.primary.main,
        border: "1px solid",
        borderRadius: "3px",
        font: "11px Arial, sans-serif",
        height: theme.spacing(2.5),
        marginLeft: theme.spacing(0.3),
        marginBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.4),
        paddingBottom: theme.spacing(0.3),
        paddingLeft: theme.spacing(0.5),      
    },
    caption: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: theme.spacing(13),
    },
    large: {
        width: theme.spacing(63.7),
    },    
    medium: {
        width: theme.spacing(35),
    },    
    short: {
        width: theme.spacing(15),
    },    
}));

export function FieldWorksheet({ caption, value, widthType }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={clsx(classes.common, classes.caption)}>
                {caption ? caption : ""}
            </div>
            <div className={
                widthType==="short" 
                ? clsx(classes.common, classes.short) 
                : widthType ==="medium" 
                    ? clsx(classes.common, classes.medium) 
                    : clsx(classes.common, classes.large)}>
                {value ? value : ""}
            </div>
        </div>
    )
}
