/**
 * Mapa de ubicación de informe
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MapStatic } from "../common/MapStatic";

const useStyles = makeStyles ((theme) => ({
    container: {
        height: theme.spacing(14.5),
        width: theme.spacing(28),
        marginLeft: theme.spacing(0.3),
    },
    caption: {
        borderColor: theme.palette.primary.main,
        border: "1px solid",
        borderRadius: "3px",
        font: "11px Arial, sans-serif",
        height: theme.spacing(2.5),
        marginLeft: theme.spacing(0.3),
        marginBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.4),
        paddingBottom: theme.spacing(0.3),
        paddingLeft: theme.spacing(0.5),      
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: theme.spacing(28),
    },    
}));

export function MapReport({ latitude, longitude }) {
    const classes = useStyles();
    let location = `${parseFloat(latitude)},${parseFloat(longitude)}`;

    return (
        <div className={classes.container}>
            <div className={classes.caption}>Ubicación</div>
            <MapStatic location={location}/>
        </div>
    )
}
