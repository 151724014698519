/**
 * Lista con scroll infinito y renderización virtualizada
 */
import React, { useCallback } from "react";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from '@material-ui/core/colors';
import { itemHeight } from "../../config";
import { limit } from "../../config";

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: "white",
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.background.default 
        },        
    },
    avatar: {
        backgroundColor: blueGrey[100],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: blueGrey[800]
        },
    },
    item: {
        height: `${itemHeight}px`,
    },
}));

export function InfiniteList({ 
    data, 
    multiselect,
    loadMoreItems, 
    hasNextPage, 
    handleClose, 
    item:Item, 
    icon:Icon, 
    height, 
    longPressEvent 
}) {
    const classes = useStyles();
    const itemCount = hasNextPage ? data.length + limit : data.length;
    const isItemLoaded = useCallback(index => !!data[index], [data]);

    function Row ({ index, style }) {
        return (
            <div key={index} style={style}>
                <Item              
                    rowData={data[index]} 
                    multiselect={multiselect}
                    icon={Icon} 
                    handleClose={handleClose}
                    classes={classes}
                    longPressEvent={longPressEvent}
                />
            </div>
        )    
    }    

    return (
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={limit}
        >
            {({ onItemsRendered, ref}) => (
                <FixedSizeList
                    ref={ref} 
                    height={height} 
                    itemCount={itemCount}
                    itemSize={itemHeight}
                    onItemsRendered={onItemsRendered}
                    className={classes.paper}
                >
                    {Row}
                </FixedSizeList>
            )}
        </InfiniteLoader>
    )
}
