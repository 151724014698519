/**
 * Dashboard para el perfil supervisor
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { CardMap } from "./CardMap";
import { CardSamples } from "./CardSamples";
import { CardAnimals } from "./CardAnimals";

const useStyles = makeStyles((theme)=> ({
    root: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5),
        }
    }
}))

export function DashboardSupervisor() {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={12} md={12}><CardMap /></Grid>
            <Grid item xs={12} sm={6} md={6}><CardSamples /></Grid>
            <Grid item xs={12} sm={6} md={6}><CardAnimals /></Grid>
        </Grid>
    )
}
