/**
 * Item de grupo de etiqueta
 */
import React from "react";
import { ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Checkbox } from "@material-ui/core";

export function LabelItem({ 
    rowData, 
    multiselect,
    icon:Icon, 
    classes,
    longPressEvent
}) {        
    return (
        <div>
            <ListItem 
                className={classes.item} 
                button 
                dense 
                name={rowData ? rowData.id : ""} 
                {...longPressEvent}
            >
                { multiselect 
                ?<Checkbox 
                    color="primary" 
                    value={rowData ? rowData.id : ""} 
                    checked={rowData ? rowData.checkbox : false} 
                />
                : ""}                
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <Icon selector={rowData ? rowData.type : ""}/>
                    </Avatar>                    
                </ListItemAvatar>
                <ListItemText
                    primary={rowData ? rowData.id + " - " + rowData.client_name : ""}
                    secondary={rowData ? rowData.count + " Etiquetas" + (rowData.user_name ? " - " + rowData.user_name : "") : ""}
                />
            </ListItem>
            <Divider />            
        </div>
    )
}
