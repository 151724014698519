/**
 * Conjunto de funciones y hook para gestionar la API
 */
import { useEffect, useState } from "react";
import { baseUrl } from "../config";

export const apiUrl = `${baseUrl}/api`;
export const apiKey = "540f269b9f34c48ccd8593bf23e2c79a";

export function mysqlSpanishDecimal (string) {
    // Convierte una cadena con un número en formato de España a float
    const result = parseFloat(string.replaceAll(".", "").replaceAll(",", "."));
    return isNaN(result) ? 0 : result;
}

export function mysqlEscape(stringToEscape) {
    /**
     * Escapa las cadenas para ser utilizas en consultas sql
     */
    if (stringToEscape) {
        return  stringToEscape
        .replaceAll("\0", "\\0")
        .replaceAll("\\", "\\\\")        
        .replaceAll("'", "\\'")
        .replaceAll("\"", "\\\"")
        .replaceAll("%", "\\%")
        .replaceAll("\n", "\\n")
        .replaceAll("\r", "\\r")
        .replaceAll("\x00", "\\\x00")
        .replaceAll("\x08", "\\b")
        .replaceAll("\x09", "\\t")
        .replaceAll("\x1a", "\\z");
    } else {
        return "";
    }
}

export function mysqlInteger(stringToInt) {
    /**
     * Convierte cualquier cadena a número entero o cero si no procede
     */
    return parseInt(stringToInt) || 0;
}

export function mysqlFloat(stringToFloat) {
    /**
     * Convierte cualquier cadena a número flotante o cero si no procede
     */
     return parseFloat(stringToFloat) || 0;
}

export function jsonParseMysql(data) {
    /**
     * Convierte los valores booleanos obtenidos de la API
     */
    return JSON.parse(data, function (k,v) {
        if (k.substring(0,3) === "is_") {
            switch (v) {
                case "1":
                    return true;
                case "0":
                    return false;            
                default:
                    return v;
            } 
        } else {
            return v;
        }
    })
}

export async function fetchApi(data) {
    /**
     * Realiza la petición fetch a la API con la autorización
     */
    if (navigator.onLine) { 
       return fetch(`${apiUrl}/index.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${apiKey}`
            },
            body: JSON.stringify(data)   
        })        
        .then(res => res.json())
        .catch(() => ({ 
            status : "500",
            message: "El servicio no está disponible. Inténtalo más tarde." 
        }));
    } else {
        return { 
            status : "500",
            message: "Error de conexión. Por favor, comprueba tu conexión." 
        }
    }
} 

export function useDataFetch({ query }) {
    /**
     * Hook para gestionar la carga inicial de datos desde la API
     */
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState({ open: false, status: '' });

    useEffect (() => {
        async function fetchLoad() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ query: query }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                if (isMounted) setData(jsonParseMysql(response.data));
            };
            if (isMounted) setIsLoading(false);
        }
        let isMounted = true;
        fetchLoad();
        return () => { isMounted = false };      
    },[query])

    return { data, setData, error, setError, isLoading }
}
