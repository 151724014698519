/**
 * Conjunto de pestañas de información para la ficha de animal vista desde el usuario supervisor
 */
import React, { Fragment, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import { GenotypeTab } from "../samples/GenotypeTab";
import { AnimalTab } from "./AnimalTab";

export function AnimalTabs({ 
    data, 
    setData,
    matching, 
    setMatching,    
    matchingSel,
    image,
    isLoading, 
    setError,
}) {
    const [value, setValue] = useState("0");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <TabContext value={value}>
            <Tabs 
                value={value} 
                indicatorColor="primary"
                onChange={handleChange} 
                aria-label="pestañas de animal"
            >
                <Tab label="Animal" value="0"></Tab>
                {data[0].sample_id ? <Tab label="Genotipado" value="1"></Tab> : ""}
            </Tabs>              
            <TabPanel value="0">
                <AnimalTab 
                    data={data} 
                    image={image}
                    isLoading={isLoading}
                />
            </TabPanel>
            {data[0].sample_id ? 
            <Fragment>
                <TabPanel value="1">
                    <GenotypeTab 
                        data={data}
                        setData={setData}
                        matching={matching}
                        setMatching={setMatching}
                        matchingSel={matchingSel}
                        isLoading={isLoading}
                        readOnly={true}
                        setError={setError}
                    />
                </TabPanel>
            </Fragment>
            : ""}
        </TabContext>                               
    )
}
