/**
 * Componente principal de entrada para mostrar las detecciones 
 */
import React, { Fragment, useContext, useState } from "react";
import { useLocation } from "react-router";
import { UserContext } from "../../contexts/UserContext";
import { ListForm } from "../common/ListForm";
import { DetectionIcon } from "./DetectionIcon";
import { DetectionsMenuFilter } from "./DetectionsMenuFilter";
import { fetchApi, mysqlEscape, mysqlInteger } from "../../hooks/DataFetch";
import { getSelect } from "../../utils/queryUtils";
import { useIsMounted } from "../../hooks/IsMounted";
import { FloatAlert } from "../common/FloatAlert";

export function Detections({ pathBack }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const { user } = useContext(UserContext);    
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();
    
    const table = "detection";
    const defaultColumns = [
        { id: "checkbox", width: 50, type: "checkbox"},
        { id: "id", label: "Id.", width: 70},
        { id: "date", label: "Fecha", width: 100, type: "date" },
        { id: "address", label: "Dirección", width: 150 },
        { id: "is_attached", label: "Foto", width: 70, type: "attached" },
        { id: "observations", label: "Observaciones", width: 300 },
    ]
    // Cuando se accede como supervisor se muestra el detector
    if (user.profile!=="vigilante") defaultColumns.push({ id: "name", label: "Detector", width: 150, table: "user" })

    function getQueryDetections ({ filter, search, limitPlus, offset = 0, columns, orderBy, orderDirection }) {
        const whereForeign = user.profile!=="vigilante" 
            ? user.client_id !== "" ? " AND " + table + ".client_id = '" + mysqlEscape(user.client_id) + "'" : ""
            : " AND user_id = '" + mysqlEscape(user.id) + "'"            
        const whereArchived = filter === "archivada" ? table + ".is_archived = 1" : table + ".is_archived = 0"        
        let where = ` WHERE (${whereArchived}${whereForeign})`;        
        const leftJoin = user.profile!=="vigilante" ? " LEFT JOIN user ON (" + table + ".user_id = user.id) " : "";
        const fixedFields = `, ${table}.is_archived`;
        return getSelect({ 
            columns, 
            defaultColumns, 
            table, 
            leftJoin, 
            where, 
            search, 
            fixedFields, 
            offset, 
            limitPlus, 
            orderBy, 
            orderDirection 
        }); 
    };

    function getTitleDetections (filter) {
        return (filter==="archivada" ? "Archivadas" : "Detecciones")
    };    
    
    function actionDetectionClick ({ id, pathBack, filter, history }) {
        if (filter) {
            history.push(`${pathBack}/detecciones/${id}?filter=${filter}`);
        } else {
            history.push(`${pathBack}/detecciones/${id}`);
        }    
    };;

    async function deleteDetection (id) {

        async function fetchDelete() {
            const query = "DELETE FROM detection WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        if (await fetchDelete()) {
            // Eliminación correcta
            return true;              
        } else {
            // Error de API
            return false;
        }
    }

    async function archiveDetection (id) {

        async function fetchUpdate(query) {
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        async function isDetectionArchived() {
            const query = "SELECT is_archived FROM detection WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return false;   
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_archived) === 1) {
                    return true;
                } else {
                    return false;
                }
            }
        }  

        if (await isDetectionArchived(id)) {
            await fetchUpdate(`UPDATE detection SET is_archived = 0 WHERE id=${mysqlInteger(id)}`);
        } else {
            await fetchUpdate(`UPDATE detection SET is_archived = 1 WHERE id=${mysqlInteger(id)}`);
        }
        return true;
    }

    async function actionFunction1Element (element) {
        switch (user.profile) {
            case "vigilante":
                // Eliminar 
                await deleteDetection(element.id);
                return { status: "deleted" };
            default:
                // Archivar
                await archiveDetection(element.id);
                return { status: "archived" };
        }
    };

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    }    

    const textFunction1 = user.profile==="vigilante"?"Eliminar":"Archivar"
    const textDeleteQuestion = user.profile==="vigilante"
        ?"¿Estás seguro de que quieres eliminar las detecciones?":"";
    const textDeleteDescription = user.profile==="vigilante"
        ?"Si continúas este proceso se eliminará la detección del sistema":"";

    return (
        <Fragment>
            <ListForm 
                filterParam={filterParam}
                pathBack={pathBack}
                pathNew={user.profile === "vigilante" ? "/nuevadeteccion" : ""}
                icon={DetectionIcon}
                menu={DetectionsMenuFilter} 
                getQuery={getQueryDetections} 
                getTitle={getTitleDetections}
                actionItemClick={actionDetectionClick}
                defaultColumns={defaultColumns}
                actionFunction1Element={actionFunction1Element}
                textFunction1={textFunction1}
                textDeleteQuestion={textDeleteQuestion}
                textDeleteDescription={textDeleteDescription}

            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido eliminar o actualizar las detecciones. Por favor, comprueba tu conexión." 
                severity="error"
            />
        </Fragment>
    )

}
