/**
 * Campo de etiqueta que visualiza la etiqueta completa
 */
import React, { Fragment } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Card, Chip, Grid, Slider, Typography } from "@material-ui/core";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { LabelView } from "./LabelView";
import { maxBlockLabel, heighLabel, widthLabel, prefixAnimal, prefixFeces, prefixSaliva } from "../../config";

const useStyles = makeStyles ((theme) => ({
    field: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    border: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        border: "1px dashed",
        borderRadius: "5px",
        borderColor: theme.palette.grey[400],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            borderColor: theme.palette.grey[600],
        }
    },    
    chip: {
        margin: theme.spacing(1),
    },
    subtitle: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    card: {
        width: widthLabel,
        height: heighLabel,
    },
    printContainer: {
        display: "none",
    },
    button: {
        marginTop: theme.spacing(2),
    },
}))

export function LabelField({ data, clients, handleSliderChange, isNew }) {
    const classes = useStyles();
    const windowDim = useWindowDimensions();

    function valuetext(value) {
        return `${value}`;
    } 

    function zeroFill(number, width) {
        // Rellena con ceros a la  izquierda hasta completar el ancho
        const length = number.toString().length;  
        const zero = "0";

        if (width <= length) {
            return number.toString(); 
        } else {
            return ((zero.repeat(width - length)) + number.toString()); 
        }
    }

    const marks = [];
    for (let i = 1; i<=10; i++) {
        marks.push({ 
            value: maxBlockLabel*i, 
            label: windowDim.width > 400 ? maxBlockLabel*i : "" 
        });       
    } 
    const prefixType = 
        data[0] && data[0].type ==="Animal" ? `${prefixAnimal}-` : 
        data[0] && data[0].type ==="Saliva" ? `${prefixSaliva}-` : 
        data[0] && data[0].type ==="Heces" ? `${prefixFeces}-` :"";
    const clientsFilter = data[0] && clients.filter(client => (client.value===data[0].client_id));
    const serial = clientsFilter[0] && clientsFilter[0].serial;
    const serialFormat = serial ? serial+"-" : "";
    const block = zeroFill(data[0].id, 5)+"-";
    const start = "001";
    const end = zeroFill(data[0].count, 3);
    const client = clientsFilter[0] && clientsFilter[0].label;

    async function printLabel (label) {
        if (document.getElementById(label)) {
            let printWindow = window.open('', 'PRINT');
            printWindow.document.write('<!DOCTYPE html>');
            printWindow.document.write('<html lang="es">');
            printWindow.document.write('<head>');
            printWindow.document.write('<style type="text/css" media="print">');
            printWindow.document.write('@page { size: 215mm 297.2mm; margin: 6mm 0 0 0; }');
            printWindow.document.write('html, body { width: 215mm; height: 100%; }');
            printWindow.document.write('</style>');
            printWindow.document.write('<meta charset="utf-8">');
            printWindow.document.write('<title>Impresión de etiquetas Labtegen</title>');        
            printWindow.document.write('</head>');
            printWindow.document.write('<body>');
            printWindow.document.write('<div>');
            printWindow.document.write(document.getElementById(label).innerHTML);
            printWindow.document.write('</div>');
            printWindow.document.write('</body>');    
            printWindow.document.close(); // necessary for IE >= 10
            printWindow.focus(); // necessary for IE >= 10
            printWindow.print(); 
            printWindow.close();
            return true;
        }
    }    

    async function printClick() {
        await printLabel ("label");
    } 

    const handlePrintClick = () => {
        window.name = "parent";
        printClick();
    }    

    const arrayLabels = [];
    for (let i=1; i<=data[0].count; i++) {
        arrayLabels.push(i);
    }    

    return (
        <Fragment>
            <div className={clsx(classes.field, classes.border)} >
                <Typography variant="h6" color="primary">
                    Número de etiquetas
                </Typography>
                <Box>
                    <Slider 
                        arial-label="Número de etiquetas"
                        defaultValue={maxBlockLabel}
                        value={parseInt(data[0] && data[0].count !== undefined ? data[0].count : maxBlockLabel)}
                        getAriaValueText={valuetext}
                        onChange={handleSliderChange}
                        step={maxBlockLabel}
                        min={maxBlockLabel}
                        max={maxBlockLabel*10}
                        marks={marks}
                        valueLabelDisplay="auto"
                    />
                </Box>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="caption" color="primary">
                            Inicial:
                        </Typography>
                        <Chip 
                            className={classes.chip} 
                            label={`${prefixType}${serialFormat}${block}${start}`}
                        />                           
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption" color="primary">
                            Final:
                        </Typography>
                        <Chip 
                            className={classes.chip} 
                            label={`${prefixType}${serialFormat}${block}${end}`}
                        />
                    </Grid>
                </Grid>            
                <Typography variant="h6" color="primary" className={classes.subtitle}>
                    Formato de etiqueta
                </Typography>
                <Card className={classes.card}>
                    <LabelView 
                        code={`${prefixType}${serialFormat}${block}${start}`} 
                        type={data[0] && data[0].count !== undefined ? data[0].type : ""}
                        client={client==="(ninguno)" ? "" : client} 
                        block={parseInt(block)}
                        label={parseInt(start)}
                    />
                </Card>
                {/* Sección oculta en pantalla sólo para visualización en impresora de las etiquetas */}
                <div id="label" className={classes.printContainer}>
                    <div style={{marginTop: "-2mm"}}> 
                        { arrayLabels.map((value, i) => {
                            return <LabelView 
                                key={i}
                                code={`${prefixType}${serialFormat}${block}${zeroFill(i+1, 3)}`}
                                type={data[0] && data[0].count !== undefined ? data[0].type : ""}
                                client={client==="(ninguno)" ? "" : client} 
                                block={parseInt(block)}
                                label={i+1}
                            />
                        })}
                    </div>
                </div>
            </div>
            <Button
                className={classes.button} 
                color="primary" 
                variant="outlined"
                onClick={handlePrintClick}
                disabled={isNew}
            >
                Imprimir etiquetas
            </Button>                            
        </Fragment>
    )
}
