/**
 * Icono de checked
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Icon } from '../../assets/check.svg';
import { viewBox } from "../../config";

export function CheckedIcon() {
    return (
        <SvgIcon  component={Icon} viewBox={viewBox} />
    )
}
