/**
 * Tabla con scroll infinito y renderización virtualizada
 */
import React, { useCallback, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import InfiniteLoader from "react-window-infinite-loader";
import moment from 'moment';
import clsx from "clsx";
import memoize from "memoize-one";
import { FixedSizeList } from "react-window";
import { Table, TableBody, TableHead, TableCell, TableRow, TableContainer, Avatar, Checkbox, TableSortLabel } from "@material-ui/core";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from '@material-ui/core/colors';
import { rowHeight, rowTitleHeight, limit, prefixAnimal, prefixFeces, prefixSaliva, sampleStatus, indexIssued } from "../../config";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { AttachedIcon } from "./AttachedIcon";
import { CheckedIcon } from "./CheckedIcon";
import { ImportedIcon } from "./ImportedIcon";
import { WarningIcon } from "./WarningIcon";
import { DangerIcon } from "./DangerIcon";
import { zeroFill } from "../../utils/StringUtils";

export const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.background.default 
        },         
    },
    root: {
        display: "block",
        flex: 1,
        overflow: "hidden"
    },
    table: {
        height: "100%",
        width: "100%"
    },
    list: {},
    thead: {},
    tbody: {
        cursor: "pointer",
        width: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        boxSizing: "border-box",
        userSelect: "none",
        minWidth: "100%",
        width: "100%",
        "&:hover": {
            backgroundColor: `${theme.palette.action.hover} !important`,
        }
    },
    headerRow: {},
    cell: {
        display: "flex",
        alignItems: "center",
        flexGrow: 0,
        flexShrink: 0,
    },
    expandingCell: {
        flex: 1
    },
    title: {
        fontSize: "105%",
    },
    item: {
        paddingTop: "5px",
        paddingLeft: "10px",
        margin: 0,
    },
    containerCell: {
        paddingLeft: "12px"
    },
    fontSmall: {
        fontSize: "75%"
    },
    avatar: {
        backgroundColor: blueGrey[100],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: blueGrey[800]
        },
    },
}));

const createItemData = memoize((classes, columns, data) => ({ classes, columns, items: data }));

export function InfiniteTable ({ 
    data, 
    columns, 
    orderBy,
    orderDirection,
    loadMoreItems, 
    hasNextPage, 
    icon:Icon, 
    height, 
    colors,
    longPressEvent,
    onRequestSort
}) {    
    const { user } = useContext(UserContext);    
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const classes = useStyles();
    const itemData = createItemData(classes, columns, data);
    const itemCount = hasNextPage ? data.length + limit : data.length;
    const isItemLoaded = useCallback(index => !!data[index], [data]);
    const windowDim = useWindowDimensions();
    const theme = useTheme();
    moment.locale('es');

    function Title ({ classes, columns, onRequestSort, orderBy, orderDirection }) {

        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableRow component="div" className={clsx(classes.row, classes.headerRow)}>
                {columns.map((column, colIndex) => (
                    <TableCell                    
                        key={colIndex}
                        component="div"
                        variant="head"
                        align={column.numeric || column.units || false ? "right" : "left"}
                        className={clsx(
                            classes.cell,
                            classes.title,
                            !column.width && classes.expandingCell
                        )}
                        style={{
                            flexBasis: column.width || false,
                            height: rowTitleHeight
                        }}
                        scope="col"
                    >
                        <TableSortLabel
                            active={orderBy === (colIndex + 1)}
                            direction={orderDirection}
                            onClick={createSortHandler(colIndex + 1)}
                            hideSortIcon={column.id === 'type' || column.id === 'checkbox'}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        );
    }   
    
    function getPropColor ({ item, columns}) {
        // Obtiene la propiedad de color de fondo para la fila si procede
        let propColor = {};
        const arrayColor = columns.filter(column => column.type === "color");
        if (arrayColor.length > 0 ) {
            const fieldColor = arrayColor[0].id;
            const colorRow = colors.filter(color => color.label === item[fieldColor])[0];
            if (colorRow) {
                propColor = { backgroundColor : (prefersDarkMode ? colorRow.darkColor : colorRow.lightColor) };
            }
        }        
        return propColor;
    }

    const Row = ({ index, style, data: { classes, columns, items } }) => {
        const item = items[index];
        if (!item) return ""; 
        const propColor = getPropColor({ item, columns }); 
        return (
            <TableRow 
                component="div" 
                className={classes.row} 
                style={{...style, ...propColor }} 
                hover
                name={item.id} 
                {...longPressEvent}
            >
                {columns.map((column, colIndex) => (
                    <TableCell       
                        key={item.id + colIndex}                                        
                        component="div"
                        variant="body"
                        align={column.numeric || column.units || false ? "right" : "left"}
                        className={clsx(classes.cell, !column.width && classes.expandingCell)}
                        style={{flexBasis: column.width || false, height: rowHeight}}
                        padding="none"
                    >
                        <div className={clsx(
                            classes.containerCell, 
                            // La proporción 3.85 es estimada a partir de los píxeles y el ancho de texto 
                            // Cuando la columna es la última no tiene ancho y en pantalla reducida se considera 30                 
                            item[column.id] 
                            ? (
                                (
                                    (item[column.id].length > (column.width/3.85)) || 
                                    ((windowDim.width <= theme.breakpoints.values.sm) && !column.width && item[column.id].length > 30)
                                ) 
                                ? classes.fontSmall 
                                : ""
                            ) 
                            : ""
                        )}>
                            {
                                column.type === "checkbox"
                                ? <Checkbox color="primary" value={item.id} checked={item[column.id]} />
                                : (
                                    column.type === "labelA"
                                    ? ( item[column.id] > 0 
                                        ? `${prefixAnimal}-${
                                            item['serial'] ? item['serial'] : user.serial
                                        }-${zeroFill(item['animal_block_label_id'],5)}-${zeroFill(item[column.id],3)}` 
                                        : "" )
                                    : (
                                        column.type === "labelASaliva"
                                        ? ( item[column.id] > 0 
                                            ? `${item['type']==="Heces"?prefixFeces:prefixSaliva}-${
                                                item['serialSaliva'] ? item['serial'] : user.serial
                                            }-${zeroFill(item['animal_block_label_idSaliva'],5)}-${zeroFill(item[column.id],3)}` 
                                            : "" )
                                        : (                                    
                                            column.type === "labelS"
                                            ? ( item[column.id] > 0 
                                                ? `${item['type']==="Heces"?prefixFeces:prefixSaliva}-${
                                                    item['serial'] ? item['serial'] : user.serial
                                                }-${zeroFill(item['sample_block_label_id'],5)}-${zeroFill(item[column.id],3)}` 
                                                : "" )
                                            : (
                                                column.type === "labelSSaliva"
                                                ? ( item[column.id] > 0 
                                                    ? `${item['type']==="Heces"?prefixFeces:prefixSaliva}-${
                                                        item['serialSaliva'] ? item['serialSaliva'] : user.serial
                                                    }-${zeroFill(item['sample_block_label_idSaliva'],5)}-${zeroFill(item[column.id],3)}` 
                                                    : "" )
                                                : (
                                                    column.type === "icon" 
                                                    ? (
                                                        <Avatar className={classes.avatar}>
                                                            <Icon 
                                                                selector={(item['sample_id'] > 0 && item['status'] === sampleStatus[indexIssued].label)  
                                                                    ? "Match" 
                                                                    : (column ? item[column.id] : "")}/>
                                                        </Avatar>
                                                    )
                                                    : (
                                                        column.type === "attached"
                                                        ? item[column.id] ? <AttachedIcon /> : ""
                                                        : ( 
                                                            column.type === "checked"
                                                            ? item[column.id] ? <CheckedIcon /> : ""
                                                            : (
                                                                column.type === "imported"
                                                                ? item[column.id] ? <ImportedIcon /> : ""
                                                                : (
                                                                    column.type === "warning"
                                                                    ? item[column.id] ? <WarningIcon /> : ""
                                                                    : (
                                                                        column.type === "danger"
                                                                        ? item[column.id] ? <DangerIcon /> : ""
                                                                        : (                                                            
                                                                            item[column.id] ? (
                                                                                column.type === "date" 
                                                                                ? (moment(item[column.id]).isValid() ? moment(item[column.id]).format("DD/MM/YYYY") : "")
                                                                                : (
                                                                                    column.units 
                                                                                    ? parseFloat(item[column.id]).toLocaleString()  + " " + column.units 
                                                                                    : (
                                                                                        column.type === "nozero"
                                                                                        ? (parseInt(item[column.id]) || 0) === 0 ? "" : item[column.id] 
                                                                                        : (
                                                                                            column.type === "idName"
                                                                                            ? item[column.id] + " " + item['name']
                                                                                            : item[column.id]
                                                                                        )
                                                                                    )
                                                                                )
                                                                            ) : ""
                                                                        )
                                                                    )
                                                                )                                                                      
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    ) 
                                )
                            }
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    return (
        <div className={classes.paper} style={{height: height}}>   
            <TableContainer className={classes.root}>
                <Table className={classes.table} component="div">
                    <TableHead component="div" className={classes.thead}>
                        <Title 
                            classes={classes} 
                            columns={columns} 
                            orderBy={orderBy} 
                            orderDirection={orderDirection} 
                            onRequestSort={onRequestSort} 
                        />
                    </TableHead>
                    <TableBody component="div" className={classes.tbody}>
                        <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={itemCount}
                            loadMoreItems={loadMoreItems}
                            minimumBatchSize={limit}
                        >
                            {({ onItemsRendered, ref }) => (
                                <FixedSizeList
                                    ref={ref}
                                    height={height-rowTitleHeight}
                                    itemCount={itemCount}
                                    itemSize={rowHeight}
                                    itemData={itemData}
                                    onItemsRendered={onItemsRendered}
                                    className={classes.list}
                                >
                                    {Row}
                                </FixedSizeList>
                            )}
                        </InfiniteLoader>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}