/**
 * Icono de notificación mostrado en el avatar del item
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Icon } from "../../assets/bell.svg";
import { viewBox } from "../../config";

export function NotificationIcon() {
    return (
        <SvgIcon component={Icon} viewBox={viewBox} />
    )
}
