/**
 * Muestra el logotipo del cliente asociado al usuario, si procede
 */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { fetchApi, jsonParseMysql, mysqlEscape } from "../../hooks/DataFetch";
import { makeStyles } from "@material-ui/core/styles";
import { useIsMounted } from "../../hooks/IsMounted";
import { UserContext } from "../../contexts/UserContext";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme)=> ({
    iconMenu: {
        marginLeft: theme.spacing(2),
    },
    imageLogo: {
        height: theme.spacing(7),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    containerLogo: {
        textAlign: "center",
        padding: 0,
        margin: 0,
    },    
}))

export function UserLogo() {
    const classes = useStyles();
    const isMounted = useIsMounted();    
    const [logo, setLogo] = useState();
    const { user } = useContext(UserContext);

    useEffect (() => {    

        async function fetchLoadImage() {
            // La imagen se lee por separado para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT logo FROM client WHERE id='" + mysqlEscape(user.client_id) + "'"
            });
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setLogo(jsonData[0].logo);
            };
        }

        user.client_id && fetchLoadImage();         
    },[user.client_id, isMounted])     

    return (
        <Fragment>
            {logo
            ?<Fragment>
                <div className={classes.containerLogo}>
                    <img className={classes.imageLogo} src={`data:image/png;base64,${logo}`} alt="logo"/>
                </div> 
                <Divider />        
            </Fragment> 
            :""}
        </Fragment>
    )
}
