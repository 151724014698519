/**
 * Utilidades de tratamiento de cadenas
 */
export function zeroFill(number, width) {
    // Rellena con ceros a la  izquierda hasta completar el ancho
    const length = number.toString().length;  
    const zero = "0";

    if (width <= length) {
        return number.toString(); 
    } else {
        return ((zero.repeat(width - length)) + number.toString()); 
    }
}

export function spaceFill(string, width) {
    // Rellena con espacios a la  izquierda hasta completar el ancho
    const length = string.toString().length;  
    const space = " ";

    if (width <= length) {
        return string.toString(); 
    } else {
        return ((space.repeat(width - length)) + string.toString()); 
    }
}

export function spaceFillRight(string, width) {
    // Rellena con espacios a la  derecha hasta completar el ancho
    const length = string.toString().length;  
    const space = " ";

    if (width <= length) {
        return string.toString(); 
    } else {
        return (string.toString() + (space.repeat(width - length))); 
    }
}

export function csvToArray (string) {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",").map(item=>item.trim().replace(/\s+/g, ''));
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
        const values = i.split(",").map(item=>item.trim());
        const obj = csvHeader.reduce((object, header, index) => {
            object[header] = values[index];
            return object;
        }, {});
        return obj;
    });

    return(array);
};
 
export function formatStringDate(string) {
    // Aplica el formato dd/mm/yyyy a una cadena de entrada
    const date = new Date(string);
    return [
        zeroFill(date.getDate(),2),
        zeroFill(date.getMonth() + 1,2),
        date.getFullYear(),
    ].join('/');
}

export function formatStringLongDate(string) {
    // Aplica el formato de fecha larga a una cadena de entrada
    if (string) {
        const date = new Date(string);
        const months = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
        const days = ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"];
        return days[date.getDay()]+", "+date.getDate()+" de " + months[date.getMonth()]+" de "+date.getFullYear();    
    } else {
        return "";
    }
}