import React from "react";
import { useBarcode } from "react-barcodes";

export function Barcode({ value, isHide, isHideValue }) {
    const { inputRef } = useBarcode({
        value: value,
        options: {
            width: 1.2,
            height: isHide ? 20 : 45,
            textAlign: isHide ? "left" : "center",
            fontSize: 12,
            marginTop: 5,
            marginBottom: 0,
            marrginLeft: 10,
            textPosition: isHide ? "bottom" : "top",
            displayValue: !isHideValue,
        }
    })
    return <svg ref={inputRef} />
}