/**
 * Detalle de animal de sólo lectura con pestañas de información adicional
 */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as AnimalsIcon } from '../../assets/dog.svg';
import { fetchApi, jsonParseMysql, mysqlInteger } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { Loading } from "../Loading";
import { zeroFill } from "../../utils/StringUtils";
import { prefixAnimal } from "../../config";
import { AnimalTabs } from "./AnimalTabs";

const useStyles = makeStyles (() => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",        
    },
    containerTabs: {
        maxWidth: "1200px",
    },    
}));

export function AnimalDetail({ pathBack }) {
    const { id } = useParams();
    const classes = useStyles();
    
    // Estados de carga de datos
    const [data, setData] = useState([{
        animal_id: "",
        animal_label: "",
        animal_serial: "",
        animal_name: "",
        animal_block_label_id: "",
        animal_type: "",
        animal_chip: "",
        is_animal_dangerous: "",
        animal_birthday: "",
        animal_weight: "",
        animal_breed: "",
        animal_death: "",
        animal_sex: "",
        animal_hair: "",
        animal_pcode: "",
        animal_owner: "",
        animal_dni: "",
        animal_city: "",
        animal_province: "",
        animal_address: "",
        animal_phone: "",
        animal_email: "",
        veterinary_name: "",
        results: "",        
        date_start: "",
        date_end: "",
        observations: "",
        is_disabled: "",        
    }]);
    const [image, setImage] = useState();
    const [matching, setMatching] = useState([]);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);

    // Estados de validación de errores
    const [error, setError] = useState({ open: false, status: '' });
    
    useEffect (() => {  

        async function fetchLoad() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT animal.id as animal_id, animal.name as animal_name, animal.chip as animal_chip, animal.weight as animal_weight, " 
                    + "animal.birthday as animal_birthday, animal.death as animal_death, animal.breed as animal_breed, animal.hair as animal_hair, " 
                    + "animal.is_dangerous as is_animal_dangerous, animal.type as animal_type, animal.sex as animal_sex, animal.owner as animal_owner, " 
                    + "animal.dni as animal_dni, animal.city as animal_city, animal.pcode as animal_pcode, animal.address as animal_address, " 
                    + "animal.province as animal_province, animal.phone as animal_phone, animal.email as animal_email, animal.label as animal_label, " 
                    + "animal.block_label_id as animal_block_label_id, animal.is_disabled as is_disabled, client.serial as animal_serial, " 
                    + "user.name as veterinary_name, sample.id as sample_id, sample.results as results, sample.date_start as date_start, " 
                    + "sample.date_end as date_end, sample.observations as observations FROM animal " 
                    + "LEFT JOIN client ON (animal.client_id = client.id) " 
                    + "LEFT JOIN user ON (animal.user_id = user.id) "
                    + "LEFT JOIN sample ON (sample.animal_id = animal.id) "
                    + "WHERE animal.id=" + mysqlInteger(id) + " AND ((sample.type = 'Saliva') OR (sample.type IS NULL)) " 
                    + "ORDER BY sample.sampled DESC LIMIT 1"
            });             

            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                jsonData[0].weight = parseFloat(jsonData[0].weight).toLocaleString();  
                if (isMounted) setData(jsonData);
                // Lee las muestras coincidentes 
                const where = "WHERE sample.sample_id=" + mysqlInteger(jsonData[0].sample_id)             
                const responseMatch = await fetchApi({ 
                    query: "SELECT sample.id as id, client.serial as serial, sample.block_label_id as block_label_id, sample.label as label, "
                        + "sample.results as results, sample.type as type, sample.sampled as sampled, sample.sent as sent, sample.authority as authority, "
                        + "sample.latitude as latitude, sample.longitude as longitude, sample.comments_rec as comments_rec, animal.type as animal_type, "
                        + "animal.chip as animal_chip, animal.label as animal_label, clientAnimal.serial as animal_serial, " 
                        + "animal.block_label_id as animal_block_label_id "
                        + "FROM sample "
                        + "LEFT JOIN user ON (sample.user_id = user.id) "
                        + "LEFT JOIN client ON (user.client_id = client.id) "
                        + "LEFT JOIN animal ON (sample.animal_id = animal.id) "
                        + "LEFT JOIN client as clientAnimal ON (animal.client_id = clientAnimal.id) "
                        + where 
                });
                if (responseMatch.status !== "200") { setError({ open: true, status: responseMatch.status }) };
                if (responseMatch.data) {
                    const readMatching = jsonParseMysql(responseMatch.data); 
                    if (isMounted) setMatching(readMatching.map (match => ({...match, selected: true})));
                };                    
            };
            if (isMounted) setIsLoading(false);
        }        

        async function fetchLoadImage() {
            // La imagen se lee por separador para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT image FROM animal WHERE id=" + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setImage(jsonData[0].image);
            };
        }         
        
        id && fetchLoad();
        id && fetchLoadImage();
    },[id, isMounted])    
        
    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }   

    const label = data[0] && data[0].animal_label !== undefined 
        && `${prefixAnimal}-${data[0].animal_serial}-${zeroFill(data[0].animal_block_label_id, 5)}-${zeroFill(data[0].animal_label, 3)}`;    
    const matchingSel = matching.filter(match => match.selected);   // Coincidencias seleccionadas            

    if (isLoading) {
        return <Loading />
    } else {
        return (
            <div className={classes.container}>
                <Title 
                    text={label} 
                    icon={AnimalsIcon} 
                    pathBack={`${pathBack}/animales`}
                    disabled={data[0] && data[0].is_disabled}
                />
                <div className={classes.containerTabs}>
                    <AnimalTabs
                        data={data}
                        setData={setData}
                        image={image}
                        matching={matching}
                        setMatching={setMatching}
                        matchingSel={matchingSel}
                        isLoading={isLoading}
                        setError={setError}
                    />      
                </div>          
                <FloatAlert 
                    open={error.open} 
                    onClose={handleErrorClose} 
                    text="No hemos podido leer la información. Comprueba tu conexión." 
                    severity="error"
                />
            </div>
        )
    }
}
