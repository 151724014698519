/**
 * Menú de filtros para detecciones 
 */
import React from "react";
import { Menu, MenuItem, Typography } from "@material-ui/core";

export function DetectionsMenuFilter({ anchorEl, setAnchorEl, setFilter }) {

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleAllClick = () => {
        setAnchorEl(null);
        setFilter(null);
    }

    const handleDisabledClick = () => {
        setAnchorEl(null);
        setFilter("archivada");
    }

    return (
        <Menu
            id="menu-filter"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}                    
        >
            <MenuItem onClick={handleAllClick}><em>Todas</em></MenuItem>
            <MenuItem onClick={handleDisabledClick}>{<Typography color="secondary">Archivadas</Typography>}</MenuItem>            
        </Menu>              
    )
}
