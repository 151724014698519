/**
 * Menú de filtros para coincidencias 
 */
import React from "react";
import { Menu, MenuItem, Typography } from "@material-ui/core";

export  function MatchesMenuFilter({ anchorEl, setAnchorEl, setFilter }) {

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        const { myValue } = event.currentTarget.dataset;
        setAnchorEl(null);
        setFilter(myValue);
    }

    return (
        <Menu
            id="menu-filter"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}                    
        >
            <MenuItem key="todas" onClick={handleClick}><em>Todas</em></MenuItem>
            <MenuItem key="archivada" data-my-value={"archivada"} onClick={handleClick}>
                <Typography color="secondary">Archivadas</Typography>
            </MenuItem>            
        </Menu>              
    )
}
