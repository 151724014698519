/**
 * Tarjeta para mostrar acceso directo a la creación de una detección
 */
import React, { Fragment, useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ReactComponent as cameraIcon } from '../../assets/camera.svg';
import { CardDashboard } from "../common/CardDashboard";
import { makeStyles } from "@material-ui/core/styles";
import { fetchApi, mysqlEscape, mysqlFloat } from "../../hooks/DataFetch";
import moment from 'moment';
import { usePosition } from "../../hooks/Position";
import { FloatAlert } from "../common/FloatAlert";
import Geocode from "react-geocode";
import { apiKeyGoogle } from "../../credentials";
import { useIsMounted } from "../../hooks/IsMounted";

const useStyles = makeStyles (() => ({
    input: {
        display: "none"
    }
}));    

export function CardFastDetection() {
    const { user } = useContext(UserContext);    
    const classes = useStyles();
    const position = usePosition();
    const [error, setError] = useState({ open: false, status: '' });
    const [isSaving, setIsSaving] = useState(false);
    const [openSaved, setOpenSaved] = useState();
    const isMounted = useIsMounted();
    const isSamsungBrowser = navigator.userAgent.match(/SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i);

    const handleCapture = (target) => {  
        
        async function fetchInsert ({ lat, lng, address, image }) {            
            const data = {
                date: moment(new Date()).format("YYYY-MM-DD"),
                lat: lat,
                lng: lng,
                address: address ? address : ""
            }

            const query = "INSERT INTO detection " 
                + "(date, address, latitude, longitude, is_attached, image, user_id, client_id)" 
                + " VALUES ('" 
                + mysqlEscape(data.date) + "', '" + mysqlEscape(data.address) + "', " 
                + mysqlFloat(data.lat) + ", " + mysqlFloat(data.lng) + ", 1, '" + mysqlEscape(image) + "', '"
                + mysqlEscape(user.id) + "', '" + mysqlEscape(user.client_id) + "')";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                if (isMounted) setOpenSaved(true);
                return true;
            }                                
        } 

        function save (reader) {
            if (isMounted && !isSamsungBrowser) setIsSaving(true);
            const lat = position.lat.toString();
            const lng = position.lng.toString();
            Geocode.setApiKey(apiKeyGoogle);
            Geocode.setLanguage("es");
            Geocode.setRegion("es");    
            Geocode.fromLatLng(lat, lng).then (
                (response) => {
                    const responseAddress = response.results[0].formatted_address;
                    fetchInsert({ 
                        lat: lat, 
                        lng: lng, 
                        address: responseAddress, 
                        image: reader.result ? reader.result.replace("data:image/jpeg;base64,", "") : ""
                    })                    
                },
                () => {
                    if (isMounted) setError(true);
                }
            ); 
        };

        function getBase64(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = save(reader);
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }

        if (target.files) {
            if (target.files.length !== 0) {
                getBase64(target.files[0]);
            }
        }
    };    

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }

    const handleSavedClose = () => {
        setOpenSaved(false);
        if (!isSamsungBrowser) setIsSaving(false);
    }

    return (
        <Fragment>
            <input
                accept="image/jpeg"
                className={classes.input}
                id="button-file"
                type="file"
                capture="environment"
                onChange={(e) => handleCapture(e.target)}
            />
            <label htmlFor="button-file">
                <CardDashboard
                    title="Detección rápida"
                    subtitle="Toma una foto y guarda"
                    contentIcon={cameraIcon}
                    isSaving={isSaving && !openSaved}
                    shortXs
                />        
            </label>
            <FloatAlert 
                open={openSaved} 
                onClose={handleSavedClose} 
                text="Se ha registrado correctamente la detección." 
                severity="success"
            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido registrar la detección. Comprueba tu conexión." 
                severity="error"
            />
        </Fragment>        
    )
}
