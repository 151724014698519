/**
 * Muestra el mapa usando la API de Google Maps
 */
import React from "react";
import GoogleMapReact from "google-map-react";
import { MapMarker } from "./MapMarker";
import { apiKeyGoogle } from "../../credentials";

export function Map({ center, points, defaultZoom = 18, handleMapClick, handleMarkerClick }) {
    return (
        <GoogleMapReact
            options={{ 
                draggableCursor: 'default',
                mapTypeId: "hybrid",
            }}
            bootstrapURLKeys={{
                key: apiKeyGoogle,
                language: "es",
                region: "ES"
            }}
            center={center}
            defaultZoom={defaultZoom}
            zoom={(center.lat===0 && center.lng===0)?3:defaultZoom}
            onClick={handleMapClick} 
        >
            {points.map (({ lat, lng, source, id }) => (
                <MapMarker
                    id={id}
                    key={id} 
                    lat={lat} 
                    lng={lng}
                    source={source}
                    handleMarkerClick={handleMarkerClick}
                />
            ))}
        </GoogleMapReact>
    )
}


