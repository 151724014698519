/**
 * Contenedor princpal que muestra la opción de menú seleccionada 
 */
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from "../../config";
import { UserContext } from "../../contexts/UserContext";
import { SwitchAdmin } from '../../routes/SwitchAdmin';
import { SwitchVeterinary } from '../../routes/SwitchVeterinary';
import { SwitchVigilant } from '../../routes/SwitchVigilant';
import { SwitchCollector } from '../../routes/SwitchCollector';
import { SwitchLaboratory } from '../../routes/SwitchLaboratory';
import { SwitchSupervisor } from '../../routes/SwitchSupervisor';

const useStyles = makeStyles(theme => ({
    container: {
        position: "absolute",
        top: "0",
        bottom: "0",    
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,   
        },
        overflow: "hidden"
    }, 
    header: theme.mixins.toolbar,    
}))

export function ContainerMain({ setNumNotif }) {
    const classes = useStyles();
    const { user } = useContext(UserContext);

    switch (user.profile) {
        case "administrador":
            return (
                <main className={classes.container}>
                    <div className={classes.header} />
                    <SwitchAdmin setNumNotif={setNumNotif} />         
                </main>
            )
        case "veterinario":
            return (
                <main className={classes.container}>
                    <div className={classes.header} />
                    <SwitchVeterinary setNumNotif={setNumNotif} />            
                </main>
            )
        case "vigilante":
            return (
                <main className={classes.container}>
                    <div className={classes.header} />
                    <SwitchVigilant setNumNotif={setNumNotif} />       
                </main>
            )
        case "recolector":
            return (
                <main className={classes.container}>
                    <div className={classes.header} />
                    <SwitchCollector setNumNotif={setNumNotif} />        
                </main>
            )
        case "laboratorio":
            return (
                <main className={classes.container}>
                    <div className={classes.header} />            
                    <SwitchLaboratory setNumNotif={setNumNotif} />
                </main>
            )
        case "supervisor":
            return (
                <main className={classes.container}>
                    <div className={classes.header} />   
                    <SwitchSupervisor setNumNotif={setNumNotif} />         
                </main>
            )    
        default:
            return (<div />);
    }
}
