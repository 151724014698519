/**
 * Pie del informe
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles (() => ({
    container: {
        font: "8px Arial, sans-serif",
        textAlign: "center",
        paddingBottom: "12px",
    },
    page: {
        float: "right",
    },
    padding1: {
        height: "53px",
    },
    padding2: {
        height: "700px",
    }
}));

export  function FooterReport({ numPage, totalPages }) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={numPage === 2 ? classes.padding2 : classes.padding1}/>
            <div className={classes.page}>Hoja {numPage} de {totalPages}</div>
            <div>Inscrita en el Registro Mercantil de Valencia, Tomo 9702, Libro 6984, Folio 63, Sección 8, Hoja V-156426</div>
            <div>LabTecnic. Laboratorio miembro de International Society for Animal Genetics ISAG</div>
            <div>C/ Pare Carles Ferri, 23 46702 Gandía (Valencia)</div>
            <div>Tel. 960 700 747 www.labtecnic.com</div>
        </div>
    )
}
