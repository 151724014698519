/**
 * Menú principal con las funciones de laboratorio
 */
import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Divider, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { ReactComponent as SalivaIcon } from '../../assets/blot.svg';
import { ReactComponent as FecesIcon } from '../../assets/blot2.svg';
import { ReactComponent as NotifIcon } from '../../assets/bell.svg';
import { ReactComponent as UserIcon } from '../../assets/user.svg';
import { ReactComponent as ClientsIcon } from '../../assets/handshake.svg';
import { ReactComponent as LogoutIcon } from '../../assets/standby.svg';
import { viewBox } from "../../config";
import { UserContext } from "../../contexts/UserContext";
import { UserLogo } from "../common/UserLogo";

const useStyles = makeStyles((theme)=> ({
    iconMenu: {
        marginLeft: theme.spacing(2),
    }
}))

export function MenuLaboratory({ handleDrawerClose }) {
    const { user, setUser } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();

    const handleHomeClick = () => {
        handleDrawerClose();
        history.push("/laboratorio/");                
    }

    const handleSampleSalivaClick = () => {
        handleDrawerClose();
        history.push("/laboratorio/muestrassaliva");        
    }

    const handleSampleFecesClick = () => {
        handleDrawerClose();
        history.push("/laboratorio/muestrasheces");        
    }

    const handleClientsClick = () => {
        handleDrawerClose();
        history.push("/laboratorio/clientes");        
    }

    const handleNotificationsClick = () => {
        handleDrawerClose();
        history.push("/laboratorio/notificaciones");        
    }

    const handleAccountClick = () => {
        handleDrawerClose();
        history.push(`/laboratorio/micuenta/${user.id}`);        
    }

    const handleLogoutClick = () => {
        handleDrawerClose();
        localStorage.removeItem('credentials');
        setUser(null);
    }

    return (
        <Fragment>
            <UserLogo />
            <List component="nav">
                <ListItem button onClick={handleHomeClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={HomeIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem button onClick={handleSampleSalivaClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={SalivaIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Muestras saliva" />
                </ListItem>
                <ListItem button onClick={handleSampleFecesClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={FecesIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Muestras heces" />
                </ListItem>
                { user.client_id === "" 
                ? 
                    <ListItem button onClick={handleClientsClick}>
                        <ListItemIcon>
                            <SvgIcon className={classes.iconMenu} component={ClientsIcon} viewBox={viewBox}/> 
                        </ListItemIcon>
                        <ListItemText primary="Clientes" />
                    </ListItem>             
                : "" }
                <ListItem button onClick={handleNotificationsClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={NotifIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Notificaciones" />
                </ListItem>
                <Divider />
                <ListItem button onClick={handleAccountClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={UserIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Mi cuenta" />
                </ListItem>
                <ListItem button onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={LogoutIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión"/>
                </ListItem>
            </List> 
        </Fragment>
    )
}
