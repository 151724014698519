/**
 * Menú principal con las funciones de recolector
 */
import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Divider, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { ReactComponent as DetectionIcon} from '../../assets/compass.svg'
import { ReactComponent as CollectionIcon } from '../../assets/blot2.svg';
import { ReactComponent as NotifIcon } from '../../assets/bell.svg';
import { ReactComponent as UserIcon } from '../../assets/user.svg';
import { ReactComponent as LogoutIcon } from '../../assets/standby.svg';
import { viewBox } from "../../config";
import { UserContext } from "../../contexts/UserContext";
import { UserLogo } from "../common/UserLogo";

const useStyles = makeStyles((theme)=> ({
    iconMenu: {
        marginLeft: theme.spacing(2),
    }
}))

export function MenuCollector({ handleDrawerClose }) {
    const { user, setUser } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();

    const handleHomeClick = () => {
        handleDrawerClose();
        history.push("/recolector/");                
    }

    const handleDetectionsClick = () => {
        handleDrawerClose();
        history.push("/recolector/detecciones");        
    }

    const handleCollectionsClick = () => {
        handleDrawerClose();
        history.push("/recolector/muestras");        
    }

    const handleNotificationsClick = () => {
        handleDrawerClose();
        history.push("/recolector/notificaciones");        
    }

    const handleAccountClick = () => {
        handleDrawerClose();
        history.push(`/recolector/micuenta/${user.id}`);        
    }

    const handleLogoutClick = () => {
        handleDrawerClose();
        localStorage.removeItem('credentials');
        setUser(null);
    }

    return (
        <Fragment>
            <UserLogo />
            <List component="nav">
                <ListItem button onClick={handleHomeClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={HomeIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem button onClick={handleDetectionsClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={DetectionIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Detecciones" />
                </ListItem>
                <ListItem button onClick={handleCollectionsClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={CollectionIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Muestras" />
                </ListItem>
                <ListItem button onClick={handleNotificationsClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={NotifIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Notificaciones" />
                </ListItem>
                <Divider />
                <ListItem button onClick={handleAccountClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={UserIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Mi cuenta" />
                </ListItem>
                <ListItem button onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <SvgIcon className={classes.iconMenu} component={LogoutIcon} viewBox={viewBox}/> 
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión"/>
                </ListItem>
            </List> 
        </Fragment>
    )
}
