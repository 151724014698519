/**
 * Componente principal de entrada para mostrar los animales 
 */
import React, { Fragment, useContext, useState } from "react";
import { useLocation } from "react-router";
import { UserContext } from "../../contexts/UserContext";
import { ListForm } from "../common/ListForm";
import { AnimalIcon } from "./AnimalIcon";
import { AnimalsMenuFilter } from "./AnimalsMenuFilter";
import { fetchApi, mysqlEscape, mysqlInteger } from "../../hooks/DataFetch";
import { getSelect } from "../../utils/queryUtils";
import { FloatAlert } from "../common/FloatAlert";
import { useIsMounted } from "../../hooks/IsMounted";
import { indexAccepted, indexAnalyzed, indexIssued, indexRejected, indexReplaced, sampleStatus } from "../../config";

export function Animals({ pathBack }) {
    const { user } = useContext(UserContext);    
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const selectParam = new URLSearchParams(useLocation().search).get("select")
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();
    
    const table = "animal";
    const defaultColumns = [
        { id: "checkbox", width: 50, type: "checkbox"},
        { id: "type", width: 50, type: "icon" },
        { id: "label", label: "Etiqueta", width: 130, type: "labelA"},
        { id: "name", label: "Nombre", width: 150 },
        { id: "is_dangerous", label: "PPP", width: 80, type: "danger"},
        { id: "owner", label: "Propietario", width: 140 },
        { id: "phone", label: "Teléfono", width: 120 },
        { id: "weight", label: "Peso", width: 90, units: "kg" },
        { id: "breed", label: "Raza", width: 120 },
        { id: "hair", label: "Pelaje", width: 120 },
        { id: "birthday", label: "F. nac.", width: 120, type: "date" },
        { id: "death", label: "F. baja.", width: 120, type: "date" },
        { id: "address", label: "Dirección", width: 300 },
    ]
    if (user.profile==="administrador") {
        defaultColumns.splice(4, 0, { id: "client_name", label: "Cliente", width: 140, table:"client", fieldName: "name"})
    }
    if (user.profile==="supervisor" || user.profile==="administrador") {
        //defaultColumns.splice(3, 0, { id: "is_coincident", label: "Coinc.", width: 80, type: "checked"})
        defaultColumns.splice(6, 0, { id: "user_name", label: "Veterinario", width: 140, table:"user", fieldName: "name"})
    }

    function getQueryAnimals ({ filter, search, limitPlus, offset = 0, columns, orderBy, orderDirection }) {
        const whereDisabled = filter==="deshabilitado" ? `${table}.is_disabled = 1` : `${table}.is_disabled = 0`
        const whereForeign = user.profile==="administrador" ? "" 
            : user.profile==="supervisor" 
            ? ` AND ${table}.client_id = '${mysqlEscape(user.client_id)}'` 
            : ` AND ${table}.user_id = '${mysqlEscape(user.id)}'`;
        const whereDangerous = filter==="ppp" ? " AND is_dangerous = 1" : ""; 
        const whereCoincident = "" //filter==="coincidencia" ? " AND is_coincident = 1" : "";                                                   
        let where = `WHERE (${whereDisabled}${whereDangerous}${whereCoincident}${whereForeign})`;
        let leftJoin = ` LEFT JOIN client ON (${table}.client_id = client.id) ` 
        if (user.profile==="supervisor" || user.profile==="administrador") {
            leftJoin += ` LEFT JOIN user ON (${table}.user_id = user.id) `;
        }
        const fixedFields = ", animal.id as id, animal.block_label_id as animal_block_label_id, animal.is_disabled, client.serial"   
        return getSelect({ 
            columns, 
            defaultColumns, 
            table, 
            leftJoin, 
            where, 
            search, 
            fixedFields, 
            offset, 
            limitPlus, 
            orderBy, 
            orderDirection 
        }); 
    };

    function getTitleAnimals (filter) {
        return (filter==="deshabilitado" ? "Deshabilitados" : "Animales")
    };    
    
    function actionAnimalClick ({ id, pathBack, filter, history, data }) {
        if (selectParam) {
            const item = data.filter(item => item.id === id)
            localStorage.setItem('animal', JSON.stringify({
                id: id,
                label: item[0] ? item[0].label : "",
                block_label_id: item[0] ? item[0].animal_block_label_id : "",
                name: item[0] ? item[0].name : ""
            }));
            history.push(`${pathBack}/${selectParam}`);
        } else {
            if (filter) {
                history.push(`${pathBack}/animales/${id}?filter=${filter}`);
            } else {
                history.push(`${pathBack}/animales/${id}`);
            }        
        }
    };

    async function deleteAnimal (id) {

        async function isDeleteAllowed() {
            if (user.profile === "administrador") {
                return true;
            } else {
                const query = `SELECT COUNT(*) as count FROM sample ` 
                + `WHERE (status='${sampleStatus[indexAccepted].label}' `
                + `OR status='${sampleStatus[indexRejected].label}' ` 
                + `OR status='${sampleStatus[indexReplaced].label}' ` 
                + `OR status='${sampleStatus[indexAnalyzed].label}' ` 
                + `OR status='${sampleStatus[indexIssued].label}') ` 
                + `AND animal_id=${mysqlInteger(id)}`; 
                const response = await fetchApi({
                    query: query
                });
                if (response.status !== "200") {
                    const errorRead = { open: true, status: response.status };
                    if (isMounted) setError(errorRead);                
                    return false;     
                } else {
                    if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }

        async function isLinked() {
            const query = "SELECT COUNT(*) as count FROM sample WHERE animal_id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function isDisabled() {
            const query = "SELECT is_disabled FROM animal WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_disabled) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdateDisabled() {
            const query = "UPDATE animal SET is_disabled = 1, death = NOW() WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchUpdateEnabled() {
            const query = "UPDATE animal SET is_disabled = 0, death = NULL WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM animal WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
           
        if (await isDisabled()) {
            if (await fetchUpdateEnabled()) {
                // Habilitación correcta
                return true
            } else {
                // Error de API
                return false
            }
        } else {
            if (await isDeleteAllowed()) {
                if (await isLinked()) {
                    if (await fetchUpdateDisabled()) {
                        // Deshabilitación correcta
                        return true;              
                    } else {
                        // Error de API
                        return false;
                    }
                } else {
                    if (await fetchDelete()) {
                        // Eliminación correcta
                        return true;              
                    } else {
                        // Error de API
                        return false;
                    }
                }
            } else {
                return false;
            }
        }
    }

    async function actionFunction1Element (element) {
        switch (user.profile) {
            case "administrador":
            case "veterinario":
                // Eliminar si es posible 
                const result = await deleteAnimal(element.id);
                if (result) {
                    return { status: "deleted" };
                } else {
                    return { status: "notdeleted" };
                }
            default:
        }
    };    

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    } 

    const textFunction1 = user.profile==="administrador" || user.profile==="veterinario" ?"Eliminar":"";
    const textDeleteQuestion = user.profile==="administrador" || user.profile==="veterinario" 
        ?"¿Estás seguro de que quieres eliminar los animales?":"";
    const textDeleteDescription = user.profile==="administrador" || user.profile==="veterinario"
        ?"Si continúas este proceso se eliminarán los animales del sistema"
        + "salvo que tuvieran información vinculada, en cuyo caso serían deshabilidados":"";

    return (
        <Fragment>
            <ListForm 
                filterParam={filterParam}
                selectParam={selectParam}
                pathBack={pathBack}
                pathNew={user.profile==="supervisor" ? null : "/nuevoanimal"}
                icon={AnimalIcon}
                menu={AnimalsMenuFilter} 
                getQuery={getQueryAnimals} 
                getTitle={getTitleAnimals}
                actionItemClick={actionAnimalClick}
                defaultColumns={defaultColumns}
                actionFunction1Element={actionFunction1Element}
                textFunction1={textFunction1}
                textDeleteQuestion={textDeleteQuestion}
                textDeleteDescription={textDeleteDescription}
            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido eliminar o actualizar las muestras. Por favor, comprueba tu conexión." 
                severity="error"
            />
        </Fragment>
    )

}
