/**
 * Tarjeta genérica para mostrar información en el dashboard
 */
import React from "react";
import { Card, CardContent, CardHeader, Avatar, ButtonBase, SvgIcon, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import { viewBox } from "../../config";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

const useStyles = makeStyles((theme)=> ({
    card: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0.5),
        }
    },
    cardAction: {
        width: "100%",
        display: "block",
        textAlign: "initial",        
    },
    cardAcionXs: {
        width: "100%",
        display: "block",
        textAlign: "initial",        
        [theme.breakpoints.down('xs')]: {
            display: "flex",
        },
    },
    cardAcionHeight: {
        width: "100%",
        textAlign: "initial",        
        display: "flex",
    },
    content: {
        textAlign: "center",
        marginLeft: "auto",
    },
    childrenContainer: {
        width: "100%",
        height: theme.spacing(30),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(20),
        },
    },
    childrenContainerXs: {
        width: "100%",
        height: theme.spacing(30),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(20),
        },
        [theme.breakpoints.down('xs')]: {
            height: theme.spacing(5),
            maxWidth: theme.spacing(5)
        },
    },
    childrenContainerHeight: {
        width: "100%",
        height: theme.spacing(5),
        maxWidth: theme.spacing(5),
    },    
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    svgIcon: {
        height: "80%",
        width: "80%",
        [theme.breakpoints.down('xs')]: {
            height: "100%",
            width: "100%"
        }
    },    
}))

export function CardDashboard({ icon, title, subtitle, handleClick, shortXs, contentIcon, isSaving, ...props }) {
    const classes = useStyles();
    const windowDim = useWindowDimensions();

    return (
        <Card className={classes.card}>
            <ButtonBase
                name={title} 
                className={
                    windowDim.height <= 600 
                    ? classes.cardAcionHeight
                    : shortXs ? classes.cardAcionXs : classes.cardAction
                } 
                onClick={handleClick}
                component="span" // Se requiere para que funcione input
            >
            <CardHeader                    
                avatar={icon 
                    ? <Avatar >{icon}</Avatar> 
                    : <Avatar className={classes.avatar}>
                        {isSaving ? <CircularProgress color="inherit" size={28}/> : <AddIcon />}
                    </Avatar>}
                title={title} 
                subheader={subtitle}
            >
            </CardHeader>
            <CardContent className={classes.content}>
                <div 
                    className={
                        windowDim.height <= 600 
                        ? classes.childrenContainerHeight 
                        : shortXs ? classes.childrenContainerXs : classes.childrenContainer
                    }
                >
                    {contentIcon 
                        ? <SvgIcon 
                            component={contentIcon} 
                            className={classes.svgIcon}
                            viewBox={viewBox}
                        /> 
                        : props.children}
                </div>
            </CardContent>
            </ButtonBase>
        </Card>
    )
}
 