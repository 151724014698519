/**
 * Menú principal con las funciones de administrador
 */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Divider, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { ReactComponent as UsersIcon } from '../../assets/users3.svg';
import { ReactComponent as SettingsIcon } from '../../assets/gearwheel.svg';
import { ReactComponent as CollectionIcon } from '../../assets/jar.svg';
import { ReactComponent as AnimalsIcon } from '../../assets/dog.svg';
import { ReactComponent as NotifIcon } from '../../assets/bell.svg';
import { ReactComponent as UserIcon } from '../../assets/user.svg';
import { ReactComponent as ClientsIcon } from '../../assets/handshake.svg';
import { ReactComponent as barcodeIcon} from '../../assets/barcode.svg'
import { ReactComponent as LogoutIcon } from '../../assets/standby.svg';
import { viewBox } from "../../config";
import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles((theme)=> ({
    iconMenu: {
        marginLeft: theme.spacing(2),
    }
}))

export function MenuAdmin({ handleDrawerClose }) {
    const { user, setUser } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();

    const handleHomeClick = () => {
        handleDrawerClose();
        history.push("/administrador/");                
    }

    const handleUsersClick = () => {
        handleDrawerClose();
        history.push("/administrador/usuarios");        
    }

    const handleClientsClick = () => {
        handleDrawerClose();
        history.push("/administrador/clientes");        
    }

    const handleAnimalsClick = () => {
        handleDrawerClose();
        history.push("/administrador/animales");        
    }

    const handleSampleClick = () => {
        handleDrawerClose();
        history.push("/administrador/muestras");        
    }

    const handleLabelsClick = () => {
        handleDrawerClose();
        history.push("/administrador/etiquetas");        
    }

    const handleSettingsClick = () => {
        handleDrawerClose();
        history.push("/administrador/preferencias");        
    }

    const handleNotificationsClick = () => {
        handleDrawerClose();
        history.push("/administrador/notificaciones");        
    }

    const handleAccountClick = () => {
        handleDrawerClose();
        history.push(`/administrador/micuenta/${user.id}`);        
    }

    const handleLogoutClick = () => {
        handleDrawerClose();
        localStorage.removeItem('credentials');
        setUser(null);
    }

    return (
        <List component="nav">
            <ListItem button onClick={handleHomeClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={HomeIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItem>
            <ListItem button onClick={handleUsersClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={UsersIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Usuarios" />
            </ListItem>
            <ListItem button onClick={handleClientsClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={ClientsIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Clientes" />
            </ListItem>
            <ListItem button onClick={handleSampleClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={CollectionIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Muestras" />
            </ListItem>
            <ListItem button onClick={handleAnimalsClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={AnimalsIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Animales" />
            </ListItem>
            <ListItem button onClick={handleLabelsClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={barcodeIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Etiquetas" />
            </ListItem>
            <ListItem button onClick={handleSettingsClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={SettingsIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Preferencias" />
            </ListItem>
            <ListItem button onClick={handleNotificationsClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={NotifIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Notificaciones" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleAccountClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={UserIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Mi cuenta" />
            </ListItem>
            <ListItem button onClick={handleLogoutClick}>
                <ListItemIcon>
                    <SvgIcon className={classes.iconMenu} component={LogoutIcon} viewBox={viewBox}/> 
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión"/>
            </ListItem>
        </List> 
    )
}
