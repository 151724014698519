/**
 * Dashboard para el perfil laboratorio
 */
import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { CardSamplesSaliva } from "./CardSamplesSaliva";
import { CardSamplesFeces } from "./CardSamplesFeces";
import { CardClients } from "./CardClients";

const useStyles = makeStyles((theme)=> ({
    root: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5),
        }
    }
}))

export function DashboardLaboratory() {
    const classes = useStyles();
    const { user } = useContext(UserContext);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={6} md={4}><CardSamplesSaliva /></Grid>
            <Grid item xs={12} sm={6} md={4}><CardSamplesFeces /></Grid>
            { 
                user.client_id === "" 
                ? <Grid item xs={12} sm={6} md={4}><CardClients /></Grid>
                : ""
            }        
        </Grid>
    )
}
