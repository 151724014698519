/**
 * Campo que muestra y gestiona el estado de la muestra
 */
import React from "react";
import { Step, StepButton, StepLabel, Stepper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { indexAccepted, indexAnalyzed, indexIssued, indexRegistered, indexRejected, indexReplaced, indexSent, sampleStatus } from "../../config";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import moment from 'moment';

const useStyles = makeStyles ((theme) => ({
    container: {
        width: "100%",        
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
}));

export function StepperStatus({ data, setData, setIsModified, profile, activeStep, setActiveStep, disabled }) {
    const classes = useStyles();
    const windowDim = useWindowDimensions();
    const steps = getSteps(data[0].status);

    function getSteps (statusLabel) {
        if (profile==="laboratorio" || profile==="supervisor") {
            switch (statusLabel) {
                case sampleStatus[indexRejected].label:
                    return [
                        sampleStatus[indexRegistered].label, 
                        sampleStatus[indexSent].label, 
                        sampleStatus[indexRejected].label, 
                        sampleStatus[indexAnalyzed].label, 
                        sampleStatus[indexIssued].label
                    ];
                case sampleStatus[indexReplaced].label:
                    return [
                        sampleStatus[indexRegistered].label, 
                        sampleStatus[indexSent].label, 
                        sampleStatus[indexReplaced].label, 
                        sampleStatus[indexAnalyzed].label, 
                        sampleStatus[indexIssued].label
                    ];                    
                default:
                    return [
                        sampleStatus[indexRegistered].label, 
                        sampleStatus[indexSent].label, 
                        sampleStatus[indexAccepted].label, 
                        sampleStatus[indexAnalyzed].label, 
                        sampleStatus[indexIssued].label
                    ];                    
            }
        } else {
            switch (statusLabel) {
                case sampleStatus[indexRejected].label:
                    return [
                        sampleStatus[indexRegistered].label, 
                        sampleStatus[indexSent].label, 
                        sampleStatus[indexRejected].label
                    ];
                case sampleStatus[indexReplaced].label:
                    return [
                        sampleStatus[indexRegistered].label, 
                        sampleStatus[indexSent].label, 
                        sampleStatus[indexReplaced].label
                    ];
                default:
                    return [
                        sampleStatus[indexRegistered].label, 
                        sampleStatus[indexSent].label, 
                        sampleStatus[indexAccepted].label
                    ];    
            }
        }
    }
    
    function getStatusLabel (step) {
        switch (step) {
            case 0:    
                return sampleStatus[indexRegistered].label;
            case 1:    
                return sampleStatus[indexSent].label;            
            case 2:     
                return sampleStatus[indexAccepted].label;            
            case 3:    
                return sampleStatus[indexAnalyzed].label;    
            case 4:    
                return sampleStatus[indexIssued].label;    
            default:
                return sampleStatus[indexRegistered].label;
        }
    }

    const handleStep = step => () => {
        const label = getStatusLabel(step);
        const date = (data[0].sent==="0000-00-00" || data[0].sent==="") && label === sampleStatus[indexSent].label 
            ? moment(new Date()).format("YYYY-MM-DD")  
            : (data[0].sent!=="0000-00-00" || data[0].sent!=="") && label === sampleStatus[indexRegistered].label 
                ? ""
                : data[0].sent;
        const newData = [{
            ...data[0],
            status : label,
            sent: date,
            date_start: date,                        
        }];  
        setData(newData);
        setIsModified(true);
        setActiveStep(step);
    };

    return (
        <Stepper 
            orientation={windowDim.width < 700 ? "vertical" : "horizontal"} 
            nonLinear 
            activeStep={activeStep}
            className={classes.container}
        >
            {steps.map ((label, index) => {
                const isDisabled = (profile==="veterinario" || profile==="recolector") 
                    ? (index > 1 || activeStep === 2) 
                    : profile==="laboratorio" ? index < 1 : profile==="supervisor";
                const labelProps = {};
                if (label === sampleStatus[indexRejected].label || label === sampleStatus[indexReplaced].label) {
                    labelProps.error = true;
                }
                return (
                    <Step key={label}>
                        <StepButton onClick={isDisabled || disabled ? undefined : handleStep(index)}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </StepButton>
                    </Step>
                )
            })}
        </Stepper>
    )
}
