/**
 * Icono de título de mapa 
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as mapIcon } from '../../assets/map_location2.svg';
import { viewBox } from "../../config";

export function MapIcon() {
    return (
        <SvgIcon component={mapIcon} viewBox={viewBox} />
    )
}