/**
 * Formulario de envío de email para recuperación de contraseña
 */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Typography, useMediaQuery } from "@material-ui/core";
import { TextFieldIcon } from "../common/TextFieldIcon";
import { FloatAlert } from "../common/FloatAlert";
import { fetchApi } from "../../hooks/DataFetch";
import logo from "../../assets/logologin.png";
import logodark from "../../assets/logologindark.png";
import { ReactComponent as EmailIcon } from "../../assets/mail.svg";

export function FormLoginEmail({ classes, setUserChanged }) {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorReg, setErrorReg] = useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (email === "") {
            setErrorEmail(true);
        } else {
            setErrorEmail(false);
            const response = await fetchApi({
                action: "sendemail",
                email: email
            });
            if (response.data) {                
                history.push("/actualiza");
                setUserChanged(response.data)                   
            } else {
                setErrorReg(true);
            }
        }
    }

    const handleCancelClick = () => {
        history.push("/");
    }

    const handleEmailChange = (event) => {
        setEmail (event.target.value);
    }

    const handleErrorClose = () => {
        setErrorReg(false);
    }

    return (
        <div className={classes.container}>
            <Container component="main" maxWidth="xs">                
                <div className={classes.paper}>
                    <img src={prefersDarkMode ? logodark : logo} alt="logo"/>
                    <Typography variant="h5" gutterBottom>
                        Restablecimiento de contraseña
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Introduce la dirección de correo electrónico que nos facilitaste anteriormente para registrar tu usuario.
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Te enviaremos un código a esta dirección para que puedas restablecer la contraseña.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextFieldIcon 
                            id="email" 
                            icon={EmailIcon} 
                            label="Correo electrónico" 
                            error={errorEmail} 
                            onChange={handleEmailChange} 
                            type="email"
                        />               
                        <Button 
                            className={classes.buttonFixed} 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            size="large"
                        >
                            Enviar
                        </Button>
                        <Button 
                            className={classes.buttonFixed} 
                            variant="contained" 
                            color="secondary" 
                            size="large" 
                            onClick={handleCancelClick}
                        >
                            Cancelar
                        </Button>
                        <FloatAlert 
                            open={errorReg} 
                            onClose={handleErrorClose} 
                            text="La dirección de correo electrónico no está registrada. Inténtalo de nuevo." 
                            severity="error"
                        />                    
                    </form>
                </div>
            </Container>
        </div>
    )
}
