/**
 * Icono de cliente para el avatar del item de la lista
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Icon } from '../../assets/handshake.svg';
import { viewBox } from "../../config";

export function ClientIcon() {
    return (
        <SvgIcon component={Icon} viewBox={viewBox} />
    )
}
