/**
 * Cuadro que muestra información de la muestra en modo de sólo lectura
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { prefixAnimal } from "../../config";
import { zeroFill } from "../../utils/StringUtils";

const useStyles = makeStyles ((theme) => ({
    container: {
        display: "block",
        padding: theme.spacing(2),
        border: "1px dashed",
        borderRadius: "5px",
        borderColor: theme.palette.grey[400],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            borderColor: theme.palette.grey[600],
        },
    },
    containerField: {
        display: "flex",
        width: "100%",
    },
    textValue: {
        marginLeft: theme.spacing(2),
    }
}));

export function SampleDataField({ data }) {
    const classes = useStyles();
    const animalLabel = (data[0].animal_label && data[0].animal_block_label_id 
        && data[0].animal_label !== undefined && data[0].animal_block_label_id !== undefined)
        ? `${prefixAnimal}-${data[0].animal_serial}-${zeroFill(data[0].animal_block_label_id, 5)}-${zeroFill(data[0].animal_label, 3)}`
        : ""

    return (
        <div>
            <Typography variant="h6" component="h2" color="primary">
                Información de la muestra de {data[0].type}
            </Typography>
            <div className={classes.container}>
                <div className={classes.containerField}>
                    <Typography variant="subtitle1" component="h2" color="primary" >
                        Fecha de toma:
                    </Typography>
                    <Typography variant="subtitle1" component="h2" color="inherit" className={classes.textValue}>
                    {moment(data[0].sampled).isValid() ? moment(data[0].sampled).format("DD/MM/YYYY") : ""}
                    </Typography>
                </div>                    
                <div className={classes.containerField}>
                    <Typography variant="subtitle1" component="h2" color="primary">
                        Fecha de envío:
                    </Typography>  
                    <Typography variant="subtitle1" component="h2" color="inherit" className={classes.textValue}>
                        {moment(data[0].sent).isValid() ? moment(data[0].sent).format("DD/MM/YYYY") : ""}
                    </Typography>                                
                </div>
                {data[0].type === "Saliva"            
                ? <div className={classes.containerField}>
                    <Typography variant="subtitle1" component="h2" color="primary">
                        Animal:
                    </Typography>
                    <Typography variant="subtitle1" component="h2" color="inherit" className={classes.textValue}>
                        { animalLabel }
                    </Typography>                                 
                </div>
                : ""} 
                {data[0].type === "Heces"            
                ? <div className={classes.containerField}>
                    <Typography variant="subtitle1" component="h2" color="primary">
                        Autoridad competente:
                    </Typography> 
                    <Typography variant="subtitle1" component="h2" color="inherit" className={classes.textValue}>
                        { data[0].authority }
                    </Typography>                                  
                </div>
                : ""}
                {data[0].comments_rec !== ""                        
                ? <div className={classes.containerField}>
                    <Typography variant="subtitle1" component="h2" color="primary">
                        Comentarios del recolector:
                    </Typography>
                    <Typography variant="subtitle1" component="h2" color="inherit" className={classes.textValue}>
                        { data[0].comments_rec }
                    </Typography>                                
                </div>
                : ""}               
            </div>            
        </div>
    )
}
