/**
 * Título con búsqueda, filtro e icono opcional
*/
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Divider, IconButton, Tooltip, Hidden, InputBase, Badge } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { titleHeight } from "../../config";

const useStyles = makeStyles((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[50]
        },        
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: alpha(theme.palette.primary.main, 0.1)
        },        
        display: "flex",
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(2),
        },
        height: `${titleHeight}px`,
        userSelect: "none",
    },
    icon: {
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(2),
    },
    back: {
        marginRight: theme.spacing(1),
    },
    text: {
        marginRight: theme.spacing(2),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }, 
    search: {
        borderRadius: theme.shape.borderRadius,
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[200],
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            },            
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey["A400"],
            '&:hover': {
                backgroundColor: theme.palette.grey[900],
            },
        },        
        marginLeft: "auto",
        width: 'auto',
    }, 
    searchIcon: {
        padding: theme.spacing(0.5, 1),
        position: "absolute",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',        
    },
    inputRoot: {
        color: 'inherit',
    },    
    inputInput: {
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: "12ch",
        '&:focus': {
            width: "20ch",
        },
        [theme.breakpoints.down('xs')]: {
            width: "5px",
            '&:focus': {
                width: "10ch",
            },
        },
    } 
}));

export function SearchTitle({ filter, setFilter, search, setSearch, pathBack, title, selectIcon:SelectIcon, menu:Menu }) {
    const [anchorEl, setAnchorEl] = useState();
    const classes = useStyles();
    const history = useHistory();
    const windowDim = useWindowDimensions();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);   
    }

    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    }

    const handleBack = () => {
        // if (pathBack) history.push (pathBack); 
        history.goBack();   // En fase de prueba
    }

    return (
        <Fragment>
            <div className={classes.container}>
                {pathBack &&    
                    <Hidden mdUp>
                        <IconButton className={classes.back} aria-label="atrás" onClick={handleBack} size="small">
                            <ArrowBackIcon />
                        </IconButton>
                    </Hidden>
                }
                <div className={classes.icon}>
                    <SelectIcon selector={filter}/>
                </div>
                <Typography className={classes.text} variant="h5" component="h2" color="secondary">{title}</Typography>
                <div className={classes.search}>
                { setSearch 
                    ? 
                    <Fragment>
                        <div className={classes.searchIcon}>
                            <SearchIcon color="primary" />
                        </div>
                        <InputBase 
                            id="search" 
                            placeholder={windowDim.width>600 ? "Buscar…": ""}
                            onChange={handleChangeSearch}
                            value={search}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'buscar' }}
                        />
                    </Fragment>
                    : ""
                }
                </div>
                { Menu ? 
                    <Fragment>               
                        <Tooltip title="Filtrar">
                            <Badge 
                                color="secondary" 
                                variant="dot"
                                overlap="circular"
                                invisible={!filter}
                            >               
                                <IconButton className={classes.button} size="small" onClick={handleMenu}>
                                    <FilterListIcon />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        <Menu anchorEl={anchorEl} setAnchorEl={setAnchorEl} setFilter={setFilter}/>
                    </Fragment>
                : "" }
            </div>
            <Divider /> 
        </Fragment>
    )
}
