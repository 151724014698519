/**
 * Componente principal de entrada para mostrar los clientes 
 */
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router";
import { ListForm } from "../common/ListForm";
import { ClientItem } from "./ClientItem";
import { ClientIcon } from "./ClientIcon";
import { ClientsMenuFilter } from "./ClientsMenuFilter";
import { fetchApi, mysqlEscape } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";
import { FloatAlert } from "../common/FloatAlert";

export function Clients({ pathBack }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();

    function getQueryClients ({ filter, search, limitPlus, offset = 0 }) {
        let where = "WHERE ";
        if (filter) { 
            if (filter === "deshabilitado") { 
                where += "(is_disabled = 1)";
            } else {
                where += "(is_disabled = 0)";
            }
        } else {
            where += "(is_disabled = 0)";
        };
        if (search) {
            const searchScape = mysqlEscape(search);
            where += " AND ((id LIKE '%"+searchScape+"%') "
                +" OR (name LIKE '%"+searchScape+"%')"
                +" OR (city LIKE '%"+searchScape+"%')"
                +" OR (pcode LIKE '%"+searchScape+"%')"
                +" OR (address LIKE '%"+searchScape+"%')"
                +" OR (province LIKE '%"+searchScape+"%')"
                +" OR (phone LIKE '%"+searchScape+"%')"
                +" OR (email LIKE '%"+searchScape+"%'))";        
        };
        return "SELECT id, name, pcode, city, is_disabled FROM client " 
            + where + " ORDER BY id LIMIT " + offset + ", " + (limitPlus===0 ? 1 : limitPlus);        
    };
    
    function getTitleClients (filter) {
        return (filter==="deshabilitado" ? "Deshabilitados" : "Clientes")
    };    
    
    function actionClientClick ({ id, pathBack, filter, history }) {
        if (filter) {
            history.push(`${pathBack}/clientes/${id}?filter=${filter}`);
        } else {
            history.push(`${pathBack}/clientes/${id}`);
        }    
    };

    async function deleteClient (id) {

        async function isLinked() {
            const query = "SELECT COUNT(*) as count FROM user WHERE client_id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function isDisabled() {
            const query = "SELECT is_disabled FROM client WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_disabled) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdateDisabled() {
            const query = "UPDATE client SET is_disabled = 1 WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchUpdateEnabled() {
            const query = "UPDATE client SET is_disabled = 0 WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM client WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
           
        if (await isDisabled()) {
            if (await fetchUpdateEnabled()) {
                // Habilitación correcta
                return true
            } else {
                // Error de API
                return false
            }
        } else {
            if (await isLinked()) {
                if (await fetchUpdateDisabled()) {
                    // Deshabilitación correcta
                    return true;              
                } else {
                    // Error de API
                    return false;
                }
            } else {
                if (await fetchDelete()) {
                    // Eliminación correcta
                    return true;              
                } else {
                    // Error de API
                    return false;
                }
            }
        }
    }

    async function actionFunction1Element (element) {
        // Eliminar si es posible
        const result = await deleteClient(element.id);
        if (result) {
            return { status: "deleted" };
        } else {
            return { status: "notdeleted" };
        }
    };

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    }    

    const textFunction1 = "Eliminar";
    const textDeleteQuestion = "¿Estás seguro de que quieres eliminar los clientes?";
    const textDeleteDescription = "Si continúas este proceso se eliminarán los clientes del sistema, " 
        + "salvo que tuvieran información vinculada, en cuyo caso serían deshabilidados";

    return (
        <Fragment>
            <ListForm 
                filterParam={filterParam}
                pathBack={pathBack}
                pathNew="/nuevocliente"
                item={ClientItem}
                icon={ClientIcon}
                menu={ClientsMenuFilter} 
                getQuery={getQueryClients} 
                getTitle={getTitleClients}
                actionItemClick={actionClientClick}                  
                actionFunction1Element={actionFunction1Element}
                textFunction1={textFunction1}
                textDeleteQuestion={textDeleteQuestion}
                textDeleteDescription={textDeleteDescription}
            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido eliminar o actualizar los clientes. Por favor, comprueba tu conexión." 
                severity="error"
            />
        </Fragment>
    )
}
