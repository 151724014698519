/**
 * Campo de resultados para informe
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { FieldResultReport } from "./FieldResultReport";
import { dogMarkers, horseMarkers } from "../../config";

const useStyles = makeStyles ((theme) => ({ 
    container: {  
        height: theme.spacing(30),
        width: theme.spacing(77),
        borderColor: theme.palette.primary.main,
        border: "1px solid",
        borderRadius: "3px",
        font: "11px Arial, sans-serif",
        marginLeft: theme.spacing(0.3),
        marginBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    caption: {
        font: "bold 14px Arial, sans-serif",
        marginBottom: theme.spacing(1),
    },    
}));

export function GridResultsReport({ results, animalType, sampleType }) {
    const classes = useStyles();
    const markers = animalType==="Caballo" ? horseMarkers : dogMarkers

    return (
        <div className={classes.container}>
            <div className={classes.caption}>GENOTIPADO DE {sampleType.toUpperCase()}</div>
            <Grid container>
                {markers.map((item, i) => {
                    return (
                        <Grid item xs={2} key={i}>
                            <FieldResultReport                                
                                indexMarker={i}
                                label={item}
                                results={results} 
                            />
                        </Grid>    
                    );
                })}            
            </Grid>
        </div>
    )
}
