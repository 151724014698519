/**
 * Selector principal de la aplicación para selección de Login o pantalla principal según perfil de usuario
 */
import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Login } from "../components/login/Login";
import { Main } from "../components/main/Main";
import { MenuAdmin } from "../components/admin/MenuAdmin";
import { MenuVeterinary } from "../components/veterinary/MenuVeterinary";
import { MenuVigilant } from "../components/vigilant/MenuVigilant";
import { MenuCollector } from "../components/collector/MenuCollector";
import { MenuLaboratory } from "../components/laboratory/MenuLaboratory";
import { MenuSupervisor } from "../components/supervisor/MenuSupervisor";
import { UserContext } from "../contexts/UserContext";

export function RouterMain () {
    const { user } = useContext(UserContext);

    return (
        <Router>
            <Switch>
                <Route path="/supervisor">
                    { (user ? user.profile : "") === "supervisor"  ? <Main title="Supervisor" menu={MenuSupervisor} /> : <Redirect to ="/" /> }
                </Route>
                <Route path="/recolector">
                    { (user ? user.profile : "") === "recolector"  ? <Main title="Recolector" menu={MenuCollector} /> : <Redirect to ="/" /> }
                </Route>
                <Route path="/vigilante">
                    { (user ? user.profile : "") === "vigilante"  ? <Main title="Vigilante" menu={MenuVigilant} /> : <Redirect to ="/" /> }                                    
                </Route>
                <Route path="/laboratorio">
                    { (user ? user.profile : "") === "laboratorio"  ? <Main title="Laboratorio" menu={MenuLaboratory} /> : <Redirect to ="/" /> }                                    
                </Route>
                <Route path="/veterinario">
                    { (user ? user.profile : "") === "veterinario"  ? <Main title="Veterinario" menu={MenuVeterinary} /> : <Redirect to ="/" /> }                                    
                </Route>
                <Route path="/administrador">
                    { (user ? user.profile : "") === "administrador"  ? <Main title="Administrador" menu={MenuAdmin} /> : <Redirect to ="/" /> }                                    
                </Route>
                <Route path="/">
                    <Login />
                </Route>
            </Switch>
        </Router>            
    )
}
