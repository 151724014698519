/**
 * Campo de dirección que obtiene a partir de unas coordenadas
 */
import React from "react"
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Geocode from "react-geocode";
import { apiKeyGoogle } from "../../credentials";

const useStyles = makeStyles ((theme) => ({
    container: {
        marginTop: theme.spacing(2),
        width: "100%"
    }
}));

export function MapAddress({ latLng, address, setAddress }) {
    const classes = useStyles();

    Geocode.setApiKey(apiKeyGoogle);
    Geocode.setLanguage("es");
    Geocode.setRegion("es");

    Geocode.fromLatLng(latLng.lat, latLng.lng).then(
        (response) => {
            const responseAddress = response.results[0].formatted_address;
            setAddress(responseAddress);
        },
        () => {
            setAddress("");
        }
    );     

    return (
        <div className={classes.container}>
            <Typography variant="body1" component="div" color="inherit">
                {address}
            </Typography>
        </div>
    )
}
