/**
 * Subselector para las opciones del perfil recolector
 */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { DashboardCollector } from "../components/collector/DashboardCollector";
import { DetectionForm } from "../components/detections/DetectionForm";
import { Detections } from "../components/detections/Detections";
import { Notifications } from "../components/notifications/Notifications";
import { SampleForm } from "../components/samples/SampleForm";
import { Samples } from "../components/samples/Samples";
import { UserForm } from "../components/users/UserForm";

export function SwitchCollector({ setNumNotif }) {
    return (
        <Switch>
            <Route path="/recolector/detecciones" exact>
                <Detections pathBack="/recolector"/>
            </Route>
            <Route path="/recolector/detecciones/:id" exact>
                <DetectionForm pathBack="/recolector"/>
            </Route>

            <Route path="/recolector/muestras" exact>
                <Samples pathBack="/recolector" />
            </Route>
            <Route path="/recolector/muestras/:id" exact>
                <SampleForm pathBack="/recolector"/>
            </Route>                
            <Route path="/recolector/nuevamuestra" exact>
                <SampleForm pathBack="/recolector" isNew />
            </Route>

            <Route path="/recolector/notificaciones" exact>  
                <Notifications setNumNotif={setNumNotif} />                  
            </Route>
            <Route path="/recolector/micuenta/:id" exact>
                <UserForm />
            </Route>            
            <Route path="/recolector" exact>                            
                <DashboardCollector />                        
            </Route>
        </Switch>
    )
}
