/**
 * Menú de filtros para muestras 
 */
import React, { useContext } from "react";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import { UserContext } from "../../contexts/UserContext";
import { indexAccepted, indexAnalyzed, indexIssued, indexRegistered, indexRejected, indexReplaced, indexSent, sampleStatus } from "../../config";

export  function SamplesMenuFilter({ anchorEl, setAnchorEl, setFilter }) {
    const { user } = useContext(UserContext);        

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        const { myValue } = event.currentTarget.dataset;
        setAnchorEl(null);
        setFilter(myValue);
    }

    return (
        <Menu
            id="menu-filter"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}                    
        >
            <MenuItem key="todas" onClick={handleClick}><em>Todas</em></MenuItem>
            {sampleStatus.filter(state => 
                (state.label===sampleStatus[indexRegistered].label && user.profile!=="laboratorio") ||
                (state.label===sampleStatus[indexSent].label) || 
                (state.label===sampleStatus[indexAccepted].label) || 
                (state.label===sampleStatus[indexRejected].label) ||
                (state.label===sampleStatus[indexReplaced].label) ||
                (state.label===sampleStatus[indexAnalyzed].label && (user.profile==="supervisor" || user.profile==="laboratorio" || user.profile==="veterinario")) ||
                (state.label===sampleStatus[indexIssued].label && (user.profile==="supervisor" || user.profile==="laboratorio" || user.profile==="veterinario"))
                ).map(state => 
                <MenuItem key={state.label} data-my-value={state.label} onClick={handleClick}>
                    {state.label===sampleStatus[indexReplaced].label
                    ?<Typography color="secondary">{state.title}</Typography>
                    :state.title}
                </MenuItem>
            )}
            { (user.profile==="administrador" || user.profile==="supervisor" || user.profile==="laboratorio")
                ?<MenuItem key="archivada" data-my-value={"archivada"} onClick={handleClick}>
                    {<Typography color="secondary">Archivadas</Typography>}
                </MenuItem>            
                : ""
            }
            { (user.profile==="administrador" || user.profile==="laboratorio")
                ? <MenuItem key="deshabilitada" data-my-value={"deshabilitada"} onClick={handleClick}>
                    {<Typography color="secondary">Deshabilitadas</Typography>}
                </MenuItem>
                : ""
        }           
        </Menu>              
    )
}
