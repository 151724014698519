/**
 * Utilidades de laboratorio
 */
import { indexAccepted, indexAnalyzed, indexIssued, indexRegistered, indexRejected, indexReplaced, indexSent, sampleStatus } from "../config";

export function getStatusIndex (statusLabel) {
    // Devuelve el índice del stepper para el estado de entrada
    switch (statusLabel) {
        case sampleStatus[indexRegistered].label:    
            return 0;
        case sampleStatus[indexSent].label:    
            return 1;            
        case sampleStatus[indexAccepted].label:    
            return 2;            
        case sampleStatus[indexRejected].label:    
            return 2;    
        case sampleStatus[indexReplaced].label:    
            return 2;    
        case sampleStatus[indexAnalyzed].label:    
            return 3;    
        case sampleStatus[indexIssued].label:    
            return 4;    
        default:
            return;
    }
}