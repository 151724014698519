/**
 * Campo de visualización y manipulación de imagen asociado a un formulario
 */
import React, { Fragment, useState } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { quality, maxWidthImage } from "../../config";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

const useStyles = makeStyles ((theme) => ({
    containerImage: {
        display: "flex",
        padding: theme.spacing(2),
        border: "1px dashed",
        borderRadius: "5px",
        borderColor: theme.palette.grey[400],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            borderColor: theme.palette.grey[600],
        },
    },
    containerMinHeight: {
        minHeight: "300px",
        [theme.breakpoints.down('xs')]: {
            minHeight: "0",
        }
    },
    containerShortHeight: {
        minHeight: "90px",
        [theme.breakpoints.down('xs')]: {
            minHeight: "0",
        }
    },    
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    }, 
    imgBox: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    img: {
        height: "inherit",
        maxWidth: "inherit",
    },
    input: {
        display: "none"
    },
    loading: {
        height: "theme.spacing(40)"
    }
}));

export function ImageField({ classContainer, image, setImage, data, setData, setIsModified, disabled, description, caption, short, photo=true }) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const windowDim = useWindowDimensions();

    const handleCapture = (target) => {  
        
        function compressBase64(base64) {
            return new Promise((resolve, reject) => {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                const img = new Image();
                img.src = base64; 
                img.onload = function () {
                    const newWidth = maxWidthImage;
                    const newHeigth = maxWidthImage * (img.height/img.width);
                    canvas.width = newWidth;
                    canvas.height = newHeigth; 
                    context.scale (newWidth/img.width, newHeigth/img.height)
                    context.drawImage(img, 0, 0); 
                    resolve(canvas.toDataURL(photo?"image/jpeg":"image/png", quality));               
                }
            });
        }

        function getBase64(file) {            
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setData ([{
                    ...data[0], 
                     is_attached: true 
                }]);
                compressBase64 (reader.result, 1920, 1080).then ((result) => {
                    const base64 = result.replace(photo?"data:image/jpeg;base64,":"data:image/png;base64,", "");        
                    setImage(base64);        
                })
            }; 
            reader.onloadstart = function () { setIsLoading(true) };
            reader.onloadend = function () { setIsLoading(false) };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };             
        }

        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                getBase64(file);
                setIsModified(true)
            }
        }
    };

    const handleDelete = () => {
        document.getElementById("button-file").value = "";
        setData ([{
            ...data[0], 
            is_attached: ""
        }]);
        setImage("");
        setIsModified(true);
    }

    const classImage = image 
    ? classes.containerImage
    : short 
        ? clsx(classes.containerImage, classes.containerShortHeight)
        : clsx(classes.containerImage, classes.containerMinHeight);

    return (
        <div className={classContainer}>
            <Typography variant="h6" component="h2" color="primary">
                {!!caption?caption:"Fotografía"}
            </Typography>
            <div className={classImage}>                
                { isLoading 
                    ? <div className={classes.loading}>
                        <Typography variant="body2" color="primary">Cargando ...</Typography>
                        <CircularProgress />
                    </div>
                    : image
                        ? <div className={classes.imgBox}>
                            <img 
                                src={photo?`data:image/jpeg;base64,${image}`:`data:image/png;base64,${image}`} 
                                alt={photo?"Foto":"Imagen"} 
                                className={classes.img}
                            />                                
                        </div>                          
                        : data[0].is_attached
                            ? <Skeleton variant="rect" width="100%">
                                <div style={{ paddingTop: '75%' }} />
                            </Skeleton>
                            : <Typography variant="caption" component="div" color="primary">
                                {description}
                            </Typography>
                }
            </div>
            { disabled 
                ? "" 
                : <div>                    
                    <input
                        accept={photo?"image/jpeg":"image/png"}
                        className={classes.input}
                        id="button-photo"
                        type="file"
                        capture={photo?"environment":null}
                        onChange={(e) => handleCapture(e.target)}
                    />
                    <label htmlFor="button-photo">
                        <Button
                            className={classes.button} 
                            color="primary" 
                            variant="outlined"                    
                            component="span"
                        >
                            {
                                photo
                                ? ((windowDim.width <= 1000 && windowDim.width >= 600) || windowDim.width < 430 
                                    ? "Foto" 
                                    : "Tomar foto")
                                :(windowDim.width <= 1050 ? "Seleccionar" : "Seleccionar imagen")
                            }
                        </Button>
                    </label>
                    { photo 
                    ?<Fragment>
                        <input
                            accept={"image/jpeg"}
                            className={classes.input}
                            id="button-file"
                            type="file"
                            onChange={(e) => handleCapture(e.target)}
                        />
                        <label htmlFor="button-file">
                            <Button
                                className={classes.button} 
                                color="primary" 
                                variant="outlined"                    
                                component="span"
                            >
                                {((windowDim.width <= 1100 && windowDim.width >= 600) || windowDim.width < 370 
                                    ? "Selec..." 
                                    : "Seleccionar")}
                            </Button>
                        </label>
                    </Fragment>
                    :""}

                    <Button
                        className={classes.button} 
                        color="secondary" 
                        variant="outlined"
                        onClick={handleDelete}
                    >
                        {
                            photo
                            ?((windowDim.width <= 660 && windowDim.width >= 600) || windowDim.width < 330 
                                ? "Elim..." 
                                : "Eliminar")
                            :(windowDim.width <= 1050 ? "Eliminar" : "Eliminar imagen")
                        }
                    </Button>
                </div>
            }   
        </div>
    )
}
