/**
 * Formulario de detale de muestra
 */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { FormGroup, Grid, TextField } from "@material-ui/core";
import { ReactComponent as SamplesIcon } from "../../assets/jar.svg";
import { fetchApi, mysqlEscape, jsonParseMysql, mysqlInteger, mysqlFloat } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { usePosition } from "../../hooks/Position";
import { Loading } from "../Loading";
import { AnimalSelector } from "./AnimalSelector";
import { SampleTitle } from "./SampleTitle";
import { FormGroupButton } from "../common/FormGroupButton";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { SaveDialog } from "../common/SaveDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { SelectField } from "../common/SelectField";
import { MapField } from "../common/MapField";
import { ImageField } from "../common/ImageField";
import { StepperStatus } from "./StepperStatus";
import { zeroFill } from "../../utils/StringUtils";
import { getStatusIndex } from "../../utils/labUtils";
import { minLabelAdvise, prefixAnimal, buttonGroup, buttonGroupXs, sampleStatus, prefixFeces, 
    prefixSaliva, indexSent, indexAccepted, indexRegistered } from "../../config";
import moment from 'moment';

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",        
    },
    form: {
        maxWidth: "1200px",
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            maxWidth: "none",
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0.5),
        }
    },
    columnGrid: {
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(2),
        }
    },
    field: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    fieldTitle: {
        width: "100%",
        marginTop: theme.spacing(2),
    },
    bottomSpace: {
        // La altura debe coincidir con FormGroupButton
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            height: buttonGroupXs,
        }        
    },
}));

export function SampleForm({ isNew, reload, pathBack }) {
    const { user } = useContext(UserContext);    
    const { id } = useParams();
    const classes = useStyles();
    const windowDim = useWindowDimensions();
    const history = useHistory();
    const position = usePosition();
    // Se recuerda el filtro del listado para poder volver al mismo listado filtrado
    const filterParam = new URLSearchParams(useLocation().search).get("filter");     

    // Estados de carga de datos
    const [data, setData] = useState([{
        id: "",
        sampled: "",
        sent: "",
        status: "",
        comments_rec: "",
        comments_lab: "",
        type: "",
        block_label_id: "",
        label: "",
        authority: "",
        animal_id: "",
        animal_label: "",
        animal_block_label_id: "",
        animal_name: "",
        latitude: "",
        longitude: "",
        serial: "",
        is_attached: "",
        is_disabled: "",
        is_archived: "",
    }]);
    const [image, setImage] = useState();
    const [address, setAddress] = useState() 
    const [labels, setLabels] = useState ([{
        value: "",
        label: "(ninguna)"
    }]);
    const [authorityList, setAuthorityList] = useState([{
        value: "",
        label: "(ninguna)"
    }]);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingLabels, setIsLoadingLabels] = useState(true);
    const [isLoadingAuthority, setIsLoadingAuthority] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isModified, setIsModified] = useState(false);    
    const [activeStep, setActiveStep] = useState(0);

    // Estados de validación de errores
    const [error, setError] = useState({ open: false, status: '' });
    const [errorLabel, setErrorLabel] = useState(false);

    // Estados de diálogos
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    async function saveForm () {

        async function sendAdviseSample (userLab) {
            const prefixSample = data[0].type === "Heces" ? prefixFeces : prefixSaliva;
            const label = `${prefixSample}-${data[0].serial}-${zeroFill(data[0].block_label_id, 5)}-${zeroFill(data[0].label, 3)}`;
            const text = "El " + user.profile + " " + user.name + " ha enviado la muestra " 
                + data[0].id + " de " + data[0].type + " con la etiqueta " + label;
            const query = "INSERT INTO advise (type, text, is_notified, is_viewed, date, user_id) "
                +"VALUES ('sample', '" + text + "', 0, 0, NOW(), '" + mysqlEscape(userLab) + "')";
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
            }
        }        

        async function fecthAdviseUsersLaboratory() {
            const response = await fetchApi({ 
                query: "SELECT id FROM user WHERE user.profile = 'laboratorio' AND (client_id = '" 
                    + mysqlEscape(user.client_id) + "' OR client_id = '') AND is_disabled = 0"
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                readData.map (user => sendAdviseSample(user.id));
            };
        }

        async function sendAdviseMinLabel (userLab) {
            const text = "El " + user.profile + " " + user.name + " tiene menos de " 
                + minLabelAdvise + " etiquetas de muestras disponibles";
            const query = "INSERT INTO advise (type, text, is_notified, is_viewed, date, user_id) "
                +"VALUES ('label', '" + text + "', 0, 0, NOW(), '" + mysqlEscape(userLab) + "')";
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
            }
        }  

        async function fecthAdviseUsersAdmin() {
            const response = await fetchApi({ 
                query: "SELECT id FROM user WHERE user.profile = 'administrador' AND is_disabled = 0"
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                readData.map (user => sendAdviseMinLabel(user.id));
            };
        }        

        async function fetchUpdate(newData) {
            let query = "";
            if (isNew) {
                query = "INSERT INTO sample (sampled, sent, status, comments_rec, comments_lab, type, address, " 
                    + "latitude, longitude, is_attached, image, label, authority, block_label_id, animal_id, user_id) VALUES ('" 
                    + mysqlEscape(newData.sampled) + "', '" + mysqlEscape(newData.sent) + "', '" 
                    + mysqlEscape(newData.status) + "', '" + mysqlEscape(newData.comments_rec) + "', '" 
                    + mysqlEscape(newData.comments_lab) + "', '" + mysqlEscape(newData.type) + "', '" 
                    + mysqlEscape(address) + "', " + mysqlFloat(lat) + ", " + mysqlFloat(lng) + ", "
                    + mysqlInteger(newData.is_attached ? 1 : 0) + ", '" + (image ? mysqlEscape(image) : "") + "', " 
                    + mysqlInteger(newData.label) + ", '" + mysqlEscape(newData.authority) + "', " 
                    + mysqlInteger(newData.block_label_id) + ", " + mysqlInteger(newData.animal_id) + ", '" 
                    + mysqlEscape(user.id) + "')";
            } else {                
                query = "UPDATE sample SET sampled='" + mysqlEscape(newData.sampled)
                    + "', sent='" + mysqlEscape(newData.sent)
                    + "', status='" + mysqlEscape(newData.status)
                    + "', comments_rec='" + mysqlEscape(newData.comments_rec)
                    + "', comments_lab='" + mysqlEscape(newData.comments_lab)
                    + "', type='" + mysqlEscape(newData.type)
                    + "', address='" + mysqlEscape(address) 
                    + "', latitude=" + mysqlFloat(newData.latitude) 
                    + ", longitude=" + mysqlFloat(newData.longitude) 
                    + ", is_attached=" + mysqlInteger(newData.is_attached ? 1 : 0) 
                    + ", image='" + (image ? mysqlEscape(image) : "") 
                    + "', label=" + mysqlInteger(newData.label)
                    + ", authority='" + mysqlEscape(newData.authority)
                    + "', block_label_id=" + mysqlInteger(newData.block_label_id)
                    + ", animal_id=" + mysqlInteger(newData.animal_id) 
                    + " WHERE id=" + mysqlInteger(data[0].id);
            }
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                if (isNew) lastId = parseInt(JSON.parse(response.data)[0].lastId) || 0;
                return true;
            }
        }     
    
        // Crea una copia de los datos
        let lastId = 0;
        let newData = {...data[0]};
        let verificationError = false;
        if (newData.label === "" || newData.label === 0 || newData.label === undefined) {
            if (isMounted) setErrorLabel(true);
            verificationError = true
        }

        // Graba los cambios si no hay errores de verificación 
        if (!verificationError) {
            if (await fetchUpdate(newData)) {
                // Reinicia estados y valores
                if (isMounted) {
                    if (user.profile === "veterinario" || user.profile === "recolector") {
                        if (newData.status === sampleStatus[indexSent].label) fecthAdviseUsersLaboratory(); 
                        if (labels.length <= minLabelAdvise) fecthAdviseUsersAdmin();    
                    }
                    setIsModified(false);                
                    setIsSaving(false);
                    setErrorLabel(false);
                }
                return lastId ? lastId : true;
            }
        }
        if (isMounted) setIsSaving(false);
        return false; 
    }
    
    async function deleteForm () {

        async function isLinked() {
            const query = `SELECT COUNT(*) as count FROM sample ` 
                + `WHERE status = '${sampleStatus[indexAccepted].label}' AND id=${mysqlInteger(id)}`;  
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdateDisabled() {
            const query = "UPDATE sample SET is_disabled = 1 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM sample WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
           
        if (await isLinked()) {
            if (await fetchUpdateDisabled()) {
                // Deshabilitación correcta
                return true                
            } else {
                // Error de API
                return false
            }
        } else {
            if (await fetchDelete()) {
                // Eliminación correcta
                return true                
            } else {
                // Error de API
                return false
            }
        }
    }

    async function saveAndExit() {
        // Función asíncrona para guardar y salir que evitar la salida en caso de error
        if (await saveForm()) {
            exit();
        }
    }

    async function saveAndRefresh() {
        // Función asíncrona para guardar y recargar la ficha
        const result = await saveForm();
        if (result) {
            if (filterParam) {
                history.push(`${pathBack}/muestras/${result}?filter=${filterParam}`);
            } else {
                history.push(`${pathBack}/muestras/${result}`);
            }    
        }
    }

    async function deleteAndExit() {
        // Función asíncrona para eliminar la muestra
        if (await deleteForm()) {
            exit();
        }
    }

    async function enableForm() {

        async function fetchUpdate() {
            const query = "UPDATE sample SET is_disabled = 0 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        } 
        
        if (fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_disabled: false,
            }];
            if (isMounted) setData(newData);
        }
    }

    function exit() {
        if (filterParam) {
            history.push (`${pathBack}/muestras?filter=${filterParam}`);
        } else {
            history.push (`${pathBack}/muestras`);
        }
    }
    
    useEffect (() => {    

        async function fetchLoad() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT sample.id as id, sample.sampled as sampled, " 
                    + "sample.sent as sent, sample.status as status, sample.comments_rec as comments_rec, " 
                    + "sample.comments_lab as comments_lab, sample.authority as authority, sample.latitude as latitude, " 
                    + "sample.longitude as longitude, sample.is_attached as is_attached, sample.type as type, " 
                    + "sample.block_label_id as block_label_id, sample.label as label, " 
                    + "sample.is_disabled as is_disabled, sample.is_archived as is_archived, " 
                    + "sample.animal_id as animal_id, animal.label as animal_label, animal.name as animal_name, " 
                    + "animal.block_label_id as animal_block_label_id, client.serial as serial " 
                    + "FROM sample LEFT JOIN animal ON (sample.animal_id = animal.id) " 
                    + "LEFT JOIN user ON (sample.user_id = user.id) "
                    + "LEFT JOIN client ON (user.client_id = client.id) "
                    + "WHERE sample.id=" + mysqlInteger(id) 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                if (isMounted) {
                    setData(readData);
                    setActiveStep(getStatusIndex(readData[0].status))
                };
            };
            if (isMounted) setIsLoading(false);
        }

        async function fetchLoadImage() {
            // La imagen se lee por separado para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT image FROM sample WHERE id=" + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setImage(jsonData[0].image);
            };
        }         
        
        async function fetchLoadUsedLabels(userLabel, type) {
            const response = await fetchApi({ 
                query: "SELECT sample.block_label_id as block_label_id, sample.label as label, " 
                    + "client.serial as serial FROM sample " 
                    + "LEFT JOIN user ON (sample.user_id = user.id) "
                    + "LEFT JOIN client ON (user.client_id = client.id) "
                    + "LEFT JOIN block_label ON (sample.block_label_id = block_label.id) " 
                    + "WHERE block_label.user_id = '" + mysqlEscape(userLabel)
                    + "' AND block_label.type = '" + type + "'" 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };          
            if (response.data) {
                if (isMounted) {
                    const readData = jsonParseMysql(response.data);
                    return readData.map((item) => {
                        const prefixSample = type === "Heces" ? prefixFeces : prefixSaliva;
                        const code = `${prefixSample}-${item.serial}-${zeroFill(item.block_label_id,5)}-${zeroFill(item.label,3)}`;                        
                        return { value: code, label: code };
                    });
                };
            };
        }

        async function fetchLoadCurrentLabel(id) {
            const response = await fetchApi({ 
                query: "SELECT sample.block_label_id as block_label_id, sample.label as label, sample.type, client.serial as serial " 
                    + "FROM sample " 
                    + "LEFT JOIN user ON (sample.user_id = user.id) " 
                    + "LEFT JOIN client ON (user.client_id = client.id) " 
                    + "WHERE sample.id = " + mysqlInteger(id) 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };          
            if (response.data) {
                if (isMounted) {
                    const readData = jsonParseMysql(response.data);
                    if (readData[0].block_label_id) {
                        const prefixSample = readData[0].type === "Heces" ? prefixFeces : prefixSaliva;
                        const code = `${prefixSample}-${readData[0].serial}-${zeroFill(readData[0].block_label_id,5)}-${zeroFill(readData[0].label,3)}`;
                        return { value: code, label: code };
                    }
                }
            }
        }

        async function fetchUserSample(id) {
            const response = await fetchApi({ 
                query: "SELECT user_id FROM sample WHERE id = " + mysqlInteger(id) 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };          
            if (response.data) {
                if (isMounted) {
                    const readData = jsonParseMysql(response.data);
                    return readData[0].user_id;
                }
            }
        }

        async function fetchLoadLabels(id, type) {
            // Carga en el seleccionable de etiquetas las etiquetas no usadas y disponibles
            
            if (isMounted) setIsLoadingLabels(true);
            const userLabel = isNew ? user.id : await fetchUserSample(id);
            const usedLabels = await fetchLoadUsedLabels(userLabel, type);
            const currentLabel = isNew ? null : await fetchLoadCurrentLabel(id);
            const response = await fetchApi({ 
                query: "SELECT block_label.id as id, block_label.count as count, client.serial as serial " 
                    + "FROM block_label " 
                    + "LEFT JOIN client ON (block_label.client_id = client.id) " 
                    + "WHERE block_label.user_id = '" + mysqlEscape(userLabel) 
                    + "' AND block_label.is_disabled = 0 AND block_label.type = '" + type + "'" 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };          
            if (response.data) {
                const blocks = jsonParseMysql(response.data);
                let newLabels = [{ value: "", label: "(ninguna)" }];
                if (currentLabel) newLabels.push(currentLabel);
                blocks.forEach(function (block) {
                    for (let i=1; i<=block.count; i++) {
                        const prefixSample = type === "Heces" ? prefixFeces : prefixSaliva;
                        const code = `${prefixSample}-${block.serial}-${zeroFill(block.id,5)}-${zeroFill(i,3)}`;
                        const elem = { value: code, label: code };
                        if (usedLabels && !usedLabels.find(usedLabel => usedLabel.value === elem.value)) {
                            newLabels.push(elem);
                        }
                    }    
                });
                if (isMounted) setLabels(newLabels); 
            }
            if (isMounted) setIsLoadingLabels(false);
        }

        async function fetchLoadAuthorityList() {
            if (isMounted) setIsLoadingAuthority(true);           
            const response = await fetchApi({ 
                query: isNew 
                    ? "SELECT authority FROM client WHERE id='" + mysqlEscape(user.client_id) + "'"
                    : "SELECT client.authority as authority FROM sample " 
                        + "LEFT JOIN user ON (sample.user_id = user.id) " 
                        + "LEFT JOIN client ON (user.client_id = client.id) WHERE sample.id=" + mysqlInteger(id)
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                const readData = jsonParseMysql(response.data);    
                const arrayData = readData[0] ? readData[0].authority.split(";") : [];
                const newList = arrayData.map(elem => { 
                    const truncElem = elem.substring(0,49);
                    return { value: truncElem, label: truncElem } 
                });
                newList.push({ value: "", label: "(ninguna)" });
                if (isMounted) setAuthorityList(newList);
            };
            if (isMounted) setIsLoadingAuthority(false);
        }

        let isMounted = true;
        if (reload) {            
            const newData = JSON.parse(localStorage.getItem('data'));            
            const animalSelect = JSON.parse(localStorage.getItem('animal'));            
            if (newData) {
                fetchLoadLabels(newData[0].id, newData[0].type);     
                if (animalSelect) {  // Puede lanzar la creación de animal nuevo y cancelar    
                    newData[0].animal_id = animalSelect.id
                    newData[0].animal_label = animalSelect.label;
                    newData[0].animal_block_label_id = animalSelect.block_label_id;
                    newData[0].animal_name = animalSelect.name;
                    setIsModified(true);    // Cuando se recarga es porque se ha seleccionado un animal
                }          
                if (isMounted) setData(newData);
            }    
            localStorage.removeItem('data');
            localStorage.removeItem('animal');
            if (isMounted) {
                setIsLoading(false);
                setIsLoadingAuthority(false);
            }   
        } else {
            const type = user.profile==="veterinario" ? "Saliva" : "Heces";
            fetchLoadLabels(id, type);    
            fetchLoadAuthorityList();
            if (isNew) {
                // Para muestras nuevas no se realiza la carga inicial        
                const newData = [{                    
                    id: "",
                    sampled: moment(new Date()).format("YYYY-MM-DD"),
                    sent: "",
                    status: sampleStatus[indexRegistered].label,
                    comments_rec: "",
                    comments_lab: "",
                    type: type,
                    block_label_id: "",
                    label: "",
                    authority: "",
                    animal_id: "",
                    animal_label: "",
                    animal_block_label_id: "",
                    animal_name: "",
                    latitude: "",
                    longitude: "",
                    serial: "",
                    user_id: "",
                    is_attached: "",    
                    is_disabled: "",
                    is_archived: "",
                }];
                if (isMounted) {
                    setData (newData);
                    setIsModified(true);
                    setIsLoading(false);
                }
            } else {
                // Carga inicial desde la API                
                id && fetchLoad();
                id && fetchLoadImage();
            }     
        }
        return () => { isMounted = false };
    },[id, isNew, reload, user])    

    const handleChange = (event) => {
        const newData = event.target.name === "sent" && event.target.value && data[0].status === sampleStatus[indexRegistered].label 
        ? [{
            ...data[0],
            [event.target.name] : event.target.value,            
            status : sampleStatus[indexSent].label 
        }]
        : [{
            ...data[0],
            [event.target.name] : event.target.value,            
        }];
        setData(newData);
        setActiveStep(getStatusIndex(newData[0].status));      
        setIsModified(true);
    }

    const handleMapClick = (event) => {
        const newData = [{
            ...data[0],
            latitude: event.lat.toFixed(7).toString(),
            longitude: event.lng.toFixed(7).toString()
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleButtonCurrentClick = () => {
        const newData = [{
            ...data[0],
            latitude: position.lat.toString(),
            longitude: position.lng.toString()
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleLabelChange = (event) => {

        const arrLabel = event.target.value.split("-");
        const newData = [{
            ...data[0],
            serial: arrLabel[1],   
            block_label_id : arrLabel[2],
            label: arrLabel[3],
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleApply = () => {
        // Botón aplicar
        if (isNew) {
            saveAndRefresh();
        } else {
            saveForm();
        }
    }

    const handleExit = () => {
        // Botón salir
        if (isModified && !isSaving) {
            setOpenSaveDialog(true);
        } else {
            setOpenSaveDialog(false);
            exit();
        }
    }

    const handleDelete = () => {
        if (data[0] && data[0].is_disabled) {
            // Botón habilitar
            enableForm();
        } else {
            // Botón eliminar
            setOpenDeleteDialog(true);
        }
    }

    const handleYesSave = () => {
        setOpenSaveDialog(false);
        saveAndExit();
    }

    const handleNoSave = () => {
        setOpenSaveDialog(false);
        exit();
    }

    const handleCancelSave = () => {
        setOpenSaveDialog(false);
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        deleteAndExit() 
    }

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }  

    const lat = (user.profile==="veterinario") 
        ? 0 
        : data[0].latitude 
            ? parseFloat(data[0].latitude) 
            : position.lat ? position.lat : (user.latitude ? parseFloat(user.latitude) : 0);
    const lng = (user.profile==="veterinario") 
        ? 0 
        : data[0].longitude 
            ? parseFloat(data[0].longitude) 
            : position.lng ? position.lng: (user.longitude ? parseFloat(user.longitude) : 0);
    
    // Valores iniciales
    let center = { lat: 0, lng: 0 };
    let points = [{ id: "", lat: 0, lng: 0 }];

    // Comprueba si las coordenadas son correctas y las asigna
    if (lat <= 90 && lat >= -90 && lng <= 180 && lng >= -180) {
        center = { lat: lat, lng: lng }
        points = [{ id: data[0].id, lat: lat, lng: lng }];
    }

    const isAllColumn = (windowDim.width < 400) 
        || (windowDim.width > 960 && windowDim.width < 1050) 
        || (windowDim.width > 600 && windowDim.width < 810)

    const comments_lab = (data[0] && data[0].comments_lab !== undefined) ? data[0].comments_lab : ""    
    const animalLabel = (data[0] && data[0].animal_label && data[0].animal_block_label_id 
        && data[0].animal_label !== undefined && data[0].animal_block_label_id !== undefined)
        ? `${prefixAnimal}-${data[0].serial}-${zeroFill(data[0].animal_block_label_id, 5)}-${zeroFill(data[0].animal_label, 3)}`
        : ""
    const prefixSample = data[0].type === "Heces" ? prefixFeces : prefixSaliva;
    const sampleLabel = (data[0] && data[0].label !== undefined && !isLoadingLabels && data[0].block_label_id > 0) 
        ? `${prefixSample}-${data[0].serial}-${zeroFill(data[0].block_label_id, 5)}-${zeroFill(data[0].label, 3)}` 
        : ""

    const readOnly = !isNew 
        && (user.profile === "recolector" || user.profile === "veterinario") 
        && data[0].status !== sampleStatus[indexRegistered].label 
        && data[0].status !== sampleStatus[indexSent].label;

    if (isLoading || isLoadingLabels || isLoadingAuthority) {
        return <Loading />
    } else {   
        return (
            <div className={classes.container}>
                <Title
                    text={isNew ? "Nueva muestra" : sampleLabel} 
                    icon={SamplesIcon} 
                    pathBack={`${pathBack}/muestras`}
                    disabled={data[0] && data[0].is_disabled}
                    archived={data[0] && data[0].is_archived}
                />    
                <FormGroup className={classes.form}>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>
                            <SampleTitle type={data[0] ? data[0].type : ""} />
                            <StepperStatus 
                                data={data} 
                                setData={setData} 
                                setIsModified={setIsModified}
                                profile={user.profile}
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                disabled={isLoading || readOnly}
                            />
                            <SelectField
                                className={classes.field}
                                disabled={isLoading || readOnly} 
                                value={sampleLabel} 
                                onChange={handleLabelChange}
                                label="Etiqueta de muestra"
                                id="label"
                                name="label"
                                list={labels}
                                error={errorLabel}
                                helperText={errorLabel ? "Campo obligatorio" : ""}
                            />
                            <Grid container spacing={isAllColumn ? 0 : 3}>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || readOnly} 
                                        variant="outlined"                                
                                        value={
                                            (data[0] && data[0].sampled !== undefined && data[0].sampled !== "0000-00-00") 
                                            ? data[0].sampled 
                                            : ""
                                        } 
                                        onChange={handleChange}
                                        label="Fecha de toma"
                                        id="sampled"
                                        name="sampled"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={isAllColumn ? 12 : 6}>                        
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || readOnly} 
                                        variant="outlined"                                
                                        value={
                                            (data[0] && data[0].sent !== undefined && data[0].sent !== "0000-00-00") 
                                            ? data[0].sent 
                                            : ""
                                        } 
                                        onChange={handleChange}
                                        label="Fecha de envío"
                                        id="sent"
                                        name="sent"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                            { data[0].type === "Saliva" 
                            ? <AnimalSelector 
                                id={data[0].animal_id} 
                                title={animalLabel}
                                subtitle={data[0].animal_name}
                                history={history}
                                pathBack={pathBack}
                                isNew={!!isNew}
                                data={data}
                                disabled={isLoading || readOnly}
                            /> : ""}                    
                            {data[0].type === "Heces"
                            ? <MapField 
                                classContainer={classes.fieldTitle} 
                                center={center} 
                                points={points}
                                address={address}
                                setAddress={setAddress}                               
                                handleMapClick={
                                    (user.profile==="administrador" || user.profile==="recolector") && !readOnly
                                    ? handleMapClick : null 
                                }
                                handleButtonCurrentClick={
                                    (user.profile==="administrador" || user.profile==="recolector") && !readOnly
                                    ? handleButtonCurrentClick : null 
                                }
                            /> : ""}
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>
                            { ((user.profile==="recolector" || user.profile==="administrador") && data[0].type==="Heces")
                            ? <SelectField
                                className={classes.field}
                                disabled={isLoading || readOnly} 
                                value={(data[0] && data[0].authority !== undefined) ? data[0].authority : ""} 
                                onChange={handleChange}
                                label="Autoridad competente"
                                id="authority"
                                name="authority"
                                list={authorityList}                                                            
                            /> : "" }
                            <TextField
                                className={classes.field}
                                disabled={isLoading || readOnly}
                                variant="outlined" 
                                value={
                                    (data[0] && data[0].comments_rec !== undefined) 
                                    ? data[0].comments_rec 
                                    : ""
                                } 
                                onChange={handleChange}
                                label="Comentarios del recolector" 
                                id="comments_rec"
                                name="comments_rec"
                                rows={data[0].type === "Heces" || windowDim.width < 600 ? 3 : 10}
                                multiline
                                inputProps={{ maxLength: 255 }}
                            />
                            {comments_lab==="" 
                            ? "" 
                            : <TextField
                                className={classes.field}
                                disabled={true} 
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" 
                                value={comments_lab} 
                                onChange={handleChange}
                                label="Comentarios del laboratorio" 
                                id="comments_lab"
                                name="comments_lab"
                                rows={data[0].type === "Heces" || windowDim.width < 600 ? 3 : 5}
                                multiline                        
                                inputProps={{ maxLength: 255 }}
                            />
                            }
                            {data[0].type === "Heces"
                            ? <ImageField
                                classContainer={classes.fieldTitle} 
                                image={image} 
                                setImage={setImage}
                                data={data}
                                setData={setData}
                                setIsModified={setIsModified}
                                disabled={(user.profile!=="administrador" && user.profile!=="recolector") || readOnly}
                                description="Fotografía de la muestra tomada (preferentemente en horizontal)."                                
                            /> : ""}                         
                        </Grid>
                    </Grid>
                    <div className={classes.bottomSpace}/>
                    <FloatAlert
                        open={error.open} 
                        onClose={handleErrorClose} 
                        text="No hemos podido leer o guardar los cambios. Comprueba tu conexión." 
                        severity="error"
                    />
                </FormGroup> 
                <FormGroupButton
                    disabledApply={isLoading || !isModified}
                    isSaving={isSaving}
                    hideDelete={!!isNew}
                    handleApplyClick={handleApply} 
                    handleExitClick={handleExit} 
                    handleDeleteClick={readOnly ? null : handleDelete}
                    textDelete={data[0] && data[0].is_disabled ? "Habilitar" : ""}                    
                />
                <SaveDialog
                    open={openSaveDialog}
                    onClickYes={handleYesSave}
                    onClickNo={handleNoSave}
                    onClickCancel={handleCancelSave}
                />
                <DeleteDialog
                    open={openDeleteDialog}
                    onClickYes={handleYesDelete}
                    onClickNo={handleCancelDelete}
                    onClickCancel={handleCancelDelete}
                    title={"¿Estás seguro de que quieres eliminar esta muestra?"}
                    description={"Si continúas este proceso se eliminará la muestra del sistema, " 
                            + "salvo que tuviera información vinculada, en cuyo caso sería deshabilidada."}
                />
            </div>
        )
    }
}