/**
 * Pantalla principal de la aplicación que integra el menú, y la barra de título
 */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBarMain } from './AppBarMain';
import { DrawerMain } from "./DrawerMain";
import { ContainerMain } from "./ContainerMain";
import { fetchApi, mysqlEscape } from "../../hooks/DataFetch";
import { UserContext } from "../../contexts/UserContext";
import { useIsMounted } from "../../hooks/IsMounted";
import { useEventListener } from '../../hooks/EventListener';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}))

export function Main({ title, menu:Menu }) {
    const { user, setUser } = useContext(UserContext);
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [numNotif, setNumNotif] = useState(0);
    const isMounted = useIsMounted();
    const history = useHistory();

    useEffect (() => {
        async function fetchNotif() {
            // Carga el número de notificaciones
            const response = await fetchApi({ 
                query: "SELECT count(*) as count FROM advise WHERE user_id='" 
                    + mysqlEscape(user.id) + "' AND is_notified = 0" 
            }); 
            if (response.data) { 
                if (isMounted) setNumNotif(JSON.parse(response.data)[0].count)
            };
        }

        async function fetchConfigClient() {
            // Carga la latitud y longitud del cliente
            const response = await fetchApi({ 
                query: "SELECT latitude, longitude, serial FROM client WHERE id='" 
                    + mysqlEscape(user.client_id) + "'" 
            }); 
            if (response.data) { 
                const dataJson = JSON.parse(response.data);
                if (isMounted) {
                    dataJson[0] && setUser({
                        ...user, 
                        latitude: dataJson[0].latitude, 
                        longitude: dataJson[0].longitude,
                        serial: dataJson[0].serial                            
                    })
                }
            };
        }

        fetchNotif();
        fetchConfigClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user.id, isMounted])

    const handleDrawerOpen = () => {
        setOpen(true);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    }  

    async function fecthIdSample({ blockLabel, label }) {
        // Obtiene el identificador de muestra a partir de la etiqueta
        const response = await fetchApi({ 
            query: "SELECT id FROM sample WHERE block_label_id = " + parseInt(blockLabel) 
                + " AND label = " + parseInt(label)
        });         
        if (response.data) {
            if (JSON.parse(response.data)[0]) { 
                if (isMounted) {
                    return JSON.parse(response.data)[0].id;
                }
            }
        };
        return false;
    }

    // Captura el teclado para detectar códigos de barras y abrir la ficha de muestra
    let codebar = "";
    async function handleKeyDown ({ key }) {
        if (user.profile==="laboratorio") {
            if (key === "Enter") {
                if (document.activeElement.type !== "text") {                
                    const arrayCodeRead = codebar.split('-',4);
                    codebar = "";
                    const blockLabel = arrayCodeRead[2];
                    const label = arrayCodeRead[3];
                    if (blockLabel && label) {
                        const idSample = await fecthIdSample ({ blockLabel, label });
                        idSample && history.push(`/laboratorio/muestras/${idSample}`);
                    }
                }
            }
            if (key.length === 1) codebar += key;
        }
    }
    useEventListener('keydown', handleKeyDown);

    return (
        <div className={classes.root}>
            <AppBarMain 
                handleDrawerOpen={handleDrawerOpen} 
                title={title} 
                numNotif={numNotif} 
            />
            <DrawerMain 
                handleDrawerClose={handleDrawerClose} 
                open={open}
            >
               <Menu handleDrawerClose={handleDrawerClose}/>
            </DrawerMain>
            <ContainerMain setNumNotif={setNumNotif} />       
        </div>
    )
}