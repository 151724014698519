/**
 * Panel de preferencias de administración
 */
import React, { useState, useContext } from "react";
import { FormControlLabel, FormGroup, Switch, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fetchApi } from "../../hooks/DataFetch";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { ReactComponent as SettingsIcon } from '../../assets/gearwheel.svg';
import { SettingsContext } from "../../contexts/SettingsContext";
import { useIsMounted } from "../../hooks/IsMounted";

const useStyles = makeStyles((theme)=> ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        userSelect: "none",
    },
    form: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(0.3),
            paddingBottom: theme.spacing(0.3),
        },
    },
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    typography: {    
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        }
    },
    option: {
        marginBottom: theme.spacing(1),
    }
}))

export function PreferencesAdmin() {
    const { settings, setSettings } = useContext(SettingsContext);
    const classes = useStyles();
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();
    
    const handleChange = (event) => {
         async function fetchUpdate(newSettings) {
            const query = "UPDATE setting SET is_remember="+
                !!newSettings.is_remember+", is_strong="+
                !!newSettings.is_strong+", is_recover="+
                !!newSettings.is_recover+" WHERE id=1";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) {
                    setError(errorRead);
                    setSettings(settings);    
                }
            }
        }      

        const newSettings = {...settings, [event.target.name]: event.target.checked};
        if (isMounted) setSettings(newSettings);
        fetchUpdate(newSettings);         
    }

    const handleErrorClose = () => { 
        setError({ ...error, open: false }) 
    }

    return (
        <div className={classes.container}>
            <Title text="Preferencias" icon={SettingsIcon} pathBack="/administrador"/>
            <FormGroup className={classes.form}>
                <Typography className={classes.title} variant="h5" component="h4" color="primary">Seguridad de acceso</Typography>
                <FormControlLabel
                    className={classes.option}
                    control={<Switch checked={settings.is_remember} onChange={handleChange} name="is_remember" />}
                    label={<Typography className={classes.typography}>Permitir recordar la contraseña en el equipo</Typography>}
                />
                <FormControlLabel 
                    className={classes.option} 
                    control={<Switch checked={settings.is_strong} onChange={handleChange} name="is_strong" />}
                    label={<Typography className={classes.typography}>Forzar el uso de contraseñas seguras</Typography>}
                />
                <Typography className={classes.title} variant="h5" component="h4" color="primary">Notificaciones</Typography>
                <FormControlLabel 
                    className={classes.option} 
                    control={<Switch checked={settings.is_recover} onChange={handleChange} name="is_recover" />}
                    label={<Typography className={classes.typography}>Notificar al administrador la recuperación de contraseña</Typography>}
                />
                <FloatAlert 
                    open={error.open} 
                    onClose={handleErrorClose} 
                    text="No hemos podido guardar los cambios. Comprueba tu conexión." 
                    severity="error"
                />
            </FormGroup>
     </div> 
    )
}
