/**
 * Título de muestra 
 */
import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SampleIcon } from "./SampleIcon";

const useStyles = makeStyles ((theme) => ({
    container: {
        display: "flex",
        flexDirection: "center",
        alignItems: "center",
        marginTop: theme.spacing(1),
    },
    text: {
        paddingLeft: theme.spacing(2),
    },
}));

export function SampleTitle({ type }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
        <SampleIcon selector={type} />
        <Typography 
            className={classes.text} 
            variant="h6" 
            component="h2" 
            color="primary"
        >
            {
                type==="Saliva" 
                ? "Muestra de saliva" : type === "Heces" 
                ? "Muestra de heces" : "Muestra no definida"
            }
        </Typography>
    </div>
)
}
