/**
 * Subtítulo del contenedor que muestra la opción seleccionada con icono opcional
 */
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Typography, SvgIcon, Divider, Hidden, IconButton, Chip } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { viewBox } from "../../config";
import { titleHeight } from "../../config";

const useStyles = makeStyles((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[50]
        },        
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: alpha(theme.palette.primary.main, 0.1)
        },
        display: "flex",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(2),
        },
        height: `${titleHeight}px`,
        userSelect: "none",
    },
    icon: {
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(2),
    },
    button: {
        marginRight: theme.spacing(1),
    },
    chip: {
        marginLeft: theme.spacing(3),
    },
    text: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}));

export function Title({ text, icon, hideDivider, pathBack, disabled, archived }) {
    const classes = useStyles();
    const history = useHistory();

    const handleBack = () => {
        if (pathBack) history.push (pathBack);
    }

    return (
        <Fragment>
            <div className={classes.container}>
                {pathBack &&    
                    <Hidden mdUp>
                        <IconButton className={classes.button} aria-label="atrás" onClick={handleBack} size="small">
                            <ArrowBackIcon />
                        </IconButton>
                    </Hidden>
                }      
                {icon && <SvgIcon className={classes.icon} component={icon} viewBox={viewBox}/>}
                <Typography variant="h5" component="h2" color="secondary" className={classes.text}>{text}</Typography>
                {disabled || archived 
                ? <Chip className={classes.chip} label={disabled ? "Deshabilitado" : "Archivado"} disabled /> 
                : ""}
            </div>
            {hideDivider ? "" : <Divider />} 
        </Fragment>
    )
}
