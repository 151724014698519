import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDataFetch, mysqlEscape } from "../../hooks/DataFetch";
import { Map } from "../common/Map";
import { indexAccepted, indexAnalyzed, indexIssued, indexRegistered, indexSent, sampleStatus, titleHeight } from "../../config";
import { SearchTitle } from "../common/SearchTitle";
import { MapMenuFilter } from "../samples/MapMenuFilter";
import { MapIcon } from "../samples/MapIcon";

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
    },
    containerMap: {
        height: `calc(100% - ${titleHeight}px - 1px)`,
    }
}));

export function SampleMap({ pathBack }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const [filter, setFilter] = useState(filterParam);

    const selectDetection = 
        "SELECT id, latitude, longitude, 'Detección' as source FROM detection " 
        + "WHERE client_id='" + mysqlEscape(user.client_id) + "' AND is_archived = 0 ";
    const selectNotAnalyzed = 
        "SELECT sample.id as id, sample.latitude as latitude, sample.longitude as longitude, 'No analizada' as source " 
        + "FROM sample LEFT JOIN user ON (sample.user_id = user.id) " 
        + "WHERE sample.type = 'Heces' AND user.client_id='" + mysqlEscape(user.client_id) 
        + "' AND (sample.status ='" + sampleStatus[indexRegistered].label 
        + "' OR sample.status ='" + sampleStatus[indexSent].label 
        + "' OR sample.status ='" + sampleStatus[indexAccepted].label 
        + "' OR sample.status ='" + sampleStatus[indexAnalyzed].label + "') ";
    const selectMatched = 
        "SELECT a.id as id, a.latitude as latitude, a.longitude as longitude, 'Coincidente' as source " 
        + "FROM sample as a LEFT JOIN user ON (a.user_id = user.id) " 
        + "WHERE a.sample_id > 0 AND a.type = 'Heces' AND user.client_id='" + mysqlEscape(user.client_id) 
        + "' AND a.status ='" + sampleStatus[indexIssued].label + "' AND (SELECT COUNT(*) FROM sample as b WHERE a.sample_id = b.sample_id) <= 1"; 
    const selectMismatched = 
        "SELECT sample.id as id, sample.latitude as latitude, sample.longitude as longitude, 'No coincidente' as source " 
        + "FROM sample LEFT JOIN user ON (sample.user_id = user.id) " 
        + "WHERE sample.sample_id = 0 AND sample.type = 'Heces' AND user.client_id='" + mysqlEscape(user.client_id) 
        + "' AND sample.status ='" + sampleStatus[indexIssued].label + "' ";
    const selectRepeatOffender = 
        "SELECT a.id as id, a.latitude as latitude, a.longitude as longitude, 'Reincidente' as source " 
        + "FROM sample as a LEFT JOIN user ON (a.user_id = user.id) " 
        + "WHERE a.sample_id > 0 AND a.type = 'Heces' AND user.client_id='" + mysqlEscape(user.client_id) 
        + "' AND a.status ='" + sampleStatus[indexIssued].label + "' AND (SELECT COUNT(*) FROM sample as b WHERE a.sample_id = b.sample_id) > 1"; 

    const query = filter==="detecciones" 
        ? selectDetection
        : filter==="noanalizadas"
            ? selectNotAnalyzed
            : filter==="coincidentes"
                ? selectMatched
                : filter==="nocoincidentes"
                    ? selectMismatched
                    : filter==="reincidentes"
                        ? selectRepeatOffender
                        : selectDetection  + " UNION " + selectNotAnalyzed + " UNION " + selectMatched 
                            + " UNION " + selectMismatched + " UNION " + selectRepeatOffender
                            
    const { data } = useDataFetch({ query: query });
 
    const center = { 
        lat: user.latitude ? parseFloat(user.latitude) : 0, 
        lng: user.longitude ? parseFloat(user.longitude) : 0
    };

    const points = data 
    ? data.map (row => ({ 
        id: row.id, 
        lat: row.latitude, 
        lng: row.longitude,
        source: row.source
    })) 
    : []; 

    function getTitleSamples (filter) {
        return (
            filter==="detecciones" 
            ? "Detecciones" 
            : (filter==="noanalizadas" 
                ? "No analizadas" 
                : (filter==="coincidentes"
                    ? "Concidentes"
                    : (filter==="nocoincidentes"
                        ? "No coincidentes"
                        : (filter==="reincidentes"
                            ? "Reincidentes"
                            : "Mapa de incidencias"
                        )
                    )
                )
            )
        )
    };

    const handleMarkerClick = (id) => {
        if (data.filter(point => point.id === id)[0].source==="Detección") {
            history.push (`/supervisor/detecciones/${id}`);
        } else {
            history.push (`/supervisor/muestras/${id}`);            
        }
    }

    return (
        <div className={classes.container}>
            <SearchTitle 
                filter={filter} 
                setFilter={setFilter}
                pathBack={pathBack}
                title={getTitleSamples(filter)}
                selectIcon={MapIcon} 
                menu={MapMenuFilter}
            />            
            <div className={classes.containerMap}>
                {user.latitude 
                ? <Map
                    center={center} 
                    points={points} 
                    defaultZoom={14}
                    handleMarkerClick={handleMarkerClick}
                />
                : ""}
            </div>
        </div>
    )
}
