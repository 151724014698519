/**
 * Campo de mapa con dirección y seleccion de coordenadas
 */
import React from "react"
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Map } from "./Map";
import { MapAddress } from "./MapAddress";

const useStyles = makeStyles ((theme) => ({
    containerMap: {
        display: "flex",
        height: "300px",
        padding: theme.spacing(2),
        border: "1px dashed",
        borderRadius: "5px",
        borderColor: theme.palette.grey[400],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            borderColor: theme.palette.grey[600],
        }
    },
    button: {
        marginTop: theme.spacing(2),
    }
}));

export function MapField({ 
    center, 
    points, 
    address, 
    setAddress, 
    classContainer, 
    handleMapClick, 
    handleButtonCurrentClick 
}) {
    const classes = useStyles();

    return (
        <div className={classContainer}>
            <Typography variant="h6" component="h2" color="primary">
                Ubicación
            </Typography>
            <div className={classes.containerMap}>
                <Map 
                    center={center} 
                    points={points} 
                    handleMapClick={handleMapClick} 
                />
            </div>
            <MapAddress 
                latLng={center} 
                address={address} 
                setAddress={setAddress} 
            />
            { handleButtonCurrentClick 
                ?
                <Button 
                    className={classes.button} 
                    color="primary" 
                    variant="outlined"
                    onClick={handleButtonCurrentClick}
                >
                    Poner mi ubicación
                </Button> 
                : "" 
            }                      
        </div>
    )
}
