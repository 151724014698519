/**
 * Componente principal de entrada para mostrar las muestras 
 */
import React, { Fragment, useContext, useState } from "react";
import { useLocation } from "react-router";
import { UserContext } from "../../contexts/UserContext";
import { ListForm } from "../common/ListForm";
import { SampleIcon } from "./SampleIcon";
import { SamplesMenuFilter } from "./SamplesMenuFilter";
import { fetchApi, mysqlEscape, mysqlInteger } from "../../hooks/DataFetch";
import { indexAccepted, indexAnalyzed, indexIssued, indexRegistered, indexReplaced, indexSent, sampleStatus } from "../../config";
import { getSelect } from "../../utils/queryUtils";
import { useIsMounted } from "../../hooks/IsMounted";
import { FloatAlert } from "../common/FloatAlert";
import moment from "moment";

export function Samples({ pathBack, type }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const { user } = useContext(UserContext);        
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();

    const table = "sample";
    const defaultColumns = [
        { id: "checkbox", width: 50, type: "checkbox"},
        { id: "type", width: 50, type: "icon" },
        { id: "label", label: "Etiqueta", width: 130, type: "labelS"},
        { id: "status", label: "Estado", width: 100, type: "color" },
        { id: "sampled", label: "Toma", width: 120, type: "date" },
        { id: "sent", label: "Envío", width: 120, type: "date" },
    ]
    // Campo para indicar fecha de importación a usuarios de laboratorio
    if (user.profile==="laboratorio") {
        defaultColumns.splice(3, 0, { id: "is_imported", label: "", width: 50, type: "imported" })   
    }    

    // El analista, el supervisor y el administrador podrán ver el recolector/veterinario que realiza la muestra
    if (user.profile==="laboratorio" || user.profile==="supervisor" || user.profile==="administrador") {
        defaultColumns.splice(6, 0, { 
            id: "user_name", 
            label: user.profile==="supervisor" ? "Recolector" : "Veterinario/Recolector", 
            width: 200, table:"user", 
            fieldName: "name"
        })
    }
    // Columnas de comentarios
    if (user.profile==="recolector" || user.profile==="veterinario" || user.profile==="administrador") {
        defaultColumns.splice(6, 0, { id: "comments_rec", label: "Comentarios del recolector", width: 250 })   
        defaultColumns.splice(7, 0, { id: "comments_lab", label: "Comentarios del laboratorio", width: 250 })   
    }    
    // El analista debe poder ver las fechas de inicio, fin y observaciones en vez de comentarios
    if (user.profile==="laboratorio" || user.profile==="supervisor" || user.profile==="administrador") {
        defaultColumns.splice(7, 0, { id: "date_start", label: "Fecha inicio", width: 120, type: "date" })
        defaultColumns.splice(8, 0, { id: "date_end", label: "Fecha fin", width: 120, type: "date" })
        defaultColumns.splice(9, 0, { id: "observations", label: "Observaciones", width: 250 })
    }
    // Si es analista sin cliente fijo se muestra la columna de cliente
    if ((user.profile==="laboratorio" && user.client_id ==="") || user.profile==="administrador") {
        defaultColumns.splice(7, 0, { id: "client_name", label: "Cliente", width: 200, table:"client", fieldName: "name"})
    }
    // Los veterinarios, supervisores y administradores verán la columna de animal
    if (user.profile==="veterinario" || user.profile==="administrador") {
        defaultColumns.splice(6, 0, { id: "animal_label", label: "Animal", width: 200, table: "animal", fieldName: "label", type: "labelA" })
    }
    // Para los recolectores, supervisores y administradores se muestra la columna de imagen adjunta, autoridad competente
    if (user.profile==="recolector" || user.profile==="supervisor" || user.profile==="administrador") {
        defaultColumns.splice(6, 0, { id: "is_attached", label: "Foto", width: 70, type: "attached" })
        defaultColumns.splice(7, 0, { id: "authority", label: "Autoridad comp.", width: 150 })
    }

    function getQuerySamples ({ filter, search, limitPlus, offset = 0, columns, orderBy, orderDirection }) {                
        // Filtro por estado seleccionado
        let whereStatus = "";
        if (filter) { 
            if (filter === "deshabilitada") { 
                whereStatus = `${table}.is_disabled = 1`;
            } else {
                if (filter === "archivada") {
                    whereStatus = `${table}.is_disabled = 0 AND ${table}.is_archived = 1`;
                } else {
                    whereStatus = `${table}.is_disabled = 0 AND ${table}.is_archived = 0 AND ${table}.status = '${mysqlEscape(filter)}'`;
                }
            }
        } else {
            whereStatus = `${table}.is_disabled = 0  AND ${table}.is_archived = 0 AND ${table}.status <> '${sampleStatus[indexReplaced].label}'`;
        }
        // Filtro por estado, tipo, usuario o cliente
        let whereProfile = "";
        let whereUser = "";
        let whereType = "";
        if (user.profile==="laboratorio") {
            if (user.client_id!=="") {
                // Laboratorio con cliente
                whereUser += ` AND client.id = '${mysqlEscape(user.client_id)}'`
            }
            whereProfile += ` AND ${table}.status <> '${sampleStatus[indexRegistered].label}'`;                
        } else {
            if (user.profile==="supervisor") {
                // Supervisor
                whereUser += ` AND client.id = '${mysqlEscape(user.client_id)}'`
            } else {
                if (user.profile!=="administrador") {
                    // Recolector o veterinario
                    whereUser += ` AND ${table}.user_id = '${mysqlEscape(user.id)}'`
                    if (user.profile==="recolector") {
                        whereProfile += ` AND ${table}.status <> '${sampleStatus[indexAnalyzed].label}' `
                            +`AND ${table}.status <> '${sampleStatus[indexIssued].label}'`;            
                    }
                }             
            }
        }
        // Desde el laboratorio se puede diferenciar el listado por tipo
        if (type && user.profile==="laboratorio") {
            if (type==="Saliva") {
                whereType += ` AND ${table}.type = 'Saliva'`
            } else {
                whereType += ` AND ${table}.type = 'Heces'`
            }    
        }
        // Los supervisores sólo ven muestras de heces
        if (user.profile==="supervisor") {
            whereType += ` AND ${table}.type = 'Heces'`
        }
        const where = ` WHERE (${whereStatus}${whereUser}${whereProfile}${whereType})`;        
        const leftJoin = " LEFT JOIN animal ON (" + table + ".animal_id = animal.id) " 
            + "LEFT JOIN user ON (" + table + ".user_id = user.id) " 
            + "LEFT JOIN client ON (user.client_id = client.id) ";
        const extraFields = user.profile==="laboratorio" ? ", sample.results" : "";
        const fixedFields = ", " + table +".block_label_id as sample_block_label_id, " 
            + "animal.block_label_id as animal_block_label_id, client.serial, sample.id as id, sample.sample_id as sample_id" 
            + extraFields;            
        const descending = true;
        return getSelect({ 
            columns, 
            defaultColumns, 
            table, 
            leftJoin, 
            where, 
            search, 
            fixedFields, 
            offset, 
            limitPlus, 
            descending, 
            orderBy, 
            orderDirection 
        }); 
    };

    function getTitleSamples (filter) {
        const itemTitle = sampleStatus.filter(state => state.label === filter)[0]
        return (
            filter==="deshabilitada" 
            ? "Deshabilitadas" 
            : (filter==="archivada" 
                ? "Archivadas" 
                : itemTitle 
                    ? itemTitle.title 
                    : user.profile==="laboratorio" 
                        ? (type==="Saliva" ? "Muestras de saliva" : "Muestras de heces")
                        : "Muestras"
            )
        )
    };    
    
    function actionSampleClick ({ id, pathBack, filter, history }) {
        if (filter) {
            history.push(`${pathBack}/muestras/${id}?filter=${filter}`);
        } else {
            history.push(`${pathBack}/muestras/${id}`);
        }    
    };

    async function deleteSample (id) {

        async function isDeleteAllowed() {
            if (user.profile === "administrador") {
                return true;
            } else {
                const query = `SELECT status FROM sample WHERE id=${mysqlInteger(id)}`; 
                const response = await fetchApi({
                    query: query
                });
                if (response.status !== "200") {
                    const errorRead = { open: true, status: response.status };
                    if (isMounted) setError(errorRead);                
                    return false;     
                } else {
                    if (JSON.parse(response.data)[0].status === sampleStatus[indexRegistered].label || 
                        JSON.parse(response.data)[0].status === sampleStatus[indexSent].label) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }

        async function isLinked() {
            const query = `SELECT COUNT(*) as count FROM sample ` 
                + `WHERE status = '${sampleStatus[indexAccepted].label}' AND id=${mysqlInteger(id)}`;   
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function isDisabled() {
            const query = "SELECT is_disabled FROM sample WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_disabled) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdateDisabled() {
            const query = "UPDATE sample SET is_disabled = 1 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchUpdateEnabled() {
            const query = "UPDATE sample SET is_disabled = 0 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM sample WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
           
        if (await isDisabled()) {
            if (await fetchUpdateEnabled()) {
                // Habilitación correcta
                return true
            } else {
                // Error de API
                return false
            }
        } else {
            if (await isDeleteAllowed()) {
                if (await isLinked()) {
                    if (await fetchUpdateDisabled()) {
                        // Deshabilitación correcta
                        return true;              
                    } else {
                        // Error de API
                        return false;
                    }
                } else {
                    if (await fetchDelete()) {
                        // Eliminación correcta
                        return true;              
                    } else {
                        // Error de API
                        return false;
                    }
                }
            } else {
                return false;
            }
        }
    }

    async function updateSample (element, status) {

        async function fetchUpdate(query) {
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function isStatusSample(id, status) {
            const query = "SELECT status FROM sample WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return false;   
            } else {
                if (JSON.parse(response.data)[0].status === status) {
                    return true;
                } else {
                    return false;
                }
            }
        }     
        
        async function isSampleArchived(id) {
            const query = "SELECT is_archived FROM sample WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return false;   
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_archived) === 1) {
                    return true;
                } else {
                    return false;
                }
            }
        }         

        switch (status) {
            case sampleStatus[indexAccepted].label:  
                if (await isStatusSample(element.id, sampleStatus[indexSent].label)) {
                    // Sólo se aceptan muestras en estado Enviada  
                    element.status = sampleStatus[indexAccepted].label;
                    await fetchUpdate(`UPDATE sample SET status='${sampleStatus[indexAccepted].label}' WHERE id=${mysqlInteger(element.id)}`);
                    return element;
                } else {
                    return element;
                }
            case sampleStatus[indexSent].label:   
                if (await isStatusSample(element.id, sampleStatus[indexRegistered].label)) {
                    // Sólo se envían muestras en estado Registrada  
                    element.sent = moment(new Date()).format("YYYY-MM-DD");
                    element.status = sampleStatus[indexSent].label;
                    await fetchUpdate(`UPDATE sample SET status='${sampleStatus[indexSent].label}', sent='${element.sent}' WHERE id=${mysqlInteger(element.id)}`);
                    return element;
                } else {
                    return element;
                }
            case "Archivada": // No es un estado real 
                if (await isSampleArchived(element.id)) {
                    await fetchUpdate(`UPDATE sample SET is_archived = 0 WHERE id=${mysqlInteger(element.id)}`);
                } else {
                    await fetchUpdate(`UPDATE sample SET is_archived = 1 WHERE id=${mysqlInteger(element.id)}`);
                }
                return element;
            default:
        }
    }

    async function actionFunction1 (data) {

        async function actionFunction1Element (element) {
            switch (user.profile) {
                case "laboratorio":
                    // Aceptar
                    const newElementAccepted = await updateSample(element, sampleStatus[indexAccepted].label);
                    return { status: "updated", element:newElementAccepted };
                case "administrador":
                case "veterinario":
                case "recolector":
                    // Eliminar si es posible
                    const result = await deleteSample(element.id);
                    if (result) {
                        return { status: "deleted" };
                    } else {
                        return { status: "notdeleted" };
                    }
                case "supervisor":
                    // Archivar
                    await updateSample(element, "Archivada");
                    return { status: "archived" };
                default:
            }
        };

        let newData = [];
        let warning = false;
        for (const element of data) {
            if (element.checkbox) {
                const result = await actionFunction1Element(element);
                switch (result.status) {
                    case "updated":
                        newData.push(result.element);
                        break;
                    case "notdeleted":
                        newData.push(element);
                        warning = true;
                        break;
                    default:                
                }
            } else {
                newData.push(element);
            }
        }
        for (let i=0; i<newData.length; i++) {
            newData[i].checkbox = false;
        }
        return {data: newData, warning: warning};
    }

    async function actionFunction2 ({ pathBack, filter, history, data }) {
        let newData = [];
        switch (user.profile) {
            case "laboratorio":
                // Orden
                let listId = [];
                for (const element of data) {
                    if (element.checkbox) listId.push(element.id);
                }
                if (listId.length > 0) {
                    if (filter) {
                        history.push(`${pathBack}/ordenes/${listId}?filter=${filter}`);
                    } else {
                        history.push(`${pathBack}/ordenes/${listId}`);
                    }                    
                }
                newData = [...data];
                break;
            case "veterinario":
            case "recolector":
                // Enviar
                for (const element of data) {
                    if (element.checkbox) {
                        const newElement = await updateSample(element, sampleStatus[indexSent].label);
                        newData.push(newElement);
                    } else {
                        newData.push(element);
                    }
                }                        
                break;
            default:
        }
        for (let i=0; i<newData.length; i++) {
            newData[i].checkbox = false;
        }
        return newData;
    }

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    }    

    const textFunction1 = user.profile==="laboratorio"?"Aceptar"
        :user.profile==="administrador" || user.profile==="veterinario" || user.profile==="recolector"?"Eliminar"
        :user.profile==="supervisor"?"Archivar":"";
    const textFunction2 = user.profile==="laboratorio"?"Orden"
        :user.profile==="veterinario"?"Enviar":"";
    const textDeleteQuestion = user.profile==="administrador" || user.profile==="veterinario" || user.profile==="recolector"
        ?"¿Estás seguro de que quieres eliminar las muestras?":"";
    const textDeleteDescription = user.profile==="administrador" || user.profile==="veterinario" || user.profile==="recolector"
        ?"Si continúas este proceso se eliminarán las muestras del sistema, " 
        + "salvo que tuvieran información vinculada, en cuyo caso serían deshabilidadas":"";

    return (
        <Fragment>
            <ListForm 
                filterParam={filterParam}
                pathBack={pathBack}
                pathNew={user.profile==="veterinario" || user.profile==="recolector" ? "/nuevamuestra" : ""}
                icon={SampleIcon}
                menu={SamplesMenuFilter} 
                getQuery={getQuerySamples} 
                getTitle={getTitleSamples}
                actionItemClick={actionSampleClick}
                defaultColumns={defaultColumns}
                colors={sampleStatus}
                isImport={user.profile==="laboratorio"}
                actionFunction1={actionFunction1}
                actionFunction2={actionFunction2}
                textFunction1={textFunction1}
                textFunction2={textFunction2} 
                textDeleteQuestion={textDeleteQuestion}
                textDeleteDescription={textDeleteDescription}
            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido eliminar o actualizar las muestras. Por favor, comprueba tu conexión." 
                severity="error"
            />
        </Fragment> 
    )

}
