/**
 * Icono de animal para el avatar del item de la lista
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as DogIcon } from '../../assets/dog.svg';
import { ReactComponent as HorseIcon } from '../../assets/horse.svg';
import { viewBox } from "../../config";

export function AnimalIcon({ selector }) {
    return (
        <SvgIcon component={
            selector === "Perro" ? DogIcon : 
            selector === "Caballo" ? HorseIcon : DogIcon
        } viewBox={viewBox} />
    )
}
