import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { spaceFillRight } from "../../utils/StringUtils";

const useStyles = makeStyles ((theme) => ({ 
    container: {
        padding: "5px",
        width: "100%",
        textAlign: "center"
    },    
    field: {
        textAlign: "center",
        marginTop: "1px",
        height: "10px",
        font: "11px Arial, sans-serif",
    },
    label: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        borderRadius: "3px",
        font: "11px Arial, sans-serif",
    },
}));

export function FieldResultReport({ label, indexMarker, results }) {
    const classes = useStyles();
    
    const strResults = results !== "" ? spaceFillRight(results, 114) : " ".repeat(114);
    const arrayResults = [...strResults.match(/.{1,3}/g)];    
    const indexAlelo1 = indexMarker * 2;
    const indexAlelo2 = indexMarker * 2 + 1;
    
    return (
        <div className={classes.container}>
            <div className={classes.label}>
                {label}
            </div>
            <div className={classes.field}>
                {arrayResults && arrayResults[indexAlelo1] ? arrayResults[indexAlelo1].trim() : ""}
            </div>
            <div className={classes.field}>
                {arrayResults && arrayResults[indexAlelo2] ? arrayResults[indexAlelo2].trim() : ""}
            </div>            
        </div> 
    )
}
