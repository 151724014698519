/**
 * Formulario de detalle de bloque de etiquetas
 */
import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { FormGroup, Grid } from "@material-ui/core";
import { ReactComponent as AnimalIcon } from '../../assets/dog.svg';
import { ReactComponent as SalivaIcon } from '../../assets/blot.svg';
import { ReactComponent as FecesIcon } from '../../assets/blot2.svg';
import { ReactComponent as Icon } from '../../assets/barcode.svg';
import { fetchApi, mysqlEscape, jsonParseMysql, mysqlInteger } from "../../hooks/DataFetch";
import { FormGroupButton } from "../common/FormGroupButton";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { Loading } from "../Loading";
import { SaveDialog } from "../common/SaveDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { useIsMounted } from "../../hooks/IsMounted";
import { SelectField } from "../common/SelectField";
import { LabelField } from "./LabelField";
import { buttonGroup, buttonGroupXs, maxBlockLabel } from "../../config";

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",        
    },
    form: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0.5),
        }
    },
    columnGrid: {
        maxWidth: "600px",
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(2),
        }
    },
    field: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    bottomSpace: {
        // La altura debe coincidir con FormGroupButton
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            height: buttonGroupXs,
        }        
    },
}));

export function LabelForm({ isNew, pathBack }) {
    const { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    // Se recuerda el filtro del listado para poder volver al mismo listado filtrado
    const filterParam = new URLSearchParams(useLocation().search).get("filter");     
    
    // Estados de carga de datos
    const [data, setData] = useState([{
        id: "",
        type: "",
        count: maxBlockLabel,
        client_id: "",
        user_id: "",
        user_name: "",
        is_disabled: ""
    }]);
    const [clients, setClients] = useState ([{
        value: "",
        label: "(ninguno)",
        serial: "",
        phone: ""
    }]);
    const [users, setUsers] = useState ([{
        value: "",
        label: "(ninguno)"
    }]);
    const [userLinked, setUserLinked] = useState(false);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingClients, setIsLoadingClients] = useState(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isModified, setIsModified] = useState(false);

    // Estados de validación de errores
    const [errorType, setErrorType] = useState(false);
    const [errorClient, setErrorClient] = useState(false);
    const [error, setError] = useState({ open: false, status: '' });

    // Estados de diálogos
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    async function isLinked({ id, type }) {
        // Comprueba si el bloque de entrada ya fue usado 
        const query = type === "Animal" 
            ? "SELECT COUNT(*) as count FROM animal WHERE block_label_id='" + mysqlEscape(id) + "'" 
            : "SELECT COUNT(*) as count FROM sample WHERE block_label_id='" + mysqlEscape(id) + "'"
        const response = await fetchApi({
            query: query
        });
        if (response.status !== "200") {
            const errorRead = { open: true, status: response.status };
            if (isMounted) setError(errorRead);
            return true;    // No está vinculado pero se evita continuar 
        } else {
            if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                return false;
            } else {
                return true;
            }
        }
    }

    async function saveForm () {

        async function fetchUpdate(newData) {
            let query = "";
            if (isNew) {
                query = "INSERT INTO block_label (type, count, client_id, user_id) VALUES ('" 
                    + mysqlEscape(newData.type) + "', " + mysqlInteger(newData.count) + ", '" 
                    + mysqlEscape(newData.client_id) + "', '" + mysqlEscape(newData.user_id) + "')";
            } else {
                query = "UPDATE block_label SET type='" + mysqlEscape(newData.type) + "', count=" + mysqlInteger(newData.count) 
                    + ", client_id='" + mysqlEscape(newData.client_id) + "', user_id='" + mysqlEscape(newData.user_id) 
                    + "' WHERE id='" + mysqlEscape(id) +"'";
            }
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                if (isNew) lastId = parseInt(JSON.parse(response.data)[0].lastId) || 0;
                return true;
            }
        }     
    
        async function sendAdvise () {
            const text = `Se le ha asignado un nuevo bloque de ${data[0].count} etiquetas.`;
            const query = "INSERT INTO advise (type, text, is_notified, is_viewed, date, user_id) "
                +"VALUES ('label', '" + text + "', 0, 0, NOW(), '" + mysqlEscape(data[0].user_id) + "')";
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
            }
        }

        // Crea una copia de los datos
        if (isMounted) setIsSaving(true);
        let lastId = 0;
        let newData = {...data[0]};
        let verificationError = false;

        // Comprueba los campos obligatorios
        if (newData.type === "") {
            if (isMounted) setErrorType (true);
            verificationError= true;
        }
        if (newData.client_id === "") {
            if (isMounted) setErrorClient (true);
            verificationError= true;
        }

        // Graba los cambios si no hay errores de verificación 
        if (!verificationError) {
            if (await fetchUpdate(newData)) {
                // Envía notificación al usuario
                if (isNew) sendAdvise();
                // Reinicia estados y valores
                if (isMounted) {
                    setIsModified(false);                
                    setIsSaving(false);
                    setErrorType(false);
                    setErrorClient(false);
                }
                return lastId ? lastId : true;
            }
        }
        if (isMounted) setIsSaving(false);
        return false;
    }
    
    async function deleteForm () {

        async function fetchUpdate() {
            const query = "UPDATE block_label SET is_disabled = 1 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM block_label WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        if (await isLinked({ id: data[0].id, type: data[0].type})) {
            if (await fetchUpdate()) {
                // Baja correcta
                return true                
            } else {
                // Error de API
                return false
            }
        } else {
            if (await fetchDelete()) {
                // Eliminación correcta
                return true                
            } else {
                // Error de API
                return false
            }
        }
    }

    async function saveAndExit() {
        // Función asíncrona para guardar y salir que evitar la salida en caso de error
        if (await saveForm()) {            
            exit();
        } 
    }

    async function saveAndRefresh() {
        // Función asíncrona para guardar y recargar la ficha
        const result = await saveForm();
        if (result) {
            if (filterParam) {
                history.push(`${pathBack}/etiquetas/${result}?filter=${filterParam}`);
            } else {
                history.push(`${pathBack}/etiquetas/${result}`);
            }    
        }
    }

    async function deleteAndExit() {
        // Función asíncrona para eliminar el grupo de etiquetas
        if (await deleteForm()) {
            exit();
        }
    }

    async function enableForm() {

        async function fetchUpdate() {
            const query = "UPDATE block_label SET is_disabled = 0 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        } 
        
        if (await fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_disabled: false,
            }];
            if (isMounted) setData(newData);
        }
    }

    function exit() {
        if (filterParam) {
            history.push (`${pathBack}/etiquetas?filter=${filterParam}`);
        } else {
            history.push (`${pathBack}/etiquetas`);
        }
    }
    
    useEffect (() => {    

        async function fetchLoadClients() {
            // Carga la lista de clientes en el desplegable
            if (isMounted) setIsLoadingClients(true);
            const response = await fetchApi({ 
                query: "SELECT id, name, serial, phone FROM client WHERE is_disabled = 0" 
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                const newClients = readData.map(item => ({ 
                    value: item.id, 
                    label: item.name,
                    serial: item.serial,
                    phone: item.phone
                }));                
                if (isMounted) setClients([...newClients, { value: "", label: "(ninguno)", serial: "", phone: "" }]);
            };                  
            if (isMounted) setIsLoadingClients(false);
        }

        async function fetchLoad() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT block_label.id as id, block_label.type as type, block_label.count as count, " 
                    + "block_label.client_id as client_id, block_label.user_id as user_id, " 
                    + "block_label.is_disabled as is_disabled, client.name as client_name, " 
                    + "client.serial as client_serial, client.phone as client_phone, user.name as user_name " 
                    + "FROM block_label " 
                    + "LEFT JOIN client ON (block_label.client_id = client.id) " 
                    + "LEFT JOIN user ON (block_label.user_id = user.id) "
                    + "WHERE block_label.id=" + mysqlInteger(id)  
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                if (isMounted) {
                    const readData = jsonParseMysql(response.data);
                    // Activa el estado enlazado si procede
                    if (await isLinked({ id: readData[0].id, type: readData[0].type})) {
                        setUserLinked(true); 
                    }
                    // Si el cliente esta de baja se añade manualmente al desplegable
                    setClients(prevState => {
                        if (!prevState.find(element => element.value === readData[0].client_id)) {
                            const current = { 
                                value: readData[0].client_id, 
                                label: readData[0].client_name,
                                serial: readData[0].client_serial,
                                phone: readData[0].client_phone
                            };
                            return [...prevState, current];
                        } else {
                            return [...prevState];
                        }
                    });
                    setData(readData);
                }
            };
            if (isMounted) setIsLoading(false);
        }        

        fetchLoadClients();
        if (isNew) {
            // Para grupos de etiquetas nuevos no se realiza la carga inicial
            if (isMounted) setIsLoading(false);
        } else {
            // Carga inicial desde la API
            id && fetchLoad();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id, isNew, isMounted])

    useEffect(() => {
        
        async function fetchLoadUsers() {
            // Carga la lista de usuarios recolectores y veterinarios en el desplegable
            if (isMounted) setIsLoadingUsers(true);
            if (data[0].client_id !== "") {
                const response = await fetchApi({ 
                    query: "SELECT id, name FROM user " 
                        + "WHERE (profile = 'veterinario' OR profile = 'recolector') AND client_id = '" 
                        + mysqlEscape(data[0].client_id) + "'AND is_disabled = 0" 
                });
                if (response.status !== "200") { 
                    if (isMounted) setError({ open: true, status: response.status }) 
                };
                if (response.data) {
                    const readData = jsonParseMysql(response.data);
                    const newUsers = readData.map(item => ({ 
                        value: item.id, 
                        label: item.name 
                    }));
                    // Inserta el usuario actual si no fue cargado por estar de baja
                    if (data[0].user_id) {
                        if (!newUsers.find(element => element.value === data[0].user_id)) {
                            data[0].user_id && newUsers.push ({ value: data[0].user_id, label: data[0].user_name });             
                        }                        
                    }
                    if (isMounted) setUsers([...newUsers, { value: "", label: "(ninguno)" }]);
                };
            } else {
                setUsers([{ value: "", label: "(ninguno)" }]);
            }
            if (isMounted) setIsLoadingUsers(false);
        }

        fetchLoadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, data[0].client_id])

    const handleChange = (event) => {
        const newData = [{
            ...data[0],
            [event.target.name] : event.target.value            
        }];    
        setData(newData);
        setIsModified(true);
    }

    const handleChangeClient = (event) => {
        const newData = [{
            ...data[0],
            [event.target.name] : event.target.value,
            user_id: "",       
        }];    
        setData(newData);
        setIsModified(true);
    }

    const handleSliderChange = (event, value) => {
        const newData = [{
            ...data[0],
            count : value            
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleApply = () => {
        // Botón aplicar
        if (isNew) {
            saveAndRefresh();
        } else {
            saveForm();
        }
    }

    const handleExit = () => {
        // Botón salir
        if (isModified && !isSaving) {
            setOpenSaveDialog(true);
        } else {
            setOpenSaveDialog(false);
            exit();
        }
    }

    const handleDelete = () => {
        if (data[0] && data[0].is_disabled) {
            // Botón habilitar
            enableForm();
        } else {
            // Botón eliminar
            setOpenDeleteDialog(true);
        }
    }

    const handleYesSave = () => {
        setOpenSaveDialog(false);
        saveAndExit();
    }

    const handleNoSave = () => {
        setOpenSaveDialog(false);
        exit();
    }

    const handleCancelSave = () => {
        setOpenSaveDialog(false);
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        deleteAndExit();
    }

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }

    if (isLoading) {
        return <Loading />
    } else {
        return (
            <div className={classes.container}>
                <Title 
                    text={isNew ? "Nuevo bloque de etiquetas" : "Bloque: "+id} 
                    icon={data[0] && data[0].type === "Animal" ? AnimalIcon : 
                        data[0] && data[0].type === "Saliva" ? SalivaIcon : 
                        data[0] && data[0].type === "Heces" ? FecesIcon : Icon} 
                    pathBack={`${pathBack}/etiquetas`}
                    disabled={data[0] && data[0].is_disabled}
                />
                <FormGroup className={classes.form}>
                    <Grid container>
                        <Grid item xs={12} className={classes.columnGrid}>
                            <SelectField
                                className={classes.field}
                                disabled={isLoading || userLinked} 
                                value={(data[0] && data[0].type !== undefined) ? data[0].type : ""} 
                                onChange={handleChange}
                                id="type"
                                name="type"
                                label="Tipo"
                                list={[
                                    {"value" : "Animal", "label" : "Etiqueta de animal"},
                                    {"value" : "Saliva", "label" : "Etiqueta de muestra de saliva"},
                                    {"value" : "Heces", "label" : "Etiqueta de muestra de heces"},
                                    {"value" : "", "label" : "(ninguno)"}                                
                                ]}
                                error={errorType}
                                helperText={errorType ? "Campo obligatorio" : ""}
                            />
                            <SelectField
                                className={classes.field}
                                disabled={isLoading || userLinked} 
                                value={(data[0] && data[0].client_id !== undefined && !isLoadingClients ) ? data[0].client_id : ""} 
                                onChange={handleChangeClient}
                                id="client_id"
                                name="client_id"
                                label="Cliente"
                                list={clients}                                                           
                                error={errorClient}
                                helperText={errorClient ? "Campo obligatorio" : ""}
                            />                        
                            <SelectField
                                className={classes.field}
                                disabled={isLoading || userLinked} 
                                value={(data[0] && data[0].user_id !== undefined && !isLoadingUsers ) ? data[0].user_id : ""} 
                                onChange={handleChange}
                                id="user_id"
                                name="user_id"
                                label="Recolector o veterinario"
                                list={users}                                                         
                            />
                            <LabelField
                                data={data}
                                clients={clients}
                                handleSliderChange={handleSliderChange}
                                isNew={isNew}
                            />                            
                        </Grid>
                    </Grid>                        
                    <div className={classes.bottomSpace}/>
                    <FloatAlert 
                        open={error.open} 
                        onClose={handleErrorClose} 
                        text="No hemos podido leer o guardar los cambios. Comprueba tu conexión." 
                        severity="error"
                    />
                </FormGroup> 
                <FormGroupButton 
                    disabledApply={isLoading || !isModified}
                    isSaving={isSaving}
                    hideDelete={!!isNew}
                    handleApplyClick={handleApply} 
                    handleExitClick={handleExit} 
                    handleDeleteClick={handleDelete}
                    textDelete={data[0] && data[0].is_disabled ? "Habilitar" : ""}
                />
                <SaveDialog 
                    open={openSaveDialog}
                    onClickYes={handleYesSave}
                    onClickNo={handleNoSave}
                    onClickCancel={handleCancelSave}
                />
                <DeleteDialog
                    open={openDeleteDialog}
                    onClickYes={handleYesDelete}
                    onClickNo={handleCancelDelete}
                    onClickCancel={handleCancelDelete}
                    title="¿Estás seguro de que quieres eliminar este grupo de etiquetas?"
                    description={"Si continúas este proceso se eliminará el grupo de etiquetas del sistema,"
                        +" salvo que tuviera información vinculada, en cuyo caso sería deshabilidado."}
                />
            </div>
        )
    }
}
