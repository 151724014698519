/**
 * Icono de cliente para el avatar del item de la lista
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as AnimalIcon } from '../../assets/dog.svg';
import { ReactComponent as SalivaIcon } from '../../assets/blot.svg';
import { ReactComponent as FecesIcon } from '../../assets/blot2.svg';
import { ReactComponent as Icon } from '../../assets/barcode.svg';
import { viewBox } from "../../config";

export function LabelIcon({ selector }) {
    return (
        <SvgIcon component={
            selector === "Animal" ? AnimalIcon :
            selector === "Saliva" ? SalivaIcon : 
            selector === "Heces" ? FecesIcon : Icon 
        } viewBox={viewBox} />
    )
}
