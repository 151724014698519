/**
 * Pestaña de información del animal en modo de sólo lectura
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Chip } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { ReadTextField } from "../common/ReadTextField";
import { ImageField } from "../common/ImageField";
import { prefixAnimal } from "../../config";
import { formatStringDate, zeroFill } from "../../utils/StringUtils";

const useStyles = makeStyles ((theme) => ({
    columnGrid: {
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(0),
            paddingLeft: theme.spacing(0),
        }
    },
    chip: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    fieldTitle: {
        width: "100%",
    },
}));

export function AnimalTab({ data, image }) {
    const classes = useStyles();
    const windowDim = useWindowDimensions();

    const isAllColumn = (windowDim.width < 400) 
        || (windowDim.width > 960 && windowDim.width < 1050) 
        || (windowDim.width > 600 && windowDim.width < 810);

    const label = data[0].animal_label && data[0].animal_label !== "" 
        ? data[0] && data[0].animal_label && data[0].animal_serial && data[0].animal_block_label_id
            && `${prefixAnimal}-${data[0].animal_serial}-${zeroFill(data[0].animal_block_label_id, 5)}-${zeroFill(data[0].animal_label, 3)}`
        : data[0] && data[0].animal2_label && data[0].animal2_serial && data[0].animal2_block_label_id
            && `${prefixAnimal}-${data[0].animal2_serial}-${zeroFill(data[0].animal2_block_label_id, 5)}-${zeroFill(data[0].animal2_label, 3)}`;    
    
    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={6} className={classes.columnGrid}>
                    <Grid container spacing={isAllColumn ? 0 : 2}>
                        <Grid item xs={isAllColumn ? 12 : 6}>
                            <ReadTextField 
                                label="Nombre"
                                value={(data[0].animal_name ?? "") + (data[0].animal2_name ?? "")} 
                            />
                            <ReadTextField 
                                label="Etiqueta"
                                value={label} 
                            />
                        </Grid>
                        <Grid item xs={isAllColumn ? 12 : 6}>
                            <ReadTextField 
                                label="Tipo"
                                value={(data[0].animal_type ?? "") + (data[0].animal2_type ?? "")} 
                            />
                            <ReadTextField 
                                label="Chip"
                                value={(data[0].animal_chip ?? "") + (data[0].animal2_chip ?? "")} 
                            />                                                                              
                        </Grid>
                    </Grid>
                    <ImageField
                        classContainer={classes.fieldTitle} 
                        image={image} 
                        data={data}
                        disabled
                        description="Fotografía del animal (preferiblemente en formato horizontal)."                                
                    />
                    { (data[0] && data[0].is_animal_dangerous) || (data[0] && data[0].is_animal2_dangerous)
                    ? <Chip
                        icon={<CheckIcon/>}
                        label="Perro potencialmente peligroso"
                        color="secondary"
                        className={classes.chip}
                    />                                                                              
                    : ""}                        
                </Grid>
                <Grid item xs={12} sm={6} className={classes.columnGrid}>                            
                    <Grid container spacing={isAllColumn ? 0 : 2}>
                        <Grid item xs={isAllColumn ? 12 : 6}>
                            <ReadTextField 
                                label="Fecha de nacimiento"
                                value={data[0].sample_id > 0 
                                    ? (data[0] && data[0].animal2_birthday !== undefined && data[0].animal2_birthday !== "0000-00-00" 
                                        ? formatStringDate(data[0].animal2_birthday) : "")
                                    : (data[0] && data[0].animal_birthday !== undefined && data[0].animal_birthday !== "0000-00-00" 
                                        ? formatStringDate(data[0].animal_birthday) : "")} 
                            />                                                                              
                            <ReadTextField 
                                label="Peso"
                                value={(data[0].animal_weight ?? "") + (data[0].animal2_weight ?? "") + " Kg"} 
                            />
                            <ReadTextField 
                                label="Raza"
                                value={(data[0].animal_breed ?? "") + (data[0].animal2_breed ?? "")} 
                            />
                            <ReadTextField 
                                label="Veterinario"
                                value={(data[0].veterinary_name ?? "") + (data[0].veterinary2_name ?? "")} 
                            />
                            <ReadTextField 
                                label="Código postal"
                                value={(data[0].animal_pcode ?? "") + (data[0].animal2_pcode ?? "")} 
                            /> 
                            <ReadTextField 
                                label="Provincia"
                                value={(data[0].animal_province ?? "") + (data[0].animal2_province ?? "")} 
                            />                                                                              
                        </Grid>
                        <Grid item xs={isAllColumn ? 12 : 6}>
                            <ReadTextField 
                                label="Fecha de baja"
                                value={data[0].sample_id > 0 
                                    ? (data[0] && data[0].animal2_death !== undefined && data[0].animal2_death !== "0000-00-00" 
                                        ? formatStringDate(data[0].animal2_death) : "")
                                    : (data[0] && data[0].animal_death !== undefined && data[0].animal_death !== "0000-00-00" 
                                        ? formatStringDate(data[0].animal_death) : "")} 
                            />                                                                              
                            <ReadTextField 
                                label="Sexo"
                                value={(data[0].animal_sex ?? "") + (data[0].animal2_sex ?? "")} 
                            />
                            <ReadTextField 
                                label="Pelaje"
                                value={(data[0].animal_hair ?? "") + (data[0].animal2_hair ?? "")} 
                            />
                            <ReadTextField 
                                label="Propietario"
                                value={(data[0].animal_owner ?? "") + (data[0].animal2_owner ?? "")} 
                            />
                            <ReadTextField 
                                label="Población"
                                value={(data[0].animal_city ?? "") + (data[0].animal2_city ?? "")} 
                            />
                            <ReadTextField 
                                label="DNI"
                                value={(data[0].animal_dni ?? "") + (data[0].animal2_dni ?? "")} 
                            />                                                                              
                        </Grid>
                    </Grid>
                    <ReadTextField 
                        label="Dirección"
                        value={(data[0].animal_address ?? "") + (data[0].animal2_address ?? "")} 
                    /> 
                    <Grid container spacing={isAllColumn ? 0 : 2}>
                        <Grid item xs={isAllColumn ? 12 : 6}>
                            <ReadTextField 
                                label="Teléfono"
                                value={(data[0].animal_phone ?? "") + (data[0].animal2_phone ?? "")}
                                phone
                            />
                        </Grid>
                        <Grid item xs={isAllColumn ? 12 : 6}>
                            <ReadTextField 
                                label="Correo electrónico"
                                value={(data[0].animal_email ?? "") + (data[0].animal2_email ?? "")}
                                email
                            />
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        </div>
    )
}
