/**
 * Formulario de detalle de usuario
 */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { FormGroup, Grid, TextField } from "@material-ui/core";
import { sha256 } from "js-sha256";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as AdminIcon } from '../../assets/security_agent.svg';
import { ReactComponent as VeterinaryIcon } from '../../assets/doctor.svg';
import { ReactComponent as AnalystIcon } from '../../assets/scientist.svg';
import { ReactComponent as SupervisorIcon } from '../../assets/businesswoman2.svg';
import { ReactComponent as GathererIcon } from '../../assets/delivery_man.svg';
import { ReactComponent as VigilantIcon } from '../../assets/policeman.svg';
import { fetchApi, mysqlEscape, jsonParseMysql } from "../../hooks/DataFetch";
import { FormGroupButton } from "../common/FormGroupButton";
import { AcordionChangePass } from "./AcordionChangePass";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { Loading } from "../Loading";
import { SettingsContext } from "../../contexts/SettingsContext";
import { UserContext } from "../../contexts/UserContext";
import { SaveDialog } from "../common/SaveDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { SelectField } from "../common/SelectField";
import { useIsMounted } from "../../hooks/IsMounted";
import { buttonGroup, buttonGroupXs } from "../../config";

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },        
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",        
    },
    form: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            maxWidth: "none",
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0.5),
        }
    },
    columnGrid: {
        maxWidth: "600px",
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(2),
        }
    },    
    field: {
        width: "100%",
        marginTop: theme.spacing(3),
    },    
    bottomSpace: {
        // La altura debe coincidir con FormGroupButton
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            height: buttonGroupXs,
        },
    },
}));

export function UserForm({ isNew }) {
    const { settings } = useContext(SettingsContext);
    const { user } = useContext(UserContext);
    const { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    // Se recuerda el filtro del listado para poder volver al mismo listado filtrado
    const filterProfile = new URLSearchParams(useLocation().search).get("filter");     
    const predProfile = (filterProfile && filterProfile !== "deshabilitado") ? filterProfile : "";

    // Estados de carga de datos
    const [data, setData] = useState([{
        id: "",
        name: "",
        pass: "",
        profile: predProfile,
        email: "",
        is_disabled: "",
        client_id: "",
    }]);
    const [clients, setClients] = useState ([{
        value: "",
        label: "(ninguno)"
    }]);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingClients, setIsLoadingClients] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isModified, setIsModified] = useState(false);

    // Estados de validación de errores
    const [pass, setPass] = useState("");
    const [repeat, setRepeat] = useState("");
    const [errorPass, setErrorPass] = useState(false);
    const [errorRepeat, setErrorRepeat] = useState(false);
    const [errorId, setErrorId] = useState(false);
    const [errorClient, setErrorClient] = useState(false);
    const [error, setError] = useState({ open: false, status: '' });

    // Estados de diálogos
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    async function saveForm () {

        async function fetchDuplicate(newId) {
            const query = "SELECT COUNT(*) as count FROM user WHERE id='" + mysqlEscape(newId) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return true;    // No está duplicado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdate(newData) {
            let query = "";
            if (isNew) {
                query = "INSERT INTO user (id, name, pass, profile, email, client_id) VALUES ('" 
                    + mysqlEscape(newData.id) + "', '" + mysqlEscape(newData.name) + "', '" + mysqlEscape(newData.pass) + "', '" 
                    + mysqlEscape(newData.profile) + "', '" + mysqlEscape(newData.email) + "', '" + mysqlEscape(newData.client_id) + "')";
            } else {
                query = "UPDATE user SET name='" + mysqlEscape(newData.name) + "', email='" + mysqlEscape(newData.email) 
                    + "', profile='" + mysqlEscape(newData.profile) + "', pass='" + mysqlEscape(newData.pass) 
                    +"', client_id='" + mysqlEscape(newData.client_id) + "' WHERE id='" + mysqlEscape(id) +"'";
            }
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }     
    
        // Crea una copia de los datos
        if (isMounted) setIsSaving(true);
        let newData = [{...data[0]}];
        let verificationError = false;

        // Comprueba el identificador si es nuevo usuario
        if (isNew) {
            if (newData[0].id === "") {
                // Identificador no definido            
                if (isMounted) setErrorId(true);
                verificationError= true;
            } else {
                if (await fetchDuplicate(newData[0].id)) {
                    // Identificador duplicado
                    if (isMounted) setErrorId(true);
                    verificationError= true;    
                }
            }
        }        

        // Comprueba la contraseña, si fue modificada
        if (pass !== "" && !verificationError) {
            if (settings.is_strong ? !pass.match(/[A-Z]/) || !pass.match(/[a-z]/) || !pass.match(/[1-9]/) || (pass.length < 8) : pass === "") {
                if (isMounted) setErrorPass(true);
                verificationError = true;
            } else {
                if (isMounted) setErrorPass(false);
                if (repeat !== pass) {
                    if (isMounted) setErrorRepeat(true);
                    verificationError = true;
                } else {
                    // Verificación correcta
                    if (isMounted) setErrorRepeat(false);
                    newData[0].pass = sha256(pass);
                    setData(newData);
                }
            }
        }

        // Comprueba si el cliente es obligatorio según el perfil
        if ((newData[0].profile === "vigilante" || newData[0].profile === "veterinario" 
            || newData[0].profile === "recolector" || newData[0].profile === "supervisor") 
            && newData[0].client_id === "") {
            if (isMounted) setErrorClient(true);
            verificationError = true
        }

        // Graba los cambios si no hay errores de verificación de contraseña
        if (!verificationError) {
            if (await fetchUpdate(newData[0])) {
                // Reinicia estados y valores
                if (pass !== "") {
                    document.getElementById("pass").value = null
                    document.getElementById("repeat").value = null;
                    if (isMounted) {
                        setPass("");
                        setRepeat("");
                    }
                }
                if (isMounted) {
                    setIsModified(false);
                    setIsSaving(false);
                    setErrorClient(false);
                }
                return true;
            } else {
                if (isMounted) setIsSaving(false);
                return false;
            }
        } else {
            if (isMounted) setIsSaving(false);
            return false;
        }
    }
    
    async function deleteForm () {
        
        async function isLinked() {
            const query = "SELECT (SELECT COUNT(*) FROM sample WHERE user_id='" 
                + mysqlEscape(id) +"') + (SELECT COUNT(*) FROM animal WHERE user_id='" 
                + mysqlEscape(id) +"') + (SELECT COUNT(*) FROM block_label WHERE user_id='"
                + mysqlEscape(id) +"') as count";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdate() {
            const query = "UPDATE user SET is_disabled = 1 WHERE id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM user WHERE id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        if (await isLinked()) {
            if (await fetchUpdate()) {
                // Baja correcta
                return true                
            } else {
                // Error de API
                return false
            }
        } else {
            if (await fetchDelete()) {
                // Eliminación correcta
                return true                
            } else {
                // Error de API
                return false
            }
        }
    }

    async function saveAndExit() {
        // Función asíncrona para guardar y salir que evitar la salida en caso de error
        if (await saveForm()) {            
            exit();
        } 
    }

    async function saveAndRefresh() {
        // Función asíncrona para guardar y recargar la ficha
        if (await saveForm()) {
            if (filterProfile) {
                history.push(`/administrador/usuarios/${data[0].id}?filter=${filterProfile}`);
            } else {
                history.push(`/administrador/usuarios/${data[0].id}`);
            }    
        }
    }

    async function deleteAndExit() {
        // Función asíncorona para eliminar el usuario
        if (await deleteForm()) {
            exit();
        }
    }

    async function enableForm() {
        
        async function fetchUpdate() {
            const query = "UPDATE user SET is_disabled = 0 WHERE id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        } 
        
        if (fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_disabled: false,
            }];
            if (isMounted) setData(newData);
        }
    }

    function exit() {
        if (user.profile === "administrador") {         
            if (filterProfile) {
                history.push (`/administrador/usuarios?filter=${filterProfile}`);
            } else {
                history.push (`/administrador/usuarios`);
            }
        } else {
            history.push (`/${user.profile}`);
        }
    }
    
    useEffect (() => {    
        async function fetchLoadClients() {
            // Carga la lista de clientes en el desplegable
            if (isMounted) setIsLoadingClients(true);
            const response = await fetchApi({ 
                query: "SELECT id, name FROM client WHERE is_disabled = 0" 
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                const newClients = readData.map(item => ({ 
                    value: item.id, 
                    label: item.name 
                }));          
                if (isMounted) setClients([...newClients, { value: "", label: "(ninguno)" }]);
            };                  
            if (isMounted) setIsLoadingClients(false);
        }
        
        async function fetchLoad() {
            // Lee la ficha de usuario
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT user.id as id, user.name as name, user.pass as pass, user.profile as profile, " 
                    + "user.email as email, user.is_disabled as is_disabled, client_id, client.name as client_name " 
                    + "FROM user LEFT JOIN client ON (user.client_id = client.id) WHERE user.id='" + mysqlEscape(id) +"'" 
            }); 
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                if (isMounted) {
                    // Si el cliente esta de baja se añade manualmente al desplegable
                    setClients(prevState => {
                        if (!prevState.find(element => element.value === readData[0].client_id)) {
                            const current = { value: readData[0].client_id, label: readData[0].client_name };
                            return [...prevState, current];
                        } else {
                            return [...prevState];
                        }
                    });
                    setData(readData);
                }
            };
            if (isMounted) setIsLoading(false);
        }        

        fetchLoadClients();
        if (isNew) {
            // Para usuarios nuevos no se realiza la carga inicial
            if (isMounted) setIsLoading(false);
        } else {
            // Carga inicial desde la API
            id && fetchLoad();
        } 
    },[id, isNew, isMounted])

    const handleChange = (event) => {
        let newData = [];
        if (event.target.name === "profile" && event.target.value === "administrador") {
            newData = [{
                ...data[0],
                [event.target.name] : event.target.value,
                client_id: ""           
            }];    
        } else {
            newData = [{
                ...data[0],
                [event.target.name] : event.target.value            
            }];    
        }
        setData(newData);
        setIsModified(true);
    }

    const handleApply = () => {
        // Botón aplicar
        if (isNew) {
            saveAndRefresh();
        } else {
            saveForm();
        }
    }

    const handleDelete = () => {
        if (data[0] && data[0].is_disabled) {
            // Botón habilitar
            enableForm();
        } else {
            // Botón eliminar
            setOpenDeleteDialog(true);
        }
    }

    const handleExit = () => {
        // Botón salir
        if (isModified && !isSaving) {
            setOpenSaveDialog(true);
        } else {
            setOpenSaveDialog(false);
            exit();
        }
    }

    const handleYesSave = () => {
        setOpenSaveDialog(false);
        saveAndExit();
    }

    const handleNoSave = () => {
        setOpenSaveDialog(false);
        exit();
    }

    const handleCancelSave = () => {
        setOpenSaveDialog(false);
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        deleteAndExit();
    }

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }   

    if (user.profile === "administrador" || user.id === id) {
        if (isLoading) {
            return <Loading />
        } else {
            return (
                <div className={classes.container}>
                    <Title 
                        text={isNew ? "Nuevo usuario" : id} 
                        icon={data[0] && data[0].profile === "administrador" ? AdminIcon : 
                            data[0] && data[0].profile === "veterinario" ? VeterinaryIcon : 
                            data[0] && data[0].profile === "laboratorio" ? AnalystIcon : 
                            data[0] && data[0].profile === "supervisor" ? SupervisorIcon : 
                            data[0] && data[0].profile === "recolector" ? GathererIcon : 
                            data[0] && data[0].profile === "vigilante" ? VigilantIcon : UserIcon} 
                        pathBack="/administrador/usuarios"
                        disabled={data[0] && data[0].is_disabled}
                    />
                    <FormGroup className={classes.form}>
                        <Grid container>
                            <Grid item xs={12} className={classes.columnGrid}>
                                {id===undefined 
                                ? <TextField                        
                                    className={classes.field}
                                    disabled={isLoading} 
                                    variant="outlined" 
                                    value={(data[0] && data[0] !== undefined) ? data[0].id : ""} 
                                    onChange={handleChange}
                                    label="Identificador" 
                                    id="id"
                                    name="id"
                                    error={errorId}
                                    helperText={errorId ? "Identificador no válido o duplicado" : ""}
                                    inputProps={{ maxLength: 30 }}
                                /> 
                                : ""}
                                <TextField
                                    className={classes.field}
                                    disabled={isLoading} 
                                    variant="outlined" 
                                    value={(data[0] && data[0].name !== undefined) ? data[0].name : ""} 
                                    onChange={handleChange}
                                    label="Nombre" 
                                    id="name"
                                    name="name"
                                    inputProps={{ maxLength: 30 }}
                                />                
                                <TextField 
                                    className={classes.field}
                                    disabled={isLoading} 
                                    variant="outlined" 
                                    value={(data[0] && data[0].email !== undefined) ? data[0].email : ""} 
                                    onChange={handleChange}
                                    label="Correo electrónico" 
                                    id="email"
                                    name="email"
                                    inputProps={{ maxLength: 100 }}
                                />
                                <SelectField
                                    className={classes.field}
                                    disabled={isLoading || user.profile !== "administrador"} 
                                    value={(data[0] && data[0].profile !== undefined) ? data[0].profile : ""} 
                                    onChange={handleChange}
                                    id="profile"
                                    name="profile"
                                    label="Perfil"
                                    list={[
                                        {"value" : "administrador", "label" : "Administrador"},
                                        {"value" : "veterinario", "label" : "Veterinario"},
                                        {"value" : "laboratorio", "label" : "Analista"},
                                        {"value" : "supervisor", "label" : "Supervisor"},
                                        {"value" : "recolector", "label" : "Recolector"},
                                        {"value" : "vigilante", "label" : "Vigilante"},
                                        {"value" : "", "label" : "(ninguno)"}                                
                                    ]}                                                            
                                />
                                <SelectField
                                    className={classes.field}
                                    disabled={isLoading || user.profile !== "administrador" || (data[0] && data[0].profile === "administrador") } 
                                    value={(data[0] && data[0].client_id !== undefined && !isLoadingClients ) ? data[0].client_id : ""} 
                                    onChange={handleChange}
                                    id="client_id"
                                    name="client_id"
                                    label="Cliente"
                                    list={clients}
                                    error={errorClient}
                                    helperText={errorClient ? "Campo obligatorio para este perfil" : ""}
                                />                        
                                <AcordionChangePass 
                                    className={classes.field}
                                    disabled={isLoading}
                                    setIsModified={setIsModified}
                                    setPass={setPass}
                                    setRepeat={setRepeat}
                                    errorPass={errorPass}
                                    setErrorPass={setErrorPass}
                                    errorRepeat={errorRepeat}
                                    setErrorRepeat={setErrorRepeat}
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.bottomSpace}/>
                        <FloatAlert 
                            open={error.open} 
                            onClose={handleErrorClose} 
                            text="No hemos podido leer o guardar los cambios. Comprueba tu conexión." 
                            severity="error"
                        />
                    </FormGroup> 
                    <FormGroupButton 
                        disabledApply={isLoading || !isModified}
                        isSaving={isSaving}
                        hideDelete={id === user.id || !!isNew}
                        handleApplyClick={handleApply} 
                        handleExitClick={handleExit} 
                        handleDeleteClick={handleDelete}
                        textDelete={data[0] && data[0].is_disabled ? "Habilitar" : ""}
                    />
                    <SaveDialog 
                        open={openSaveDialog}
                        onClickYes={handleYesSave}
                        onClickNo={handleNoSave}
                        onClickCancel={handleCancelSave}
                    />
                    <DeleteDialog
                        open={openDeleteDialog}
                        onClickYes={handleYesDelete}
                        onClickNo={handleCancelDelete}
                        onClickCancel={handleCancelDelete}
                        title="¿Estás seguro de que quieres eliminar este usuario?"
                        description={"Si continúas este proceso se eliminará el usuario del sistema, "
                            +"salvo que tuviera información vinculada, en cuyo caso sería deshabilidado."}
                    />
                </div>
            )
        }
    } else {
        return <div />
    }
}