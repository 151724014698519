/**
 * Pestaña de datos de genotipado y coincidencias
 */
import React, { useState } from "react";
import { Button, Grid, TextField, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldResultCell } from "./FieldResultCell";
import { FieldResultDates } from "./FieldResultDates";
import { csvToArray, spaceFill } from "../../utils/StringUtils";
import { dogMarkers, horseMarkers, indexAnalyzed, indexIssued, sampleStatus } from "../../config";
import { MatchingList } from "./MatchingList";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { fetchApi, jsonParseMysql } from "../../hooks/DataFetch";
import moment from "moment";

const useStyles = makeStyles ((theme) => ({
    container: {
        paddingLeft: theme.spacing(1),
        userSelect: "none",
    },
    button: {
        marginRight: theme.spacing(2),
    },
    textfield: {
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }, 
    input: {
        display: "none"
    },
    containerTable: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    avatarSmall: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    }
}));

export function GenotypeTab({ isLoading, data, setData, matching, setMatching, matchingSel, readOnly, setIsModified, setError }) {
    const classes = useStyles();
    const markers = data[0].animal_type==="Caballo" ? horseMarkers : dogMarkers
    const theme = useTheme();
    const windowDim = useWindowDimensions();
    const [finded, setFinded] = useState(false);
    const [finding, setFinding] = useState(false);

    const handleChange = (event) => {
        const newData = [{
            ...data[0],
            [event.target.name] : event.target.value            
        }];
        setData(newData);
        setIsModified(true);
    }    

    const handleCapture = (target) => {
        let fileReader = new FileReader();
        if (target.files) {
            if (target.files.length !== 0) {
                let file = target.files[0];
                fileReader.onload = function (event) {
                    const arrCsvImported = csvToArray(event.target.result);
                    const resultsImported = markers.map(label => {
                        const rowSelect = arrCsvImported.find(row => row.Marker === label);
                        return rowSelect ? spaceFill(rowSelect.Allele1,3) + spaceFill(rowSelect.Allele2,3) : "      ";
                    }).join("");
                    const newData = [{
                        ...data[0],
                        date_end: moment(new Date()).format("YYYY-MM-DD"),
                        status: sampleStatus[indexAnalyzed].label, 
                        results: resultsImported
                    }];
                    setData(newData);
                }
                fileReader.readAsText(file);
                setIsModified(true)
            }
        }
        target.value = "";  // Permite importar el mismo archivo
    };

    const handleFind = () => {

        async function fetchLoad() { 
            
            function reduceGaps (results) {
                // Elimina los huecos del array y registra las posiciones dónde había huecos
                const reduced = [];
                const gaps = [];
                for (let i=0; i<results.length; i++) {
                    if (results[i].trim()==="") {
                        gaps.push(i);
                    } else {
                        reduced.push(results[i]);
                    }
                }
                return { reduced: reduced, gaps: gaps };
            }

            function insertGaps (results, gaps) {
                // Inserta los hucecos previamente eliminados en cada combinación generada
                const newResults = [...results].map (combination => {
                    for (let i=0; i<gaps.length; i++) {
                        combination.splice(gaps[i], 0, "______");
                    }                        
                    return combination
                });                
                return newResults;
            }

            function getPositionCombinations (m, n, position) {
                // Construye las combinaciones de posiciones de huecos
                const result = [];
                if (m > 0 && n > 0) {
                    if (n===1) {
                        for (let i=position; i<m; i++) {
                            result.push([i]);
                        }
                    } else {
                        for (let j=position; j<m-1; j++) {
                            const previous = [...getPositionCombinations (m, n-1, j+1)];
                            const newCombination = previous.map(elem=>[j, ...elem]);
                            result.push(...newCombination);
                        }
                    }
                }
                return result;
            }

            function getFilterCombinations(results, minMarkers) {
                // Devuelve el array de resultados con todas las combinaciones de huecos posibles
                const {reduced, gaps} = reduceGaps(results);
                const positionCombinations = getPositionCombinations (reduced.length, reduced.length-minMarkers, 0);      
                const resultCombinations = positionCombinations.length > 0 
                ? positionCombinations.map(positionCombination => {
                    const resultCombination = [...reduced];                    
                    for (let i=0; i<positionCombination.length; i++) {
                        resultCombination[positionCombination[i]]="______"
                    }                    
                    return resultCombination;
                })
                : [[...reduced]];
                const expanded = insertGaps (resultCombinations, gaps);
                return expanded.map (result => result.join(""));            
            }

            setFinding(true);                  
            const filters = data[0].results ? getFilterCombinations([...data[0].results.match(/.{1,6}/g)], 10) : [];
            let filterString = filters.length > 0 
            ? filters.map ((filter) => {
                return filter !== "" ? " OR sample.results LIKE '" + filter + "%'" : "";
            }).join("")
            : "";
            // Eliminar OR inicial y lo sustituye por AND 
            filterString = filterString.trim() !== "" ? " AND (" + filterString.substring(3) + ")" : ""
            const type = data[0].type === "Heces" ? "Saliva" : "Heces";
            const response = await fetchApi({ 
                // Por comodidad, se considera que el cliente del animal es el propio cliente de la muestra (serie)
                query: "SELECT sample.id as id, client.serial as serial, sample.block_label_id as block_label_id, " 
                    + "sample.label as label, sample.results as results, sample.animal_id as animal_id, animal.type as animal_type, "
                    + "sample.sampled as sampled, sample.sent as sent, sample.authority as authority, sample.comments_rec as comments_rec, "
                    + "animal.label as animal_label, animal.block_label_id as animal_block_label_id, "
                    + "sample.latitude as latitude, sample.longitude as longitude, "
                    + "animal.chip as animal_chip, sample.type as type, client.serial as animal_serial FROM sample "
                    + "LEFT JOIN animal ON (sample.animal_id = animal.id) " 
                    + "LEFT JOIN user ON (sample.user_id = user.id) "                    
                    + "LEFT JOIN client ON (user.client_id = client.id) "
                    + "WHERE sample.type = '" + type + "' AND (sample.status = '" + sampleStatus[indexIssued].label 
                    + "' OR sample.status = '" + sampleStatus[indexAnalyzed].label + "') " 
                    + filterString 
                    + " LIMIT 20 "
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                if (readData[0]) {
                    readData[0].selected = true;
                    if (data[0].type==="Heces") {
                        const newData = [{
                            ...data[0],
                            sample_id : readData[0].id            
                        }];
                        setData(newData);
                    }                
                }
                setMatching(readData);
                setIsModified(true);   
                setFinded(true);
                setFinding(false);
            };
        }

        fetchLoad();
    }

    return (
        <div className={classes.container}>
            <Grid container>
                {markers.map((item, i) => {
                    return (
                        <Grid item xs={6} sm={4} md={3} lg={2} key={i}>
                            <FieldResultCell                                
                                indexMarker={i}
                                label={item}
                                data={data} 
                                setData={setData}
                                matchingSel={matchingSel}
                                disabled={isLoading || readOnly} 
                                setIsModified={setIsModified} 
                                readOnly={readOnly}
                            />
                        </Grid>    
                    );
                })}
                <Grid item xs={6} sm={4} md={3} lg={4}>
                    <FieldResultDates 
                        label="Fechas" 
                        data={data} 
                        setData={setData} 
                        disabled={isLoading || readOnly} 
                        setIsModified={setIsModified} 
                    />
                </Grid>
                <Grid 
                    item xs={12} 
                    sm={markers.length===19 ? 4 : 12} 
                    md={markers.length===19 ? 12 : 6} 
                    lg={markers.length===19 ? 6 : 8}
                >
                    <TextField                         
                        className={classes.textfield}
                        disabled={isLoading || readOnly} 
                        label="Observaciones"
                        value={(data[0] && data[0].observations !== undefined) ? data[0].observations : ""} 
                        onChange={handleChange}
                        id="observations"
                        name="observations"
                        size="small" 
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={5}                        
                    />
                </Grid>
            </Grid>
            { !readOnly ? <div>
                <input
                    accept=".csv"
                    className={classes.input}
                    id="button-file"
                    type="file"
                    onChange={(e) => handleCapture(e.target)}
                />
                <label htmlFor="button-file">
                    <Button
                        className={classes.button} 
                        color="primary" 
                        variant="outlined"                    
                        component="span"
                    >
                        {windowDim.width > theme.breakpoints.values.sm?"Importar resultados":"Importar"}
                    </Button>
                </label>
                <Button 
                    className={classes.button} 
                    color="primary" 
                    variant="outlined" 
                    onClick={handleFind}
                    disabled={finding}
                >
                    {windowDim.width > theme.breakpoints.values.sm?"Buscar coincidencias":"Buscar"}
                </Button>                     
            </div> : "" }
            { finded || matching.length > 0
            ? <MatchingList 
                data={data}
                setData={setData}
                matching={matching}
                setMatching={setMatching}
                setIsModified={setIsModified}
                readOnly={readOnly}
                finding={finding}
            />
            : ""}
        </div>
    )
}
