/**
 * Hook para gestionar la geoposición actual del usuario
 */
import { useState, useEffect } from "react";

export function usePosition (watch = false) {
    const [position, setPosition] = useState({ 
        lat: "", 
        lng: "", 
        error: ""
    });
    const [error, setError] = useState(null);
  
    const onChange = ({coords}) => {
        setPosition({
            lat: coords.latitude,
            lng: coords.longitude,
        });
    };

    const onError = (error) => {
        setError(error.message);
    };

    useEffect(() => {
        if (!navigator.geolocation) {
            setError("Geolocalización no soportada en este dispositivo");
            return;
        }
        if (watch) {
            const watcher = navigator.geolocation.watchPosition(onChange, onError);
            return () => navigator.geolocation.clearWatch(watcher);    
        }
        navigator.geolocation.getCurrentPosition(onChange, onError);
    }, [watch]);

    return {...position, error};
}