/**
 * Subselector de opciones para la página de login 
 */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { Loading } from "../components/Loading";
import { FormLoginUser } from "../components/login/FormLoginUser";
import { FormLoginEmail } from "../components/login/FormLoginEmail";
import { FormLoginChange } from "../components/login/FormLoginChange";

export function SwitchLogin({ classes, userChanged, setUserChanged, passChanged, setPassChanged }) {
    return (
        <Switch>
            <Route path="/cargando">
                <Loading />
            </Route>
            <Route path="/email">
                <FormLoginEmail 
                    classes={classes}
                    setUserChanged={setUserChanged}
                />                
            </Route>
            <Route path="/actualiza">
                <FormLoginChange 
                    classes={classes}
                    userChanged={userChanged} 
                    setPassChanged={setPassChanged}
                />            
            </Route>
            <Route path="/">
                <FormLoginUser 
                    classes={classes}
                    passChanged={passChanged} 
                    setPassChanged={setPassChanged}
                />
            </Route> 
        </Switch>
    )
}
