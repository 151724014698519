/**
 * Diálogo de confirmación al grabar
 */
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from "@material-ui/core";

const useStyles = makeStyles (() => ({
    container: {
        userSelect: "none",
    }
}));

export function SaveDialog (props) {
    // En este caso desestructurar directamente no funciona
    const { open, onClickYes, onClickNo, onClickCancel } = props
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClickCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.container}
        >
            <DialogTitle id="alert-dialog-title">¿Quieres guardar los cambios?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Si continúas este proceso sin guardar los cambios, se perderán las modificaciones realizadas.
                </DialogContentText>
                <DialogActions>
                    <Button onClick={onClickYes} color="primary">Sí</Button>
                    <Button onClick={onClickNo} color="primary">No</Button>
                    <Button onClick={onClickCancel} color="secondary">Cancelar</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>            
    )
}
