/**
 * Tarjeta para mostrar un gráfico de donut con el número de animales según el estado deshabilitado
 */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useTheme } from "@material-ui/core";
import { AnimalIcon } from "../animals/AnimalIcon";
import { useDataFetch, mysqlEscape } from "../../hooks/DataFetch";
import { CardDashboard } from "../common/CardDashboard";
import { Doughnut } from "react-chartjs-2";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

export function CardAnimals() {
    const history = useHistory();
    const theme = useTheme();   
    const windowDim = useWindowDimensions();
    const { user } = useContext(UserContext);    
    const { data } = useDataFetch({ 
        query: "SELECT is_disabled as id, count(*) as value FROM animal WHERE user_id='" 
            + mysqlEscape(user.id) + "' GROUP BY is_disabled" 
    });
    // Si sólo hay una fila se debe completar la otra con valor cero, ya sea PPP o no PPP
    if (data.length === 1) {
        if (data[0].id === "0") { 
            data.push({id: "1", value: 0})
        } else {
            data.unshift({id: "0", value: 0})
        }
    }    
    const chartData={
        labels: ["Activos", "Baja"],
        datasets: [{
            backgroundColor: [theme.palette.primary.main, theme.palette.secondary.main],
            borderColor: theme.palette.background.paper,
            data: data
        }]
    };
    const chartOptions={
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: windowDim.width > 600 && windowDim.height > 800,
                position: "right",
                labels: {
                    color: theme.palette.primary.main
                }
            }
        }
    }

    const handleClick = () => {
        history.push ("/veterinario/animales");
    }

    return (
        <CardDashboard 
            icon={<AnimalIcon selector="Perro"/>}
            title="Animales"
            subtitle="Registrados"
            handleClick={handleClick}
        >
            { data.length > 0
            ? <Doughnut
                data={chartData}
                options={chartOptions}
            />
            : "" }
        </CardDashboard>
    )
}
 