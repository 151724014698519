/**
 * Subselector para las opciones del perfil de administrador 
 */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { PreferencesAdmin } from "../components/admin/PreferencesAdmin";
import { DashboardAdmin } from "../components/admin/DashboardAdmin";
import { Notifications } from "../components/notifications/Notifications";
import { Users } from "../components/users/Users";
import { UserForm } from "../components/users/UserForm";
import { Clients } from "../components/clients/Clients";
import { ClientForm } from "../components/clients/ClientForm";
import { Labels } from "../components/labels/Labels";
import { LabelForm } from "../components/labels/LabelForm";
import { Samples } from "../components/samples/Samples";
import { SampleForm } from "../components/samples/SampleForm";
import { Animals } from "../components/animals/Animals";
import { AnimalForm } from "../components/animals/AnimalForm";

export function SwitchAdmin({ setNumNotif }) {
    return (
        <Switch>
            <Route path="/administrador/preferencias" exact>
                <PreferencesAdmin />
            </Route>
            <Route path="/administrador/usuarios" exact>
                <Users pathBack="/administrador" />
            </Route>
            <Route path="/administrador/usuarios/:id" exact>
                <UserForm />
            </Route>                
            <Route path="/administrador/nuevousuario/" exact>
                <UserForm isNew/>
            </Route>                
            <Route path="/administrador/micuenta/:id" exact>
                <UserForm />
            </Route>

            <Route path="/administrador/clientes" exact>
                <Clients pathBack="/administrador" />
            </Route>
            <Route path="/administrador/clientes/:id" exact>
                <ClientForm pathBack="/administrador"/>
            </Route>                
            <Route path="/administrador/nuevocliente/" exact>
                <ClientForm pathBack="/administrador" isNew />
            </Route>                

            <Route path="/administrador/muestras" exact>
                <Samples pathBack="/administrador" />
            </Route>
            <Route path="/administrador/muestras/:id" exact>
                <SampleForm pathBack="/administrador"/>
            </Route>
            <Route path="/administrador/muestrarecarga" exact>
                <SampleForm pathBack="/administrador" reload/>
            </Route>                

            <Route path="/administrador/animales" exact>
                <Animals pathBack="/administrador" />
            </Route>
            <Route path="/administrador/animales/:id" exact>
                <AnimalForm pathBack="/administrador" />
            </Route>                
            <Route path="/administrador/nuevoanimal" exact>
                <AnimalForm pathBack="/administrador" isNew />
            </Route>

            <Route path="/administrador/etiquetas" exact>
                <Labels pathBack="/administrador" />
            </Route>
            <Route path="/administrador/etiquetas/:id" exact>
                <LabelForm pathBack="/administrador"/>
            </Route>                
            <Route path="/administrador/nuevaetiqueta/" exact>
                <LabelForm pathBack="/administrador" isNew />
            </Route>                

            <Route path="/administrador/notificaciones" exact>
                <Notifications setNumNotif={setNumNotif} />
            </Route>
            
            <Route path="/administrador" exact>
                <DashboardAdmin />
            </Route>
        </Switch>
    )
}
