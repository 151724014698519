import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles ((theme) => ({
    container: {
        textAlign: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        font: "25px bold Arial, sans-serif",
    },
}));

export function TitleReport({ caption }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {caption}
        </div>
    )
}
