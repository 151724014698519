/**
 * Tarjeta para mostrar un gráfico de donut con la distribución de muestras por cliente
 */
import React from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import { ClientIcon } from "../clients/ClientIcon";
import { useDataFetch } from "../../hooks/DataFetch";
import { CardDashboard } from "../common/CardDashboard";
import { Doughnut } from "react-chartjs-2";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

export function CardClients() {
    const history = useHistory();
    const theme = useTheme();   
    const windowDim = useWindowDimensions();
    const { data } = useDataFetch({ 
        query: "SELECT client.name as id, count(*) as value FROM sample " 
            + "LEFT JOIN user ON (sample.user_id = user.id) " 
            + "LEFT JOIN client ON (user.client_id = client.id) " 
            + "WHERE sample.is_disabled = 0 " 
            + "GROUP BY client.id ORDER BY value DESC" 
    });

    const handleClick = () => {
        history.push ("/laboratorio/clientes");
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const chartData={
        labels: data.map(item => item.id),
        datasets: [{
            backgroundColor: data.map((item, index) => 
                index===0
                ? theme.palette.primary.main
                : (
                    index===1
                    ? theme.palette.secondary.main
                    : getRandomColor()
                )
            ),
            borderColor: theme.palette.background.paper,
            data: data
        }]
    };
    const chartOptions={
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: windowDim.width > 600 && windowDim.height > 800,
                position: "right",
                labels: {
                    color: theme.palette.primary.main
                }
            }
        }
    }

    return (
        <CardDashboard 
            icon={<ClientIcon />}
            title="Clientes"
            subtitle="Distribución de muestras"
            handleClick={handleClick}
        >
            <Doughnut
                data={chartData}
                options={chartOptions}
            />
        </CardDashboard>
    )
}
 