/** 
 * Punto de entrada de la aplicación LABTEGEN
 * 
 *   @author Dabiz Spuch Calvar
 *   @copyright 2024 SPUCH SOFTWARE
 */

import React, { useState, useMemo } from "react";
import { UserContext } from "./contexts/UserContext";
import { SettingsContext } from "./contexts/SettingsContext";
import { RouterMain } from "./routes/RouterMain";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blueGrey, deepOrange } from '@material-ui/core/colors';
import { CssBaseline, useMediaQuery } from "@material-ui/core";

export function App() {
    const [user, setUser] = useState({
        id: '',
        name: '',
        profile: '',
        client_id: '',
        latitude: '',
        longitude: '',
        serial: ''
    });
    const [settings, setSettings] = useState({
        is_remember: false,
        is_strong: true,
        is_recover: true
    });
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    primary: {                        
                        main: blueGrey[500]
                    },
                    secondary: {
                        main: deepOrange[500]
                    },
                },
            }),
        [prefersDarkMode],
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SettingsContext.Provider value={{settings, setSettings}}>
                <UserContext.Provider value={{user, setUser}}>
                    <RouterMain />
                </UserContext.Provider>
            </SettingsContext.Provider>
        </ThemeProvider>
    )
}