//export const baseUrl = "https://www.spuch.com/labtegen";
export const baseUrl = "https://labtegen.com";

export const drawerWidth = 240;
export const titleHeight = 48;
export const buttonGroup = 80;
export const buttonGroupXs = 80;
export const itemHeight = 60;
export const rowTitleHeight = 55;
export const rowHeight = 50;

export const viewBox = "0 0 512 512";

export const quality = 0.7;
export const maxWidthImage = 534;

export const limit = 100;

export const minLabelAdvise = 10;
export const maxBlockLabel = 33;
export const widthLabel = "71.5mm";
export const heighLabel = "26.3mm";

export const prefixAnimal = "A";
export const prefixSaliva = "M";
export const prefixFeces = "H";

export const indexRegistered = 0;
export const indexSent = 1;
export const indexAccepted = 2;
export const indexRejected = 3;
export const indexReplaced = 4;
export const indexAnalyzed = 5;
export const indexIssued = 6;

export const sampleStatus = [
    { label: "Registrada", title: "Registradas", darkColor: "#908751", lightColor: "#fff3aa"},
    { label: "Enviada", title: "Enviadas", darkColor: "#212121", lightColor: "#f0fafa"},
    { label: "Aceptada", title: "Aceptadas", darkColor: "#304716", lightColor: "#aed581"},
    { label: "Rechazada", title: "Rechazadas", darkColor: "#bf360c", lightColor: "#ff5722"},
    { label: "Reemplazada", title: "Reemplazadas", darkColor: "#b38b7e", lightColor: "#dc876c"},
    { label: "Analizada", title: "Analizadas", darkColor: "#0f4f75", lightColor: "#4db9fa"},
    { label: "Emitida", title: "Emitidas", darkColor: "#4a3c5a", lightColor: "#ecdcff"},
]

export const dogMarkers = ["AHT121", "AHT137", "AHTh171", "AHTh260", "AHTk211", "AHTk253", "Amelogenin", 
    "CXX0279", "FH2054", "FH2848", "INRA21", "INU005", "INU030", "INU055", "REN162C04", "REN169D01", 
    "REN169O18", "REN247M23", "REN54P11"];

export const horseMarkers = ["AHT4", "AHT5", "ASB17", "ASB2", "ASB23", "CA425", "HMS1", "HMS2", 
    "HMS3", "HMS6", "HMS7", "HTG10", "HTG4", "HTG6", "HTG7", "LEX3", "VHL20"]