/**
 * Menú de filtros por tipo de usuario 
 */
import React from "react";
import { Menu, MenuItem, Typography } from "@material-ui/core";

export  function UsersMenuFilter({ anchorEl, setAnchorEl, setFilter }) {

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        const { myValue } = event.currentTarget.dataset;
        setAnchorEl(null);
        setFilter(myValue);
    }

    return (
        <Menu
            id="menu-filter"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}                    
        >
            <MenuItem key="todos" data-my-value={null} onClick={handleClick}><em>Todos</em></MenuItem>
            <MenuItem key="administrador" data-my-value={"administrador"} onClick={handleClick}>Administradores</MenuItem>
            <MenuItem key="veterinario" data-my-value={"veterinario"} onClick={handleClick}>Veterinarios</MenuItem>
            <MenuItem key="laboratorio" data-my-value={"laboratorio"} onClick={handleClick}>Analistas</MenuItem>
            <MenuItem key="supervisor" data-my-value={"supervisor"} onClick={handleClick}>Supervisores</MenuItem>
            <MenuItem key="recolector" data-my-value={"recolector"} onClick={handleClick}>Recolectores</MenuItem>
            <MenuItem key="vigilante" data-my-value={"vigilante"} onClick={handleClick}>Vigilantes</MenuItem>
            <MenuItem key="deshabilitado" data-my-value={"deshabilitado"} onClick={handleClick}>
                {<Typography color="secondary">Deshabilitados</Typography>}
            </MenuItem>
        </Menu>              
    )
}
