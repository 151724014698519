/**
 * Icono de muestra para el avatar del item de la lista
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as salivaIcon } from '../../assets/blot.svg';
import { ReactComponent as fecesIcon } from '../../assets/blot2.svg';
import { ReactComponent as matchIcon } from '../../assets/blot3.svg';
import { ReactComponent as sampleIcon } from '../../assets/jar.svg';
import { viewBox } from "../../config";

export function SampleIcon({ selector }) {
    return (
        <SvgIcon component={
            selector === "Saliva" ? salivaIcon : 
            selector === "Heces" ? fecesIcon :
            selector === "Match" ? matchIcon : sampleIcon
        } viewBox={viewBox} />
    )
}