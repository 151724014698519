/**
 * Contexto de usuario autenticado
 */
import { createContext } from "react";

export const UserContext = createContext({
    user: {
        id: '',
        name: '',
        profile: '',
        client_id: '',
        latitude: '',
        longitude: '',
        serial: ''        
    },
    setUser: ()=> {}
});