/**
 * Menú de filtros para grupos de etiquetas
 */
import React from "react";
import { Menu, MenuItem, Typography } from "@material-ui/core";

export  function LabelsMenuFilter({ anchorEl, setAnchorEl, setFilter }) {

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleAllClick = () => {
        setAnchorEl(null);
        setFilter(null);
    }

    const handleAnimalClick = () => {
        setAnchorEl(null);
        setFilter("Animal");
    }

    const handleSalivaClick = () => {
        setAnchorEl(null);
        setFilter("Saliva");
    }

    const handleHecesClick = () => {
        setAnchorEl(null);
        setFilter("Heces");
    }

    const handleDisabledClick = () => {
        setAnchorEl(null);
        setFilter("Deshabilitado");
    }

    return (
        <Menu
            id="menu-filter"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}                    
        >
            <MenuItem key="Todos" onClick={handleAllClick}><em>Todos</em></MenuItem>
            <MenuItem key="Animal" onClick={handleAnimalClick}>{<Typography>Animal</Typography>}</MenuItem>            
            <MenuItem key="Saliva" onClick={handleSalivaClick}>{<Typography>Saliva</Typography>}</MenuItem>            
            <MenuItem key="Heces" onClick={handleHecesClick}>{<Typography>Heces</Typography>}</MenuItem>            
            <MenuItem key="Deshabilitados" onClick={handleDisabledClick}>{<Typography color="secondary">Deshabilitados</Typography>}</MenuItem>            
        </Menu>              
    )
}
