/**
 * Tarjeta para mostrar acceso directo a la creación de una detección
 */
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as detectionsIcon } from '../../assets/compass.svg';
import { CardDashboard } from "../common/CardDashboard";

export function CardNewDetection() {
    const history = useHistory();

    const handleClick = () => {
        history.push ("/vigilante/nuevadeteccion/");
    }

    return (
        <CardDashboard
            title="Nueva detección"
            subtitle="Registro normal"
            handleClick={handleClick}
            contentIcon={detectionsIcon}
            shortXs
        />
    )
}
