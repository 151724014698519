import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FieldWorksheet } from "./FieldWorksheet";
import moment from "moment";

const useStyles = makeStyles (() => ({
    block: {
        height: "110px",
    },
    group: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

export function InfoWorksheet({ data }) {
    const classes = useStyles();

    return (
        <div className={classes.block}>
            <div className={classes.group}>
                <FieldWorksheet
                    caption="Cliente" 
                    value={data[0].client_name}
                />
                <FieldWorksheet 
                    caption="Fecha recepción" 
                    value={moment(data[0].sampled).format("DD-MM-YYYY")} 
                />
                <FieldWorksheet 
                    caption="Perfil" 
                    value={
                        data[0].animal_type === "Caballo" 
                        ? "Equino - Huella genética (17 STR)"
                        : "Canino - Huella genética (19 STR)"
                    } 
                />
                <FieldWorksheet 
                    caption="Tipo" 
                    value={data[0].type} 
                />
            </div>
        </div>
    )
}
