/**
 * Tarjeta para mostrar el gráfico circular de muestras según el estado
 */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { SampleIcon } from "../samples/SampleIcon";
import { useDataFetch, mysqlEscape } from "../../hooks/DataFetch";
import { Pie } from "react-chartjs-2";
import { sampleStatus } from "../../config";
import { CardDashboard } from "../common/CardDashboard";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

export function CardSamples() {
    const history = useHistory();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useTheme();   
    const windowDim = useWindowDimensions();
    const { user } = useContext(UserContext);        
    const { data } = useDataFetch({ 
        query: "SELECT sample.status as id, count(*) as value FROM sample " 
            + "LEFT JOIN user ON (sample.user_id = user.id) " 
            + "WHERE user.client_id='" + mysqlEscape(user.client_id) + "' " 
            + "AND sample.type = 'Heces' AND sample.is_disabled = 0 AND sample.is_archived = 0 " 
            + "GROUP BY status"
    });
    const leyends = sampleStatus.filter(state => state.label !== "Archivada");    
    const dataProp = leyends.map(leyend => {
        const row = data.filter(item => item.id === leyend.label);
        if (row[0]) {
            return row[0].value;
        } else {
            return "";
        }
    });
    const charData = {
        labels: sampleStatus.map(state => state.label),
        datasets: [{
            backgroundColor: leyends.map(state => prefersDarkMode ? state.darkColor : state.lightColor),
            borderColor: theme.palette.background.paper,
            data: dataProp 
        }]
    }
    const charOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: windowDim.width > 600 && windowDim.height > 800,
                position: "right",
                labels: {
                    color: theme.palette.primary.main
                }
            }
        }
    }

    const handleClick = () => {
        history.push ("/supervisor/muestrasheces");
    }

    return (
        <CardDashboard
            icon={<SampleIcon selector="Heces"/>}
            title="Muestras"
            subtitle="Muestras activas"
            handleClick={handleClick}
            shortXs
        >
            { data.length > 0 
                ? <Pie
                    data={charData}
                    options={charOptions}
                />
                : ""
            }
        </CardDashboard>
    )
}
