import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import { spaceFill, spaceFillRight } from "../../utils/StringUtils";
import { green, red, yellow } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles ((theme) => ({ 
    container: {
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        width: "100%",
    },    
    field: {
        width: "60%",
    },
    valueComp: {
        width: "40%",
        paddingTop: theme.spacing(1.3),
        borderRadius: "5px",
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[300],
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[800],
        },
    },
    group: {
        display: "flex",
        marginBottom: theme.spacing(1),
    },
    label: {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        borderRadius: "5px",
        marginBottom: theme.spacing(1),
    },
    redCell: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: red[100],
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: red[800],
        }
    },
    yellowCell: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: yellow[100],
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: yellow[800],
        },
    },
    greenCell: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: green[100],
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: green[800],
        },
    },

}));

export function FieldResultCell({ label, indexMarker, data, setData, matchingSel, disabled, setIsModified }) {
    const classes = useStyles();
    
    const strResults = data[0] && data[0].results !== "" ? spaceFillRight(data[0].results, 114) : " ".repeat(114);
    const arrayResults = [...strResults.match(/.{1,3}/g)];    
    const strMatching = matchingSel[0] && matchingSel[0].results !== "" 
        ? spaceFillRight(matchingSel[0].results, 114) 
        : " ".repeat(114);
    const arrayMatching = [...strMatching.match(/.{1,3}/g)];
    const indexAlelo1 = indexMarker * 2;
    const indexAlelo2 = indexMarker * 2 + 1;

    const classColor = arrayMatching[indexAlelo1].trim() === "" & arrayMatching[indexAlelo2].trim() === ""
        ? classes.greyCell
        : arrayResults[indexAlelo1] !== arrayMatching[indexAlelo1] 
        && arrayResults[indexAlelo1].trim() !== "" 
        && arrayMatching[indexAlelo1].trim() !== ""
        && arrayResults[indexAlelo2] !== arrayMatching[indexAlelo2] 
        && arrayResults[indexAlelo2].trim() !== ""
        && arrayMatching[indexAlelo2].trim() !== ""
            ? classes.redCell
            : arrayResults[indexAlelo1] === arrayMatching[indexAlelo1] && arrayResults[indexAlelo1].trim() !== "" 
            && arrayResults[indexAlelo2] === arrayMatching[indexAlelo2] && arrayResults[indexAlelo2].trim() !== ""
                ? classes.greenCell
                : classes.yellowCell
    
    const handleChange = (event) => {
        const index = event.target.name === "Alelo1" ? indexAlelo1 : indexAlelo2;
        arrayResults[index] = spaceFill(event.target.value, 3);
        const newData = [{
            ...data[0],
            results: arrayResults.join("")            
        }];
        setData(newData);
        setIsModified(true);
    }

    return (
        <div className={classes.container}>
            <Typography variant="subtitle2" align="center" className={classes.label}>
                {label}
            </Typography>
            <div>
                <div className={classes.group}>
                    <TextField 
                        className={classes.field} 
                        disabled={disabled} 
                        label="Alelo 1"
                        name="Alelo1"
                        value={arrayResults && arrayResults[indexAlelo1] ? arrayResults[indexAlelo1].trim() : ""} 
                        onChange={handleChange}
                        size="small"
                        variant="outlined"
                        inputProps={{ maxLength: 3 }}
                    />
                    <Typography variant="body2" align="center" className={clsx(classes.valueComp, classColor)}>
                        {arrayMatching && arrayMatching[indexAlelo1] ? arrayMatching[indexAlelo1].trim() : ""}
                    </Typography>
                </div>
                <div className={classes.group}> 
                    <TextField 
                        className={classes.field} 
                        disabled={disabled} 
                        label="Alelo 2" 
                        name="Alelo2"
                        value={arrayResults && arrayResults[indexAlelo2] ? arrayResults[indexAlelo2].trim() : ""} 
                        onChange={handleChange}
                        size="small" 
                        variant="outlined" 
                        inputProps={{ maxLength: 3 }}
                    />
                    <Typography variant="body2" align="center" className={clsx(classes.valueComp, classColor)}>
                        {arrayMatching && arrayMatching[indexAlelo2] ? arrayMatching[indexAlelo2].trim() : ""}
                    </Typography>
                </div>
            </div>
        </div> 
    )
}
