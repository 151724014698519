import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Typography, useTheme } from "@material-ui/core";
import { prefixFeces, prefixSaliva } from "../../config";
import { spaceFillRight, zeroFill } from "../../utils/StringUtils";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { orange, green, red } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles ((theme) => ({
    containerTable: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    avatarSmall: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    avatarGreen: {
        backgroundColor: green[600]
    },
    avatarOrange: {
        backgroundColor: orange[800]
    },
    avatarRed: {
        backgroundColor: red[500]
    },
    tbody:{
        cursor: "pointer",
    }
}));

export function MatchingList({ data, setData, matching, setMatching, setIsModified, readOnly, finding }) {
    const classes = useStyles();
    const theme = useTheme();
    const windowDim = useWindowDimensions();
    const history = useHistory();

    const handleClick = (e) => {
        if (readOnly) {
            // Lanza el detalle de muestra
            const id = e.currentTarget.getAttribute("name");
            history.push(`/supervisor/muestras/${id}`);
        } else {
            // Actualiza lista de muestras coincidentes
            const id = e.currentTarget.getAttribute("name");
            const newMatching = [...matching];        
            newMatching.map(sample => (sample.selected = (sample.id === id) ? !sample.selected : false));
            setMatching(newMatching);
            
            // Actualiza asignación en la muestra principal si es una muestra de heces
            if (data[0].type==="Heces") {
                const sample = newMatching.find(sample => sample.id === id);
                const newData = [{
                    ...data[0],
                    sample_id : sample.selected ? id : 0 
                }];
                setData(newData);
            }
            setIsModified(true);        
        }
    }

    const rows = matching.map (row => {
        const prefixSample = row.type==="Heces" ? prefixFeces : prefixSaliva;
        const sampleLabel = windowDim.width > theme.breakpoints.values.sm 
        ? `${prefixSample}-${row.serial}-${zeroFill(row.block_label_id, 5)}-${zeroFill(row.label, 3)}`
        : `${prefixSample}-${row.serial}-${row.block_label_id}-${row.label}`; 

        // Resultados muestra original 
        const strResultsOriginal = data[0] && data[0].results !== "" 
            ? spaceFillRight(data[0].results, 114) 
            : " ".repeat(114);
        const arrayResultsOriginal = [...strResultsOriginal.match(/.{1,6}/g)];                                                        
        // Resultados muestra a comparar
        const strResultsCompare = row && row.results !== "" 
            ? spaceFillRight(row.results, 114) 
            : " ".repeat(114);
        const arrayResultsCompare = [...strResultsCompare.match(/.{1,6}/g)];
        
        // Recuento                            
        const countAnalyzed = arrayResultsCompare.filter(result => result.trim() !== "").length;
        const countNoAnalyzed = arrayResultsCompare.filter(result => result.trim() === "").length;
        const countMatched = arrayResultsCompare.filter ((result, index) => {
            return result.substring(0,3) === arrayResultsOriginal[index].substring(0,3) 
                && result.substring(0,3).trim() !== "" 
                && result.substring(4,6) === arrayResultsOriginal[index].substring(4,6) 
                && result.substring(4,6).trim() !== ""
        }).length                            
        const countNoMatched = arrayResultsCompare.filter ((result, index) => {
            return result.substring(0,3) !== arrayResultsOriginal[index].substring(0,3)
                && result.substring(0,3).trim() !== ""
                && result.substring(4,6) !== arrayResultsOriginal[index].substring(4,6)
                && result.substring(4,6).trim() !== ""
        }).length

        const countDubious = countAnalyzed - countMatched - countNoMatched;                            
        const percentage = countAnalyzed > 0 ? parseInt(countMatched * 100 / countAnalyzed) : 0;                                                                                    

        return { 
            id: row.id,
            selected: row.selected,
            sampleLabel : sampleLabel,
            countAnalyzed: countAnalyzed,
            countNoAnalyzed: countNoAnalyzed,
            countMatched: countMatched,
            countNoMatched : countNoMatched,
            countDubious: countDubious,
            percentage: percentage
        }        
    });
    function compare (a,b) {
        if (a.countMatched < b.countMatched) return 1;
        if (a.countMatched > b.countMatched) return -1;
        if (a.countMatched === b.countMatched) {
            if (a.percentage < b.percentage) return 1;
            if (a.percentage > b.percentage) return -1;
            if (a.percentage === b.percentage) {
                if (a.countDubious < b.countDubious) return 1;
                if (a.countDubious > b.countDubious) return -1;
                return 0;    
            }
            return 0;
        }
        return 0;
    }
    rows.sort (compare);

    return (
        <div className={classes.containerTable}>
            {finding 
            ? <CircularProgress />
            : matching && matching.length === 0
            ? <Typography variant="body1" color="error" paragraph>No se han encontrado coincidencias</Typography>
            : <TableContainer component={Paper}>
                <Table size="small" aria-label="Muestras coincidentes">
                    <TableHead>
                        <TableRow>
                            {windowDim.width > theme.breakpoints.values.sm?<TableCell padding="checkbox"/>:null}
                            <TableCell>Muestra</TableCell>
                            <TableCell>
                                {windowDim.width > theme.breakpoints.values.md 
                                ? "Analizados" 
                                : windowDim.width > theme.breakpoints.values.sm ? "Analiz." : "A."}
                            </TableCell>
                            {windowDim.width > 1150
                            ?<TableCell>
                                {windowDim.width > theme.breakpoints.values.lg 
                                ? "No analizados" 
                                : "No analiz."}
                            </TableCell>
                            :null}
                            <TableCell>
                                {windowDim.width > theme.breakpoints.values.md 
                                ? "Coincidentes"
                                : windowDim.width > theme.breakpoints.values.sm ? "Coinc." : "C."}
                            </TableCell>
                            <TableCell>
                                {windowDim.width > theme.breakpoints.values.lg 
                                ? "No coincidentes"
                                : windowDim.width > theme.breakpoints.values.sm ? "No coinc." : "NC."}
                            </TableCell>
                            {windowDim.width > 700
                            ?<TableCell>
                                {windowDim.width > theme.breakpoints.values.sm ? "Dudosos" : "D."}
                            </TableCell>
                            :null}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                        { rows.map((row) => {
                            const classAvatar = row.percentage > 95 
                                ? clsx(classes.avatarSmall, classes.avatarGreen) 
                                : row.percentage > 80 
                                    ? clsx(classes.avatarSmall, classes.avatarOrange) 
                                    : clsx(classes.avatarSmall, classes.avatarRed);
                                    
                            return (
                                <TableRow 
                                    hover 
                                    selected={readOnly ? false : row.selected} 
                                    key={row.id} 
                                    name={row.id} 
                                    onClick={handleClick}
                                >
                                    {windowDim.width > theme.breakpoints.values.sm
                                    ?<TableCell><Avatar className={classAvatar}>
                                        <Typography variant="caption">{row.percentage}%</Typography></Avatar>
                                    </TableCell>
                                    :null}
                                    <TableCell>{row.sampleLabel}</TableCell>
                                    <TableCell>{row.countAnalyzed}</TableCell>
                                    {windowDim.width > 1150
                                    ?<TableCell>{row.countNoAnalyzed}</TableCell>
                                    :null}
                                    <TableCell>{row.countMatched}</TableCell>
                                    <TableCell>{row.countNotMatched}</TableCell>
                                    {windowDim.width > 700
                                    ?<TableCell>{row.countDubious}</TableCell>
                                    :null}
                                </TableRow>
                                )
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </div>
    )
}
