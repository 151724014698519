/**
 * Tarjeta para mostrar un mapa de las detecciones y muestras 
 */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../contexts/UserContext";
import { Card, CardContent } from "@material-ui/core";
import { useDataFetch, mysqlEscape } from "../../hooks/DataFetch";
import { Map } from "../common/Map";

const useStyles = makeStyles((theme)=> ({
    card: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0.5),
        }
    },
    content: {
        height: theme.spacing(43),
        textAlign: "center",
        marginLeft: "auto",
    },    
}))

export function CardMap() {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const { data } = useDataFetch({ 
        query: "SELECT id, latitude, longitude, 'Detección' as source FROM detection WHERE client_id='" 
            + mysqlEscape(user.client_id) + "' AND is_archived = 0 UNION " 
            + "SELECT sample.id as id, sample.latitude as latitude, sample.longitude as longitude, status as source " 
            + "FROM sample LEFT JOIN user ON (sample.user_id = user.id) " 
            + "WHERE sample.type = 'Heces' AND user.client_id='" + mysqlEscape(user.client_id) + "'" 
    });

    const center = { 
        lat: user.latitude ? parseFloat(user.latitude) : 0, 
        lng: user.longitude ? parseFloat(user.longitude) : 0
    };

    const points = data 
    ? data.map (row => ({ 
        id: row.id, 
        lat: row.latitude, 
        lng: row.longitude,
        source: row.source
    })) 
    : []; 

    const handleMarkerClick = (id) => {
        if (data.filter(point => point.id === id)[0].source==="Detección") {
            history.push (`/supervisor/detecciones/${id}`);
        } else {
            history.push (`/supervisor/muestras/${id}`);            
        }
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                {user.latitude 
                ? <Map
                    center={center} 
                    points={points} 
                    defaultZoom={13}
                    handleMarkerClick={handleMarkerClick}
                />
                : ""}
            </CardContent>
        </Card>        
    )
}
 