/**
 * Foto de informe
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles ((theme) => ({
    container: {
        display: "flex",
        marginLeft: theme.spacing(0.3),
        width: theme.spacing(28),
        height: theme.spacing(14.5),
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        font: "11px Arial, sans-serif",
    },
    image: {
        maxWidth: theme.spacing(28),
        maxHeight: theme.spacing(14.5),
    },
}));

export function FotoReport({ image }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {image 
            ? <img 
                src={`data:image/jpeg;base64,${image}`} 
                alt={"Foto"} 
                className={classes.image}
            />
            : "Fotografía no disponible"}                                
        </div>
    )
}
