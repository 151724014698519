import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles ((theme) => ({
    container: {
        width: theme.spacing(25),
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        font: "bold 12px Arial, sans-serif",
        borderRadius: "3px",
        marginBottom: theme.spacing(0.5),
        marginLeft: theme.spacing(0.3),
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.2),
        paddingLeft: theme.spacing(0.5),      
    },
}));

export function SubtitleReport({ caption }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {caption}
        </div>
    )
}
