/**
 * Componente para cambio de contraseña desde formulario con despliegue de acordeón
 */
import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextFieldIcon } from "../common/TextFieldIcon";
import { ReactComponent as PassIcon } from "../../assets/key3.svg";

const useStyles = makeStyles ((theme) => ({
    accordion: {
        border: "1px solid #b0bec5",
        boxShadow: "none",
    },
    accordionDetails: {
        width: "100%",
    },
    blockspace: {
        width: theme.spacing(2),
    }    
}));

export const AcordionChangePass = ({ 
    disabled, 
    setIsModified, 
    setPass, 
    setRepeat, 
    errorPass, 
    setErrorPass, 
    errorRepeat, 
    setErrorRepeat, 
    className 
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = () => {
        if (expanded) {
            document.getElementById("pass").value = null
            document.getElementById("repeat").value = null;
            setErrorPass(false);
            setErrorRepeat(false);
            setPass("");
            setRepeat("");
        }
        setExpanded (!expanded);        
    }
    
    const handlePassChange = (event) => {
        setPass(event.target.value);
        // No se activa el estado modificado
    }

    const handleRepeatChange = (event) => {
        setRepeat(event.target.value);
        if (event.target.value) setIsModified(true);
    }

    return (
        <Accordion 
            className={classes.accordion + " " + className} 
            disabled={disabled} 
            expanded={expanded}
            onChange={handleAccordionChange}
        >
            <AccordionSummary 
                expandIcon={<ExpandMoreIcon />} 
                id="changepass" 
                aria-controls="changepass-content"
            >
                <Typography>Cambiar o establecer contraseña</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.blockspace}/>
                <div className={classes.accordionDetails}>
                    <TextFieldIcon
                        id="pass" 
                        icon={PassIcon} 
                        label="Nueva contraseña" 
                        error={errorPass} 
                        textError="La contraseña introducida no es segura"
                        onChange={handlePassChange}
                        type="password"
                    />
                    <TextFieldIcon 
                        id="repeat" 
                        icon={PassIcon} 
                        label="Repite la nueva contraseña" 
                        error={errorRepeat} 
                        textError="La contraseña repetida no coincide"
                        onChange={handleRepeatChange} 
                        type="password"
                    />
                </div>
                <div className={classes.blockspace}/>
            </AccordionDetails>
        </Accordion>            
    )
}
