/**
 * Dashboard para el perfil vigilante
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { CardNewDetection } from "./CardNewDetection";
import { CardFastDetection } from "./CardFastDetection";
import { CardMapDetection } from "./CardMapDetection";

const useStyles = makeStyles((theme)=> ({
    root: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5),
        }
    }
}))

export function DashboardVigilant() {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={6} md={6}><CardNewDetection /></Grid>
            <Grid item xs={12} sm={6} md={6}><CardFastDetection /></Grid>
            <Grid item xs={12} sm={12} md={12}><CardMapDetection /></Grid>
        </Grid>
    )
}
