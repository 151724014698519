import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles ((theme) => ({
    tableTitle: {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main,
        border: "1px dotted",   
        color: "White",
        height: "35px"
    },
}));

export function TableTitleWorksheet() {
    const classes = useStyles();

    return (
        <thead>
            <tr>
                <th className={classes.tableTitle} width="70px">CÓDIGO</th>
                <th className={classes.tableTitle} width="70px">MUESTRA</th>
                <th className={classes.tableTitle} width="120px">EXTRAC.</th>
                <th className={classes.tableTitle} width="120px">PCR</th>
                <th className={classes.tableTitle} width="120px">SECUEN.</th>
                <th className={classes.tableTitle} width="150px">OBSERVACIONES</th>
                <th className={classes.tableTitle} width="50px">INFOR. ENV.</th>
            </tr>
        </thead>
    )
}