/**
 * Tarjeta para mostrar un mapa de las detecciones 
 */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { UserContext } from "../../contexts/UserContext";
import { Card, CardContent } from "@material-ui/core";
import { useDataFetch, mysqlEscape } from "../../hooks/DataFetch";
import { usePosition } from "../../hooks/Position";
import { Map } from "../common/Map";

const useStyles = makeStyles((theme)=> ({
    card: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0.5),
        }
    },
    content: {
        height: theme.spacing(43),
        textAlign: "center",
        marginLeft: "auto",
    },    
}))

export function CardMapDetection() {
    const classes = useStyles();
    const history = useHistory();
    const { lat, lng } = usePosition();
    const { user } = useContext(UserContext);    
    const { data } = useDataFetch({ 
        query: "SELECT id, latitude, longitude FROM detection WHERE user_id='" 
            + mysqlEscape(user.id) + "' " 
    });

    const center = { 
        lat: lat ? lat : (user.latitude ? parseFloat(user.latitude) : 0), 
        lng: lng ? lng : (user.longitude ? parseFloat(user.longitude) : 0)
    };

    const points = data 
        ? data.map (row => ({ 
            id: row.id, 
            lat: row.latitude, 
            lng: row.longitude,
            source: "detection"
        })) 
        : [];    

    const handleMarkerClick = (id) => {
        history.push (`/vigilante/detecciones/${id}`);
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                {user.latitude 
                ? <Map
                    center={center} 
                    points={points} 
                    defaultZoom={13}
                    handleMarkerClick={handleMarkerClick}
                />
                : ""}
            </CardContent>
        </Card>        
    )
}
 