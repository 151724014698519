/**
 * Tarjeta para mostrar acceso directo a la creación de una muestra
 */
import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as salivaIcon } from '../../assets/blot.svg';
import { CardDashboard } from "../common/CardDashboard";

export function CardNewSample() {
    const history = useHistory();

    const handleClick = () => {
        history.push ("/veterinario/nuevamuestra/");
    }

    return (
        <CardDashboard
            title="Nueva muestra"
            subtitle="Muestra de saliva"
            handleClick={handleClick}
            shortXs={true}
            contentIcon={salivaIcon}
        />
    )
}
