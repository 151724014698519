/**
 * Barra de título principal que muestra la sección de usuario, notificaciones y funciones de logout
 */
import React, { useState, useContext }from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, SvgIcon, Badge, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as NotifIcon } from '../../assets/bell.svg';
import { ReactComponent as UserIcon } from '../../assets/user.svg';
import { drawerWidth, viewBox } from "../../config";
import { UserContext } from "../../contexts/UserContext";

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    },
    iconButton: {
        marginLeft: theme.spacing(1)
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        userSelect: "none",    
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}`,    
        }
    }, 
}))

export function AppBarMain ({ handleDrawerOpen, title, numNotif }) {
    const { user, setUser } = useContext(UserContext);
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleAccountClick = () => {
        setAnchorEl(null);
        history.push(`/${user.profile}/micuenta/${user.id}`);
    };

    const handleLogoutClick = () => {
        setAnchorEl(null);
        localStorage.removeItem('credentials');
        setUser(null);
    }

    const handleNotifClick = () => {
        history.push(`/${user.profile}/notificaciones`);
    };

    return (
        <AppBar id="appbar" color="primary" className={classes.appBar}>
            <Toolbar>
                <Tooltip title="menú">                  
                    <IconButton 
                        aria-label="menú"
                        color="inherit" 
                        className={classes.menuButton}
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <Tooltip title="Notificaciones">                  
                    <Badge 
                        badgeContent={ parseInt(numNotif) || 0 }
                        color="secondary"
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <IconButton 
                            aria-label="notificaciones" 
                            className={classes.iconButton}
                            onClick={handleNotifClick}
                        >
                            <SvgIcon component={NotifIcon} viewBox={viewBox} color="error"/>                  
                        </IconButton>
                    </Badge>
                </Tooltip>
                <Tooltip title="Mi cuenta">                  
                    <IconButton 
                        aria-label="mi cuenta" 
                        className={classes.iconButton}
                        aria-controls="menu-profile"
                        aria-haspopup="true"
                        onClick={handleMenu}
                    >
                        <SvgIcon component={UserIcon} viewBox={viewBox}/>
                    </IconButton>
                </Tooltip>
                <Menu
                    id="menu-profile"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}                    
                >
                    <MenuItem onClick={handleAccountClick}>Mi cuenta</MenuItem>
                    <MenuItem onClick={handleLogoutClick}>Cerrar sesión</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}