/**
 * Orden de trabajo imprimible
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HeaderWorksheet } from "./HeaderWorksheet";
import { TitleWorksheet } from "./TitleWorksheet";
import { TableTitleWorksheet } from "./TableTitleWorksheet";
import { TableRowWorksheet } from "./TableRowWorksheet";
import { InfoWorksheet } from "./InfoWorksheet";

const useStyles = makeStyles ((theme) => ({
    container: {
        size: "21cm 29.7cm",
        paddingLeft: "2.5cm",
        paddingRight: "2cm",
    }, 
    sign: {
        paddingTop: "10px",
        paddingRight: "100px",
        marginBottom: "100px"
    },
    table: {
        font: "11px Arial, sans-serif",
        borderCollapse: "collapse",
    },
}));

export const Worksheet = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const data = props.data;
    const samplesPerPage = 13;

    let pages = [{numPage:1, samples:[]}];
    for (const [index, value] of data.entries()) {
        const indexPage = Math.floor(index/samplesPerPage);
        if (!pages[indexPage]) {
            pages.push({
                numPage:indexPage+1, 
                samples:[]
            });
        };
        pages[indexPage].samples.push(value);
    }

    return (
        <div ref={ref} id="worksheet" className={classes.container}>
            {pages.map((page) => {
                return(
                <div key={page.numPage}>
                    <HeaderWorksheet 
                        numPage={page.numPage}
                        totalPages={pages.length}
                    /> 
                    <TitleWorksheet caption="Hoja de trabajo"/>
                    <InfoWorksheet data={page.samples} />
                    <table className={classes.table}>                    
                        <TableTitleWorksheet />
                        <tbody>
                            {page.samples.map((sample, key) => {
                                return (<TableRowWorksheet data={sample} key={key}/>);                    
                            })}
                        </tbody>
                    </table>
                    <div className={classes.sign}>Firma del responsable</div>
                </div>)
            })}
        </div>
    )
});
