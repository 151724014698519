import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { zeroFill } from "../../utils/StringUtils";
import { prefixFeces, prefixSaliva } from "../../config";

const useStyles = makeStyles ((theme) => ({
    tableCell: {
        borderColor: theme.palette.primary.main,
        border: "1px dotted",
        height: "50px"
    },
    tableText: {
        margin: 0,
        padding: 0,
    }
}));

export function TableRowWorksheet({ data }, key) {
    const classes = useStyles();
    const prefixSample = data.type === "Heces" ? prefixFeces : prefixSaliva;   
    const sampleLabel = (data && data.label !== undefined && data.block_label_id > 0) 
        ? `${prefixSample}-${data.serial}-${zeroFill(data.block_label_id, 5)}-${zeroFill(data.label, 3)}` 
        : ""

    return (
        <tr key={key}>
            <td className={classes.tableCell}>{sampleLabel}</td>
            <td className={classes.tableCell}></td>
            <td className={classes.tableCell}>
                <p className={classes.tableText}>FECHA:</p>
                <p className={classes.tableText}>LOTE:</p>
                <p className={classes.tableText}>PROT.:</p>
            </td>
            <td className={classes.tableCell}>
                <p className={classes.tableText}>FECHA:</p>
                <p className={classes.tableText}><br></br></p>
                <p className={classes.tableText}>H. TRAB. ADJ.</p>
            </td>
            <td className={classes.tableCell}>
                <p className={classes.tableText}>FECHA:</p>
                <p className={classes.tableText}>LOTE:</p>
                <p className={classes.tableText}>PROT.:</p>
            </td>
            <td className={classes.tableCell}></td>
            <td className={classes.tableCell}></td>
        </tr>
    )
}