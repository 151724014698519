/**
 * Icono de adjunto
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Icon } from '../../assets/paperclip.svg';
import { viewBox } from "../../config";

export function AttachedIcon() {
    return (
        <SvgIcon  component={Icon} viewBox={viewBox} />
    )
}
