/**
 * Icono según el tipo de usuario para el avatar del item de la lista 
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as AdminIcon } from '../../assets/security_agent.svg';
import { ReactComponent as VeterinaryIcon } from '../../assets/doctor.svg';
import { ReactComponent as AnalystIcon } from '../../assets/scientist.svg';
import { ReactComponent as SupervisorIcon } from '../../assets/businesswoman2.svg';
import { ReactComponent as GathererIcon } from '../../assets/delivery_man.svg';
import { ReactComponent as VigilantIcon } from '../../assets/policeman.svg';
import { ReactComponent as UsersIcon } from '../../assets/users3.svg';
import { viewBox } from "../../config";

export function UserIcon({ selector }) {
    return (
        <SvgIcon component={
            selector === "supervisor" ? SupervisorIcon :
            selector === "recolector" ? GathererIcon :
            selector === "vigilante" ? VigilantIcon :
            selector === "laboratorio" ? AnalystIcon :
            selector === "veterinario" ? VeterinaryIcon :
            selector === "administrador" ? AdminIcon : UsersIcon 
        } viewBox={viewBox}
        />
    )
}
