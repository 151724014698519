/**
 * Formulario de actualización de la nueva contraseña
 */
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Typography, useMediaQuery } from "@material-ui/core";
import { TextFieldIcon } from "../common/TextFieldIcon";
import { FloatAlert } from "../common/FloatAlert";
import { fetchApi } from "../../hooks/DataFetch";
import { sha256 } from "js-sha256";
import logo from "../../assets/logologin.png";
import logodark from "../../assets/logologindark.png";
import { ReactComponent as PassIcon } from "../../assets/key3.svg";
import { ReactComponent as CodeIcon } from "../../assets/numeric_keypad.svg";
import { SettingsContext } from "../../contexts/SettingsContext";

export function FormLoginChange ({ classes, userChanged, setPassChanged }) {
    const { settings } = useContext(SettingsContext);
    const history = useHistory();
    const [code, setCode] = useState("");
    const [pass, setPass] = useState("");
    const [repeat, setRepeat] = useState("");
    const [errorCode, setErrorCode] = useState(false);
    const [errorPass, setErrorPass] = useState(false);
    const [errorRepeat, setErrorRepeat] = useState(false);
    const [errorVerif, setErrorVerif] = useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (code === "") {
            setErrorCode(true);
        } else {
            setErrorCode(false);
            if (settings.is_strong ? !pass.match(/[A-Z]/) || !pass.match(/[a-z]/) || !pass.match(/[1-9]/) || (pass.length < 8) : pass === "") {
                setErrorPass(true);
            } else {
                setErrorPass(false);
                if (repeat !== pass) {
                    setErrorRepeat(true);
                } else {
                    setErrorRepeat(false);
                    const response = await fetchApi({
                        action: "change",
                        userId: userChanged,
                        code: code,
                        pass: sha256(pass)
                    });
                    if (response.data) {
                        setErrorVerif(false);
                        history.push("/");
                        setPassChanged(true);
                    } else {
                        setErrorVerif(true);
                    }
                }
            }
        }
    }

    const handleCancelClick = () => {
        history.push("/");
    }

    const handleCodeChange = (event) => {
        setCode (event.target.value);
    }

    const handlePassChange = (event) => {
        setPass (event.target.value);
    }

    const handleRepeatChange = (event) => {
        setRepeat (event.target.value);
    }

    const handleErrorClose = () => {
        setErrorVerif(false);
    }

    return (
        <div className={classes.container} >
            <Container component="main" maxWidth="xs">                
                <div className={classes.paper}>
                    <img src={prefersDarkMode ? logodark : logo} alt="logo"/>
                    <Typography variant="h5" gutterBottom>
                        Actualiza tu contraseña
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        Introduce el código que has recibido en tu cuenta de correo electrónico y establece la nueva contraseña.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextFieldIcon 
                            id="code" 
                            icon={CodeIcon} 
                            label="Código" 
                            error={errorCode} 
                            onChange={handleCodeChange} 
                            type="number"
                        />               
                        <TextFieldIcon 
                            id="pass" icon={PassIcon} 
                            label="Nueva contraseña" 
                            error={errorPass} 
                            textError="La contraseña introducida no es segura"
                            onChange={handlePassChange} 
                            type="password"
                        />               
                        <TextFieldIcon 
                            id="repeat" 
                            icon={PassIcon} 
                            label="Repite la nueva contraseña" 
                            error={errorRepeat} 
                            textError="La contraseña repetida no coincide"
                            onChange={handleRepeatChange} 
                            type="password"/>               
                        <Button className={classes.buttonFixed} type="submit" variant="contained" color="primary" size="large">
                            Enviar
                        </Button>
                        <Button className={classes.buttonFixed} variant="contained" color="secondary" size="large" onClick={handleCancelClick}>
                            Cancelar
                        </Button>
                        <FloatAlert 
                            open={errorVerif} 
                            onClose={handleErrorClose} 
                            text="El código introducido no es correcto. Inténtalo de nuevo." 
                            severity="error"
                        />               
                    </form>
                </div>
            </Container>
        </div>
    )
}

