/**
 * Componente principal de entrada para mostrar las notificaciones
 */
import React, { useEffect, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ListForm } from "../common/ListForm";
import { NotificationItem } from "./NotificationItem";
import { NotificationIcon } from "./NotificationIcon";
import { fetchApi, mysqlEscape, mysqlInteger } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";

export function Notifications({ setNumNotif }) {
    const { user } = useContext(UserContext);
    const isMounted = useIsMounted();

    function getTitleNotifications () {
        return "Notificaciones";
    };  

    function getQueryNotifications ({ filter, search, limitPlus, offset = 0 }) {
        let where = "WHERE user_id = '" + mysqlEscape(user.id) +"'"
        if (filter) { 
            if (filter === "nuevas") { 
                where += "(is_viewed = 0)";
            } else {
                where += "(is_viewed = 1)";
            }
        }
        if (search) {
            const searchScape = mysqlEscape(search);
            where += " AND (text LIKE '%"+searchScape+"%' OR date LIKE '%"+searchScape+"%') ";        
        }
        return "SELECT id, type, text, is_notified, is_viewed, date, user_id FROM advise " 
            + where + " ORDER BY id DESC LIMIT " + offset + ", " + (limitPlus===0 ? 1 : limitPlus);                    
    };

    function actionNotificationClick ({ id, data, setData, setError }) {
        // Establece la notificación como vista
        async function fetchUpdate() {
            const response = await fetchApi({ 
                query: "UPDATE advise SET is_viewed=1 WHERE id = " + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
        }
    
        const newData = data.map(item => Object.assign({}, item));
        let index = newData.findIndex((item) => { return item.id === id });
        if (index >= 0) {
            if (!newData[index].is_viewed) {
                newData[index].is_viewed = true;        
                setData(newData);
                fetchUpdate();        
            }
        }  
    }

    function actionNotificationClose ({ id, data, setData, setError }) {
        // Borra la notificación seleccionada
        async function fetchDelete() {
            const response = await fetchApi({ 
                query: "DELETE FROM advise WHERE id = " + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
        }
    
        let newData = data.map(item => Object.assign({}, item));
        newData = newData.filter(item => item.id !== id);
        setData(newData);
        fetchDelete();
    }    

    useEffect (() => {
        // Actualización inicial de notificaciones como notificadas        
        async function fetchUpdate() {
            await fetchApi({ 
                query: "UPDATE advise SET is_notified=1 WHERE user_id = '" 
                    + mysqlEscape(user.id) + "'"
            }); 
        }        
        fetchUpdate();
        setNumNotif(0); 
    },[user.id, setNumNotif]);

    return (
        <ListForm 
            pathBack="/administrador"
            item={NotificationItem}
            icon={NotificationIcon}
            getQuery={getQueryNotifications} 
            getTitle={getTitleNotifications}                  
            actionItemClick={actionNotificationClick}
            actionItemClose={actionNotificationClose}
        />                                
    )
}
