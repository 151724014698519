/**
 * Diálogo de confirmación al grabar
 */
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

export function DeleteDialog (props) {
    // En este caso desesctructurar directamente no funciona
    const { open, onClickYes, onClickNo, onClickCancel, title, description } = props

    return (
        <Dialog
            open={open}
            onClose={onClickCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                <DialogActions>
                    <Button onClick={onClickYes} color="primary">Sí</Button>
                    <Button onClick={onClickNo} color="primary">No</Button>
                    <Button onClick={onClickCancel} color="secondary">Cancelar</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>            
    )
}
