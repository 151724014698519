/**
 * Subselector para las opciones del perfil vigilante
 */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { DetectionForm } from "../components/detections/DetectionForm";
import { Detections } from "../components/detections/Detections";
import { Notifications } from "../components/notifications/Notifications";
import { UserForm } from "../components/users/UserForm";
import { DashboardVigilant } from "../components/vigilant/DashboardVigilant";

export function SwitchVigilant({ setNumNotif }) {
    return (
        <Switch>
            <Route path="/vigilante/detecciones" exact>
                <Detections pathBack="/vigilante" />
            </Route>
            <Route path="/vigilante/detecciones/:id" exact>
                <DetectionForm pathBack="/vigilante" />
            </Route>
            <Route path="/vigilante/nuevadeteccion" exact>
                <DetectionForm pathBack="/vigilante" isNew />
            </Route>
            
            <Route path="/vigilante/notificaciones" exact>                    
                <Notifications setNumNotif={setNumNotif} />
            </Route>
            <Route path="/vigilante/micuenta/:id" exact>
                <UserForm />
            </Route>
            <Route path="/vigilante" exact>
                <DashboardVigilant />                        
            </Route>
        </Switch>
    )
}
