/**
 * Muestra el mapa estático usando la API de Google Maps
 */
import React from "react";
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import { apiKeyGoogle } from "../../credentials";

export function MapStatic({ location }) {
    return (
        <StaticGoogleMap size="224x116" apiKey={apiKeyGoogle} maptype="hybrid">
            <Marker location={location} />
        </StaticGoogleMap>
    )
}


