/**
 * Formulario de detalle de detección
 */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { FormGroup, Grid, TextField } from "@material-ui/core";
import { ReactComponent as DetectionsIcon } from '../../assets/compass.svg';
import { fetchApi, jsonParseMysql, mysqlEscape, mysqlInteger, mysqlFloat } from "../../hooks/DataFetch";
import { usePosition } from "../../hooks/Position";
import { useIsMounted } from "../../hooks/IsMounted";
import { FormGroupButton } from "../common/FormGroupButton";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { SaveDialog } from "../common/SaveDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { MapField } from "../common/MapField";
import { ImageField } from "../common/ImageField";
import { Loading } from "../Loading";
import moment from 'moment';
import { buttonGroup, buttonGroupXs } from "../../config";

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",
    },
    form: {
        maxWidth: "1200px",
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            maxWidth: "none",
            paddingTop: theme.spacing(0),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0.5),
        }, 
    },
    columnGrid: {
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(2),
        }
    },
    field: {
        width: "100%",
        marginTop: theme.spacing(3),        
    }, 
    fieldTitle: {
        width: "100%",
        marginTop: theme.spacing(2),        
    }, 
    bottomSpace: {
        // La altura debe coincidir con FormGroupButton
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            height: buttonGroupXs,
        }        
    },
}));

export function DetectionForm({ isNew, pathBack }) {
    const { user } = useContext(UserContext);    
    const { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const position = usePosition();
    // Se recuerda el filtro del listado para poder volver al mismo listado filtrado
    const filter = new URLSearchParams(useLocation().search).get("filter");     
    
    // Estados de carga de datos
    const [data, setData] = useState([{
        id: "",
        date: "",
        observations: "",
        latitude: "",
        longitude: "",
        is_attached: "",
        is_archived: "",
    }]);
    const [image, setImage] = useState();
    const [address, setAddress] = useState() 
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isModified, setIsModified] = useState(false);

    // Estados de validación de errores
    const [error, setError] = useState({ open: false, status: '' });

    // Estados de diálogos
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    async function saveForm () { 

        async function fetchUpdate(newData) {
            let query = "";
            if (isNew) {
                query = "INSERT INTO detection " 
                    + "(date, observations, address, latitude, longitude, is_attached, image, user_id, client_id)" 
                    + " VALUES ('" 
                    + mysqlEscape(newData.date) + "', '" + mysqlEscape(newData.observations) + "', '" 
                    + mysqlEscape(address) + "', " + mysqlFloat(lat) + ", " + mysqlFloat(lng) + ", "
                    + mysqlInteger(newData.is_attached ? 1 : 0) + ", '" + (image ? mysqlEscape(image) : "") + "', '"
                    + mysqlEscape(user.id) + "', '" + mysqlEscape(user.client_id) + "')";
            } else {
                query = "UPDATE detection SET date='" + mysqlEscape(newData.date) 
                    + "', observations='" + mysqlEscape(newData.observations) 
                    + "', address='" + mysqlEscape(address) 
                    + "', latitude=" + mysqlFloat(newData.latitude) 
                    + ", longitude=" + mysqlFloat(newData.longitude) 
                    + ", is_attached=" + mysqlInteger(newData.is_attached ? 1 : 0) 
                    + ", image='" + (image ? mysqlEscape(image) : "") 
                    + "' WHERE id=" + mysqlInteger(id);
            }
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                if (isNew) lastId = parseInt(JSON.parse(response.data)[0].lastId) || 0;
                return true;
            }
        }     
    
        // Crea una copia de los datos
        if (isMounted) setIsSaving(true);
        let lastId = 0;
        let newData = {...data[0]};               
        let verificationError = false;

        // Graba los cambios si no hay errores de verificación 
        if (!verificationError) {
            if (await fetchUpdate(newData)) {
                // Reinicia estados y valores
                if (isMounted) {
                    setIsModified(false);                
                    setIsSaving(false);
                }
                return lastId ? lastId : true;
            }
        }
        if (isMounted) setIsSaving(false);
        return false;        
    }
    
    async function deleteForm () {

        async function fetchDelete() {
            const query = "DELETE FROM detection WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        if (await fetchDelete()) {
            // Eliminación correcta
            return true                
        } else {
            // Error de API
            return false
        }
    }

    async function enableForm() {

        async function fetchUpdate() {
            const query = "UPDATE detection SET is_disabled = 0 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        if (await fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_disabled: false,
            }];
            setData(newData);
        }               
    }

    async function archiveForm() {

        async function fetchUpdate() {
            const query = "UPDATE detection SET is_archived = 1 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        if (await fetchUpdate()) {
            // Archivo correcto
            return true                
        } else {
            // Error de API
            return false
        }        
    }

    async function unarchiveForm() {

        async function fetchUpdate() {
            const query = "UPDATE detection SET is_archived = 0 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        if (await fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_archived: false,
            }];
            setData(newData);
        }       
    }

    async function saveAndExit() {
        // Función asíncrona para guardar y salir que evitar la salida en caso de error
        if (await saveForm()) {            
            exit();
        } 
    }

    async function saveAndRefresh() {
        // Función asíncrona para guardar y recargar la ficha
        const result = await saveForm();
        if (result) {
            if (filter) {
                history.push(`${pathBack}/detecciones/${result}?filter=${filter}`);
            } else {
                history.push(`${pathBack}/detecciones/${result}`);
            }    
        }
    }

    async function deleteAndExit() {
        // Función asíncrona para eliminar el elemento
        if (await deleteForm()) {
            exit();
        }
    }

    async function archiveAndExit() {
        // Función asíncrona para archivar el elemento
        if (await archiveForm()) {
            exit();
        }
    }

    function exit() {
        if (filter) {
            history.push (`${pathBack}/detecciones?filter=${filter}`);
        } else {
            history.push (`${pathBack}/detecciones`);
        }
    }
    
    useEffect (() => { 

        async function fetchLoad() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT id, date, observations, latitude, longitude, is_attached, is_archived " 
                    + "FROM detection WHERE id=" + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setData(jsonData);
            };
            if (isMounted) setIsLoading(false);
        }   
    
        async function fetchLoadImage() {
            // La imagen se lee por separador para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT image FROM detection WHERE id=" + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setImage(jsonData[0].image);
            };
        }         
        
        if (isNew) {
            // Para detecciones nuevas no se realiza la carga inicial
            const newData = [{
                id: "",
                date: moment(new Date()).format("YYYY-MM-DD"),
                observations: "",
                latitude: "",
                longitude: "",
                is_attached: "",
                is_archived: "",                
            }];
            if (isMounted) {
                setData (newData);
                setIsModified(true);
                setIsLoading(false);
            }
        } else {
            // Carga inicial desde la API
            id && fetchLoad();
            id && fetchLoadImage();
        }
    },[id, isNew, isMounted])    

    const handleChange = (event) => {
        const newData = [{
            ...data[0],            
            [event.target.name] : event.target.value            
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleMapClick = (event) => {
        const newData = [{
            ...data[0],
            latitude: event.lat.toFixed(7).toString(),
            longitude: event.lng.toFixed(7).toString()
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleButtonCurrentClick = () => {
        const newData = [{
            ...data[0],
            latitude: position.lat.toString(),
            longitude: position.lng.toString()
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleApply = () => {
        // Botón aplicar
        if (isNew) {
            saveAndRefresh();
        } else {
            saveForm();
        }
    }

    const handleExit = () => {
        // Botón salir
        if (isModified && !isSaving) {
            setOpenSaveDialog(true);
        } else {
            setOpenSaveDialog(false);
            exit();
        }
    }

    const handleDelete = () => {
        if (data[0] && data[0].is_disabled) {
            // Botón habilitar
            enableForm();
        } else {
            // Botón eliminar
            setOpenDeleteDialog(true);
        }
    }

    const handleArchive = () => {
        if (data[0] && data[0].is_archived) {
            // Botón desarchivar
            unarchiveForm();
        } else {
            // Botón archivar
            setOpenDeleteDialog(true);
        }
    }    

    const handleYesSave = () => {
        setOpenSaveDialog(false);
        saveAndExit();
    }

    const handleNoSave = () => {
        setOpenSaveDialog(false);
        exit();
    }

    const handleCancelSave = () => {
        setOpenSaveDialog(false);
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        user.profile!=="vigilante" ? archiveAndExit() : deleteAndExit();
    }

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }

    const lat = data[0].latitude 
        ? parseFloat(data[0].latitude) 
        : position.lat ? position.lat : (user.latitude ? parseFloat(user.latitude) : 0);
    const lng = data[0].longitude 
        ? parseFloat(data[0].longitude) 
        : position.lng ? position.lng: (user.longitude ? parseFloat(user.longitude) : 0);
    
    // Valores iniciales
    let center = { lat: 0, lng: 0 };
    let points = [{ id: data[0].id, lat: 0, lng: 0, source:"detection" }];

    // Comprueba si las coordenadas son correctas y las asigna
    if (lat <= 90 && lat >= -90 && lng <= 180 && lng >= -180) {
        center = { lat: lat, lng: lng }
        points = [{ id: data[0].id, lat: lat, lng: lng, source:"detection" }];
    }

    if (isLoading) {
        return <Loading />
    } else {
        return (
            <div className={classes.container}>
                <Title 
                    text={isNew ? "Nueva detección" : "Detección: " + id } 
                    icon={DetectionsIcon} 
                    pathBack={`${pathBack}/detecciones`}
                    archived={data[0] && data[0].is_archived}
                />
                <FormGroup className={classes.form}>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>
                            <MapField 
                                classContainer={classes.fieldTitle} 
                                center={center} 
                                points={points}
                                address={address}
                                setAddress={setAddress}                               
                                handleMapClick={user.profile==="vigilante" ? handleMapClick : null }
                                handleButtonCurrentClick={user.profile==="vigilante" ? handleButtonCurrentClick : null }
                            />
                            <TextField
                                className={classes.field}
                                disabled={isLoading || user.profile!=="vigilante"} 
                                variant="outlined"                                
                                value={(data[0] && data[0].date !== undefined && data[0].date !== "0000-00-00") ? data[0].date : ""} 
                                onChange={handleChange}
                                label="Fecha"
                                id="date"
                                name="date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                className={classes.field}
                                disabled={isLoading || user.profile!=="vigilante"} 
                                variant="outlined" 
                                value={(data[0] && data[0].observations !== undefined) ? data[0].observations : ""} 
                                onChange={handleChange}
                                label="Observaciones" 
                                id="observations"
                                name="observations"
                                rows={3}
                                multiline
                                inputProps={{ maxLength: 255 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>
                            <ImageField 
                                classContainer={classes.fieldTitle} 
                                image={image} 
                                setImage={setImage}
                                data={data}
                                setData={setData}
                                setIsModified={setIsModified}
                                disabled={user.profile!=="vigilante"}
                                description="La imagen tomada debería poder ayudar al recolector a ubicar el punto de muestreo (preferentemente en formato horizontal)."
                            />                           
                        </Grid>
                    </Grid>
                    <div className={classes.bottomSpace}/>
                    <FloatAlert 
                        open={error.open} 
                        onClose={handleErrorClose} 
                        text="No hemos podido leer o guardar los cambios. Comprueba tu conexión." 
                        severity="error"
                    />
                </FormGroup> 
                <FormGroupButton 
                    disabledApply={isLoading || !isModified}
                    isSaving={isSaving}
                    hideDelete={!!isNew}
                    handleApplyClick={handleApply} 
                    handleExitClick={handleExit} 
                    handleDeleteClick={user.profile!=="vigilante" ? handleArchive : handleDelete }
                    textDelete={                         
                        user.profile!=="vigilante" 
                        ? (data[0] && data[0].is_archived ? "Desarchivar" : "Archivar")
                        : (data[0] && data[0].is_disabled ? "Habilitar" : "")                       
                    }
                />
                <SaveDialog 
                    open={openSaveDialog}
                    onClickYes={handleYesSave}
                    onClickNo={handleNoSave}
                    onClickCancel={handleCancelSave}
                />
                <DeleteDialog
                    open={openDeleteDialog}
                    onClickYes={handleYesDelete}
                    onClickNo={handleCancelDelete}
                    onClickCancel={handleCancelDelete}
                    title={
                        user.profile!=="vigilante"
                        ? "¿Estás seguro de que quieres archivar esta detección?"
                        : "¿Estás seguro de que quieres eliminar esta detección?" 
                    }
                    description={
                        user.profile!=="vigilante"
                        ? "Si continúas este proceso se archivará la detección, quedando ocultada en el listado principal."
                        : "Si continúas este proceso se eliminará la detección del sistema."
                    }
                />
            </div>
        )
    }
}