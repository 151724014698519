/**
 * Icono de detección para el avatar del item de la lista
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Icon } from '../../assets/compass.svg';
import { viewBox } from "../../config";

export function DetectionIcon() {
    return (
        <SvgIcon component={Icon} viewBox={viewBox} />
    )
}
