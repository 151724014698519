/**
 * Desplegable de selección de animal
 */
import React from "react";
import { Avatar, IconButton, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { AnimalIcon } from "../animals/AnimalIcon";

const useStyles = makeStyles ((theme) => ({
    field: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    container: {
        display: "flex",
        flexDirection: "center",
        alignItems: "center", 
        width: "100%",
        padding: theme.spacing(1),
        border: "1px solid",
        borderRadius: "5px",
        borderColor: theme.palette.grey[400],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            borderColor: theme.palette.grey[600],
        }
    },
    containerText: {
        marginLeft: theme.spacing(2),
        marginRight: "auto",
        maxWidth: "300px",
    },
    textDisabled: {
        color: theme.palette.grey[400],
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            color: theme.palette.grey[700],
        }
    },
}));

export function AnimalSelector({ id, title, subtitle, pathBack, history, isNew, data, disabled }) {
    const classes = useStyles();

    const handleClickNew = () => {
        localStorage.setItem('data', JSON.stringify(data));
        if (isNew) {
            history.push(`${pathBack}/nuevoanimal?select=nuevamuestrarecarga`);
        } else {
            history.push(`${pathBack}/nuevoanimal?select=muestrarecarga`);
        }
    }

    const handleClickSelect = () => {
        localStorage.setItem('data', JSON.stringify(data));
        if (isNew) {
            history.push(`${pathBack}/animales?select=nuevamuestrarecarga`);   
        } else {
            history.push(`${pathBack}/animales?select=muestrarecarga`);   
        }
    }

    return (
        <div className={classes.field}>
            <Typography variant="h6" component="h3" color="primary">
                Animal
            </Typography>
            <div className={classes.container}>
                <Avatar><AnimalIcon /></Avatar>
                <div className={classes.containerText}>
                    <Typography 
                        component="div" 
                        className={id && (parseInt(id) || 0) !== 0 ? "" : classes.textDisabled }
                    >
                        {title && (parseInt(id) || 0) !== 0 ? title : "Selecciona el animal"}
                    </Typography>
                    <Typography variant="caption" >{subtitle}</Typography>
                </div>
                { disabled ? "" : 
                <div>               
                    <Tooltip title="Nuevo">
                        <IconButton onClick={handleClickNew} aria-label="nuevo"><AddIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Seleccionar">
                        <IconButton onClick={handleClickSelect} aria-label="seleccionar"><SearchIcon /></IconButton>
                    </Tooltip>
                </div>
                }
            </div>
        </div>
    )
}
