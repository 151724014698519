/**
 * Subselector para las opciones del perfil supervisor
 */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { Animals } from "../components/animals/Animals";
import { AnimalDetail } from "../components/animals/AnimalDetail";
import { DetectionForm } from "../components/detections/DetectionForm";
import { Detections } from "../components/detections/Detections";
import { Notifications } from "../components/notifications/Notifications";
import { UserForm } from "../components/users/UserForm";
import { Samples } from "../components/samples/Samples";
import { ResultForm } from "../components/samples/ResultForm";
import { DashboardSupervisor } from "../components/supervisor/DashboardSupervisor";
import { SampleMap } from "../components/supervisor/SampleMap";
import { Matches } from "../components/samples/Matches";

export function SwitchSupervisor({ setNumNotif }) {
    return (
        <Switch>
            <Route path="/supervisor/mapa" exact>
                <SampleMap pathBack="/supervisor" />
            </Route>
            <Route path="/supervisor/detecciones" exact>
                <Detections pathBack="/supervisor" />
            </Route>
            <Route path="/supervisor/detecciones/:id" exact>
                <DetectionForm pathBack="/supervisor" />
            </Route>
            <Route path="/supervisor/muestrasheces" exact>
                <Samples pathBack="/supervisor" />
            </Route>
            <Route path="/supervisor/muestras/:id" exact>
                <ResultForm pathBack="/supervisor" />
            </Route>    
            <Route path="/supervisor/coincidencias/:id" exact>
                <ResultForm pathBack="/supervisor" coincidence/>
            </Route>    
            <Route path="/supervisor/coincidencias" exact>
                <Matches pathBack="/supervisor" />
            </Route>
            <Route path="/supervisor/animales" exact>
                <Animals pathBack="/supervisor" />
            </Route>
            <Route path="/supervisor/animales/:id" exact>
                <AnimalDetail pathBack="/supervisor" />
                </Route>            
            <Route path="/supervisor/notificaciones" exact>
                <Notifications setNumNotif={setNumNotif} />
            </Route>
            <Route path="/supervisor/micuenta/:id" exact>
                <UserForm />
            </Route>
            <Route path="/supervisor" exact>
                <DashboardSupervisor />
            </Route>
        </Switch>            
    )
}
