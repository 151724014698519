/**
 * Informe de resultados
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FieldReport } from "./FieldReport";
import { formatStringDate, zeroFill } from "../../utils/StringUtils";
import { TitleReport } from "./TitleReport";
import { SubtitleReport } from "./SubtitleReport";
import { HeaderReport } from "./HeaderReport";
import { prefixAnimal, prefixFeces, prefixSaliva } from "../../config";
import { FotoReport } from "./FotoReport";
import { FieldReportMultiline } from "./FieldReportMultiline";
import { FooterReport } from "./FooterReport";
import { CommentsReport } from "./CommentsReport";
import { MapReport } from "./MapReport";
import { GridResultsReport } from "./GridResultsReport";

const useStyles = makeStyles (() => ({
    container: {
        size: "21cm 29.7cm",
        paddingLeft: "2.5cm",
        paddingRight: "2cm",
    }, 
    group: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

export const Report = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const data = props.data;
    const prefixSample = data[0].type === "Heces" ? prefixFeces : prefixSaliva;   
    const sampleLabel = (data[0] && data[0].label !== undefined && data[0].block_label_id > 0) 
        ? `${prefixSample}-${data[0].serial}-${zeroFill(data[0].block_label_id, 5)}-${zeroFill(data[0].label, 3)}` 
        : ""
    const matching = props.matching.filter(match => match.selected);
    const animalLabel = (data[0] && data[0].animal_label !== undefined && data[0].animal_block_label_id > 0) 
        ? `${prefixAnimal}-${data[0].animal_serial}-${zeroFill(data[0].animal_block_label_id, 5)}-${zeroFill(data[0].animal_label, 3)}` 
        : (matching[0] && matching[0].animal_label !== undefined && matching[0].animal_block_label_id > 0) 
            ? `${prefixAnimal}-${matching[0].animal_serial}-${zeroFill(matching[0].animal_block_label_id, 5)}-${zeroFill(matching[0].animal_label, 3)}` 
            : ""
    return (
        <div ref={ref} id="report" className={classes.container}>
            <HeaderReport 
                sampleLabel={sampleLabel} 
                reportId={data[0].report_id}
            />             
            <TitleReport caption="Informe de genotipado" />
            <div>
                <SubtitleReport caption="Datos del solicitante"/>
                <div className={classes.group}>
                    <FieldReport 
                        caption="Nombre" 
                        value={data[0].client_name}
                    />
                    <FieldReport 
                        caption="Dirección" 
                        value={data[0].client_address} 
                        widthType="medium"
                    />
                    <FieldReport 
                        caption="Población" 
                        value={data[0].client_pcode + " " + data[0].client_city} 
                        widthType="short"
                    />
                </div>
            </div>
            <div>
                <SubtitleReport caption="Datos de la muestra"/>
                { data[0].type==="Heces" 
                ? <div className={classes.group}>
                    <div>
                        <FieldReport 
                            caption="Perfil" 
                            value={
                                data[0].animal_type === "Caballo" 
                                ? "Equino - Huella genética (17 STR)"
                                : "Canino - Huella genética (19 STR)"
                            }
                            widthType="medium"
                        />
                        <FieldReport 
                            caption="Recolector" 
                            value={data[0].user_name} 
                            widthType="medium"
                        />
                        <FieldReport 
                            caption="Tipo de muestra" 
                            value={data[0].type} 
                            widthType="medium"
                        />
                        <FieldReport 
                            caption={"Fecha de toma"} 
                            value={data[0] && data[0].sampled !== undefined && data[0].sampled !== "0000-00-00" 
                                ? formatStringDate(data[0].sampled) : ""}
                            widthType="medium"
                        />
                        <FieldReport 
                            caption="Autoridad presente" 
                            value={data[0].authority} 
                            widthType="medium"
                        />
                        <FieldReport 
                            caption="Codigo de muestra" 
                            value={sampleLabel} 
                            widthType="medium"
                        />
                    </div>
                    <MapReport 
                        latitude={data[0].type==="Heces" ? data[0].latitude : matching[0].latitude} 
                        longitude={data[0].type==="Heces" ? data[0].longitude : matching[0].longitude}
                    />
                </div>
                : <div className={classes.group}>
                    <FieldReport 
                        caption="Perfil" 
                        value="Canino - Huella genética (19 STR)" 
                        widthType="medium"
                    />
                    <FieldReport 
                        caption="Tipo de muestra" 
                        value={data[0].type} 
                        widthType="short"
                    />
                    <FieldReport 
                        caption="Recolector" 
                        value={data[0].user_name} 
                        widthType="medium"
                    />
                    <FieldReport 
                        caption={"Fecha de toma"} 
                        value={data[0] && data[0].sampled !== undefined && data[0].sampled !== "0000-00-00" 
                            ? formatStringDate(data[0].sampled) : ""}
                        widthType="short"
                    />
                </div>}
            </div>
            { data[0].type==="Heces"
            ? ""
            : <div>
                <SubtitleReport caption="Datos del animal"/>
                <div className={classes.group}>
                    <div>
                        <FieldReport caption={"Especie"} value={data[0].animal_type} widthType="medium"/>
                        <FieldReport caption={"Raza"} value={data[0].animal_breed} widthType="medium"/>
                        <FieldReport caption={"Sexo"} value={data[0].animal_sex} widthType="medium"/>
                        <FieldReport caption={"Nombre"} value={data[0].animal_name} widthType="medium"/>
                        <FieldReport caption={"Microchip"} value={data[0].animal_chip} widthType="medium"/>
                    </div>
                    <FotoReport image={props.imageAnimal} />
                </div>
            </div>}
            <div>
                <SubtitleReport caption="Resultados"/>
                <div className={classes.group}>
                    <FieldReport 
                        caption={"Método de análisis"} 
                        value={"PCR - Análisis de fragmentos (STR)"}
                    />
                    <FieldReport 
                        caption={"Fecha inicio"} 
                        value={data[0] && data[0].date_start && data[0].date_start !== undefined 
                            && data[0].date_start !== "0000-00-00" ? formatStringDate(data[0].date_start) : ""}
                        widthType="medium"
                    />
                    <FieldReport 
                        caption={"Fecha finalización"} 
                        value={data[0] && data[0].date_end && data[0].date_end !== undefined 
                            && data[0].date_end !== "0000-00-00" ? formatStringDate(data[0].date_end) : ""}
                        widthType="short"
                    />
                </div>
                <GridResultsReport 
                    results={data[0].results} 
                    animalType={data[0].animal_type}
                    sampleType={data[0].type}
                /> 
                { data[0].type==="Heces" && matching[0]
                ? <div> 
                    <div className={classes.group}>
                        <FieldReport 
                            caption={"Resultado"} 
                            value={"Positivo"}
                        />
                        <FieldReport 
                            caption={"Código animal"} 
                            value={animalLabel}
                            widthType="medium"
                        />
                        <FieldReport 
                            caption={"Nº microchip"} 
                            value={data[0].animal_chip ? data[0].animal_chip : matching[0].animal_chip}
                            widthType="short" 
                        />
                    </div>                
                    <GridResultsReport 
                        results={matching[0].results} 
                        animalType={matching[0].animal_type}
                        sampleType={matching[0].type}
                    />
                    <FooterReport 
                        numPage={1} 
                        totalPages={2} 
                    />
                    <HeaderReport 
                        sampleLabel={sampleLabel} 
                        reportId={data[0].report_id}
                    />                                 
                </div>
                : "" }                
            </div>
            <FieldReportMultiline 
                caption={"Observaciones"} 
                value={data[0].observations}
            />
            <CommentsReport date={data[0].report_date}/>
            <FooterReport 
                numPage={data[0].type==="Heces" && matching[0] ? 2 : 1} 
                totalPages={data[0].type==="Heces" && matching[0] ? 2 : 1}
            />
        </div>
    )
});
