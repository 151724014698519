/**
 * Menu principal desplegable que recibe y contiene el menú como parámetro
 */
import React from "react";
import { Divider, Drawer, IconButton, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import logo from "../../assets/logomenu.png";
import logodark from "../../assets/logomenudark.png";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { drawerWidth } from "../../config";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    drawer: {
        drawer: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth
    },
    imageLogo: {
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(1),
    },
    containerLogo: {
        flexGrow: 1,
    },
    header: {
        ...theme.mixins.toolbar,
        display: "flex",
        alignItems: "center",
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: blueGrey[50]
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: blueGrey[800]
        },
    },
    iconMenuClose: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    }
}));

export function DrawerMain({ handleDrawerClose, open, ...props }) {
    const classes = useStyles();
    const theme = useTheme();
    const windowDim = useWindowDimensions();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    return (
        <Drawer 
            className={classes.drawer} 
            variant={windowDim.width < theme.breakpoints.values.md ? "temporary" : "permanent"} 
            classes={{paper: classes.drawerPaper}}
            open={open}
            onClose={handleDrawerClose}
        >                
            <div className={classes.header}>
                <div className={classes.containerLogo}>
                    <img className={classes.imageLogo} src={prefersDarkMode ? logodark : logo} alt="logo"/>
                </div>
                <IconButton 
                    className={classes.iconMenuClose} 
                    aria-label="cerrar menú"
                    onClick={handleDrawerClose}
                >
                    <ChevronLeftIcon/>
                </IconButton>
            </div>
            <Divider />
            {props.children}      
        </Drawer>
    )
}
