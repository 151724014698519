/**
 * Contexto de preferencias
 */
import { createContext } from "react";

export const SettingsContext = createContext({
    settings: {
        is_remember: false,
        is_strong: false,
        is_recover: false
    },
    setSettings: ()=> {}
});