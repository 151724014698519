/**
 * Hook para comprobar si los componentes están cargados 
 */
import { useEffect, useState } from "react";

export function useIsMounted () {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);
    return isMounted;
}