/**
 * Componente principal de entrada para mostrar los bloques de etiquetas 
 */
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router";
import { ListForm } from "../common/ListForm";
import { LabelItem } from "./LabelItem";
import { LabelIcon } from "./LabelIcon";
import { LabelsMenuFilter } from "./LabelsMenuFilter";
import { fetchApi, mysqlEscape } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";
import { FloatAlert } from "../common/FloatAlert";

export function Labels({ pathBack }) {
    const filterParam = new URLSearchParams(useLocation().search).get("filter")
    const [error, setError] = useState({ open: false, status: '' });
    const isMounted = useIsMounted();

    function getQueryLabels ({ filter, search, limitPlus, offset = 0 }) {
        let where = "WHERE ";
        if (filter) { 
            if (filter === "deshabilitado") { 
                where += "(block_label.is_disabled = 1)";
            } else {
                where += "(block_label.is_disabled = 0 AND type = '" + mysqlEscape(filter) + "')";
            }
        } else {
            where += "(block_label.is_disabled = 0)";
        };
        if (search) {
            const searchScape = mysqlEscape(search);
            where += " AND ((block_label.id LIKE '%"+searchScape+"%') "
                +" OR (count LIKE '%"+searchScape+"%')"
                +" OR (client.name LIKE '%"+searchScape+"%')"
                +" OR (user.name LIKE '%"+searchScape+"%')"
                +" OR (type LIKE '%"+searchScape+"%'))";        
        };
        return "SELECT block_label.id as id, type, count, client.name as client_name, user.name as user_name, " 
            + "block_label.is_disabled as is_disabled FROM block_label " 
            + "LEFT JOIN client ON (block_label.client_id = client.id) " 
            + "LEFT JOIN user ON (block_label.user_id = user.id) " 
            + where + " ORDER BY id DESC LIMIT " + offset + ", " + (limitPlus===0 ? 1 : limitPlus);        
    };
    
    function getTitleLabels (filter) {
        return (filter==="Deshabilitado" ? "Deshabilitados" : 
            filter==="Animal" ? "Etiquetas de animales" :
            filter==="Saliva" ? "Etiquetas de muestras de saliva" :
            filter==="Heces" ? "Etiquetas de muestras de heces" : "Bloques de etiquetas")
    };    
    
    function actionLabelClick ({ id, pathBack, filter, history }) {
        if (filter) {
            history.push(`${pathBack}/etiquetas/${id}?filter=${filter}`);
        } else {
            history.push(`${pathBack}/etiquetas/${id}`);
        }    
    };

    async function deleteLabel (id, type) {

        async function isLinked() {
            // Comprueba si el bloque de entrada ya fue usado 
            const query = type === "Animal" 
                ? "SELECT COUNT(*) as count FROM animal WHERE block_label_id='" + mysqlEscape(id) + "'" 
                : "SELECT COUNT(*) as count FROM sample WHERE block_label_id='" + mysqlEscape(id) + "'"
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function isDisabled() {
            const query = "SELECT is_disabled FROM block_label WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);                
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].is_disabled) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdateDisabled() {
            const query = "UPDATE block_label SET is_disabled = 1 WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchUpdateEnabled() {
            const query = "UPDATE block_label SET is_disabled = 0 WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM block_label WHERE id='" + mysqlEscape(id) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
           
        if (await isDisabled()) {
            if (await fetchUpdateEnabled()) {
                // Habilitación correcta
                return true
            } else {
                // Error de API
                return false
            }
        } else {
            if (await isLinked()) {
                if (await fetchUpdateDisabled()) {
                    // Deshabilitación correcta
                    return true;              
                } else {
                    // Error de API
                    return false;
                }
            } else {
                if (await fetchDelete()) {
                    // Eliminación correcta
                    return true;              
                } else {
                    // Error de API
                    return false;
                }
            }
        }
    }

    async function actionFunction1Element (element) {
        // Eliminar si es posible
        const result = await deleteLabel(element.id, element.type);
        if (result) {
            return { status: "deleted" };
        } else {
            return { status: "notdeleted" };
        }
    };

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    }    

    const textFunction1 = "Eliminar";
    const textDeleteQuestion = "¿Estás seguro de que quieres eliminar las etiquetas?";
    const textDeleteDescription = "Si continúas este proceso se eliminarán las etiquetas del sistema, " 
        + "salvo que tuvieran información vinculada, en cuyo caso serían deshabilidadas";

        return (
        <Fragment>
            <ListForm 
                filterParam={filterParam}
                pathBack={pathBack}
                pathNew="/nuevaetiqueta"
                icon={LabelIcon}
                menu={LabelsMenuFilter} 
                getQuery={getQueryLabels} 
                getTitle={getTitleLabels}
                actionItemClick={actionLabelClick}                  
                item={LabelItem}
                actionFunction1Element={actionFunction1Element}
                textFunction1={textFunction1}
                textDeleteQuestion={textDeleteQuestion}
                textDeleteDescription={textDeleteDescription}
            />
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text="No hemos podido eliminar o actualizar las etiquetas. Por favor, comprueba tu conexión." 
                severity="error"
            />
        </Fragment>
    )
}
