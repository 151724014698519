/**
 * Campo de texto formateado y con icono 
 */
import React from "react";
import { InputAdornment, InputLabel, OutlinedInput, FormControl, FormHelperText, SvgIcon } from "@material-ui/core";
import { viewBox } from "../../config";

export function TextFieldIcon({ id, icon, label, error, textError, onChange, type }) {
    if (!textError) textError = "Campo requerido";    // Por defecto
    
    return (
        <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={onChange}
            error={error}
        > 
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                type={type}
                label = {label} // Sólo es usado para calcular el espacio
                endAdornment={
                    <InputAdornment position="end">
                        <SvgIcon component={icon} viewBox={viewBox}/>             
                    </InputAdornment>
                }
            />
            <FormHelperText>{error ? textError : ""}</FormHelperText>
        </FormControl>
    )
}
