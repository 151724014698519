/**
 * Menú de filtros para el mapa de incidencias
 */
import React from "react";
import { Menu, MenuItem, SvgIcon, Typography } from "@material-ui/core";
import { ReactComponent as MarkerIconDetection } from '../../assets/location_detection.svg';
import { ReactComponent as MarkerIconSample } from '../../assets/location_sample.svg';
import { ReactComponent as MarkerIconMatched } from '../../assets/location_sample_matched.svg';
import { ReactComponent as MarkerIconMismatched } from '../../assets/location_sample_mismatched.svg';
import { ReactComponent as MarkerIconRepeatOffender } from '../../assets/location_sample_repeatoffender.svg';
import { viewBox } from "../../config";

export  function MapMenuFilter({ anchorEl, setAnchorEl, setFilter }) {

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        const { myValue } = event.currentTarget.dataset;
        setAnchorEl(null);
        setFilter(myValue);
    }

    return (
        <Menu
            id="menu-filter"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}                    
        >
            <MenuItem key="todas" onClick={handleClick}>
                <em>Todas</em>
            </MenuItem>
            <MenuItem key="detecciones" data-my-value={"detecciones"} onClick={handleClick}>
                <SvgIcon component={MarkerIconDetection} viewBox={viewBox}/>
                <Typography>Detecciones</Typography>
            </MenuItem>            
            <MenuItem key="noanalizadas" data-my-value={"noanalizadas"} onClick={handleClick}>
                <SvgIcon component={MarkerIconSample} viewBox={viewBox}/>
                <Typography>No analizadas</Typography>
            </MenuItem>            
            <MenuItem key="coincidentes" data-my-value={"coincidentes"} onClick={handleClick}>
                <SvgIcon component={MarkerIconMatched} viewBox={viewBox}/>
                <Typography>Coincidentes</Typography>
            </MenuItem>            
            <MenuItem key="nocoincidentes" data-my-value={"nocoincidentes"} onClick={handleClick}>
                <SvgIcon component={MarkerIconMismatched} viewBox={viewBox}/>
                <Typography>No coincidentes</Typography>
            </MenuItem>            
            <MenuItem key="reincidentes" data-my-value={"reincidentes"} onClick={handleClick}>
                <SvgIcon component={MarkerIconRepeatOffender} viewBox={viewBox}/>
                <Typography>Reincidentes</Typography>
            </MenuItem>            
        </Menu>              
    )
}
