/**
 * Comentarios y firma del informe
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import sign from "../../assets/sign.png";
import { formatStringLongDate } from "../../utils/StringUtils";

const useStyles = makeStyles (() => ({
    container: {
        position: "relative",
        margin: "10px",
        height: "150px"
    },
    list: {
        position: "absolute",
        margin: "0",
        padding: "0",
        zIndex: "2",
    },
    listItem: {
        font: "8px Arial, sans-serif",
        listStyleType: "none",
    },
    sign: {
        position: "absolute",
        left: "350px",
        top: "30px",
        zIndex: "1",
    },
    boxSign: {
        position: "absolute",
        top: "65px",        
        left: "300px",
        textAlign: "center",
        font: "11px Arial, sans-serif",
        zIndex: "2",
    },
    date: {
        height: "50px",
    }
}));

export function CommentsReport({ date }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <ul className={classes.list}>
                <li className={classes.listItem}>Los resultados del presente informe solo afectan a las muestras analizadas y no puede realizarse una reproducción parcial del mismo sin la autorización del laboratorio LabTecnic.</li>
                <li className={classes.listItem}>LabTecnic no se responsabiliza de la toma de muestra y su transporte, siempre y cuando no haya sido tomada o supervisada por personal técnico de LabTecnic.</li>
                <li className={classes.listItem}>La responsabilidad de la veracidad de los datos que acompañan a la muestra, tales como datos del animal, etc., corresponden al solicitante.</li>
                <li className={classes.listItem}>En el perfil de ADN solicitado se incluye una declaración del solicitante o dueño del animal dando veracidad a los datos aportados, así como un certificado del tomador de la muestra, en el caso que la muestra haya sido tomada por un veterinario.</li>
            </ul>
            <img className={classes.sign} src={sign} alt="Firma"/>
            <div className={classes.boxSign}>
                <div className={classes.date}>{formatStringLongDate(date)}</div>
                <div>Fdo. Ricardo Muñoz (Ldo. Farmacia)</div> 
                <div>Director técnico</div> 
            </div>
        </div>
    )
}
