/**
 * Hook para gestionar la pulsación larga
 */
import { useState, useCallback, useRef } from "react";

export function useLongPress (onLongPress, onClick, { shouldPreventDefault = true, delay = 300 } = {}) {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const [moved, setMoved] = useState(false);
    const timeout = useRef();
    const target = useRef();

    const start = useCallback(
        event => {
            if (shouldPreventDefault && event.target) {
                    event.target.addEventListener("touchend", preventDefault, {
                    passive: false
                });
                target.current = event.target;
            } 
            timeout.current = setTimeout(() => {
                onLongPress(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true, touchMove = false) => {
            timeout.current && clearTimeout(timeout.current);
            shouldTriggerClick && !longPressTriggered && !moved && onClick(event);
            setLongPressTriggered(false);
            setMoved(touchMove);
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }     
        },
        [shouldPreventDefault, onClick, longPressTriggered, moved]
    ); 

    return {
        onMouseDown: e => start(e),
        onTouchStart: e => start(e),
        onMouseUp: e => clear(e),
        onMouseLeave: e => clear(e, false),
        onTouchEnd: e => clear(e),
        onTouchMove: e => clear(e, false, true)
    };
};

const isTouchEvent = event => {
    return "touches" in event;
};

const preventDefault = event => {
    if (!isTouchEvent(event)) return;
    if (event.touches.length < 2 && event.preventDefault) {
        if (event.cancelable) event.preventDefault();
    }
};
