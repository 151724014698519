/**
 * Formulario de acceso con usuario y contraseña
 */
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Checkbox, FormControlLabel, Typography, useMediaQuery, Link } from "@material-ui/core";
import { sha256 } from "js-sha256";
import { TextFieldIcon } from "../common/TextFieldIcon";
import { FloatAlert } from "../common/FloatAlert";
import { fetchApi } from "../../hooks/DataFetch";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as PassIcon } from "../../assets/key3.svg";
import logo from "../../assets/logologin.png";
import logodark from "../../assets/logologindark.png";
import { UserContext } from "../../contexts/UserContext";
import { SettingsContext } from "../../contexts/SettingsContext";

export function FormLoginUser({ classes, passChanged, setPassChanged }) {
    const { setUser } = useContext(UserContext);
    const { settings } = useContext(SettingsContext);
    const history = useHistory();
    const [identifier, setIdentifier] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [errorConn, setErrorConn] = useState();   
    const [errorAuth, setErrorAuth] = useState(false);   
    const [errorUser, setErrorUser] = useState(false);    
    const [errorPass, setErrorPass] = useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (identifier === "") { 
            setErrorUser(true);
        } else {
            setErrorUser(false);
            if  (password === "") {
                setErrorPass (true);
            } else {
                setErrorPass (false);
                // Intenta la autenticación
                const response = await fetchApi({
                    action: "login", 
                    userId: identifier, 
                    pass: sha256(password),
                });
                // Se comprueba el error de conexión
                if (response.status === "500") {
                    // Error de conexión
                    setErrorConn(response.message)
                } else {
                    // Error de autenticación                    
                    setErrorAuth(!response.data);                    
                }
                // Establece el perfil de usuario en el componente padre
                setUser(response.data);
                response.data && history.push("/"+response.data.profile);
                if (remember) { 
                    if (response.data) { 
                        // Si se activa recordar y el usario existe se guarda en el almacenamiento local
                        localStorage.setItem('credentials', JSON.stringify({ 
                            userId: identifier, 
                            pass: sha256(password) 
                        }))
                    }
                } else {
                    // Si no se ha activado recordar se borran las credenciales en el almacenamiento local
                    localStorage.removeItem('credentials');
                }
            }
        }              
    };

    const handleUsernameChange = (event) => {
        setIdentifier (event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword (event.target.value);
    }

    const handleRememberChange = (event) => {
        setRemember (event.target.value);
    }

    const handleStartClick = () => {
        setPassChanged(false);
    }

    const handleForgetClick = () => {
        setPassChanged(false);
        history.push("/email");
    }

    const handleAlertClose = () =>  {
        setPassChanged(false);
    }

    const handleErrorClose = () => {
        setErrorConn();
        setErrorAuth(false);
    }

    return (
        <div className={classes.container} >
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>              
                    <img src={prefersDarkMode ? logodark : logo} alt="logo"/>
                    <form onSubmit={handleSubmit}>
                        <TextFieldIcon id="user" icon={UserIcon} label="Usuario" error={errorUser} onChange={handleUsernameChange} />                    
                        <TextFieldIcon id="pass" icon={PassIcon} label="Contraseña" error={errorPass} onChange={handlePasswordChange} type="password"/>
                        <Button 
                            className={classes.button} 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            fullWidth 
                            size="large"
                            onClick={handleStartClick}
                        >
                            Iniciar sesión
                        </Button>
                        <FormControlLabel 
                            control={<Checkbox value="remember" color="primary" />} 
                            label="Recordarme en este equipo" 
                            onChange={handleRememberChange}
                            disabled={!settings.is_remember} 
                        />          
                        <Button 
                            className={classes.button} 
                            variant="text"                          
                            color="secondary" 
                            size="small" 
                            fullWidth
                            onClick={handleForgetClick}
                        >                            
                            ¿Has olvidado la contraseña?
                        </Button>
                        <Typography className={classes.font} variant="body2" component="span" color="textSecondary">
                            © 2023 Desarrollo de Análisis y Servicios Ambientales, S.L. Todos los derechos reservados.
                        </Typography>
                        <Link href="https://www.spuch.com/" color="textSecondary" target="_blank" rel="noopener" className={classes.font2}>
                            Powered by Spuch
                        </Link>
                        <FloatAlert 
                            open={errorConn}
                            onClose={handleErrorClose}
                            text={errorConn}
                            severity="error"
                        />
                        <FloatAlert 
                            open={errorAuth} 
                            onClose={handleErrorClose} 
                            text="Alguno de los datos introducidos no es correcto. Inténtalo de nuevo." 
                            severity="error"
                        />
                        <FloatAlert 
                            open={passChanged} 
                            onClose={handleAlertClose} 
                            text="Tu contraseña ha sido cambiada correctamente." 
                            severity="success"
                        />
                    </form>
                </div>
            </Container>
        </div>
    )
}
