/**
 * Elemento imprimible y vista previa de etiqueta
 */
import React, { Fragment } from "react";
import { Barcode } from "../common/Barcode";
import { heighLabel, widthLabel } from "../../config";

export function LabelView({ code, type, client, block, label }) {    
    const styleLabel = {
        float: "left",
        width: widthLabel, 
        height: heighLabel,
    };
    const styleSample = {
        marginTop: -5,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "top",
        overflow: "hidden",
        height: "20px",
        font: "70% sans-serif"      
    }
    const styleAnimal = {
        margin: 0,
        marginLeft: 10,
        paddingLeft: 0,
        font: "80% sans-serif"
                 
    }
    const link = "https://labtegen.com/qr.php?block="+block+"&label="+label;

    return (
        <div style={styleLabel}>
            { 
                type==="Animal" 
                ? <Fragment>
                    <Barcode value={code} isHide />
                    <p style={styleAnimal}>
                        <a target="_blank" rel="noopener noreferrer" href={link}>
                            {link}
                        </a>
                    </p>
                </Fragment>
                : <Fragment>
                    <Barcode value={code} />
                    <p style={styleSample}>{client}</p>
                </Fragment>   
            }
        </div>
    )
}
