/**
 * Formulario de detalle de cliente
 */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { FormGroup, Grid, TextField, Typography } from "@material-ui/core";
import { ReactComponent as ClientsIcon } from '../../assets/handshake.svg';
import { fetchApi, mysqlEscape, jsonParseMysql, mysqlFloat } from "../../hooks/DataFetch";
import { FormGroupButton } from "../common/FormGroupButton";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { Loading } from "../Loading";
import { SaveDialog } from "../common/SaveDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { useIsMounted } from "../../hooks/IsMounted";
import { usePosition } from "../../hooks/Position";
import { MapField } from "../common/MapField";
import { buttonGroup, buttonGroupXs } from "../../config";
import { ImageField } from "../common/ImageField";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",        
    },
    form: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0.5),
        }
    },
    columnGrid: {
        maxWidth: "600px",
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(2),
        }
    },
    field: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    fieldTitle: {
        width: "100%",
        marginTop: theme.spacing(2),
    },
    bottomSpace: {
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            height: buttonGroupXs,
        }        
    },
}));

export function ClientForm({ isNew, pathBack }) {
    const { user } = useContext(UserContext);    
    const { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const position = usePosition();
    const windowDim = useWindowDimensions();
    // Se recuerda el filtro del listado para poder volver al mismo listado filtrado
    const filterParam = new URLSearchParams(useLocation().search).get("filter");     
    
    // Estados de carga de datos
    const [data, setData] = useState([{
        id: "",
        name: "",
        city: "",
        pcode: "",
        address: "",
        province: "",
        phone: "",
        email: "",
        authority: "",
        serial: "",
        latitude: "",
        longitude: "",
        is_disabled: ""
    }]);
    const [dataCount, setDataCount] = useState([{
        animal_count: 0
    }])
    const [logo, setLogo] = useState();
    const [address, setAddress] = useState() 
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isModified, setIsModified] = useState(false);

    // Estados de validación de errores
    const [errorId, setErrorId] = useState(false);
    const [error, setError] = useState({ open: false, status: '' });

    // Estados de diálogos
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    async function saveForm () {

        async function fetchDuplicate(newId) {
            const query = "SELECT COUNT(*) as count FROM client WHERE id='" + mysqlEscape(newId) + "'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return true;    // No está duplicado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdate(newData) {
            let query = "";
            if (isNew) {
                query = "INSERT INTO client (id, name, city, pcode, address, province, " 
                    + "phone, email, authority, serial, latitude, longitude, logo) VALUES ('" 
                    + mysqlEscape(newData.id) + "', '" + mysqlEscape(newData.name) + "', '" 
                    + mysqlEscape(newData.city) + "', '" + mysqlEscape(newData.pcode) + "', '" 
                    + mysqlEscape(newData.address) +"', '" + mysqlEscape(newData.province) + "', '" 
                    + mysqlEscape(newData.phone) + "', '" + mysqlEscape(newData.email) + "', '" 
                    + mysqlEscape(newData.authority) + "', '" + mysqlEscape(newData.serial) + "', "
                    + mysqlFloat(lat) + ", " + mysqlFloat(lng) + ", '" + (logo ? mysqlEscape(logo) : "") + "')";
            } else {
                query = "UPDATE client SET name='" + mysqlEscape(newData.name) + "', city='" + mysqlEscape(newData.city) 
                    + "', pcode='" + mysqlEscape(newData.pcode) + "', address='" + mysqlEscape(newData.address) 
                    + "', province='" + mysqlEscape(newData.province) + "', phone='" + mysqlEscape(newData.phone) 
                    + "', email='" + mysqlEscape(newData.email) + "', authority='" + mysqlEscape(newData.authority) 
                    + "', serial='" + mysqlEscape(newData.serial) + "', latitude=" + mysqlFloat(newData.latitude) 
                    + ", longitude=" + mysqlFloat(newData.longitude) + ", logo='" + (logo ? mysqlEscape(logo) : "") 
                    + "' WHERE id='" + mysqlEscape(id) +"'";
            }
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }     
    
        // Crea una copia de los datos
        if (isMounted) setIsSaving(true);
        let newData = {...data[0]};
        let verificationError = false;

        // Comprueba el identificador si es nuevo cliente
        if (isNew) {
            if (newData.id === "") {
                // Identificador no definido            
                if (isMounted) setErrorId(true);
                verificationError= true;
            } else {
                if (await fetchDuplicate(newData.id)) {
                    // Identificador duplicado
                    if (isMounted) setErrorId(true);
                    verificationError= true;    
                }
            }
        }

        // Graba los cambios si no hay errores de verificación 
        if (!verificationError) {
            if (await fetchUpdate(newData)) {
                // Reinicia estados y valores
                if (isMounted) {
                    setIsModified(false);
                    setIsSaving(false);
                }                    
                return true;
            }
        }
        if (isMounted) setIsSaving(false);
        return false;
    }
    
    async function deleteForm () {

        async function isLinked(id) {
            const query = "SELECT COUNT(*) as count FROM user WHERE client_id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }

        async function fetchUpdate() {
            const query = "UPDATE client SET is_disabled = 1 WHERE id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM client WHERE id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        if (await isLinked(id)) {
            if (await fetchUpdate()) {
                // Baja correcta
                return true                
            } else {
                // Error de API
                return false
            }
        } else {
            if (await fetchDelete()) {
                // Eliminación correcta
                return true                
            } else {
                // Error de API
                return false
            }
        }
    }

    async function saveAndExit() {
        // Función asíncrona para guardar y salir que evitar la salida en caso de error
        if (await saveForm()) {            
            exit();
        } 
    }

    async function saveAndRefresh() {
        // Función asíncrona para guardar y recargar la ficha
        if (await saveForm()) {
            if (filterParam) {
                history.push(`${pathBack}/clientes/${data[0].id}?filter=${filterParam}`);
            } else {
                history.push(`${pathBack}/clientes/${data[0].id}`);
            }    
        }
    }

    async function deleteAndExit() {
        // Función asíncrona para eliminar el cliente
        if (await deleteForm()) {
            exit();
        }
    }

    async function enableForm() {

        async function fetchUpdate() {
            const query = "UPDATE client SET is_disabled = 0 WHERE id='" + mysqlEscape(id) +"'";
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        } 
        
        if (await fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_disabled: false,
            }];
            if (isMounted) setData(newData);
        }
    }

    function exit() {
        if (filterParam) {
            history.push (`${pathBack}/clientes?filter=${filterParam}`);
        } else {
            history.push (`${pathBack}/clientes`);
        }
    }
    
    useEffect (() => {    

        async function fetchLoad() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT id, name, city, pcode, address, province, phone, email, authority, " 
                    + "serial, latitude, longitude, is_disabled " 
                    + "FROM client WHERE client.id='" + mysqlEscape(id) +"'" 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                if (isMounted) setData(jsonParseMysql(response.data));
            };
            if (isMounted) setIsLoading(false);
        }  

        async function fetchLoadCount() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT count(*) as animal_count FROM animal WHERE client_id='" 
                    + mysqlEscape(id) +"' AND animal.is_disabled = 0" 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                if (isMounted) setDataCount(jsonParseMysql(response.data));
            };
            if (isMounted) setIsLoading(false);
        }        

        async function fetchLoadImage() {
            // La imagen se lee por separado para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT logo FROM client WHERE id='" + mysqlEscape(id) + "'"
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setLogo(jsonData[0].logo);
            };
        }                 
        
        if (isNew) {
            // Para clientes nuevos no se realiza la carga inicial
            if (isMounted) setIsLoading(false);
        } else {
            // Carga inicial desde la API
            id && fetchLoad();
            id && fetchLoadCount();
            id && fetchLoadImage();
        } 
    },[id, isNew, isMounted])    

    const handleChange = (event) => {
        const newData = [{
            ...data[0],
            [event.target.name] : event.target.value            
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleMapClick = (event) => {
        const newData = [{
            ...data[0],
            latitude: event.lat.toFixed(7).toString(),
            longitude: event.lng.toFixed(7).toString()
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleButtonCurrentClick = () => {
        const newData = [{
            ...data[0],
            latitude: position.lat 
                ? position.lat.toString() 
                : (user.latitude ? parseFloat(user.latitude) : 0),
            longitude: position.lng 
                ? position.lng.toString() 
                : (user.longitude ? parseFloat(user.longitude) : 0)
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleApply = () => {
        // Botón aplicar
        if (isNew) {
            saveAndRefresh();
        } else {
            saveForm();
        }
    }

    const handleExit = () => {
        // Botón salir
        if (isModified && !isSaving) {
            setOpenSaveDialog(true);
        } else {
            setOpenSaveDialog(false);
            exit();
        }
    }

    const handleDelete = () => {
        if (data[0] && data[0].is_disabled) {
            // Botón habilitar
            enableForm();
        } else {
            // Botón eliminar
            setOpenDeleteDialog(true);
        }
    }

    const handleYesSave = () => {
        setOpenSaveDialog(false);
        saveAndExit();
    }

    const handleNoSave = () => {
        setOpenSaveDialog(false);
        exit();
    }

    const handleCancelSave = () => {
        setOpenSaveDialog(false);
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        deleteAndExit();
    }

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }

    const lat = data[0] 
        ? parseFloat(data[0].latitude) 
        : position.lat ? position.lat : (user.latitude ? parseFloat(user.latitude) : 0);
    const lng = data[0] 
        ? parseFloat(data[0].longitude) 
        : position.lng ? position.lng: (user.longitude ? parseFloat(user.longitude) : 0);
    
    // Valores iniciales
    let center = { lat: 0, lng: 0 };
    let points = [{ id: data[0] && data[0].id, lat: 0, lng: 0 }];

    // Comprueba si las coordenadas son correctas y las asigna
    if (lat <= 90 && lat >= -90 && lng <= 180 && lng >= -180) {
        center = { lat: lat, lng: lng }
        points = [{ id: data[0] && data[0].id, lat: lat, lng: lng }];
    }

    const isAllColumn = (windowDim.width < 400) 
        || (windowDim.width > 960 && windowDim.width < 1050) 
        || (windowDim.width > 600 && windowDim.width < 810)
    
    if (isLoading) {
        return <Loading />
    } else {
        return (
            <div className={classes.container}>
                <Title 
                    text={isNew ? "Nuevo cliente" : id} 
                    icon={ClientsIcon} 
                    pathBack={`${pathBack}/clientes`}
                    disabled={data[0] && data[0].is_disabled}
                />
                <FormGroup className={classes.form}>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>
                            {id===undefined 
                            ? <TextField     
                                className={classes.field}                   
                                disabled={isLoading} 
                                variant="outlined" 
                                value={(data[0] && data[0] !== undefined) ? data[0].id : ""} 
                                onChange={handleChange}
                                label="Identificador" 
                                id="id"
                                name="id"
                                error={errorId}
                                helperText={errorId ? "Identificador no válido o duplicado" : ""}
                                inputProps={{ maxLength: 30 }}
                            /> 
                            : ""}
                            <TextField
                                className={classes.field}                   
                                disabled={isLoading} 
                                variant="outlined" 
                                value={(data[0] && data[0].name !== undefined) ? data[0].name : ""} 
                                onChange={handleChange}
                                label="Nombre" 
                                id="name"
                                name="name"
                                inputProps={{ maxLength: 50 }}
                            />
                            <Grid container spacing={isAllColumn ? 0 : 3}>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}                   
                                        disabled={isLoading} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].city !== undefined) ? data[0].city : ""} 
                                        onChange={handleChange}
                                        label="Población" 
                                        id="city"
                                        name="city"
                                        inputProps={{ maxLength: 30 }}
                                    />                                  
                                </Grid>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}                   
                                        disabled={isLoading} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].province !== undefined) ? data[0].province : ""} 
                                        onChange={handleChange}
                                        label="Provincia" 
                                        id="province"
                                        name="province"
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                className={classes.field}                   
                                disabled={isLoading} 
                                variant="outlined" 
                                value={(data[0] && data[0].pcode !== undefined) ? data[0].pcode : ""} 
                                onChange={handleChange}
                                label="Código postal" 
                                id="pcode"
                                name="pcode"
                                inputProps={{ maxLength: 10 }}
                            />
                            <TextField
                                className={classes.field}                   
                                disabled={isLoading} 
                                variant="outlined" 
                                value={(data[0] && data[0].address !== undefined) ? data[0].address : ""} 
                                onChange={handleChange}
                                label="Dirección" 
                                id="address"
                                name="address"
                                inputProps={{ maxLength: 100 }}
                            />
                            <Grid container spacing={isAllColumn ? 0 : 3}>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}                   
                                        disabled={isLoading} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].phone !== undefined) ? data[0].phone : ""} 
                                        onChange={handleChange}
                                        label="Teléfono" 
                                        id="phone"
                                        name="phone"
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid> 
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField 
                                        className={classes.field}                   
                                        disabled={isLoading} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].email !== undefined) ? data[0].email : ""} 
                                        onChange={handleChange}
                                        label="Correo electrónico" 
                                        id="email"
                                        name="email"
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                            </Grid>
                            <ImageField
                                classContainer={classes.fieldTitle} 
                                image={logo} 
                                setImage={setLogo}
                                data={data}
                                setData={setData}
                                setIsModified={setIsModified}
                                disabled={isLoading}
                                description="Seleccione una imagen en fomato png con fondo transparente" 
                                caption="Logotipo"
                                short
                                photo={false}                              
                            />                            
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>
                            <TextField
                                className={classes.field}                   
                                disabled={isLoading} 
                                variant="outlined" 
                                value={(data[0] && data[0].authority !== undefined) ? data[0].authority : ""} 
                                onChange={handleChange}
                                label="Autoridad competente (lista)" 
                                id="authority"
                                name="authority"
                                inputProps={{ maxLength: 500 }}
                            />
                            <TextField
                                className={classes.field}                   
                                disabled={isLoading} 
                                variant="outlined" 
                                value={(data[0] && data[0].serial !== undefined) ? data[0].serial : ""} 
                                onChange={handleChange}
                                label="Serie" 
                                id="serial"
                                name="serial"
                                inputProps={{ maxLength: 3 }}
                            />
                            <MapField 
                                classContainer={classes.fieldTitle} 
                                center={center} 
                                points={points}
                                address={address}
                                setAddress={setAddress}                               
                                handleMapClick={handleMapClick}
                                handleButtonCurrentClick={handleButtonCurrentClick}
                            />
                            <Typography className={classes.field} variant="h6" component="h2" color="primary">
                                Número de animales: {dataCount[0] && dataCount[0].animal_count}
                            </Typography>                            
                        </Grid>
                    </Grid>
                    <div className={classes.bottomSpace}/>
                    <FloatAlert 
                        open={error.open} 
                        onClose={handleErrorClose} 
                        text="No hemos podido leer o guardar los cambios. Comprueba tu conexión." 
                        severity="error"
                    />
                </FormGroup> 
                <FormGroupButton 
                    disabledApply={isLoading || !isModified}
                    isSaving={isSaving}
                    hideDelete={!!isNew}
                    handleApplyClick={handleApply} 
                    handleExitClick={handleExit} 
                    handleDeleteClick={handleDelete}
                    textDelete={data[0] && data[0].is_disabled ? "Habilitar" : ""}
                />
                <SaveDialog 
                    open={openSaveDialog}
                    onClickYes={handleYesSave}
                    onClickNo={handleNoSave}
                    onClickCancel={handleCancelSave}
                />
                <DeleteDialog
                    open={openDeleteDialog}
                    onClickYes={handleYesDelete}
                    onClickNo={handleCancelDelete}
                    onClickCancel={handleCancelDelete}
                    title="¿Estás seguro de que quieres eliminar este cliente?"
                    description="Si continúas este proceso se eliminará el cliente del sistema, salvo que tuviera información vinculada, en cuyo caso sería deshabilidado."
                />
            </div>
        )
    }
}
