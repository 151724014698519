/**
 * Tarjeta para mostrar el gráfico circular de muestras según el estado
 */
import React from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { SampleIcon } from "../samples/SampleIcon";
import { useDataFetch } from "../../hooks/DataFetch";
import { Pie } from "react-chartjs-2";
import { indexRegistered, sampleStatus } from "../../config";
import { CardDashboard } from "../common/CardDashboard";
import { useWindowDimensions } from "../../hooks/WindowDimensions";

export function CardSamplesFeces() {
    const history = useHistory();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = useTheme();   
    const windowDim = useWindowDimensions();
    const { data } = useDataFetch({ 
        query: `SELECT status as id, count(*) as value FROM sample ` 
            + `WHERE is_disabled = 0 AND is_archived = 0 AND status <> '${sampleStatus[indexRegistered].label}' AND type = 'Heces' ` 
            + `GROUP BY status`
    });
    const leyends = sampleStatus.filter(state => state.label !== sampleStatus[indexRegistered].label); // Omite registradas
    const dataProp = leyends.map(leyend => {
        const row = data.filter(item => item.id === leyend.label);
        if (row[0]) {
            return row[0].value;
        } else {
            return "";
        }
    });

    const handleClick = () => {
        history.push ("/laboratorio/muestrasheces");
    }

    return (
        <CardDashboard
            icon={<SampleIcon selector="Heces" />}
            title="Muestras de heces"
            subtitle="Distribución de muestras activas"
            handleClick={handleClick}
        >
            { data.length > 0 
                ? <Pie
                    data={{
                        labels: leyends.map(state => state.label),
                        datasets: [{
                            backgroundColor: leyends.map(state => prefersDarkMode ? state.darkColor : state.lightColor),
                            borderColor: theme.palette.background.paper,
                            data: dataProp 
                        }]
                    }}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                display: windowDim.width > 600 && windowDim.height > 800,
                                position: "right",
                                labels: {
                                    color: theme.palette.primary.main
                                }
                            }
                        }
                    }}
                />
                : ""
            }
        </CardDashboard>
    )
}
