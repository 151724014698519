import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import { indexAnalyzed, sampleStatus } from "../../config";

const useStyles = makeStyles ((theme) => ({ 
    container: {
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        width: "100%",
    },    
    group: {
        display: "flex",
        marginBottom: theme.spacing(1),
    },
    label: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        borderRadius: "5px",
        marginBottom: theme.spacing(1),
    }
}));

export function FieldResultDates({ data, setData, disabled, setIsModified }) {
    const classes = useStyles();

    const handleChange = (event) => {
        const newData = (event.target.name === "date_end" && event.target.value !== "") 
        ? [{
            ...data[0],
            [event.target.name] : event.target.value,
            status: sampleStatus[indexAnalyzed].label,        
        }] 
        : [{
            ...data[0],
            [event.target.name] : event.target.value            
        }];
        setData(newData);
        setIsModified(true);
    }

    return (
        <div className={classes.container}>
            <Typography variant="subtitle2" align="center" className={classes.label}>
                Fechas
            </Typography>
            <div>
                <div className={classes.group}>
                    <TextField 
                        className={classes.field} 
                        disabled={disabled} 
                        label="Inicio"
                        name="date_start"
                        value={
                            (data[0] && data[0].date_start && data[0].date_start !== "0000-00-00") 
                            ? data[0].date_start 
                            : ""
                        } 
                        onChange={handleChange}
                        size="small" 
                        variant="outlined" 
                        type="date" 
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div className={classes.group}> 
                    <TextField 
                        className={classes.field} 
                        disabled={disabled} 
                        label="Fin" 
                        name="date_end"
                        value={
                            (data[0] && data[0].date_end && data[0].date_end !== "0000-00-00") 
                            ? data[0].date_end 
                            : ""
                        }
                        onChange={handleChange}
                        size="small" 
                        variant="outlined" 
                        type="date" 
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
            </div>
        </div>     
    )
}
