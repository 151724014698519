/**
 * Menú de filtros para animales 
 */
import React from "react";
//import React, { useContext } from "react";
//import { UserContext } from "../../contexts/UserContext";
import { Menu, MenuItem, Typography } from "@material-ui/core";

export  function AnimalsMenuFilter({ anchorEl, setAnchorEl, setFilter }) {
    //const { user } = useContext(UserContext);    

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const handleAllClick = () => {
        setAnchorEl(null);
        setFilter(null);
    }

    const handleDangerousClick = () => {
        setAnchorEl(null);
        setFilter("ppp");
    }

/*     const handleCoincidentClick = () => {
        setAnchorEl(null);
        setFilter("coincidencia");
    } */

    const handleDisabledClick = () => {
        setAnchorEl(null);
        setFilter("deshabilitado");
    }

    return (
        <Menu
            id="menu-filter"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}                    
        >
            <MenuItem onClick={handleAllClick}><em>Todos</em></MenuItem>
            <MenuItem onClick={handleDangerousClick}>
                {<Typography>PPP</Typography>}
            </MenuItem>            
{/*             { user.profile==="supervisor" || user.profile==="administrador"
            ? <MenuItem onClick={handleCoincidentClick}>
                {<Typography>Coincidencia</Typography>}
            </MenuItem> 
            : "" }
 */}            <MenuItem onClick={handleDisabledClick}>
                {<Typography color="secondary">Deshabilitados</Typography>}
            </MenuItem>            
        </Menu>              
    )
}
