/**
 * Item de notificación 
 */
import React from "react";
import { ListItem, ListItemAvatar, Avatar, ListItemText, Divider, 
    Hidden, ListItemSecondaryAction, Tooltip, IconButton } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export function NotificationItem({ rowData, icon:Icon, handleClick, handleClose, classes }) {        
    return (
        <div>
            <ListItem 
                className={classes.item} 
                button 
                dense 
                name={rowData ? rowData.id : ""} 
                onClick={handleClick} 
                selected={rowData ? (rowData.is_viewed ? false : true) : false}
                ContainerComponent="div"
            >
                <Hidden xsDown>
                    <ListItemAvatar>
                        <Avatar className={classes.avatar}><Icon/></Avatar>                    
                    </ListItemAvatar>
                </Hidden>
                <ListItemText
                    primary={rowData ? rowData.text : ""}
                    secondary={rowData ? new Date(rowData.date).toLocaleString("es-ES",{ year: 'numeric', month: 'long', day: 'numeric' }) : ""}
                />
                <ListItemSecondaryAction>
                    <Tooltip title="borrar notificación">
                        <IconButton name={rowData ? rowData.id : ""} aria-label="cerrar" color="primary" onClick={handleClose}>
                            <HighlightOffIcon />
                        </IconButton>                
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />            
        </div>
    )
}
