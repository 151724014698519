/**
 * Componente que muestra el mensaje flotante de error durante unos segundos
 */
import React from "react";
import { Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

export function FloatAlert({ open, onClose, text, severity }) {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <Alert elevation={6} variant="filled" severity={severity}>
                {text}
            </Alert>
        </Snackbar>            
    )
}
