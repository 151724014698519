/**
 * Item de usuario
 */
import React from "react";
import { ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Checkbox } from "@material-ui/core";

export function UserItem({ 
    rowData, 
    multiselect,
    icon:Icon, 
    classes,
    longPressEvent    
}) {
    return (
        <div>
            <ListItem 
                className={classes.item} 
                button 
                dense 
                name={rowData ? rowData.id : ""} 
                {...longPressEvent}                
            >
                { multiselect 
                ?<Checkbox 
                    color="primary" 
                    value={rowData ? rowData.id : ""} 
                    checked={rowData ? rowData.checkbox : false} 
                />
                : ""}                
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <Icon selector={rowData ? rowData.profile : ""}/>
                    </Avatar>                    
                </ListItemAvatar>
                <ListItemText
                    primary={rowData ? rowData.name : ""}
                    secondary={rowData ? (rowData.id + (rowData.email==="" ? "" : " - " + rowData.email)) : ""}
                />
            </ListItem>
            <Divider />
        </div>
    )
}
