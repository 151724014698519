/**
 * Campo de informe multilínea
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles ((theme) => ({
    common: {
        borderColor: theme.palette.primary.main,
        border: "1px solid",
        borderRadius: "3px",
        font: "11px Arial, sans-serif",
        marginLeft: theme.spacing(0.3),
        marginBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.4),
        paddingBottom: theme.spacing(0.3),
        paddingLeft: theme.spacing(0.5),      
    },
    caption: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        height: theme.spacing(2.5),
        width: theme.spacing(13),
    },
    value: {
        width: theme.spacing(77),
        height: theme.spacing(6),
    }
}));

export function FieldReportMultiline({ caption, value }) {
    const classes = useStyles();

    return (
        <div>
            <div className={clsx(classes.common, classes.caption)}>
                {caption ? caption : ""}
            </div>
            <div className={clsx(classes.common, classes.value)}>
                {value ? value : ""}
            </div>
        </div>
    )
}
