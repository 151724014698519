/**
 * Pestaña de información de muestra correspondiente a SampleTabs
 */
import React, { useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { StepperStatus } from "./StepperStatus";
import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MapField } from "../common/MapField";
import { ImageField } from "../common/ImageField";
import { SampleDataField } from "./SampleDataField";
import { usePosition } from "../../hooks/Position";
import { getStatusIndex } from "../../utils/labUtils"
import moment from "moment";
import { indexAccepted, indexIssued, indexRejected, indexReplaced, indexSent, sampleStatus } from "../../config";

const useStyles = makeStyles ((theme) => ({
    container: {
        userSelect: "none",
    },
    columnGrid: {
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(0),
            paddingLeft: theme.spacing(0),
        }
    },
    field: {
        width: "100%",
        marginTop: theme.spacing(3),
    },    
    fieldTitle: {
        width: "100%",
        marginTop: theme.spacing(1),
    },     
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: theme.spacing(15),
    }, 
}));

export function SampleTab({ data, setData, matchingSel, image, address, setAddress, isLoading, setIsModified }) {
    const { user } = useContext(UserContext);    
    const classes = useStyles();
    const position = usePosition();
    const [activeStep, setActiveStep] = useState(getStatusIndex(data[0].status));

    const lat = (data[0].type === "Heces") 
            ? parseFloat(data[0].latitude) 
            : matchingSel[0] && matchingSel[0].latitude !== undefined
                ? parseFloat(matchingSel[0].latitude)
                : position.lat ? position.lat : (user.latitude ? parseFloat(user.latitude) : 0);
    const lng = (data[0].type === "Heces") 
            ? parseFloat(data[0].longitude) 
            : matchingSel[0] && matchingSel[0].longitude !== undefined
                ? parseFloat(matchingSel[0].longitude)
                : position.lng ? position.lng: (user.longitude ? parseFloat(user.longitude) : 0);
    const sampleId = data[0].type === "Heces" 
        ? data[0].id 
        : matchingSel[0] && matchingSel[0].id !== undefined ? matchingSel[0].id : ""

    // Valores iniciales
    let center = { lat: 0, lng: 0 };
    let points = [{ id: sampleId, lat: 0, lng: 0 }]; 

    // Comprueba si las coordenadas son correctas y las asigna
    if (lat <= 90 && lat >= -90 && lng <= 180 && lng >= -180) {
        center = { lat: lat, lng: lng }
        points = [{ id: sampleId, lat: lat, lng: lng }];
    }

    const handleChange = (event) => {
        const newData = [{
            ...data[0],
            [event.target.name] : event.target.value            
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleAccept = () => {
        const date = (data[0].sent==="0000-00-00" || data[0].sent==="")
            ? moment(new Date()).format("YYYY-MM-DD")  
            : data[0].sent         
        const newData = [{
            ...data[0],
            status: sampleStatus[indexAccepted].label,             
            sent: date,
            date_start: date,                        
        }];
        setData(newData);
        setActiveStep(2)
        setIsModified(true);
    }

    const handleReject = () => {
        const newData = [{
            ...data[0],
            status : sampleStatus[indexRejected].label            
        }];
        setData(newData);
        setActiveStep(2)
        setIsModified(true);
    }

    const handleReplace = () => {
        const newData = [{
            ...data[0],
            status : sampleStatus[indexReplaced].label            
        }];
        setData(newData);
        setActiveStep(2)
        setIsModified(true);
    }    

    return (
        <div className={classes.container}>
            {user.profile === "supervisor" && data[0].status === sampleStatus[indexIssued].label && data[0].sample_id > 0
            ? "" :             
            <StepperStatus 
                data={data} 
                setData={setData} 
                setIsModified={setIsModified}
                profile={user.profile}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />}
            {data[0].status===sampleStatus[indexSent].label && user.profile==="laboratorio" 
            ? <div>
                <Button className={classes.button} color="primary" variant="outlined" onClick={handleAccept}>
                    Aceptar
                </Button>
                <Button className={classes.button} color="secondary" variant="outlined" onClick={handleReject}>
                    Rechazar
                </Button>                     
            </div>
            : data[0].status===sampleStatus[indexRejected].label && user.profile==="laboratorio" 
            ? <Button className={classes.button} color="secondary" variant="outlined" onClick={handleReplace}>
                Reemplazar
            </Button>                     
            : "" }
            <Grid container>
                <Grid item xs={12} sm={6} className={classes.columnGrid}>
                    <SampleDataField data={data} />
                    {matchingSel[0]?<SampleDataField data={matchingSel} />:""}
                    {data[0].type === "Heces" || matchingSel[0]
                    ? <MapField 
                        classContainer={classes.fieldTitle} 
                        center={center} 
                        points={points}
                        address={address}
                        setAddress={setAddress}                               
                    /> : ""}                    
                </Grid>
                <Grid item xs={12} sm={6} className={classes.columnGrid}>
                    {user.profile === "laboratorio"
                    ?<TextField
                        className={classes.field}
                        disabled={isLoading || user.profile==="supervisor"} 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        value={(data[0] && data[0].comments_lab !== undefined) ? data[0].comments_lab : ""} 
                        onChange={handleChange}
                        label="Comentarios del laboratorio" 
                        id="comments_lab"
                        name="comments_lab"
                        rows={2}
                        multiline                        
                        inputProps={{ maxLength: 255 }}
                    />
                    :""}                                       
                    {data[0].type === "Heces"
                    ? <ImageField
                        classContainer={classes.fieldTitle} 
                        image={image} 
                        data={data}
                        setData={setData}
                        disabled={true}
                        description="Fotografía de la muestra tomada (preferentemente en horizontal)."                                
                    /> : ""} 
                </Grid>
            </Grid> 
        </div>
    )
}
