/**
 * Formulario de detalle de animal
 */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, FormGroup, Grid, InputAdornment, Switch, TextField, Typography } from "@material-ui/core";
import { ReactComponent as AnimalsIcon } from '../../assets/dog.svg';
import { fetchApi, jsonParseMysql, mysqlEscape, mysqlInteger, mysqlSpanishDecimal } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { Loading } from "../Loading";
import { FormGroupButton } from "../common/FormGroupButton";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { SaveDialog } from "../common/SaveDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { SelectField } from "../common/SelectField";
import { zeroFill } from "../../utils/StringUtils";
import { indexAccepted, indexAnalyzed, indexIssued, indexRejected, indexReplaced, minLabelAdvise, prefixAnimal, sampleStatus } from "../../config";
import { ImageField } from "../common/ImageField";

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
        userSelect: "none",        
    },
    form: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(0.5),
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(0.5),
        },
    },    
    columnGrid: {
        maxWidth: "600px",
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingRight: theme.spacing(2),
        }
    },
    field: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    fieldTitle: {
        width: "100%",
        marginTop: theme.spacing(2),
    },    
    bottomSpace: {
        // La altura debe coincidir con FormGroupButton
        height: theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            height: theme.spacing(8),
        }        
    },
}));

export function AnimalForm({ isNew, pathBack }) {
    const { user } = useContext(UserContext);    
    const { id } = useParams();
    const classes = useStyles();
    const windowDim = useWindowDimensions();
    const history = useHistory();
    // Se recuerda el filtro del listado para poder volver al mismo listado filtrado
    const filterParam = new URLSearchParams(useLocation().search).get("filter");     
    const selectParam = new URLSearchParams(useLocation().search).get("select");
    
    // Estados de carga de datos
    const [data, setData] = useState([{
        id: "",
        name: "",
        chip: "",
        weight: "",
        birthday: "",
        death: "",
        breed: "",
        hair: "",
        type: "",
        sex: "",
        owner: "",
        dni: "",
        city: "",
        pcode: "",
        address: "",
        province: "",
        phone: "",
        email: "",
        block_label_id: "",        
        label: "",
        serial: "",
        client_id: "",
        user_id: "",
        is_dangerous: "",
        is_disabled: ""
    }]);
    const [image, setImage] = useState();
    const [isLinked, setIsLinked] = useState(); // Animal vinculado a una muestra aceptada o rechazada
    const [labels, setLabels] = useState ([{
        value: "",
        label: "(ninguna)"
    }]);
    const [clients, setClients] = useState ([{
        value: "",
        label: "(ninguno)",
        serial: "",
    }]);
    const [users, setUsers] = useState ([{
        value: "",
        label: "(ninguno)"
    }]);
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingLabels, setIsLoadingLabels] = useState(true);
    const [isLoadingClients, setIsLoadingClients] = useState(true);
    const [isLoadingUsers, setIsLoadingUsers] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isModified, setIsModified] = useState(false);
    // Evita el warning de expresión compleja en el useEffect
    const clientSelect = (data[0] && data[0].client_id !== undefined) ? data[0].client_id : ""
    const userSelect = (data[0] && data[0].user_id !== undefined) ? data[0].user_id : ""

    // Estados de validación de errores
    const [error, setError] = useState({ open: false, status: '' });
    const [errorLabel, setErrorLabel] = useState(false);
    const [errorClient, setErrorClient] = useState(false);
    const [errorUser, setErrorUser] = useState(false);

    // Estados de diálogos
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    async function saveForm () {

        async function sendAdviseMinLabel (userLab) {
            const text = "El " + user.profile + " " + user.name + " tiene menos de " + minLabelAdvise + " etiquetas de animales disponibles";
            const query = "INSERT INTO advise (type, text, is_notified, is_viewed, date, user_id) "
                +"VALUES ('label', '" + text + "', 0, 0, NOW(), '" + mysqlEscape(userLab) + "')";
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
            }
        }  

        async function fecthAdviseUsersAdmin() {
            const response = await fetchApi({ 
                query: "SELECT id FROM user WHERE user.profile = 'administrador' AND is_disabled = 0"
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                readData.map (user => sendAdviseMinLabel(user.id));
            };
        } 

        async function fetchUpdate(newData) {
            let query = "";
            newData.is_disabled = newData.death && newData.death !== undefined && newData.death !== "0000-00-00";
            if (isNew) {
                query = "INSERT INTO animal " 
                    + "(name, chip, weight, birthday, death, breed, hair, is_dangerous, type, sex, owner, dni, city, pcode, " 
                    + "address, province, phone, email, is_attached, is_disabled, image, label, block_label_id, client_id, user_id)" 
                    + " VALUES ('" 
                    + mysqlEscape(newData.name) + "', '" + mysqlEscape(newData.chip) + "', " 
                    + mysqlSpanishDecimal(newData.weight) + ", '" 
                    + mysqlEscape(newData.birthday) + "', '" + mysqlEscape(newData.death) + "', '" 
                    + mysqlEscape(newData.breed) + "', '" + mysqlEscape(newData.hair) + "', " 
                    + !!newData.is_dangerous + ", '" + mysqlEscape(newData.type) + "', '" 
                    + mysqlEscape(newData.sex) + "', '" + mysqlEscape(newData.owner) + "', '" 
                    + mysqlEscape(newData.dni) + "', '"
                    + mysqlEscape(newData.city) + "', '" + mysqlEscape(newData.pcode) + "', '" 
                    + mysqlEscape(newData.address) +"', '" + mysqlEscape(newData.province) +"', '" 
                    + mysqlEscape(newData.phone) +"', '" + mysqlEscape(newData.email) +"', " 
                    + mysqlInteger(newData.is_attached ? 1 : 0) + ", " + mysqlInteger(newData.is_disabled ? 1 : 0) + ", '" 
                    + (image ? mysqlEscape(image) : "") + "', " 
                    + mysqlInteger(newData.label) + ", " + mysqlInteger(newData.block_label_id) + ", '" 
                    + mysqlEscape(newData.client_id) + "', '" + mysqlEscape(newData.user_id) + "')";
            } else {
                query = "UPDATE animal SET name='" + mysqlEscape(newData.name) + "', chip='" + mysqlEscape(newData.chip) 
                    + "', weight=" + mysqlSpanishDecimal(newData.weight) + ", birthday='" + mysqlEscape(newData.birthday) 
                    + "', death='" + mysqlEscape(newData.death) + "', breed='" + mysqlEscape(newData.breed) 
                    + "', hair='" + mysqlEscape(newData.hair) 
                    + "', is_dangerous=" + !!newData.is_dangerous + ", type='" + mysqlEscape(newData.type) 
                    + "', sex='" + mysqlEscape(newData.sex) + "', owner='" + mysqlEscape(newData.owner) 
                    + "', dni='" + mysqlEscape(newData.dni) 
                    + "', city='" + mysqlEscape(newData.city) + "', pcode='" + mysqlEscape(newData.pcode) 
                    + "', address='" + mysqlEscape(newData.address) + "', province='" + mysqlEscape(newData.province) 
                    + "', phone='" + mysqlEscape(newData.phone) + "', email='" + mysqlEscape(newData.email) 
                    + "', is_attached=" + mysqlInteger(newData.is_attached ? 1 : 0) 
                    + ", is_disabled=" + mysqlInteger(newData.is_disabled ? 1 : 0) 
                    + ", image='" + (image ? mysqlEscape(image) : "")                   
                    + "', label=" + mysqlInteger(newData.label) + ", block_label_id=" + mysqlInteger(newData.block_label_id) 
                    + ", client_id='" + mysqlEscape(newData.client_id) + "', user_id='" + mysqlEscape(newData.user_id) + "'"
                    + " WHERE id=" + mysqlInteger(id);
            }
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                setError(errorRead);
                return false;
            } else {
                if (isNew) lastId = parseInt(JSON.parse(response.data)[0].lastId) || 0;
                return true;
            }
        }     
    
        // Crea una copia de los datos
        if (isMounted) setIsSaving(true);
        let lastId = 0;
        let newData = {...data[0]};

        // Verificación de datos
        let verificationError = false;
        if (user.profile==="administrador") {
            if (newData.client_id === "") {
                if (isMounted) setErrorClient (true);
                verificationError= true;
            }
            if (newData.user_id === "") {
                if (isMounted) setErrorUser (true);
                verificationError= true;
            }
        }
        if (newData.label === undefined || newData.label === "" || newData.label === 0) {
            if (isMounted) setErrorLabel(true);
            verificationError = true
        }

        // Graba los cambios si no hay errores de verificación 
        if (!verificationError) {
            if (await fetchUpdate(newData)) {
                // Reinicia estados y valores
                if (isMounted) {
                    if (labels.length <= minLabelAdvise) fecthAdviseUsersAdmin();
                    setIsModified(false);                
                    setIsSaving(false);
                    setErrorLabel(false);
                    setErrorClient(false);
                    setData(prevState => { return [{
                        ...prevState[0],
                        is_disabled: prevState[0].death && prevState[0].death !== undefined && prevState[0].death !== "0000-00-00" 
                    }]; })                    
                }
                return lastId ? lastId : true;
            }
        }
        if (isMounted) setIsSaving(false);
        return false;  
    }
    
    async function deleteForm () {

        async function fetchIsLinked() {
            const query = "SELECT COUNT(*) as count FROM sample WHERE animal_id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return true;    // No está vinculado pero se evita continuar 
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }    

        async function fetchUpdate() {
            const query = "UPDATE animal SET is_disabled = 1, death = NOW() WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        async function fetchDelete() {
            const query = "DELETE FROM animal WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        if (await fetchIsLinked()) {
            if (await fetchUpdate()) {
                // Baja correcta
                return true                
            } else {
                // Error de API
                return false
            }
        } else {
            if (await fetchDelete()) {
                // Eliminación correcta
                return true                
            } else {
                // Error de API
                return false
            }
        }
    }

    async function saveAndExit() {
        // Función asíncrona para guardar y salir que evitar la salida en caso de error
        if (await saveForm()) {            
            exit();
        } 
    }

    async function saveAndExitSelection() {
        // Función asíncrona para guardar y volver al desplegable que lanzó el formulario
        const result = await saveForm();
        if (result) {                
            localStorage.setItem('animal', JSON.stringify({
                id: result,
                label: data[0].label,
                block_label_id: data[0].block_label_id,
                name: data[0].name
            }));
            exitSelection();
        }        
    }

    async function saveAndRefresh() {
        // Función asíncrona para guardar y recargar la ficha
        const result = await saveForm();
        if (result) {
            if (filterParam) {
                history.push(`${pathBack}/animales/${result}?filter=${filterParam}`);
            } else {
                history.push(`${pathBack}/animales/${result}`);
            }    
        }
    }

    async function deleteAndExit() {
        // Función asíncrona para eliminar el elemento
        if (await deleteForm()) {
            exit();
        }
    }

    async function enableForm() {

        async function fetchUpdate() {
            const query = "UPDATE animal SET is_disabled = 0, death = NULL WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        } 
        
        if (await fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_disabled: false,
                death: "",
            }];
            if (isMounted) setData(newData);
        }
    }

    function exit() {
        // Salida cuando se lanza el listado normal
        if (filterParam) {
            history.push (`${pathBack}/animales?filter=${filterParam}`);
        } else {
            history.push (`${pathBack}/animales`);
        }
    }

    function exitSelection() {
        // Salida cuando se lanza desde el selector de animales
        history.push(`${pathBack}/${selectParam}`);
    }
    
    useEffect (() => {  

        async function fetchIsLinked() {
            const query = `SELECT COUNT(*) as count FROM sample ` 
                + `WHERE (status='${sampleStatus[indexAccepted].label}' `
                + `OR status='${sampleStatus[indexRejected].label}' ` 
                + `OR status='${sampleStatus[indexReplaced].label}' ` 
                + `OR status='${sampleStatus[indexAnalyzed].label}' `
                + `OR status='${sampleStatus[indexIssued].label}') ` 
                + `AND animal_id=${mysqlInteger(id)}`;
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                setIsLinked(true); // No está vinculado pero se evita continuar
            } else {
                if (parseInt(JSON.parse(response.data)[0].count) === 0) {
                    setIsLinked(false);
                } else {
                    setIsLinked(true);
                }
            }
        }

        async function fetchLoad() {
            if (isMounted) setIsLoading(true);
            const response = await fetchApi({ 
                query: "SELECT animal.id as id, animal.name as name, animal.chip as chip, animal.weight as weight, " 
                    + "animal.birthday as birthday, animal.death as death, animal.breed as breed, animal.hair as hair, " 
                    + "animal.is_dangerous as is_dangerous, animal.type as type, animal.sex as sex, " 
                    + "animal.owner as owner, animal.dni as dni, animal.city as city, " 
                    + "animal.pcode as pcode, animal.address as address, " 
                    + "animal.province as province, animal.phone as phone, animal.email as email, animal.label as label, " 
                    + "animal.block_label_id as block_label_id, client.serial as serial, animal.client_id, " 
                    + "animal.is_attached as is_attached, animal.is_disabled as is_disabled, animal.user_id as user_id " 
                    + "FROM animal LEFT JOIN client ON (animal.client_id = client.id) " 
                    + "WHERE animal.id=" + mysqlInteger(id)
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                jsonData[0].weight = parseFloat(jsonData[0].weight).toLocaleString();  
                if (isMounted) setData(jsonData);
            };
            if (isMounted) setIsLoading(false);
        }        

        async function fetchLoadImage() {
            // La imagen se lee por separado para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT image FROM animal WHERE id=" + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setImage(jsonData[0].image);
            };
        }         
        
        async function fetchLoadClients() {
            // Carga la lista de clientes en el desplegable
            if (isMounted) setIsLoadingClients(true);
            const response = await fetchApi({ 
                query: "SELECT id, name, serial FROM client WHERE is_disabled = 0" 
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                const newClients = readData.map(item => ({ 
                    value: item.id, 
                    label: item.name,
                    serial: item.serial,
                }));                
                if (isMounted) setClients([...newClients, { value: "", label: "(ninguno)", serial: "" }]);
            };                  
            if (isMounted) setIsLoadingClients(false);
        }

        async function fetchLoadUsers() {
            // Carga la lista de veterinarios en el desplegable
            if (isMounted) setIsLoadingUsers(true);
            const response = await fetchApi({ 
                query: "SELECT id, name FROM user WHERE is_disabled = 0 AND profile = 'veterinario'" 
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                const newUsers = readData.map(item => ({ 
                    value: item.id, 
                    label: item.name,
                }));                
                if (isMounted) setUsers([...newUsers, { value: "", label: "(ninguno)" }]);
            };                  
            if (isMounted) setIsLoadingUsers(false);
        }

        if (user.profile==="administrador") {
            fetchLoadClients();
            fetchLoadUsers();
        }
        if (isNew) {
            // Para animales nuevos no se realiza la carga inicial
            if (isMounted) {
                setIsModified(true);
                setIsLoading(false);
            }
        } else {
            // Carga inicial desde la API
            id && fetchLoad();
            id && fetchIsLinked();
            id && fetchLoadImage();
        } 
    },[id, isNew, isMounted, user])    

    useEffect(() => {

        async function fetchLoadUsedLabels() {
            const whereUser = user.profile !== "administrador" 
            ? "AND block_label.user_id = '" + mysqlEscape(user.id) + "' " 
            : "AND block_label.client_id = '" + mysqlEscape(clientSelect) + "' "
            const response = await fetchApi({ 
                query: "SELECT animal.block_label_id as block_label_id, animal.label as label, client.serial as serial FROM animal " 
                    + "LEFT JOIN block_label ON (animal.block_label_id = block_label.id) " 
                    + "LEFT JOIN client ON (animal.client_id = client.id) "
                    + "WHERE block_label.type='animal' "
                    + whereUser 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };          
            if (response.data) {
                if (isMounted) {
                    const readData = jsonParseMysql(response.data);
                    return readData.map((item) => {
                        const code = `${prefixAnimal}-${item.serial}-${zeroFill(item.block_label_id,5)}-${zeroFill(item.label,3)}`;                        
                        return { value: code, label: code };
                    });
                }
            };
        }

        async function fetchLoadCurrentLabel(id) {
            const response = await fetchApi({ 
                query: "SELECT animal.block_label_id as block_label_id, animal.label as label, client.serial as serial " 
                    + "FROM animal LEFT JOIN client ON (animal.client_id = client.id) WHERE animal.id = " + mysqlInteger(id) 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };          
            if (response.data) {
                if (isMounted) {
                    const readData = jsonParseMysql(response.data);
                    if (readData[0].block_label_id) {
                        const code = `${prefixAnimal}-${readData[0].serial}-${zeroFill(readData[0].block_label_id,5)}-${zeroFill(readData[0].label,3)}`;
                        return { value: code, label: code };
                    }
                }
            }
        }        

        async function fetchLoadLabels() {
            // Carga en el seleccionable de etiquetas las etiquetas no usadas y disponibles
            
            if (isMounted) setIsLoadingLabels(true);
            const whereUser = user.profile !== "administrador" 
            ? "AND block_label.user_id = '" + mysqlEscape(user.id) + "' " 
            : "AND block_label.client_id = '" + mysqlEscape(clientSelect) + "' "
            const usedLabels = await fetchLoadUsedLabels();
            const currentLabel = isNew ? null : await fetchLoadCurrentLabel(id);
            const response = await fetchApi({ 
                query: "SELECT block_label.id as id, count, client.serial as serial FROM block_label " 
                    + "LEFT JOIN client ON (block_label.client_id = client.id) " 
                    + "WHERE block_label.is_disabled = 0 AND block_label.type='animal' "
                    + whereUser 
            }); 
            if (response.status !== "200") { setError({ open: true, status: response.status }) };          
            if (response.data) {
                const blocks = jsonParseMysql(response.data);
                let newLabels = [{ value: "", label: "(ninguna)" }];
                if (currentLabel) newLabels.push(currentLabel);
                blocks.forEach(function (block) {
                    for (let i=1; i<=block.count; i++) {
                        const code = `${prefixAnimal}-${block.serial}-${zeroFill(block.id,5)}-${zeroFill(i,3)}`;
                        const elem = { value: code, label: code };
                        if (usedLabels && !usedLabels.find(usedLabel => usedLabel.value === elem.value)) {
                            newLabels.push(elem);
                        }
                    }    
                });
                if (isMounted) setLabels(newLabels); 
            }
            if (isMounted) setIsLoadingLabels(false);
        }  
        fetchLoadLabels();
    }, [id, isNew, isMounted, user.profile, user.id, clientSelect])

    useEffect(() => {
        // Carga inicial de datos del usuario cuando es un animal nuevo y no es administrador
        setData(prevState => {
            if (isNew && user.profile!=="administrador") {
                return [{
                    ...prevState[0],
                    user_id: user.id,
                    client_id: user.client_id,
                    serial: user.serial 
                }];
            } else {
                return [...prevState];
            }
        })
    }, [isNew, user])

    const handleChange = (event) => {
        const newData = [{
            ...data[0],
            [event.target.name] : event.target.value            
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleChekedChange = (event) => {
        const newData = [{
            ...data[0],
            [event.target.name] : event.target.checked            
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleLabelChange = (event) => {

        const arrLabel = event.target.value.split("-");
        const newData = [{
            ...data[0],
            serial: arrLabel[1],   
            block_label_id : arrLabel[2],
            label: arrLabel[3],
        }];
        setData(newData);
        setIsModified(true);
    }

    const handleChangeClient = (event) => {
        const newData = [{
            ...data[0],
            block_label_id: "",
            label: "",
            [event.target.name]: event.target.value,
        }];    
        setData(newData);
        setIsModified(true);
    }
    
    const handleApply = () => {
        if (selectParam) {
            // Botón aceptar
            saveAndExitSelection();
        } else {
            // Botón aplicar
            if (isNew) {
                saveAndRefresh();
            } else {
                saveForm();
            }
        }
    }

    const handleExit = () => {
        if (selectParam) {
            // Botón cancelar
            if (isModified && !isSaving) {
                setOpenSaveDialog(true);
            } else {
                setOpenSaveDialog(false);
                exitSelection();
            }            
        } else {
            // Botón salir
            if (isModified && !isSaving) {
                setOpenSaveDialog(true);
            } else {
                setOpenSaveDialog(false);
                exit();
            }
        }
    }
    
    const handleDelete = () => {
        if (data[0] && data[0].is_disabled) {
            // Habilitar
            enableForm();
        } else {
            // Eliminar
            setOpenDeleteDialog(true);
        }
    }

    const handleYesSave = () => {
        setOpenSaveDialog(false);
        if (selectParam) {
            saveAndExitSelection();
        } else {
            saveAndExit();
        }
    }

    const handleNoSave = () => {
        setOpenSaveDialog(false);
        if (selectParam) {
            exitSelection();
        } else {
            exit();
        }
    }

    const handleCancelSave = () => {
        setOpenSaveDialog(false);
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        deleteAndExit();
    }

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }   

    const isAllColumn = (windowDim.width < 400) 
        || (windowDim.width > 960 && windowDim.width < 1050) 
        || (windowDim.width > 600 && windowDim.width < 810)
    const serial = isNew && user.profile!=="administrador" ? user.serial : data[0].serial
    const label = data[0] && data[0].label !== undefined 
        && `${prefixAnimal}-${serial}-${zeroFill(data[0].block_label_id, 5)}-${zeroFill(data[0].label, 3)}`; 
        
    if (isLoading) {
        return <Loading />
    } else {
        return (
            <div className={classes.container}>
                <Title 
                    text={isNew ? "Nuevo animal" : label} 
                    icon={AnimalsIcon} 
                    pathBack={`${pathBack}/animales`}
                    disabled={data[0] && data[0].is_disabled}
                />
                <FormGroup className={classes.form}>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>
                            <Grid container spacing={isAllColumn ? 0 : 3}>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].name !== undefined) ? data[0].name : ""} 
                                        onChange={handleChange}
                                        label="Nombre del animal" 
                                        id="name"
                                        name="name"
                                        inputProps={{ maxLength: 30 }}
                                    />
                                    <SelectField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        value={(!isLoadingLabels && data[0].block_label_id > 0) ? label : ""} 
                                        onChange={handleLabelChange}
                                        label="Identificación QR"
                                        id="label"
                                        name="label"
                                        list={labels}
                                        error={errorLabel}
                                        helperText={errorLabel ? "Campo obligatorio" : ""}    
                                    />                                    
                                </Grid>
                                <Grid item xs={isAllColumn ? 12 : 6}>                        
                                    <SelectField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        value={(data[0] && data[0].type !== undefined) ? data[0].type : ""} 
                                        onChange={handleChange}
                                        label="Tipo"
                                        id="type"
                                        name="type"
                                        list={[
                                            {"value" : "Perro", "label" : "Perro"},
                                            {"value" : "Caballo", "label" : "Caballo"},
                                            {"value" : "", "label" : "(ninguno)"}                                
                                        ]}                                                            
                                    />
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].chip !== undefined) ? data[0].chip : ""} 
                                        onChange={handleChange}
                                        label="Número de chip" 
                                        id="chip"
                                        name="chip"
                                        inputProps={{ maxLength: 15 }}
                                    />
                                </Grid>
                            </Grid>
                            { user.profile === "administrador" 
                            ? <SelectField
                                className={classes.field}
                                disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                value={isLoadingClients ? "" : clientSelect} 
                                onChange={handleChangeClient}
                                id="client_id"
                                name="client_id"
                                label="Cliente"
                                list={clients}                                                           
                                error={errorClient}
                                helperText={errorClient ? "Campo obligatorio" : ""}
                            /> : "" }
                            { user.profile === "administrador" 
                            ? <SelectField
                                className={classes.field}
                                disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                value={isLoadingUsers ? "" : userSelect} 
                                onChange={handleChange}
                                id="user_id"
                                name="user_id"
                                label="Veterinario"
                                list={users}                                                           
                                error={errorUser}
                                helperText={errorUser ? "Campo obligatorio" : ""}
                            /> : "" }                            
                            <ImageField
                                classContainer={classes.fieldTitle} 
                                image={image} 
                                setImage={setImage}
                                data={data}
                                setData={setData}
                                setIsModified={setIsModified}
                                disabled={isLoading || (user.profile !== "administrador" && isLinked)}
                                description="Fotografía del animal (preferiblemente en formato horizontal)."                                
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.columnGrid}>                            
                            <Grid container spacing={isAllColumn ? 0 : 3}>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        variant="outlined"                                
                                        value={(data[0] && data[0].birthday !== undefined && data[0].birthday !== "0000-00-00") ? data[0].birthday : ""} 
                                        onChange={handleChange}
                                        label="Fecha de nacimiento"
                                        id="birthday"
                                        name="birthday"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                    />                                    
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].weight !== undefined) ? data[0].weight : ""} 
                                        onChange={handleChange}
                                        label="Peso" 
                                        id="weight"
                                        name="weight"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                                        }}
                                    />
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].breed !== undefined) ? data[0].breed : ""} 
                                        onChange={handleChange}
                                        label="Raza" 
                                        id="breed"
                                        name="breed"
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        variant="outlined"                                
                                        value={(data[0].death && data[0].death !== undefined && data[0].death !== "0000-00-00") ? data[0].death : ""} 
                                        onChange={handleChange}
                                        label="Fecha de baja"
                                        id="death"
                                        name="death"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <SelectField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        value={(data[0] && data[0].sex !== undefined) ? data[0].sex : ""} 
                                        onChange={handleChange}
                                        label="Sexo"
                                        id="sex"
                                        name="sex"
                                        list={[
                                            {"value" : "Hembra", "label" : "Hembra"},
                                            {"value" : "Macho", "label" : "Macho"},
                                            {"value" : "", "label" : "(ninguno)"}                                
                                        ]}                                                            
                                    />
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                        variant="outlined" 
                                        value={(data[0] && data[0].hair !== undefined) ? data[0].hair : ""} 
                                        onChange={handleChange}
                                        label="Pelaje" 
                                        id="hair"
                                        name="hair"
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid>
                            </Grid>
                            <FormControlLabel
                                className={classes.fieldTitle}
                                control={
                                    <Switch 
                                        checked={!!data[0].is_dangerous} 
                                        onChange={handleChekedChange} 
                                        name="is_dangerous" 
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)} 
                                    />
                                }
                                label={<Typography>Perro potencialmente peligroso (PPP)</Typography>}
                            />
                            <Grid container spacing={isAllColumn ? 0 : 3}>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                        variant="outlined" 
                                        value={(data[0] && data[0].owner !== undefined) ? data[0].owner : ""} 
                                        onChange={handleChange}
                                        label="Propietario" 
                                        id="owner"
                                        name="owner"
                                        inputProps={{ maxLength: 50 }}
                                    />
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                        variant="outlined" 
                                        value={(data[0] && data[0].pcode !== undefined) ? data[0].pcode : ""} 
                                        onChange={handleChange}
                                        label="Código postal" 
                                        id="pcode"
                                        name="pcode"
                                        inputProps={{ maxLength: 10 }}
                                    />
                                </Grid>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                        variant="outlined" 
                                        value={(data[0] && data[0].dni !== undefined) ? data[0].dni : ""} 
                                        onChange={handleChange}
                                        label="DNI" 
                                        id="dni"
                                        name="dni"
                                        inputProps={{ maxLength: 15 }}
                                    />
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                        variant="outlined" 
                                        value={(data[0] && data[0].city !== undefined) ? data[0].city : ""} 
                                        onChange={handleChange}
                                        label="Población" 
                                        id="city"
                                        name="city"
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                className={classes.field}
                                disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                variant="outlined" 
                                value={(data[0] && data[0].province !== undefined) ? data[0].province : ""} 
                                onChange={handleChange}
                                label="Provincia" 
                                id="province"
                                name="province"
                                inputProps={{ maxLength: 30 }}
                            />
                            <TextField
                                className={classes.field}
                                disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                variant="outlined" 
                                value={(data[0] && data[0].address !== undefined) ? data[0].address : ""} 
                                onChange={handleChange}
                                label="Dirección" 
                                id="address"
                                name="address"
                                inputProps={{ maxLength: 100 }}
                            />
                            <Grid container spacing={isAllColumn ? 0 : 3}>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                        variant="outlined" 
                                        value={(data[0] && data[0].phone !== undefined) ? data[0].phone : ""} 
                                        onChange={handleChange}
                                        label="Teléfono" 
                                        id="phone"
                                        name="phone"
                                        inputProps={{ maxLength: 30 }}
                                    /> 
                                </Grid>
                                <Grid item xs={isAllColumn ? 12 : 6}>
                                    <TextField 
                                        className={classes.field}
                                        disabled={isLoading || (user.profile !== "administrador" && isLinked)}                                  
                                        variant="outlined" 
                                        value={(data[0] && data[0].email !== undefined) ? data[0].email : ""} 
                                        onChange={handleChange}
                                        label="Correo electrónico" 
                                        id="email"
                                        name="email"
                                        inputProps={{ maxLength: 100 }}
                                    />                                                                                                                                                                       
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.bottomSpace}/>
                    <FloatAlert 
                        open={error.open} 
                        onClose={handleErrorClose} 
                        text="No hemos podido leer o guardar los cambios. Comprueba tu conexión." 
                        severity="error"
                    />
                </FormGroup> 
                <FormGroupButton 
                    disabledApply={isLoading || !isModified}
                    isSaving={isSaving}
                    hideDelete={!!isNew}
                    handleApplyClick={user.profile !== "administrador" && isLinked ? null : handleApply} 
                    handleExitClick={handleExit} 
                    handleDeleteClick={user.profile !== "administrador" && isLinked ? null : handleDelete}
                    textDelete={data[0] && data[0].is_disabled ? "Habilitar" : ""}
                    acceptCancelMode={selectParam}
                />
                <SaveDialog 
                    open={openSaveDialog}
                    onClickYes={handleYesSave}
                    onClickNo={handleNoSave}
                    onClickCancel={handleCancelSave}
                />
                <DeleteDialog
                    open={openDeleteDialog}
                    onClickYes={handleYesDelete}
                    onClickNo={handleCancelDelete}
                    onClickCancel={handleCancelDelete}
                    title="¿Estás seguro de que quieres eliminar este animal?"
                    description="Si continúas este proceso se eliminará el animal del sistema, salvo que tuviera información vinculada, en cuyo caso sería deshabilidado."
                />
            </div>
        )
    }
}
