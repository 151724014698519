/**
 * Subselector para las opciones del perfil veterinario
 */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { Notifications } from "../components/notifications/Notifications";
import { UserForm } from "../components/users/UserForm";
import { Animals } from "../components/animals/Animals";
import { AnimalForm } from "../components/animals/AnimalForm";
import { Samples } from "../components/samples/Samples";
import { SampleForm } from "../components/samples/SampleForm";
import { DashboardVeterinary } from "../components/veterinary/DashboardVeterinary";

export function SwitchVeterinary({ setNumNotif }) {
    return (
        <Switch>
            <Route path="/veterinario/muestras" exact>
                <Samples pathBack="/veterinario" />
            </Route>
            <Route path="/veterinario/muestras/:id" exact>
                <SampleForm pathBack="/veterinario"/>
            </Route>                
            <Route path="/veterinario/muestrarecarga" exact>
                <SampleForm pathBack="/veterinario" reload/>
            </Route>                
            <Route path="/veterinario/nuevamuestrarecarga" exact>
                <SampleForm pathBack="/veterinario" isNew reload />
            </Route>                
            <Route path="/veterinario/nuevamuestra" exact>
                <SampleForm pathBack="/veterinario" isNew />
            </Route>

            <Route path="/veterinario/animales" exact>
                <Animals pathBack="/veterinario" />
            </Route>
            <Route path="/veterinario/animales/:id" exact>
                <AnimalForm pathBack="/veterinario" />
            </Route>                
            <Route path="/veterinario/nuevoanimal" exact>
                <AnimalForm pathBack="/veterinario" isNew />
            </Route>     
                       
            <Route path="/veterinario/notificaciones" exact>                    
                <Notifications setNumNotif={setNumNotif} />
            </Route>
            <Route path="/veterinario/micuenta/:id" exact>
                <UserForm />
            </Route>
            <Route path="/veterinario" exact>
                <DashboardVeterinary />                          
            </Route>
        </Switch>
    )
}
