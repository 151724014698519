/**
 * Botonera inferior de formularios con botones aplicar, cancelar y eliminar
 */
import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { drawerWidth, buttonGroup, buttonGroupXs } from "../../config";

const useStyles = makeStyles ((theme) => ({
    bottomNavigation: {
        display: "flex",
        position: "fixed",
        bottom: "0", 
        alignItems: "center",        
        width: "100%",
        justifyContent: "center",
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            justifyContent: "left",
        },
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            alignItems: "start",        
            height: buttonGroupXs,
        },
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[50],
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: alpha(theme.palette.primary.main, 0.1)
        },
        zIndex: 5,
    },
    buttonGroup: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(0),
            marginTop: theme.spacing(1),
        }
    },
    buttonNav: {
        height: theme.spacing(5),
        margin: theme.spacing(0.5),
        width: theme.spacing(15),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0.2),
            width: theme.spacing(12),                
        }
    },
}));

export const FormGroupButton = ({ 
        disabledApply,
        isSaving, 
        hideDelete, 
        handleApplyClick, 
        handleExitClick, 
        handleDeleteClick, 
        textDelete, 
        acceptCancelMode,
        printMode
    }) => {
    const classes = useStyles();

    return (
        <div className={classes.bottomNavigation}>
            <div className={classes.buttonGroup}>
                { handleApplyClick 
                ? <Button 
                    disabled={disabledApply || isSaving} 
                    className={classes.buttonNav} 
                    color="primary" 
                    variant="contained" 
                    onClick={handleApplyClick}
                >
                    { isSaving 
                    ? <CircularProgress color="inherit" size={16}/> 
                    : (printMode ? "Imprimir" : acceptCancelMode ? "Aceptar" : "Aplicar") }
                </Button>
                : "" }
                <Button
                    className={classes.buttonNav} 
                    color="primary" 
                    variant="contained" 
                    onClick={handleExitClick}
                >
                    { acceptCancelMode ? "Cancelar" : "Salir" }
                </Button>                
                { !hideDelete && handleDeleteClick ?
                    <Button
                        className={classes.buttonNav} 
                        color="secondary" 
                        variant="contained" 
                        onClick={handleDeleteClick}
                    >{textDelete ? textDelete : "Eliminar"}</Button>
                : ""}
            </div>
        </div>                
    ) 
}
