/**
 * Utilidades de construcción de consultas SQL
 */
import { mysqlEscape } from "../hooks/DataFetch";
import { prefixAnimal, prefixFeces, prefixSaliva } from "../config";

export function getSelect({ 
    columns, 
    defaultColumns, 
    table, 
    leftJoin, 
    where, 
    search, 
    fixedFields,    
    offset, 
    limitPlus,
    descending,
    orderBy,
    orderDirection
}) {
    // Devuelve el select a partir de los parámetros habituales de consulta

    // La búsqueda es sobre todas las columnas
    if (search) {
        if (search.substring(0,2) === `${prefixAnimal}-` || search.substring(0,2) === `${prefixFeces}-` || search.substring(0,2) === `${prefixSaliva}-`) {
            // Si entiende que la cadena de búsqueda es una etiqueta intenta extraer el bloque y el índice
            const labelParts = search.substring(2).split("-", 3);
            const block = labelParts[1];
            const label = labelParts[2];
            if (defaultColumns.filter((field => field.id === "label"))) {
                // Si existe el campo label se realiza el filtro por etiqueta
                if (block) where += " AND (" + table + ".block_label_id = " + parseInt(block) + ")"
                if (label) where += " AND (" + table + ".label = " + parseInt(label) + ")"
            }
        } else {
            // Filtro general por cualquier campo
            const searchScape = mysqlEscape(search);
            where += " AND (" + defaultColumns.filter(((field) => (field.id !== "checkbox"))).map ((field) => ("(" 
                + (field.table?field.table:table) + "." + (field.fieldName?field.fieldName:field.id) 
                + " LIKE '%" + searchScape + "%')")).join(" OR ") + ")"    
        }
    };
    //let orderBy = columns.findIndex(column => column.order !== null && column.order !== undefined);
    //let orderDirection = "";
    if (orderBy === -1 ) {
        orderBy = "id"
        orderDirection = descending ? "desc" : "";
    }    

    // El select es sobre las columnas visibles en la vista 
    return "SELECT " + columns.filter(field => field.id !== "checkbox").map((field) => 
        (`${field.table?field.table:table}.${field.fieldName?field.fieldName:field.id} as ${field.id}`)).join(",") 
        + fixedFields 
        + " FROM " + table + leftJoin + where 
        + " ORDER BY " + orderBy + " " + orderDirection + " LIMIT " + offset + ", " + (limitPlus===0 ? 1 : limitPlus);    
}
