/**
 * Subselector para las opciones del perfil de analista
 */
import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Notifications } from "../components/notifications/Notifications";
import { UserForm } from "../components/users/UserForm";
import { ClientForm } from "../components/clients/ClientForm";
import { UserContext } from "../contexts/UserContext";
import { Clients } from "../components/clients/Clients";
import { Samples } from "../components/samples/Samples";
import { ResultForm } from "../components/samples/ResultForm";
import { DashboardLaboratory } from "../components/laboratory/DashboardLaboratory";
import { WorksheetForm } from "../components/worksheets/WorksheetForm";

export function SwitchLaboratory({ setNumNotif }) {
    const { user } = useContext(UserContext);

    return (
        <Switch>
            <Route path="/laboratorio/muestrassaliva" exact>
                <Samples pathBack="/laboratorio" type="Saliva" />
            </Route>
            <Route path="/laboratorio/muestrasheces" exact>
                <Samples pathBack="/laboratorio" type="Heces" />
            </Route>
            <Route path="/laboratorio/muestras/:id" exact>
                <ResultForm pathBack="/laboratorio" />
            </Route>            

            <Route path="/laboratorio/ordenes/:listId" exact>
                <WorksheetForm pathBack="/laboratorio" />
            </Route>

            <Route path="/laboratorio/clientes" exact>
                { (user ? user.client_id : "nothing") === ""  
                    ? <Clients pathBack="/laboratorio" />
                    : <Redirect to ="/" /> 
                }                
            </Route>
            <Route path="/laboratorio/clientes/:id" exact>
                { (user ? user.client_id : "nothing") === ""  
                    ? <ClientForm pathBack="/laboratorio" /> 
                    : <Redirect to ="/" /> 
                }                
            </Route>             
            <Route path="/laboratorio/nuevocliente/" exact>
                <ClientForm pathBack="/laboratorio" isNew />
            </Route>
                            
            <Route path="/laboratorio/notificaciones" exact>                    
                <Notifications setNumNotif={setNumNotif} />                  
            </Route>
            <Route path="/laboratorio/micuenta/:id" exact>
                <UserForm />
            </Route>
            <Route path="/laboratorio" exact>
                <DashboardLaboratory />                           
            </Route>
        </Switch>            
    )
}
