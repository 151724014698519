/**
 * Icono de coincidencia para el avatar del item de la lista
 */
import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as fecesIcon } from '../../assets/blot3.svg';
import { ReactComponent as dnaIcon } from '../../assets/dna.svg';
import { viewBox } from "../../config";

export function MatchIcon({ selector }) {
    return (
        <SvgIcon component={
            selector === "Heces" ? fecesIcon : dnaIcon
        } viewBox={viewBox} />
    )
}