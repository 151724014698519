/**
 * Campo de texto de sólo lectura
 */
import React from "react";
import { Link, makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles ((theme) => ({
    container: {
        width: "100%",
        marginBottom: theme.spacing(2),
    }, 
    titleContainer: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[100],
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[900],
        },
    },
    title: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
    },
    text: {
        minHeight: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
    }
}));

export function ReadTextField({ label, value, phone, email }) {
    const classes = useStyles();

    return (
        <Paper className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant="subtitle1" color="inherit" className={classes.title}>{label}</Typography>
            </div>
            <Typography variant="subtitle2" color="inherit" className={classes.text}>
                {phone 
                ? <Link href={"Tel:+34"+value}>{value}</Link> 
                : email 
                    ? <Link href={"mailto:"+value}>{value}</Link>
                    : value}
            </Typography>                       
        </Paper>
    )
}
