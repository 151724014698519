/**
 * Diseño de marcador para el mapa
 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as MarkerIconDetection } from '../../assets/location_detection.svg';
import { ReactComponent as MarkerIconSample } from '../../assets/location_sample.svg';
import { ReactComponent as MarkerIconMatched } from '../../assets/location_sample_matched.svg';
import { ReactComponent as MarkerIconMismatched } from '../../assets/location_sample_mismatched.svg';
import { ReactComponent as MarkerIconRepeatOffender } from '../../assets/location_sample_repeatoffender.svg';
import { viewBox } from "../../config";

const useStyles = makeStyles (() => ({
    container: {
        position: "absolute",
        left: "-12.5px",
        top: "-25px",
    }
}));

export function MapMarker({ id, source, handleMarkerClick }) {
    const classes = useStyles();

    const handleClick = () => {
        handleMarkerClick && handleMarkerClick(id);
    }

    return (
        <div className={classes.container} onClick={handleClick}>
            <SvgIcon 
                component={
                    source==="Detección" ? MarkerIconDetection : 
                    source==="No analizada" ? MarkerIconSample : 
                    source==="Coincidente" ? MarkerIconMatched : 
                    source==="No coincidente" ? MarkerIconMismatched: 
                    source==="Reincidente" ? MarkerIconRepeatOffender : MarkerIconSample
                } 
                viewBox={viewBox} 
            />
        </div>
    )
}
