/**
 * Componente de autenticación de usuarios con recuperación de contraseña
 */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Loading } from "../Loading";
import { fetchApi, jsonParseMysql } from "../../hooks/DataFetch";
import { UserContext } from "../../contexts/UserContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { SwitchLogin } from "../../routes/SwitchLogin";
import { useIsMounted } from "../../hooks/IsMounted";

const useStyles = makeStyles((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        position: 'absolute',
        top:'0',
        bottom: '0',
        width: '100%',
        userSelect: "none",
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: theme.spacing(9),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },        
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    buttonFixed: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
        width: theme.spacing(14),
    },
    alert: {
        color: 'error',
    },
    font: {
        fontSize: 'smaller',            
    },
    font2: {
        display: "block",
        marginTop: theme.spacing(1),
        fontSize: 'smaller',
    },
}));

export function Login() {
    const { setUser } = useContext(UserContext);
    const { setSettings } = useContext(SettingsContext);
    const classes = useStyles();    
    const history = useHistory();    
    const [userChanged, setUserChanged] = useState(null);
    const [passChanged, setPassChanged] = useState(false);        
    const [isLoading, setIsLoading] = useState(true);      
    const isMounted = useIsMounted();

    useEffect (() => {
        //Carga las preferencias
        async function fetchLoad() {
            const response = await fetchApi({
                query: "SELECT is_remember, is_strong, is_recover FROM setting WHERE id=1"
            });
            if (response.data) {
                const dataConvert =  jsonParseMysql(response.data);            
                if (isMounted) setSettings(dataConvert[0]);
                // Fuerza a borrar las credencias guardadas cuando consigue averiguar la configuración
                // Así, en próximas sesiones el usario no podrá acceder con la información recordada
                if (!dataConvert[0].is_remember) localStorage.removeItem('credentials');    
            }
        }

        fetchLoad();
    },[setSettings, isMounted])    

    useEffect (() => {
        // Recupera el usuario y la contraseña del almacenamiento interno
        async function fetchLogin() {
            // Se requiere una función asíncrona 
            if (isMounted) setIsLoading(true);
            const credentials = JSON.parse(localStorage.getItem('credentials'));
            if (credentials) {  
                credentials.action = "login";                  
                const response = await fetchApi(credentials);
                if (response.data) {
                    if (isMounted) setUser(response.data);
                    history.replace("/"+response.data.profile);
                }
            }
            if (isMounted) setIsLoading(false);
        }

        fetchLogin();
    },[setUser, history, isMounted])

    if (isLoading) {
        return <Loading />;  
    } else {
        return(
            <SwitchLogin 
                classes={classes} 
                userChanged={userChanged} 
                setUserChanged={setUserChanged} 
                passChanged={passChanged} 
                setPassChanged={setPassChanged} 
            />            
        )
    }
}