/**
 * Componente de carga que muestra un círculo de progreso
 */
import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export function Loading({ minHeight }) {
    const useStyles = makeStyles(() => ({
        gridCenter: {
            minHeight: minHeight ? minHeight+'vh' : '85vh'
        }
    }))    
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.gridCenter}
        >
            <CircularProgress color="primary"/>
        </Grid>
    )
}
