/**
 * Pestaña de informe 
 */
import React, { useContext, useRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import { Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useReactToPrint } from "react-to-print";
import { Report } from "../reports/Report";
import { indexAnalyzed, indexIssued, sampleStatus } from "../../config";

const useStyles = makeStyles ((theme) => ({
    container: {
        marginLeft: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    paper: {
        width: "21cm",
        height: "29,7cm",
    }
}));

export function ReportTab({ data, setData, matching, setIsModified, setIsIssue, imageAnimal }) {
    const classes = useStyles();
    const { user } = useContext(UserContext);    
    const reportRef = useRef();

    const handleIssue = () => {
        const newData = [{
            ...data[0],
            status: sampleStatus[indexIssued].label, 
        }];
        setData(newData);
        setIsModified(true);
        setIsIssue(true);
    }

    const handleDelete = () => {
        const newData = [{
            ...data[0],
            status: sampleStatus[indexAnalyzed].label,
        }];
        setData(newData);
        setIsModified(true)    
    }

    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
    });

    return (        
        <div className={classes.container}>
            {user.profile === "laboratorio" && data[0].status !== sampleStatus[indexIssued].label 
            ?<Button 
                className={classes.button} 
                color="primary" 
                variant="outlined" 
                onClick={handleIssue}
            >                
            Emitir informe
            </Button> : ""}
            {user.profile === "laboratorio" && data[0].status === sampleStatus[indexIssued].label
            ? <Button 
                className={classes.button} 
                color="secondary" 
                variant="outlined" 
                onClick={handleDelete}
            >                
            Eliminar informe
            </Button> : ""} 
            {data[0].status === sampleStatus[indexIssued].label
            ? <Button 
                className={classes.button} 
                color="primary" 
                variant="outlined" 
                onClick={handlePrint}
            >                
            Imprimir informe
            </Button> : ""}
            {data[0].status === sampleStatus[indexIssued].label
            ? <Paper className={classes.paper}>
                <Report 
                    ref={reportRef} 
                    data={data} 
                    matching={matching} 
                    imageAnimal={imageAnimal}
                />
            </Paper> : ""}
        </div>
    )
}
