/**
 * Botonera inferior de selección múltple con botones seleccionar todo, eliminar y otros
 */
import React, { Fragment, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import { drawerWidth, buttonGroup, buttonGroupXs } from "../../config";
import { DeleteDialog } from "./DeleteDialog";

const useStyles = makeStyles ((theme) => ({
    bottomNavigation: {
        display: "flex",
        position: "fixed",
        bottom: "0", 
        alignItems: "center",        
        width: "100%",
        justifyContent: "center",
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            justifyContent: "left",
        },
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            alignItems: "start",        
            height: buttonGroupXs,
        },
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: theme.palette.grey[50],
        },
        ['@media (prefers-color-scheme: dark)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: alpha(theme.palette.primary.main, 0.1)
        },
        zIndex: 5,
    },
    buttonGroup: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(0),
            marginTop: theme.spacing(1),
        }
    },
    buttonNav: {
        height: theme.spacing(5),
        margin: theme.spacing(0.5),
        width: theme.spacing(15),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(0.2),
            width: theme.spacing(12),                
        }
    },
}));

export const SelectGroupButton = ({ 
        handleSelectAllClick,
        handleFunction1Click, 
        handleFunction2Click, 
        textFunction1,
        textFunction2,
        textDeleteQuestion,
        textDeleteDescription,
        filter,
        isUpdating1,
        isUpdating2
    }) => {

    const classes = useStyles();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    const handleDeleteClick = () => {
        if (textDeleteQuestion && textDeleteQuestion !== "" && filter!=="deshabilitada" && filter!=="deshabilitado") {
            setOpenDeleteDialog(true)
        } else {
            handleFunction1Click();
        };
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        handleFunction1Click(); 
    }
    
    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    return (
        <Fragment>
            <div className={classes.bottomNavigation}>
                <div className={classes.buttonGroup}>
                    <Button
                        className={classes.buttonNav} 
                        color="primary" 
                        variant="contained" 
                        onClick={handleSelectAllClick}
                    >
                        Sel.todo
                    </Button>                
                    { textFunction1 && textFunction1 !== "" && handleFunction1Click ?
                        <Button
                            className={classes.buttonNav} 
                            color="secondary" 
                            variant="contained" 
                            onClick={handleDeleteClick}
                        >
                            {isUpdating1
                                ?<CircularProgress color="inherit" size={16}/>
                                :filter==="deshabilitada"||filter==="deshabilitado"?"Habilitar"
                                :filter==="archivada"&&textFunction1==="Archivar"?"Desarchiv.":textFunction1}
                        </Button>
                    : ""}
                    { textFunction2 && textFunction2 !== "" && handleFunction2Click ?
                        <Button
                            className={classes.buttonNav} 
                            color="primary" 
                            variant="contained" 
                            onClick={handleFunction2Click}
                        >
                            {isUpdating2?<CircularProgress color="inherit" size={16}/>:textFunction2}
                        </Button>
                    : ""}                
                </div>
            </div>
            <DeleteDialog
                open={openDeleteDialog}
                onClickYes={handleYesDelete}
                onClickNo={handleCancelDelete}
                onClickCancel={handleCancelDelete}
                title={textDeleteQuestion}
                description={textDeleteDescription}
            />
        </Fragment>  
    ) 
}
