/**
 * Desplegable de selección 
 */
import React from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";

export const SelectField = ({ list, label, name, value, onChange, disabled, className, error, helperText }) => {    
    return (
        <FormControl 
            fullWidth 
            variant="outlined" 
            className={className} 
            disabled={disabled} 
            error={error}
        >
            <InputLabel id="client">{label}</InputLabel>
            <Select 
                labelId={`label-${name}}`} 
                id={`select-${name}}`} 
                name={name}
                value={value}
                onChange={onChange}
                label={label}
            >
                { list.map((item) => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
            { error ? <FormHelperText>{helperText}</FormHelperText> : ""}
        </FormControl>
    )
}
