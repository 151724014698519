/**
 * Formulario de detale de resultados o detalle de muestra para el laboratorio con pestañas de información adicional
 */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";
import { FormGroup } from "@material-ui/core";
import { ReactComponent as SamplesIcon } from "../../assets/jar.svg";
import { ReactComponent as SalivaIcon } from "../../assets/blot.svg";
import { ReactComponent as FecesIcon } from "../../assets/blot2.svg";
import { ReactComponent as MatchIcon } from "../../assets/blot3.svg";
import { fetchApi, mysqlEscape, jsonParseMysql, mysqlInteger } from "../../hooks/DataFetch";
import { useIsMounted } from "../../hooks/IsMounted";
import { Loading } from "../Loading";
import { FormGroupButton } from "../common/FormGroupButton";
import { FloatAlert } from "../common/FloatAlert";
import { Title } from "../common/Title";
import { SaveDialog } from "../common/SaveDialog";
import { DeleteDialog } from "../common/DeleteDialog";
import { zeroFill } from "../../utils/StringUtils";
import { buttonGroup, buttonGroupXs, indexIssued, prefixFeces, prefixSaliva, sampleStatus } from "../../config";
import { SampleTabs } from "./SampleTabs";
import moment from 'moment';

const useStyles = makeStyles ((theme) => ({
    container: {
        ['@media (prefers-color-scheme: light)']: { // eslint-disable-line no-useless-computed-key
            backgroundColor: 'white'
        },
        height: `calc(100% - ${document.getElementById("appbar").clientHeight}px)`,
        overflowY: "auto",
    },
    form: {
        maxWidth: "1200px",
    },
    bottomSpace: {
        // La altura debe coincidir con FormGroupButton
        height: buttonGroup,
        [theme.breakpoints.down('xs')]: {
            height: buttonGroupXs,
        }        
    },
}));

export function ResultForm({ pathBack, coincidence }) {
    const { user } = useContext(UserContext);    
    const { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    // Se recuerda el filtro del listado para poder volver al mismo listado filtrado
    const filterParam = new URLSearchParams(useLocation().search).get("filter");     

    // Estados de carga de datos
    const [data, setData] = useState([{
        id: "",
        sampled: "",
        sent: "",
        status: "",
        comments_rec: "",
        comments_lab: "",
        authority: "",
        latitude: "",
        longitude: "",
        is_attached: "",
        type: "",
        block_label_id: "",
        label: "",
        sample_id: "",
        report_id: "",
        serial: "",
        date_start: "",
        date_end: "",
        observations: "",
        results: "",        
        is_disabled: "",
        is_archived: "",
        animal_id: "",
        animal_label: "",
        animal_serial: "",
        animal_name: "",
        animal_block_label_id: "",
        animal_type: "",
        animal_chip: "",
        is_animal_dangerous: "",
        animal_birthday: "",
        animal_weight: "",
        animal_breed: "",
        animal_death: "",
        animal_sex: "",
        animal_hair: "",
        animal_pcode: "",
        animal_owner: "",
        animal_city: "",
        animal_province: "",
        animal_address: "",
        animal_phone: "",
        animal_email: "",
        veterinary_name: "",
        report_date: "",                
    }]);
    const [matching, setMatching] = useState([]);
    const [image, setImage] = useState();
    const [imageAnimal, setImageAnimal] = useState();
    const [address, setAddress] = useState() 
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [isIssue, setIsIssue] = useState(false); 

    // Estados de validación de errores
    const [error, setError] = useState({ open: false, status: '' });

    // Estados de diálogos
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const type = data[0] && data[0].type    // Tipo de muestra
    const matchingSel = matching.filter(match => match.selected);   // Coincidencias seleccionadas            

    async function saveForm () {

        async function fetchUpdate(newData) {
            // Actualización de los datos de la muestra actual
            const query = "UPDATE sample SET sampled='" + mysqlEscape(newData.sampled)
                + "', status='" + mysqlEscape(newData.status)
                + "', comments_lab='" + mysqlEscape(newData.comments_lab)
                + "', observations='" + mysqlEscape(newData.observations)
                + "', date_start='" + mysqlEscape(newData.date_start)
                + "', date_end='" + mysqlEscape(newData.date_end)
                + "', results='" + mysqlEscape(newData.results)
                + "', sample_id=" + mysqlInteger(newData.sample_id)
                + ", report_id=" + mysqlInteger(newData.report_id)
                + " WHERE id=" + mysqlInteger(data[0].id);
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }
        
        async function fetchUpdateDual(newData) {
            // Actualización de la relación dual con la otra muestra para muestras de saliva
            if (type==="Saliva" && matchingSel[0]) {
                const query = "UPDATE sample SET sample_id=" + mysqlInteger(newData.id)
                    + " WHERE id=" + mysqlInteger(matchingSel[0].id);                    
                const response = await fetchApi({
                    query: query,
                    lastId: true
                });
                if (response.status !== "200") {
                    const errorRead = { open: true, status: response.status };
                    if (isMounted) setError(errorRead);
                    return false;
                } else {
                    return true;
                }
            } else {
                // Para muestras de heces el vínculo se establece desde la propia muestra de heces
                return true;
            }
        } 

        async function fetchInsertReport() {
            // Inserta un número de informe y devuelve el autoincremento generado
            const query = "INSERT INTO report (date) VALUES (NOW())";                
            const response = await fetchApi({
                query: query,
                lastId: true
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {                
                return parseInt(JSON.parse(response.data)[0].lastId) || 0;                
            }
        }        
    
        // Crea una copia de los datos
        let lastId = 0;
        let newData = {...data[0]};
        let verificationError = false;

        // Graba los cambios si no hay errores de verificación 
        if (!verificationError) {
            if (isIssue) {
                // Se ha emitido el informe
                lastId = await fetchInsertReport();
                newData.report_id = lastId;
            }
            if (await fetchUpdate(newData) && await fetchUpdateDual(newData)) {
                // Reinicia estados y valores
                if (isMounted) {
                    setIsModified(false);                
                    setIsSaving(false);
                }
                return lastId ? lastId : true;
            }
        }
        if (isMounted) setIsSaving(false);
        return false; 
    }    

    async function saveAndExit() {
        // Función asíncrona para guardar y salir que evitar la salida en caso de error
        if (await saveForm()) {
            exit();
        }
    }

    async function archiveAndExit() {
        // Función asíncrona para archivar el elemento
        if (await archiveForm()) {
            exit();
        }
    }

    async function archiveForm() {

        async function fetchUpdate() {
            const query = "UPDATE sample SET is_archived = 1 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        if (await fetchUpdate()) {
            // Archivo correcto
            return true                
        } else {
            // Error de API
            return false
        }        
    }

    async function unarchiveForm() {

        async function fetchUpdate() {
            const query = "UPDATE sample SET is_archived = 0 WHERE id=" + mysqlInteger(id);
            const response = await fetchApi({
                query: query
            });
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }

        if (await fetchUpdate()) {
            const newData = [{
                ...data[0],
                is_archived: false,
            }];
            setData(newData);
        }       
    }    

    function exit() {
        if (user.profile==="supervisor") {
            if (coincidence) {
                if (filterParam) {
                    history.push (`${pathBack}/coincidencias?filter=${filterParam}`);
                } else {
                    history.push (`${pathBack}/coincidencias`);
                }        
            } else {
                if (filterParam) {
                    history.push (`${pathBack}/muestrasheces?filter=${filterParam}`);
                } else {
                    history.push (`${pathBack}/muestrasheces`);
                }
            }
        } else {
            if (filterParam) {
                if (type === "Heces") {
                    history.push (`${pathBack}/muestrasheces?filter=${filterParam}`);
                } else {
                    history.push (`${pathBack}/muestrassaliva?filter=${filterParam}`);
                }
            } else {
                if (type === "Heces") {
                    history.push (`${pathBack}/muestrasheces`);
                } else {
                    history.push (`${pathBack}/muestrassaliva`);
                }
            }
        }
    }
    
    useEffect (() => {    

        async function fetchLoad() {

            if (isMounted) setIsLoading(true);            
            const response = await fetchApi({ 
                // Los campos boolean deben empezar por is_ y esto afecta a la nomenclatura con campos de otras tablas
                query: "SELECT sample.id as id, sample.sampled as sampled, " 
                    + "sample.sent as sent, sample.status as status, sample.comments_rec as comments_rec, " 
                    + "sample.comments_lab as comments_lab, sample.authority as authority, sample.latitude as latitude, " 
                    + "sample.longitude as longitude, sample.is_attached as is_attached, sample.type as type, " 
                    + "sample.block_label_id as block_label_id, sample.label as label, sample.sample_id as sample_id, "
                    + "sample.report_id as report_id, client.serial as serial, client.name as client_name, "
                    + "client.address as client_address, client.pcode as client_pcode, client.city as client_city," 
                    + "sample.date_start as date_start, sample.date_end as date_end, sample.observations as observations, "
                    + "sample.results as results, sample.is_disabled as is_disabled, sample.is_archived as is_archived, " 
                    + "sample.animal_id as animal_id, animal.label as animal_label, animal.name as animal_name, " 
                    + "animal.block_label_id as animal_block_label_id, animal.type as animal_type, animal.chip as animal_chip, "
                    + "animal.is_dangerous as is_animal_dangerous, animal.birthday as animal_birthday, animal.weight as animal_weight, "
                    + "animal.breed as animal_breed, animal.death as animal_death, animal.sex as animal_sex, animal.hair as animal_hair, "
                    + "animal.pcode as animal_pcode, animal.owner as animal_owner, animal.city as animal_city, " 
                    + "animal.province as animal_province, animal.address as animal_address, animal.phone as animal_phone, "
                    + "animal.email as animal_email, veterinary.name as veterinary_name, report.date as report_date, "
                    + "clientAnimal.serial as animal_serial "
                    + "FROM sample " 
                    + "LEFT JOIN animal ON (sample.animal_id = animal.id) " 
                    + "LEFT JOIN user ON (sample.user_id = user.id) "
                    + "LEFT JOIN user as veterinary ON (animal.user_id = veterinary.id) "
                    + "LEFT JOIN client ON (user.client_id = client.id) "
                    + "LEFT JOIN client as clientAnimal ON (animal.client_id = clientAnimal.id) "
                    + "LEFT JOIN report ON (sample.report_id = report.id) "
                    + "WHERE sample.id=" + mysqlInteger(id) 
            });       
                  
            if (response.status !== "200") { setError({ open: true, status: response.status }) };
            if (response.data) {
                const readData = jsonParseMysql(response.data);
                readData[0].animal_weight = readData[0].animal_weight ? parseFloat(readData[0].animal_weight).toLocaleString() : "";
                if (isMounted) setData(readData);    
                // Lee las muestras coincidentes 
                const where = readData[0].type==="Heces" 
                    ? "WHERE sample.id=" + mysqlInteger(readData[0].sample_id)             
                    : "WHERE sample.sample_id=" + mysqlInteger(id);
                const responseMatch = await fetchApi({ 
                    query: "SELECT sample.id as id, client.serial as serial, sample.block_label_id as block_label_id, sample.label as label, "
                        + "sample.results as results, sample.type as type, sample.sampled as sampled, sample.sent as sent, sample.authority as authority, "
                        + "sample.latitude as latitude, sample.longitude as longitude, sample.comments_rec as comments_rec, animal.type as animal_type, "
                        + "animal.chip as animal_chip, animal.label as animal_label, clientAnimal.serial as animal_serial, " 
                        + "animal.block_label_id as animal_block_label_id "
                        + "FROM sample "
                        + "LEFT JOIN user ON (sample.user_id = user.id) "
                        + "LEFT JOIN client ON (user.client_id = client.id) "
                        + "LEFT JOIN animal ON (sample.animal_id = animal.id) "
                        + "LEFT JOIN client as clientAnimal ON (animal.client_id = clientAnimal.id) "
                        + where 
                });
                if (responseMatch.status !== "200") { setError({ open: true, status: responseMatch.status }) };
                if (responseMatch.data) {
                    const readMatching = jsonParseMysql(responseMatch.data); 
                    if (isMounted) setMatching(readMatching.map (match => ({...match, selected: true})));
                };                    
            };
            if (isMounted) setIsLoading(false);
        }                   

        let isMounted = true;
        id && fetchLoad();
        return () => { isMounted = false };
    },[id, user])    

    useEffect (() => {    

        async function fetchLoadImage() {
            // La imagen se lee por separado para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT image FROM sample WHERE id=" + mysqlInteger(id)
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted) setImage(jsonData[0].image);
            };
        } 

        async function fetchLoadImageAnimal() {
            // La imagen se lee por separado para optimizar la vista
            const response = await fetchApi({ 
                query: "SELECT animal.image as animal_image " 
                    + "FROM sample " 
                    + "LEFT JOIN animal ON (sample.animal_id = animal.id) " 
                    + "WHERE sample.id=" + mysqlInteger(
                        type==="Saliva" ? id : matchingSel[0] && matchingSel[0].id
                    )
            });
            if (response.status !== "200") { 
                if (isMounted) setError({ open: true, status: response.status }) 
            };
            if (response.data) {
                let jsonData = jsonParseMysql(response.data);
                if (isMounted && jsonData[0]) setImageAnimal(jsonData[0].animal_image);
            };
        }  

        let isMounted = true;
        id && fetchLoadImage();  
        id && fetchLoadImageAnimal();  
        return () => { isMounted = false };
    }, [id, type, matchingSel])

    async function sendAdviseReport (clientId, isCoincidence) {
        const responseSelect = await fetchApi({ 
            query: "SELECT id FROM user WHERE profile='supervisor' AND client_id=" + mysqlInteger(clientId)
        });
        if (responseSelect.status !== "200") { 
            if (isMounted) setError({ open: true, status: responseSelect.status }) 
        };
        if (responseSelect.data) {
            let jsonData = jsonParseMysql(responseSelect.data);
            for (let value of jsonData) {
                const text = isCoincidence 
                ? "Se ha detectado una coincidencia para la muestra " + sampleLabel + " y se ha emitido el informe correspondiente"
                : "Se ha emitido un nuevo informe para la muestra " + sampleLabel;
                const query = "INSERT INTO advise (type, text, is_notified, is_viewed, date, user_id) "
                    +"VALUES ('sample', '" + text + "', 0, 0, NOW(), '" + mysqlEscape(value.id) + "')";
                const responseInsert = await fetchApi({
                    query: query,
                    lastId: true
                });
                if (responseInsert.status !== "200") {
                    const errorRead = { open: true, status: responseInsert.status };
                    if (isMounted) setError(errorRead);
                }
            }
        };
    } 

    async function handleApply () {
        // Botón aplicar
        const lastId = await saveForm();  
        if (isIssue) { 
            const newData = [{
                ...data[0],
                status: sampleStatus[indexIssued].label, 
                report_id: lastId,
                report_date: moment(new Date()).format("YYYY-MM-DD"),
            }];
            setData(newData);  
            sendAdviseReport(data[0].client_id, data[0].sample_id > 0); // Sólo se notifican coincidencias de heces
        }
    }

    const handleExit = () => {
        // Botón salir
        if (isModified && !isSaving) {
            setOpenSaveDialog(true);
        } else {
            setOpenSaveDialog(false);
            exit();
        }
    }

    const handleArchive = () => {
        if (data[0] && data[0].is_archived) {
            // Botón desarchivar
            unarchiveForm();
        } else {
            // Botón archivar
            setOpenDeleteDialog(true);
        }
    }     

    const handleYesSave = () => {
        setOpenSaveDialog(false);
        saveAndExit();
    }

    const handleNoSave = () => {
        setOpenSaveDialog(false);
        exit();
    }

    const handleCancelSave = () => {
        setOpenSaveDialog(false);
    }

    const handleYesDelete = () => {
        setOpenDeleteDialog(false);
        archiveAndExit();
    }

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    }

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    }   

    const prefixSample = data[0].type === "Heces" ? prefixFeces : prefixSaliva;
    const sampleLabel = (data[0] && data[0].label !== undefined && data[0].block_label_id > 0) 
    ? `${prefixSample}-${data[0].serial}-${zeroFill(data[0].block_label_id, 5)}-${zeroFill(data[0].label, 3)}` 
    : ""

    if (isLoading) {
        return <Loading />
    } else {    
        return (
            <div className={classes.container}>
                <Title
                    text={sampleLabel} 
                    icon={matchingSel[0] && data[0].status === sampleStatus[indexIssued].label 
                        ? MatchIcon 
                        : type==="Heces" 
                            ? FecesIcon 
                            : type==="Saliva" 
                                ? SalivaIcon 
                                : SamplesIcon} 
                    pathBack={`${pathBack}/${type==="Saliva"?"muestrassaliva":"muestrasheces"}`}
                    disabled={data[0] && data[0].is_disabled}
                    archived={data[0] && data[0].is_archived}
                />    
                <FormGroup className={classes.form}>
                    <SampleTabs
                        data={data}
                        setData={setData}
                        matching={matching}
                        setMatching={setMatching}
                        matchingSel={matchingSel}
                        image={image}
                        imageAnimal={imageAnimal}
                        address={address}
                        setAddress={setAddress}
                        isLoading={isLoading}
                        setIsModified={setIsModified}
                        setIsIssue={setIsIssue}
                        setError={setError}
                    />
                    { user.profile === "laboratorio" ? <>            
                        <div className={classes.bottomSpace}/>
                        <FloatAlert
                            open={error.open} 
                            onClose={handleErrorClose} 
                            text="No hemos podido leer o guardar los cambios. Comprueba tu conexión." 
                            severity="error"
                        />
                    </> : "" }
                </FormGroup> 
                <FormGroupButton
                    disabledApply={isLoading || !isModified}
                    isSaving={isSaving}
                    hideDelete={user.profile!=="supervisor"}
                    handleApplyClick={handleApply} 
                    handleExitClick={handleExit} 
                    handleDeleteClick={user.profile==="supervisor" ? handleArchive : null}
                    textDelete={data[0] && data[0].is_archived ? "Desarchivar" : "Archivar"}                                  
                />
                <SaveDialog
                    open={openSaveDialog}
                    onClickYes={handleYesSave}
                    onClickNo={handleNoSave}
                    onClickCancel={handleCancelSave}
                />
                <DeleteDialog
                    open={openDeleteDialog}
                    onClickYes={handleYesDelete}
                    onClickNo={handleCancelDelete}
                    onClickCancel={handleCancelDelete}
                    title={"¿Estás seguro de que quieres eliminar esta muestra?"}
                    description={
                        "Si continúas este proceso se eliminará la muestra del sistema, " 
                        + "salvo que tuviera información vinculada, en cuyo caso sería deshabilidada."
                    }
                />
            </div>
        )
    }
}