/**
 * Página de listado general
 */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Fab, Tooltip, CircularProgress, useTheme, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { FloatAlert } from "./FloatAlert";
import { SearchTitle } from "./SearchTitle";
import { InfiniteTable } from "./InfiniteTable";
import { InfiniteList } from "./InfiniteList";
import { fetchApi, jsonParseMysql, mysqlEscape, mysqlInteger } from "../../hooks/DataFetch";
import { useWindowDimensions } from "../../hooks/WindowDimensions";
import { limit, titleHeight, drawerWidth, prefixFeces, prefixSaliva, dogMarkers, horseMarkers, sampleStatus, buttonGroup, indexAnalyzed, indexAccepted } from "../../config";
import { useIsMounted } from "../../hooks/IsMounted";
import { csvToArray, spaceFill, zeroFill } from "../../utils/StringUtils";
import moment from "moment";
import { useLongPress } from "../../hooks/LongPress";
import { SelectGroupButton } from "./SelectGroupButton";

const useStyles = makeStyles((theme) => ({
    container: {     
        height: `calc(100% - ${
            document.getElementById("appbar")
            ? document.getElementById("appbar").clientHeight
            : 65
        }px)`,
    },
    button: {
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(0.5),            
        },
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            bottom: theme.spacing(1),
            right: theme.spacing(1),
        }        
    },
    buttonFab: {

    },
    progress: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
}));

export function ListForm({ 
    filterParam, 
    selectParam, 
    pathBack, 
    pathNew, 
    item:Item, 
    icon:Icon, 
    menu:Menu, 
    getQuery, 
    getTitle, 
    actionItemClick, 
    actionItemClose, 
    defaultColumns, 
    colors,
    isImport,
    actionFunction1,
    actionFunction2,
    textFunction1,
    textFunction2,
    textDeleteQuestion,
    textDeleteDescription
}) {
    const [filter, setFilter] = useState(filterParam);
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);    
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating1, setIsUpdating1] = useState(false);
    const [isUpdating2, setIsUpdating2] = useState(false);
    const [error, setError] = useState({ open: false, status: '' });
    const [warning, setWarning] = useState(false);
    const [height, setHeight] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [columns, setColumns] = useState(defaultColumns);
    const [orderBy, setOrderBy] = useState(-1);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [multiselect, setMultiselect] = useState(false);
    const isMounted = useIsMounted();

    const classes = useStyles(); 
    const history = useHistory();
    const refContainer = useRef(null);
    const windowDim = useWindowDimensions();
    const theme = useTheme();   

    async function fetchLoad(startIndex, stopIndex, isAccumulate) {
        if (isMounted) if (!isAccumulate) setIsLoading(true);
        const offset = startIndex;
        const limitPlus = limit + 1;
        const response = await fetchApi({ 
            query: getQuery({ filter, search, limitPlus, offset, columns, orderBy, orderDirection }) 
        }); 
        if (response.status !== "200") { 
            if (isMounted) setError({ open: true, status: response.status }) 
        };
        if (response.data) {
            const loadedData = jsonParseMysql(response.data);
            if (loadedData.length >= (stopIndex - startIndex + 1)) {
                loadedData.pop(); // El último elemento sólo sirve para comprobar si hay página siguiente
                if (isMounted) setHasNextPage(true);
            } else {
                if (isMounted) setHasNextPage(false);
            }
            if (isAccumulate) {
                if (isMounted) setData([...data, ...loadedData])
            } else {
                if (isMounted) setData(loadedData);
            }
        };
        if (isMounted) if (!isAccumulate) setIsLoading(false);
    }

    const useLoadMoreItems = ({ data, setData }) =>
        useCallback(
            (startIndex, stopIndex) => {
                fetchLoad(startIndex, stopIndex, true);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [data, setData],
        );
    const loadMoreItems = useLoadMoreItems({ data, setData });

    useEffect (() => {
        fetchLoad(0, limit, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filter, search, getQuery, isMounted, orderBy, orderDirection])

    useEffect (() => {
        setData([]);
        setFilter(filterParam);
        setSearch("");
    }, [getQuery, filterParam])

    useEffect (() => {        
        setHeight(refContainer.current.clientHeight-titleHeight - 1);
    }, [windowDim.height, windowDim.width])

    useEffect (() => { 
        function AdjustColumns () {
            // Ajusta las columnas eliminando las que no caben en pantalla o eliminando el checkbox si procede
            const copyColumns = JSON.parse(JSON.stringify(defaultColumns.filter(
                column => column.id !== "checkbox" || (column.id === "checkbox" && multiselect)
            )));
            let newColumns = [];
            let maxWidth = windowDim.width;
            if (windowDim.width >= theme.breakpoints.values.md) maxWidth -= drawerWidth;
            let widthAcum = 0;            
            copyColumns.forEach(element => {
                if (element.width) widthAcum += element.width;
                if (widthAcum <= maxWidth) newColumns.push(element);
            });
            if (newColumns.length > 0) delete newColumns[newColumns.length-1].width;
            return newColumns;
        }
        
        if (columns) {
            const newColumns = AdjustColumns();
            setColumns(newColumns);    
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowDim.width, multiselect])

    const onLongPress = () => {
        if (textFunction1 && textFunction1 !== "") {
            setMultiselect(!multiselect);
            setOrderBy(-1);
        }
    }
    
    const handleItemClick = (e) => {
        const id = e.currentTarget.getAttribute("name");
        if (multiselect) {    
            const newData = [...data];
            const index = newData.findIndex(element => element.id === id);
            newData[index].checkbox = !newData[index].checkbox;
            setData([...newData]);
        } else {
            actionItemClick ({ id, pathBack, filter, history, data, setData, setError });
        }
    }

    const longPressEvent = useLongPress(onLongPress, handleItemClick, {shouldPreventDefault: true, delay: 300,});

    const handleSelectAllClick = () => {
        if (multiselect) {    
            const newData = [...data];
            for (let i=0; i<newData.length; i++) {
                newData[i].checkbox = true;
            }
            setData([...newData]);
        } 
    }

    async function handleFunction1Click () {

        setIsUpdating1(true);
        const result = await actionFunction1(data);
        setData(result.data);
        setWarning(result.warning);
        setIsUpdating1(false);
        setMultiselect(false);
        setOrderBy(-1);
    }    

    async function handleFunction2Click () {

        setIsUpdating2(true);
        const newData = await actionFunction2({ pathBack, filter, history, data });
        setData(newData);
        setIsUpdating2(false);
        setMultiselect(false);
        setOrderBy(-1);
    }

    const handleItemClose = (e) => {
        const id = e.currentTarget.getAttribute("name");
        actionItemClose ({ id, data, setData, setError });
    }

    const handleAddClick = () =>  {
        if (filter) {
            history.push(`${pathBack}${pathNew}?filter=${filter}`);
        } else {
            history.push(`${pathBack}${pathNew}`);
        }        
    }

    const handleErrorClose = () => {
        setError({ open: false, status: '' });
    }

    const handleWarningClose = () => {
        setWarning(false);
    }

    const handleRequestSort = (event, property) => {
        const posOrder = property;
        if (posOrder === orderBy) {
            if (orderDirection === 'asc') {
                setOrderDirection('desc');
            } else {
                setOrderDirection('asc');
            }
        } else {
            setOrderDirection('desc');    
        }
        setOrderBy(posOrder);
    }

    const handleImport = (target) => {

        async function fetchClearImported() {
            // Actualiza el estado importado para todas las muestras que lo tenían activado
            const query = "UPDATE sample SET is_imported = 0 WHERE is_imported = 1";
            const response = await fetchApi({query: query});
            if (response.status !== "200") {
                const errorRead = { open: true, status: response.status };
                if (isMounted) setError(errorRead);
                return false;
            } else {
                return true;
            }
        }       

        async function fetchUpdate(newData) {
            // Actualiza los resultados y el estado importado para todas las muestras
            const selectInner = newData.map (sample => {
                return `SELECT ${mysqlInteger(sample.id)} AS id, '${mysqlEscape(sample.results)}' AS new_results, 1 AS new_is_imported`;
            }).join (" UNION ALL ");
            if (selectInner.trim() !== "") { 
                const query = `UPDATE sample s JOIN (${selectInner}) v ON s.id = v.id ` 
                    + `SET results = new_results, is_imported = new_is_imported, `
                    + `status = '${sampleStatus[indexAnalyzed].label}', date_end = NOW() `
                    + `WHERE status = '${sampleStatus[indexAccepted].label}' `
                    + `OR status = '${sampleStatus[indexAnalyzed].label}'`;
                    const response = await fetchApi({query: query});
                if (response.status !== "200") {
                    const errorRead = { open: true, status: response.status };
                    if (isMounted) setError(errorRead);
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        }        

        let fileReader = new FileReader();
        if (target.files) {
            if (target.files.length !== 0) {
                let file = target.files[0];
                fileReader.onload = function (event) {
                    const arrCsvImported = csvToArray(event.target.result);
                    const newData = [...data];                    
                    fetchClearImported();                    
                    for (let i=0; i<newData.length; i++) {
                        newData[i].is_imported = 0;
                        const prefixSample = newData[i].type === "Heces" ? prefixFeces : prefixSaliva
                        const sampleLabel = `${prefixSample}-${newData[i].serial}-${zeroFill(newData[i].sample_block_label_id,5)}-${zeroFill(newData[i].label,3)}`;
                        if (arrCsvImported.find(row => row.SampleName === sampleLabel)) {
                            if (newData[i].status === sampleStatus[indexAccepted].label || newData[i].status === sampleStatus[indexAnalyzed].label) {
                                const markers = newData[i].animal_type==="Caballo" ? horseMarkers : dogMarkers
                                const resultsImported = markers.map(markerLabel => {
                                    const rowSelect = arrCsvImported.find(row => row.Marker === markerLabel && row.SampleName === sampleLabel);
                                    return rowSelect ? spaceFill(rowSelect.Allele1,3) + spaceFill(rowSelect.Allele2,3) : "      ";
                                }).join("");
                                newData[i].results = resultsImported;
                                newData[i].is_imported = 1;
                                newData[i].status = sampleStatus[indexAnalyzed].label;
                                newData[i].date_end = moment(new Date()).format("YYYY-MM-DD");
                            }
                        }
                    }
                    setData(newData);  
                    fetchUpdate(newData.filter(sample => sample.is_imported === 1));
                }
                fileReader.readAsText(file);
            }
        }
        target.value = "";  // Permite importar el mismo archivo
    };

    return (
        <div ref={refContainer} className={classes.container}>
            <SearchTitle 
                filter={filter} 
                setFilter={setFilter}
                search={search}
                setSearch={setSearch} 
                pathBack={pathBack}
                title={getTitle(filter)}
                selectIcon={Icon} 
                menu={Menu}
            />
            { !isLoading 
                ? columns 
                    ? <InfiniteTable 
                        data={data} 
                        columns={columns}
                        orderBy={orderBy}
                        orderDirection={orderDirection}
                        loadMoreItems={loadMoreItems}
                        hasNextPage={hasNextPage}
                        icon={Icon}
                        height={multiselect?height-buttonGroup:height}
                        colors={colors}
                        longPressEvent={longPressEvent}
                        onRequestSort={handleRequestSort}
                    />
                    : <InfiniteList 
                        data={data}
                        multiselect={multiselect}
                        loadMoreItems={loadMoreItems}
                        hasNextPage={hasNextPage}
                        handleClose={handleItemClose} 
                        item={Item}
                        icon={Icon}
                        height={multiselect?height-buttonGroup:height}
                        longPressEvent={longPressEvent}
                    />
                : <CircularProgress className={classes.progress} color="primary"/> 
            }
            { pathNew && !selectParam
                ? <Tooltip title="Nuevo">
                    <Fab color="secondary" className={classes.fab} aria-label="nuevo" onClick={handleAddClick}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                : "" 
            }
            { isImport && !selectParam
                ? <Tooltip title="Importar">
                    <Fab color="secondary" className={classes.fab} aria-label="importar">
                        <input
                            accept=".csv"
                            id="button-file"
                            type="file"
                            onChange={(e) => handleImport(e.target)}                        
                            hidden
                        />
                        <label htmlFor="button-file">
                            <IconButton className={classes.buttonFab} component="span" color="inherit">
                                <PostAddIcon/>
                            </IconButton>
                        </label>                        
                    </Fab>
                </Tooltip>
                : "" 
            }
            { multiselect
                ?<SelectGroupButton 
                    handleSelectAllClick={handleSelectAllClick}
                    handleFunction1Click={handleFunction1Click}
                    handleFunction2Click={handleFunction2Click}
                    textFunction1={textFunction1}
                    textFunction2={textFunction2}
                    textDeleteQuestion={textDeleteQuestion}
                    textDeleteDescription={textDeleteDescription}
                    filter={filter}
                    isUpdating1={isUpdating1}
                    isUpdating2={isUpdating2}
                />
                :"" 
            }                       
            <FloatAlert 
                open={error.open} 
                onClose={handleErrorClose} 
                text={"No hemos podido leer o actualizar. Por favor, comprueba tu conexión."} 
                severity={"error"}
            />
            <FloatAlert 
                open={warning} 
                onClose={handleWarningClose} 
                text={"No ha sido posible eliminar o modificar alguno de los elementos marcados por falta de privilegios"} 
                severity={"warning"}
                />
        </div>
    )
}
